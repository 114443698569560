import { buildUrl, buildRequestHeaders } from "util/api_util";

export const getTicketAttributes = (params) => {
  const headers = buildRequestHeaders();
  return fetch(buildUrl("intercom_tickets/ticket_attributes", params), {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => response.json());
};

export const createTicket = (payload) => {
  const headers = buildRequestHeaders();
  return fetch(buildUrl("intercom_tickets/create"), {
    method: "POST",
    credentials: "include",
    headers,
    body: typeof payload !== "string" ? JSON.stringify(payload) : payload,
  }).then((response) => response.json());
};
