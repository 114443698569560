import React, { Component } from "react";
import moment from "moment";

import BarChart from "components/reusable/charts/bar_chart";
import StackedBarChart from "components/reusable/charts/grouped_stacked_bar_chart";
import LineChart from "components/reusable/charts/line_chart";
import PieChart from "components/reusable/charts/pie_chart";
import CalendarIcon from "resources/img/icons/calendar.svg";
import ChartIcon from "resources/img/icons/line-chart.svg";
import {
  fetchApprovedMetrics,
  fetchDashboardMetrics,
  fetchIDTypesByCountry,
  fetchIDValidationMetrics,
  fetchOutcomeMetrics,
} from "util/api_util";
import {
  getUserPartnerID,
  getUserType,
  jobTypeLookup,
  renderJobTypeOptions,
} from "util/selectors";
// TODO: all these need to be renamed to make more sense

class Metrics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      duration: "1_week",
      loading: true,
      partnerIDs: [],
      partner_id: undefined,
      job_type: undefined,
      metric: "total",
      approved: undefined,
      dailyTotals: undefined,
      outcomes: undefined,
      userType: getUserType(),
      metricTypeExplanation: null,
      countrySelected: false,
      country: null,
      options: null,
    };

    this.getData = this.getData.bind(this);
    this.onInput = this.onInput.bind(this);
    this.changeFilterState = this.changeFilterState.bind(this);
    this.onLegendClick = this.onLegendClick.bind(this);
  }

  componentDidMount() {
    this.getData();
    this.setMetricTypeHoverText(this.state.metric);
  }

  getData() {
    const options = {};
    if (this.state.duration !== "all_time") {
      const [duration, period] = this.state.duration.split("_");
      const start = moment()
        .startOf("day")
        .utc()
        .subtract(parseInt(duration, 10), period);
      options.start = start;
    }
    if (this.state.partner_id) {
      options.partner_id = this.state.partner_id;
    }
    if (this.state.job_type) {
      options.job_type = this.state.job_type;
    }
    if (this.state.userType === "partner") {
      options.partner_id = getUserPartnerID();
    }
    this.setState({ options });

    if (this.state.metric === "total") {
      fetchDashboardMetrics(options).then((data) => {
        this.setState({
          loading: false,
          dailyTotals: data,
          partnerIDs: data.partner_ids,
        });
      });
    } else if (this.state.metric === "approved") {
      fetchApprovedMetrics(options).then((data) => {
        this.setState({
          loading: false,
          approved: data.points,
          partnerIDs: data.partner_ids,
        });
      });
    } else if (this.state.metric === "outcomes") {
      fetchOutcomeMetrics(options).then((data) => {
        this.setState({
          loading: false,
          outcomes: data,
          partnerIDs: data.partner_ids,
        });
      });
    } else if (this.state.metric === "idApi") {
      fetchIDValidationMetrics(options).then((data) => {
        this.setState({
          loading: false,
          idApi: data,
          countrySelected: null,
          partnerIDs: data.partner_ids,
        });
      });
    }
  }

  onLegendClick(item) {
    const { options } = this.state;
    options.country = item;

    fetchIDTypesByCountry(options).then((data) => {
      this.setState({
        country: item,
        loading: false,
        perCountryData: data.points,
        countrySelected: true,
      });
    });
  }

  componentDidUpdate(props) {
    if (props.displayEnvironment !== this.props.displayEnvironment) {
      this.getData();
    }
  }

  onInput(e) {
    this.setState(
      {
        [e.target.name]: e.target.value,
        loading: true,
      },
      () => {
        this.getData();
      },
    );
  }

  changeFilterState(key, value) {
    this.setState(
      {
        [key]: value,
        loading: true,
      },
      () => {
        this.getData();
      },
    );
  }

  renderMetricOptions(metricsOptions) {
    return Object.keys(metricsOptions).map((metric) => (
      <div
        className={
          this.state.metric === metric
            ? "metrics-filter__graph-option active"
            : "metrics-filter__graph-option"
        }
        key={metric}
        onClick={() => this.changeFilterState("metric", metric)}
        onMouseOver={() => this.setMetricTypeHoverText(metric)}
        onMouseOut={() => this.setMetricTypeHoverText(this.state.metric)}
      >
        <div>{metricsOptions[metric]}</div>
      </div>
    ));
  }

  renderDataPeriodOptions(dataPeriods) {
    return Object.keys(dataPeriods).map((key) => (
      <div
        className={
          this.state.duration === key
            ? "metrics-filter__period-option active"
            : "metrics-filter__period-option"
        }
        key={key}
        onClick={() => this.changeFilterState("duration", key)}
      >
        <div>{dataPeriods[key]}</div>
      </div>
    ));
  }

  renderPartnerOptions() {
    return this.state.partnerIDs.map((partnerObj) => (
      <option key={partnerObj.partner_id} value={partnerObj.partner_id}>
        {" "}
        {partnerObj.partner_id} - {partnerObj.company}{" "}
      </option>
    ));
  }

  setMetricTypeHoverText(metric) {
    const explanation = {
      total: "Shows the total number of jobs",
      approved: "Shows the overview outcome of jobs (excluding ID Checks jobs)",
      outcomes: "Shows the outcome of jobs per day (excluding ID Checks jobs)",
      idApi: "Shows a breakdown of ID Checks by date, country and ID Type",
    };

    this.setState({
      metricTypeExplanation: explanation[metric],
    });
  }

  render() {
    if (
      this.state.dailyTotals &&
      Object.keys(this.state.dailyTotals).length === 0
    ) {
      return (
        <div className="loader__container">
          <div className="loader loader--centered" />
        </div>
      );
    }

    let totalJobsChart;
    let outcomesOverviewChart;
    let outcomesChart;
    let idApiByDayChart;
    let idApiByTypeChart;
    const { dailyTotals } = this.state;

    if (
      this.state.metric === "total" &&
      dailyTotals &&
      dailyTotals.points.length > 0
    ) {
      totalJobsChart = (
        <div className={this.state.loading ? "loading" : ""} key="">
          <LineChart
            width={800}
            height={500}
            data={dailyTotals}
            isAdminView={this.state.userType === "admin"}
          />
        </div>
      );
    } else if (this.state.metric === "approved" && this.state.approved) {
      let dataPresent = false;
      this.state.approved.forEach((dataPoint) => {
        if (dataPoint.count > 0) {
          dataPresent = true;
        }
      });
      if (dataPresent) {
        outcomesOverviewChart = (
          <div className={this.state.loading ? "loading" : ""} key="">
            <div className="metrics-chart__subtext">
              Note: Does not include ID Checks jobs
            </div>
            <div className="approved-pie-chart">
              <PieChart
                width={450}
                height={450}
                data={this.state.approved}
                range={["#8FC93A", "#17A3DC", "#FFAD69", "#EE6352"]}
                containerClassName=".approved-pie-chart"
              />
            </div>
          </div>
        );
      }
    } else if (
      this.state.metric === "outcomes" &&
      this.state.outcomes &&
      this.state.outcomes.points.length > 0
    ) {
      let dataPresent = false;
      const stateOutcomes = (i) =>
        [
          "machine_accepted",
          "human_accepted",
          "human_rejected",
          "machine_rejected",
        ].forEach((key) => {
          if (this.state.outcomes.points[i][key] > 0) {
            dataPresent = true;
          }
        });
      for (let i = 0; i < this.state.outcomes.points.length; i += 1) {
        stateOutcomes(i);
        if (dataPresent) break;
      }
      if (dataPresent) {
        outcomesChart = (
          <div className={this.state.loading ? "loading" : ""} key="">
            <div className="metrics-chart__subtext">
              Note: Does not include ID Checks jobs
            </div>
            <div className="metrics-chart-legend__container">
              <BarChart
                width={800}
                height={500}
                data={this.state.outcomes}
                range={["#8FC93A", "#17A3DC", "#FFAD69", "#EE6352"]}
                label={[
                  "Machine Approved",
                  "Human Approved",
                  "Human Rejected",
                  "Machine Rejected",
                ]}
                keys={[
                  "machine_accepted",
                  "human_accepted",
                  "human_rejected",
                  "machine_rejected",
                ]}
              />
              <div className="legend legend--bar-chart" />
            </div>
          </div>
        );
      }
    } else if (
      this.state.metric === "idApi" &&
      this.state.idApi &&
      this.state.idApi.points.length > 0
    ) {
      let dataPresent = false;
      if (this.state.idApi.y_max !== 0 || this.state.idApi.y_min !== 0) {
        dataPresent = true;
      }

      if (dataPresent) {
        idApiByDayChart = (
          <div>
            <div className="id_bar-chart-group">
              <StackedBarChart
                width={800}
                height={500}
                data={this.state.idApi}
                range={["#BB0000", "#E2B903", "#008751", "#002395", "#111912"]}
                label={["Kenya", "Ghana", "Nigeria", "South Africa", "Uganda"]}
                selectedLabel={this.state.countrySelected && this.state.country}
                keys={["KE", "GH", "NG", "ZA", "UG"]}
                legendClick={this.onLegendClick}
              />
            </div>
          </div>
        );
      }

      idApiByTypeChart = (
        <div>
          {dataPresent && (
            <div className="id_bar-chart-group__legend">
              <div className="newsmile-heading-h2">
                ID Checks Breakdown By Country
              </div>
              <div className="legend id_bar-chart-group__keys-container" />
            </div>
          )}
          {this.state.countrySelected && (
            <div className="id_pie-charts">
              {this.state.perCountryData.success.length === 0 &&
                this.state.perCountryData.failure.length === 0 &&
                this.state.perCountryData.failure_user_error.length === 0 &&
                this.state.perCountryData.failure_unavailable.length === 0 && (
                  <div className="metrics__no-info">
                    <img
                      className="icon--x-large"
                      src={CalendarIcon}
                      alt="calendar"
                    />
                    <div className="metrics__no-info-text">
                      There is no data for this country in this time period
                    </div>
                  </div>
                )}

              {this.state.perCountryData.success.length > 0 && (
                <div className="pie-chart-1">
                  <div className="newsmile-heading-h2">
                    Successful Checks (
                    {this.state.perCountryData.percentages.success}
                    %)
                  </div>
                  <PieChart
                    width={200}
                    height={200}
                    data={this.state.perCountryData.success}
                    range={[
                      "#6E2C00",
                      "#FFF176",
                      "#EC7063",
                      "#212F3C",
                      "#1A5276",
                      "#82E0AA",
                      "#BB8FCE",
                      "#8FC93A",
                      "#9C640C",
                      "#A09ABC",
                      "#97CC7E",
                      "#F46C00",
                    ]}
                    containerClassName=".pie-chart-1"
                  />
                </div>
              )}

              {this.state.perCountryData.failure.length > 0 && (
                <div className="pie-chart-2">
                  <div className="newsmile-heading-h2">
                    Failed Checks - Not Found (
                    {this.state.perCountryData.percentages.failure}
                    %)
                  </div>
                  <PieChart
                    width={200}
                    height={200}
                    data={this.state.perCountryData.failure}
                    range={[
                      "#6E2C00",
                      "#FFF176",
                      "#EC7063",
                      "#212F3C",
                      "#1A5276",
                      "#82E0AA",
                      "#BB8FCE",
                      "#8FC93A",
                      "#9C640C",
                      "#A09ABC",
                      "#97CC7E",
                      "#F46C00",
                    ]}
                    containerClassName=".pie-chart-2"
                  />
                </div>
              )}

              {this.state.perCountryData.failure_user_error.length > 0 && (
                <div className="pie-chart-3">
                  <div className="newsmile-heading-h2">
                    Failed Checks - Invalid ID Number Format (
                    {this.state.perCountryData.percentages.failure_user_error}
                    %)
                  </div>
                  <PieChart
                    width={200}
                    height={200}
                    data={this.state.perCountryData.failure_user_error}
                    range={[
                      "#6E2C00",
                      "#FFF176",
                      "#EC7063",
                      "#212F3C",
                      "#1A5276",
                      "#82E0AA",
                      "#BB8FCE",
                      "#8FC93A",
                      "#9C640C",
                      "#A09ABC",
                      "#97CC7E",
                      "#F46C00",
                    ]}
                    containerClassName=".pie-chart-3"
                  />
                </div>
              )}

              {this.state.perCountryData.failure_unavailable.length > 0 && (
                <div className="pie-chart-4">
                  <div className="newsmile-heading-h2">
                    Failed Checks - DB Unavailable (
                    {this.state.perCountryData.percentages.failure_unavailable}
                    %)
                  </div>
                  <PieChart
                    width={200}
                    height={200}
                    data={this.state.perCountryData.failure_unavailable}
                    range={[
                      "#6E2C00",
                      "#FFF176",
                      "#EC7063",
                      "#212F3C",
                      "#1A5276",
                      "#82E0AA",
                      "#BB8FCE",
                      "#8FC93A",
                      "#9C640C",
                      "#A09ABC",
                      "#97CC7E",
                      "#F46C00",
                    ]}
                    containerClassName=".pie-chart-4"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      );
    }

    if (
      !totalJobsChart &&
      !outcomesOverviewChart &&
      !outcomesChart &&
      !idApiByDayChart
    ) {
      if (!this.state.loading) {
        totalJobsChart = (
          <div className="metrics__no-info">
            <img className="icon--x-large" src={CalendarIcon} alt="calendar" />
            <div className="metrics__no-info-text">
              There is no data for that time period.
            </div>
          </div>
        );
      }
    }

    let loadingSpinner = <div />;
    if (this.state.loading) {
      loadingSpinner = (
        <div className="loader__container">
          <div className="loader loader--centered" />
        </div>
      );
    }

    const dataPeriods = {
      "1_week": "1 week",
      "2_weeks": "2 weeks",
      "1_month": "1 month",
      "3_months": "3 months",
      all_time: "All time",
    };

    const metricsOptions = {
      total: "Total Jobs",
      approved: "Job Outcomes Overview",
      outcomes: "Job Outcomes",
      idApi: "ID Checks Results",
    };
    return (
      <>
        <div className="metrics__header-container">
          <div className="metrics__header-sub-container">
            <h1 className="metrics__header">Analytics Overview</h1>
            <div className="metrics__sub-header">
              Keep track of your activities
            </div>
          </div>
          <div className="metrics__fake-chart">
            <img
              className="metrics__fake-chart-img"
              src={ChartIcon}
              alt="fake-chart"
            />
          </div>
        </div>

        <div className="metrics__filters-container">
          <div className="metrics__filter metrics__filter--graph-options">
            <div className="metrics-filter__label">
              <span className="metrics-filter__number">1.</span>
              <span>Select Metric Type:</span>
            </div>
            <div className="metrics-filter__options metrics-filter__options--graph-type">
              {this.renderMetricOptions(metricsOptions)}
            </div>
          </div>

          <div className="metrics-filter__subtext">
            {this.state.metricTypeExplanation}
          </div>

          <div className="metrics__filter metrics__filter--job-type">
            <div className="metrics-filter__label">
              <span className="metrics-filter__number">2.</span>
              <span>Select Job Type:</span>
            </div>
            <div className="metrics-filter__options">
              <select
                className="metrics-filter__select"
                id="job_type"
                name="job_type"
                onChange={this.onInput}
                value={this.state.job_type}
              >
                <option value="">All Job Types</option>
                {renderJobTypeOptions()}
              </select>
            </div>
          </div>

          <div className="metrics__filter metrics__filter--period">
            <div className="metrics-filter__label">
              <span className="metrics-filter__number">3.</span>
              <span>Fetch Data By:</span>
            </div>
            <div className="metrics-filter__options">
              {this.renderDataPeriodOptions(dataPeriods)}
            </div>
          </div>

          {this.state.userType === "admin" && (
            <div className="metrics__filter metrics__filter--partner">
              <div className="metrics-filter__label">
                <span className="metrics-filter__number">4.</span>
                <span>Select Partner:</span>
              </div>
              <div className="metrics-filter__options">
                <select
                  className="metrics-filter__select"
                  id="partner_id"
                  name="partner_id"
                  onChange={this.onInput}
                  value={this.state.partner_id}
                >
                  <option value="">All Partners</option>
                  {this.renderPartnerOptions()}
                </select>
              </div>
            </div>
          )}
        </div>

        <div className="metrics-chart__container">
          <div className="newsmile-heading-h2 metrics-chart__header">
            {metricsOptions[this.state.metric]} over{" "}
            {dataPeriods[this.state.duration]}
            {this.state.job_type &&
              ` for ${jobTypeLookup(this.state.job_type)}`}
            {this.state.userType === "admin" &&
              this.state.partner_id &&
              ` (Partner ${this.state.partner_id})`}
          </div>
          {loadingSpinner}
          <div className="">
            {totalJobsChart}
            {outcomesOverviewChart}
            {outcomesChart}
            {idApiByDayChart}
            {idApiByTypeChart}
          </div>
        </div>
      </>
    );
  }
}

export default Metrics;
