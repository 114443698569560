import React from "react";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import styled, { css } from "styled-components";

import Accordion from "components/ui/Accordion";
import ImageViewer from "components/ui/ImageViewer";
import FolderIcon from "resources/img/icons/folder-icon.svg";
import IdIcon from "resources/img/icons/Id.svg";
import { getProviderImage } from "util/actions_helpers";
import { getCountry, isBase64, toTitleCase } from "util/format_helpers";

const IconSpan = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
`;

export const TextSpan = styled.span`
  color: var(--color-secondary-blood-orange);
  font-weight: normal;
`;

const JobTypeIcon: React.FC<{
  jobType: number;
  country: string | undefined;
  idType: string | undefined;
}> = ({ country, idType, jobType }) => {
  const providerIcon = getProviderImage(country, idType);

  const iconConfig: { [key: string]: string } = {
    1: providerIcon,
    11: providerIcon,
    5: providerIcon,
    6: IdIcon,
  };

  const jobIcon = iconConfig[jobType] || FolderIcon;

  if (jobType === 11) {
    return (
      <IconSpan>
        {jobIcon && (
          <>
            <ImgIcon src={jobIcon} alt={jobIcon} /> +{" "}
          </>
        )}
        <ImgIcon src={IdIcon} alt={IdIcon} />
      </IconSpan>
    );
  }

  return jobIcon && <ImgIcon src={jobIcon} alt={jobIcon} />;
};

type Result = {
  Address?: string;
  Country?: string;
  DOB?: string;
  Document?: string;
  ExpirationDate?: string;
  FullName?: string;
  Gender?: string;
  IDNumber?: string;
  IDType?: string;
  IssuanceDate?: string;
  PhoneNumber?: string;
  PhoneNumber2?: string;
  Photo?: string;
  Secondary_ID_Number?: string;
  FullData?: {
    dateOfBirth?: string;
    firstname?: string;
    gender?: string;
    middlename?: string;
    phone?: string;
    surname?: string;
    photo?: string;
    nationality?: string;
    [key: string]: string | undefined;
  };
};

type IdInfo = {
  address?: string;
  country?: string;
  dob?: string;
  expiration_date?: string;
  gender?: string;
  id_number?: string;
  id_type?: string;
  issuance_date?: string;
  name?: string;
  phone_number?: string;
  result?: Result;
  secondary_id_number?: string;
  document?: string;
  last_name?: string;
  first_name?: string;
  other_name?: string;
};

export const ImgIcon = styled.img`
  height: 40px;
  width: 34px;
`;

export const TriggerDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 44px;
  align-items: center;
  justify-content: center;
`;
export const NOT_AVAILABLE = "Not Available";

const Field = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 8px;
`;

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const spanCss = css`
  font-family: "DM Sans";
  font-size: 14px;
  line-height: 18px;
`;

const LabelSpan = styled.span`
  color: var(--color-neutral-warm-grey);
  ${spanCss}
`;

const ValueSpan = styled.span`
  color: var(--color-neutral-off-black);
  ${spanCss}
`;

export const InfoICon = styled(InfoCircledIcon)`
  background-color: var(--color-secondary-red);
  border-radius: 50%;
  color: var(--color-neutral-white);
  height: 20px;
  width: 20px;
`;

export const NoResultDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;

const formatGender = (gender: string) => {
  if (gender.toLowerCase() === "f" || gender.toLowerCase() === "m") {
    return gender.toLowerCase() === "f" ? "Female" : "Male";
  }
  return gender;
};

const fieldLabelMappings: { [key: string]: string } = {
  enrollmentBank: "Enrollment Bank Code",
  maritalStatus: "Marital Status",
  marriageDate: "Marriage Date",
  idCardInd: "Id Card Ind",
  idCardDate: "Id Card Date",
  vNIN: "vNIN",
  deceasedStatus: "Deceased Status",
  deceasedDate: "Deceased Date",
};

type JobFieldProps = {
  label: string;
  value?: string | undefined | number;
  children?: React.ReactNode;
};

export const JobField: React.FC<JobFieldProps> = ({
  label,
  value,
  children,
}) => (
  <Field>
    <LabelSpan>{label}: </LabelSpan>
    {value && <ValueSpan>{value}</ValueSpan>}
    {children}
  </Field>
);

type Props = {
  idInfo: IdInfo;
  strippedPii: boolean;
  piiDataExpired: boolean;
  jobType: number;
};

const IdInfoAccordion: React.FC<Props> = ({
  idInfo,
  strippedPii,
  piiDataExpired,
  jobType,
}) => {
  const fullData = idInfo.result?.FullData;

  let photoOrDocument = {
    key: "photo_document",
    label: "Photo",
    value:
      idInfo.result?.Photo || idInfo.result?.FullData?.photo || NOT_AVAILABLE,
  };

  if ([6, 11].includes(jobType)) {
    photoOrDocument = {
      key: "photo_document",
      label: "Document",
      value: idInfo?.document || idInfo.result?.Document || NOT_AVAILABLE,
    };
  }

  let fields = [
    photoOrDocument,
    {
      key: "first_name",
      label: "First Name",
      value: toTitleCase(
        `${fullData?.firstname || fullData?.FirstName || idInfo.first_name || ""}`.trim() ||
          NOT_AVAILABLE,
      ),
    },
    {
      key: "last_name",
      label: "Last Name",
      value: toTitleCase(
        `${fullData?.surname || fullData?.LastName || idInfo.last_name || ""}`.trim() ||
          NOT_AVAILABLE,
      ),
    },
    {
      key: "other_name",
      label: "Other Name",
      value: toTitleCase(
        `${fullData?.middlename || fullData?.MiddleName || idInfo.other_name || ""}`.trim() ||
          NOT_AVAILABLE,
      ),
    },

    {
      key: "name",
      label: "Full Name",
      value: toTitleCase(
        idInfo.name || idInfo.result?.FullName || NOT_AVAILABLE,
      ),
    },
    {
      key: "country",
      label: "Country",
      value:
        getCountry(idInfo.country || idInfo.result?.Country) ||
        idInfo.country ||
        idInfo.result?.Country ||
        fullData?.nationality ||
        NOT_AVAILABLE,
    },
    {
      key: "id_type",
      label: "ID Type",
      value: idInfo.id_type || idInfo.result?.IDType || NOT_AVAILABLE,
    },
    {
      key: "id_number",
      label: "ID Number",
      value: idInfo.id_number || idInfo.result?.IDNumber || NOT_AVAILABLE,
    },
    {
      key: "secondary_id_number",
      label: "Secondary ID Number",
      value:
        idInfo.secondary_id_number ||
        idInfo.result?.Secondary_ID_Number ||
        NOT_AVAILABLE,
    },
    {
      key: "expiration_date",
      label: "Expiry Date",
      value:
        idInfo.expiration_date ||
        idInfo.result?.ExpirationDate ||
        NOT_AVAILABLE,
    },

    {
      key: "issuance_date",
      label: "Issuance Date",
      value:
        idInfo.issuance_date ||
        idInfo.result?.IssuanceDate ||
        idInfo.result?.PhoneNumber2 ||
        NOT_AVAILABLE,
    },
    {
      key: "dob",
      label: "Date of Birth",
      value:
        idInfo.dob ||
        idInfo.result?.DOB ||
        fullData?.dateOfBirth ||
        NOT_AVAILABLE,
    },
    {
      key: "phone_number",
      label: "Phone Number",
      value:
        idInfo.phone_number ||
        idInfo.result?.PhoneNumber ||
        idInfo.result?.PhoneNumber2 ||
        fullData?.phone ||
        NOT_AVAILABLE,
    },
    {
      key: "gender",
      label: "Gender",
      value: formatGender(
        idInfo.gender ||
          idInfo.result?.Gender ||
          fullData?.gender ||
          NOT_AVAILABLE,
      ),
    },
    {
      key: "address",
      label: "Address",
      value: idInfo.address || idInfo.result?.Address || NOT_AVAILABLE,
    },
  ];

  const fullDataFields = [
    "enrollmentBank",
    "maritalStatus",
    "marriageDate",
    "idCardInd",
    "idCardDate",
    "vNIN",
    "deceasedDate",
    "deceasedStatus",
  ].map(
    (field) =>
      fullData?.[field] !== undefined && (
        <JobField
          key={field}
          label={fieldLabelMappings[field]}
          value={fullData?.[field] || NOT_AVAILABLE}
        />
      ),
  );

  if (strippedPii) {
    fields = fields.filter(
      (field) => field.key === "country" || field.key === "id_type",
    );
  }

  const trigger = (
    <TriggerDiv>
      <JobTypeIcon
        jobType={jobType}
        country={idInfo?.country || idInfo?.result?.Country}
        idType={idInfo?.id_type || idInfo?.result?.IDType}
      />
      <span>{jobType === 13 ? "Matched Fields" : "ID Information"}</span>
    </TriggerDiv>
  );

  const defaultValue = piiDataExpired ? "id_information" : undefined;
  const notApplicable = ![1, 5, 6, 11, 13].includes(jobType);

  const notApplicableIcon = notApplicable ? (
    <TextSpan>Not Applicable</TextSpan>
  ) : null;

  const hasNoInfo = fields.every((field) => field.value === NOT_AVAILABLE);

  // NOTE: Remove fields that are not applicable for job type 13
  if (jobType === 13) {
    fields = fields.filter((field) =>
      ["id_number", "first_name", "last_name", "other_name"].includes(
        field.key,
      ),
    );
  }

  const idFields = fields.map((field) => {
    if (field.key === "photo_document") {
      if (field.value === NOT_AVAILABLE || !isBase64(field.value)) {
        return null;
      }
      return (
        <JobField key={field.key} label={field.label}>
          <ImageViewer
            source={`${
              field.value.includes("data:image") ||
              field.value.includes("base64")
                ? ""
                : "data:image/jpeg;base64,"
            }${field.value}`}
            className="icon--x-large"
            alt="ID card"
          />
        </JobField>
      );
    }
    return <JobField key={field.key} label={field.label} value={field.value} />;
  });

  return (
    <Accordion
      disabled={piiDataExpired || notApplicable}
      defaultValue={defaultValue}
      collapsible={!piiDataExpired}
    >
      <Accordion.Item
        icon={notApplicableIcon}
        showIcon={!piiDataExpired}
        trigger={trigger}
        value="id_information"
      >
        {!hasNoInfo ? (
          <Fields>
            {piiDataExpired ? (
              <>
                <NoResultDiv>
                  <InfoICon />
                  <span>
                    Information no longer available as job is past its data
                    retention period.
                  </span>
                </NoResultDiv>
                <JobField
                  label="ID Type"
                  value={
                    idInfo.id_type || idInfo.result?.IDType || NOT_AVAILABLE
                  }
                />
              </>
            ) : (
              <>
                {idFields} {fullDataFields}
              </>
            )}
          </Fields>
        ) : (
          <div className="no-info">No Information to show</div>
        )}
      </Accordion.Item>
    </Accordion>
  );
};

export default IdInfoAccordion;
