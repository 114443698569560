export const RECEIVE_TOKEN = "RECEIVE_TOKEN";

export const receiveToken = (token: {
  auth_token: string;
  pre_otp_user?: string;
  errors?: string[];
}) => ({
  type: RECEIVE_TOKEN,
  token,
});
