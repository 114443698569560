import "./Hint.css";

import React from "react";

import iconMap from "./icons";

interface HintProps {
  text: string;
}

const reviewerHintsMap = {
  "Uncertain text extraction": "Possible errors in extracted text",
  "Suspected Fraud": "Suspected screen image",
  "Suspected Photocopy": "Suspected Photocopy",
  "Information mismatch": "MRZ does not match document details",
  "Document could not be classified": "Document type not recognized",
};

const Hint: React.FC<HintProps> = ({ text }) => {
  const iconSrc = iconMap[text];
  const hintText = reviewerHintsMap[text as keyof typeof reviewerHintsMap];

  if (!hintText) {
    return <div className="hint" />;
  }

  return (
    <div className="hint">
      {iconSrc && <img src={iconSrc} alt={text} className="hint-icon" />}
      <div className="hint-text">{hintText}</div>
    </div>
  );
};

export default Hint;
