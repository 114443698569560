import React, { useState } from "react";
import classNames from "classnames";

import SwitchButton from "components/reusable/switch";
import AnalyticsDashboard from "containers/admin/analytics_dashboard/analytics_dashboard";
import { getShowNewAnalytics, setShowNewAnalytics } from "util/selectors";
import styles from "./analytics_wrapper.module.css";
import Metrics from "./metrics";

export default function AnalyticsWrapper() {
  const [displayNewAnalytics, setDisplayNewAnalytics] = useState(
    getShowNewAnalytics(),
  );

  const switchAnalytics = (event, useNew, source = "") => {
    event.preventDefault();
    setShowNewAnalytics(useNew);
    setDisplayNewAnalytics(useNew);
    if (window?.analytics?.track) {
      window.analytics.track(`Toggle new analytics ${useNew ? "on" : "off"}`, {
        source,
      });
    }
  };

  let switchText = "Check it out";
  let title = "New analytics view available";
  let subtitle = "View new UI for fast and improved overviews.";
  if (displayNewAnalytics) {
    switchText = "Switch back";
    title = "You are viewing new analytics";
    subtitle = "This gives you fast and improved overviews.";
  }

  return (
    <div className={classNames(styles.analytics_wrapper, "legacy")}>
      <div className={styles.switch_wrapper}>
        <div className={styles.switch_title}>{title}</div>
        <div className={styles.switch_sub_title}>{subtitle}</div>
        <button
          className={styles.switch_label_wrapper}
          onClick={(event) => switchAnalytics(event, !displayNewAnalytics)}
        >
          <label htmlFor="switch-new" className={styles.switch_label_text}>
            {switchText}
          </label>
          <SwitchButton isOn={displayNewAnalytics} />
        </button>
      </div>
      {displayNewAnalytics && <AnalyticsDashboard />}
      {!displayNewAnalytics && <Metrics />}
    </div>
  );
}
