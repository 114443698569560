import React, { useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { AnalyticsContext } from "contexts/analytics_context";
import DecreaseIcon from "resources/img/icons/decrease.svg";
import IncreaseIcon from "resources/img/icons/growth.svg";
import NoChangeIcon from "resources/img/icons/no-change.svg";
import { fetchPartnerAnalytics } from "util/api_util";
import Spotlight from "./spotlight";
import styles from "./spotlight.module.css";

export default function SpotlightContainer() {
  const [spotlightData, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { filters, partnerId } = useContext(AnalyticsContext);

  useEffect(() => {
    if (typeof partnerId !== "string") {
      return;
    }
    const abortController = new AbortController();
    setLoading(true);
    fetchPartnerAnalytics(
      "/metrics/spotlight_analytics",
      partnerId,
      filters,
      abortController,
    )
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((e) => {
        if (e.name !== "AbortError") {
          console.error(e);
          Sentry.captureException(e);
        }
      });

    return () => abortController.abort();
  }, [partnerId, filters]);

  const iconMap = {
    increase: IncreaseIcon,
    decrease: DecreaseIcon,
    noChange: NoChangeIcon,
  };

  const passRateMap = {
    increase: "Your pass rate increased by",
    decrease: "Your pass rate fell by",
    noChange: "There was no change in your pass rate",
  };

  const volumeChangeMap = {
    increase: "Your volume of jobs increased by",
    decrease: "Your volume of jobs fell by",
    noChange: "Your volume remained the same",
  };

  const downtimeRateMap = {
    increase: "ID authority uptime improved by",
    decrease: "ID authority uptime declined by",
    noChange: "ID authority uptime remained the same",
  };

  const checkChange = (change) => {
    if (change > 0) return "increase";
    if (change < 0) return "decrease";
    return "noChange";
  };

  const latestSpotlight = spotlightData[spotlightData.length - 2];
  const passRate =
    parseFloat(latestSpotlight?.pass_rate_fluctuation) * 100 || 0;
  const volumeChangeRate =
    parseFloat(latestSpotlight?.job_volume_fluctuation) * 100 || 0;

  const uptimeRate = parseFloat(latestSpotlight?.uptime_fluctuation) * 100 || 0;
  const passRateText = passRateMap[checkChange(passRate)];
  const volumeChangeText = volumeChangeMap[checkChange(volumeChangeRate)];
  const failRateText = downtimeRateMap[checkChange(uptimeRate)];

  return (
    <div className={styles.spotlight_wrapper}>
      <Spotlight
        loading={loading}
        icon={iconMap[checkChange(passRate)]}
        text={passRateText}
        rate={
          checkChange(passRate) === "noChange"
            ? ""
            : `${new Intl.NumberFormat().format(Math.abs(passRate))}%`
        }
        rateClass={checkChange(passRate) === "decrease" ? "error" : "success"}
      />
      <Spotlight
        loading={loading}
        icon={iconMap[checkChange(volumeChangeRate)]}
        text={volumeChangeText}
        rate={
          checkChange(volumeChangeRate) === "noChange"
            ? ""
            : `${new Intl.NumberFormat().format(Math.abs(volumeChangeRate))}%`
        }
        rateClass={
          checkChange(volumeChangeRate) === "decrease" ? "error" : "success"
        }
      />
      <Spotlight
        loading={loading}
        icon={iconMap[checkChange(uptimeRate)]}
        text={failRateText}
        rate={
          checkChange(uptimeRate) === "noChange"
            ? ""
            : `${new Intl.NumberFormat().format(Math.abs(uptimeRate))}%`
        }
        rateClass={checkChange(uptimeRate) === "decrease" ? "error" : "success"}
      />
    </div>
  );
}
