import React, { useEffect, useState } from "react";
import AutoPayCardSuccessModal from "components/auto_pay/AutoPayCardSuccessModal";
import AutoPayModal from "components/auto_pay/AutoPayModal";
import ConfirmAutoPayOffModal from "components/auto_pay/ConfirmAutoPayOffModal";
import {
  DEFAULT_AMOUNT_BALANCE,
  MIN_BALANCE,
} from "components/auto_pay/constants";
import { CurrencyChangeModal } from "components/currency-change";
import { PaymentPlanModal, PLANS } from "components/payment-plans";
import PaymentNotificationModal from "components/payment-plans/PaymentNotificationModal";
import Card from "components/reusable/card";
import SwitchButton from "components/reusable/switch";
import HistoryIcon from "resources/img/icons/bookmark.svg";
import TopUpIcon from "resources/img/icons/wallet-topup.svg";
import {
  fetchWallet,
  fetchPricingPlan,
  buildState,
  deactivateAutoPay,
} from "util/api_util";
import { convertCurrency, formatCurrency, isEmpty } from "util/format_helpers";
import { canUserView } from "util/route_util";
import { getAdminPartnerId, getUserType } from "util/selectors";
import { Toast } from "util/Toast";
import { DLocalPaymentFlow } from "./billing/dlocal_smart_fields";
import { DLocalCardFlow } from "./billing/DLocalSmartFieldsSaveCard";

function BillingDashboard({
  history,
  fetchPricingPlan,
  fetchWallet,
  deactivateAutoPay,
}) {
  const [walletBalance, setWalletBalance] = useState();
  const [paymentPlan, setPaymentPlan] = useState("--");
  const [invoiceData, setInvoiceData] = useState({});
  const [designatedCurrency, setDesignatedCurrency] = useState("--");
  const [modalOpen, setModalOpen] = useState(false);
  const [dlocalDetails, setDlocalDetails] = useState({});
  const modals = [
    "changePaymentPlan",
    "changeCurrency",
    "confirmAutoPayOffModal",
    "autoPaySuccess",
  ];
  const [activeModal, setActiveModal] = useState(modals[0]);
  const [autoPay, setAutoPay] = useState({
    minBalance: MIN_BALANCE,
    payAmount: DEFAULT_AMOUNT_BALANCE,
    enabled: true,
  });
  const [showChangePaymentPlanModal, setShowChangePaymentPlanModal] =
    useState(false);
  const queryParams = buildState(history.location.search);
  const isPayAsYouGo = paymentPlan === "pay_as_you_go";
  const showAutoPayModal =
    queryParams.mode === "auto-pay" &&
    isPayAsYouGo &&
    getUserType() !== "admin";
  const userHasPaymentAccess = canUserView(["/partner/billing/payment"]);

  const paymentStatus = queryParams.status?.toLowerCase();
  const amountPaid = queryParams.amount;
  const paymentRef = queryParams.paymentRef;

  const fetchData = async (partnerId) => {
    const wallet = await fetchWallet({ partner_id: partnerId });
    const { pricingPlan } = await fetchPricingPlan(partnerId);

    setWalletBalance(`${wallet.wallet_balance}`);
    setPaymentPlan(pricingPlan.contractType);
    setDesignatedCurrency(pricingPlan.designatedCurrency);

    const autoPayResult = {
      minBalance: pricingPlan.minimumWalletBalanceUSD,
      payAmount: pricingPlan.recurringTopUpAmountUSD,
      enabled: pricingPlan.autoPayEnabled,
      enabledCurrencies: pricingPlan.enabledCurrencies,
      designatedCurrency: pricingPlan.designatedCurrency,
      exchangeRates: wallet.exchange_rates,
    };
    if (pricingPlan.designatedCurrency !== "USD") {
      const conversion = {
        state: {
          exchangeRates: wallet.exchange_rates,
          currency: pricingPlan.designatedCurrency,
        },
      };
      autoPayResult.minBalance = convertCurrency(
        conversion,
        pricingPlan.minimumWalletBalanceUSD,
      );
      autoPayResult.payAmount = convertCurrency(
        conversion,
        pricingPlan.recurringTopUpAmountUSD,
      );

      const balance = convertCurrency(conversion, wallet.wallet_balance);

      setWalletBalance(balance);
    }
    setAutoPay(autoPayResult);
  };

  useEffect(() => {
    setShowChangePaymentPlanModal(queryParams.mode === "change-payment-plan");
    const partnerId = getPartnerId();
    sessionStorage.setItem("url", "/partner/billing");
    fetchData(partnerId);
  }, [queryParams.mode]);

  const resetBillingContext = () => {
    setShowChangePaymentPlanModal(false);
    setInvoiceData(null);
    history.replace("/partner/billing");
  };

  const getPartnerId = () => {
    let partner_id;
    if (localStorage.adminPartnerId) {
      partner_id = getAdminPartnerId();
    }
    return partner_id;
  };

  const onCardClick = (event) => {
    sessionStorage.setItem("url", event.target.value);
    history.push(event.target.value);
  };

  const onDlocalFlowCancel = () => {
    setInvoiceData(null);
  };

  const onCreateInvoiceResponse = ({
    amount,
    currency,
    dlocal_sf_key: dlocalKey,
    invoice,
    payment_methods: paymentMethods,
  }) => {
    setInvoiceData({
      amount,
      currency,
      dlocalKey,
      invoice,
      paymentMethods,
    });
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const toggleAutoPay = async (enabled) => {
    if (enabled) {
      history.push("/partner/billing?mode=auto-pay&step=setup");
    } else {
      setActiveModal("confirmAutoPayOffModal");
      toggleModal();
    }
  };

  const showAutoPaySuccess = () => {
    setDlocalDetails({});
    const partnerId = getPartnerId();
    fetchData(partnerId);
    setActiveModal("autoPaySuccess");
    setModalOpen(true);
  };

  const turnOffAutoPay = async () => {
    const autoPayResult = await deactivateAutoPay({
      partner_id: getAdminPartnerId(),
      auto_pay_enabled: false,
    });
    toggleModal();
    history.push("/partner/billing");
    if (autoPayResult.error) {
      Toast.fire({
        title: autoPayResult.error || "Auto-Pay could not be updated",
        timer: 5000,
      });
      return;
    }
    Toast.fire({
      title: "Auto-Pay disabled successfully",
      timer: 5000,
    });
    setAutoPay((prev) => ({ ...prev, enabled: false }));
  };

  const onCardAutoPayClick = (event) => {
    history.push(event.target.value);
  };

  const onAddFundsClick = (event) => {
    event.preventDefault();
    history.push(event.target.value);
  };

  const onChangePaymentPlanClicked = (event) => {
    event.preventDefault();
    history.push("?mode=change-payment-plan&step=planSelection");
  };

  const showDlocalFlow =
    invoiceData?.invoice &&
    !isEmpty(invoiceData.dlocalKey) &&
    userHasPaymentAccess;
  const showPaymentNotificationModal = !isEmpty(paymentStatus);
  const showPaymentPlanModal =
    showChangePaymentPlanModal && !showDlocalFlow && userHasPaymentAccess;

  const onAutoPayPageChange = (result) => {
    if (result && result.dlocalKey) {
      history.push("/partner/billing");
      setDlocalDetails(result);
      setActiveModal("saveDlocalCard");
      setModalOpen(true);
    }
  };

  return (
    <>
      <div className="legacy smile-card__container billing-dashboard__card-container">
        <Card
          cardClass="smile-card--centered billing-dashboard__card"
          heading={`Current Balance: ${
            formatCurrency(walletBalance, designatedCurrency) || "--"
          }`}
          icon={TopUpIcon}
          text={`Plan: ${PLANS[paymentPlan] || paymentPlan}`}
          subText={
            <p>
              {userHasPaymentAccess && (
                <button
                  disabled={!PLANS[paymentPlan]}
                  style={{ color: "var(--web-digital-blue" }}
                  className="btn btn-white"
                  onClick={onChangePaymentPlanClicked}
                  type="button"
                >
                  Change Plan
                </button>
              )}
            </p>
          }
          disabled={!userHasPaymentAccess || !walletBalance}
          buttonText={userHasPaymentAccess && "Add Funds"}
          redirectValue="?mode=change-payment-plan&step=add-funds"
          onCardClick={onAddFundsClick}
        />
        {isPayAsYouGo && (
          <Card
            cardClass="smile-card--centered billing-dashboard__card new-typography auto-pay"
            heading="Auto-Pay"
            icon={TopUpIcon}
            disabled={getUserType() === "admin"}
            subText={
              <div className="auto-pay-card">
                <p className="auto-pay-card-title">
                  <span className="h3">Auto-Pay</span>
                  <span>
                    <SwitchButton
                      isOn={autoPay.enabled}
                      size={40}
                      isDisabled={getUserType() === "admin"}
                      onChange={(e, value) => toggleAutoPay(value)}
                    />
                  </span>
                </p>
                <p className="auto-pay-card-action">
                  <span className="h4">Minimum balance</span>
                  <span>
                    {autoPay.enabled
                      ? formatCurrency(
                          parseFloat(autoPay.minBalance).toFixed(2),
                          designatedCurrency,
                        )
                      : "--"}
                  </span>
                </p>
                <p className="auto-pay-card-action">
                  <span className="h4">Auto-Pay amount</span>
                  <span>
                    {autoPay.enabled
                      ? formatCurrency(
                          parseFloat(autoPay.payAmount).toFixed(2),
                          designatedCurrency,
                        )
                      : "--"}
                  </span>
                </p>
              </div>
            }
            buttonText="Manage Auto-Pay"
            redirectValue="?mode=auto-pay"
            onCardClick={onCardAutoPayClick}
          />
        )}
        <Card
          cardClass="smile-card--centered billing-dashboard__card"
          heading="Wallet History"
          icon={HistoryIcon}
          text="View payment history and see statements for past usage"
          buttonText="View Wallet History"
          redirectValue="billing/history"
          onCardClick={onCardClick}
        />
      </div>
      <AutoPayModal
        isOpen={showAutoPayModal}
        setAutoPay={setAutoPay}
        autoPay={autoPay}
        onPageChange={onAutoPayPageChange}
      />
      {showPaymentPlanModal && (
        <PaymentPlanModal
          isOpen={showPaymentPlanModal}
          paymentPlan={paymentPlan}
          autoPay={autoPay}
          designatedCurrency={designatedCurrency}
          onCreateInvoiceResponse={onCreateInvoiceResponse}
        />
      )}
      {showPaymentNotificationModal && (
        <PaymentNotificationModal
          show={showPaymentNotificationModal}
          onClick={resetBillingContext}
          paymentStatus={paymentStatus}
          paymentRef={paymentRef}
          currency={designatedCurrency}
          amount={amountPaid}
        />
      )}
      {showDlocalFlow && (
        <DLocalPaymentFlow
          amount={parseFloat(invoiceData?.amount)}
          invoiceId={invoiceData?.invoice?.invoice_id}
          currency={invoiceData?.currency}
          apiKey={invoiceData?.dlocalKey}
          paymentMethods={invoiceData?.paymentMethods}
          show={showDlocalFlow}
          onCancel={onDlocalFlowCancel}
          onComplete={resetBillingContext}
          partnerId={localStorage.adminPartnerId}
        />
      )}
      {modalOpen &&
        {
          changeCurrency: (
            <CurrencyChangeModal open={modalOpen} closeModal={toggleModal} />
          ),
          confirmAutoPayOffModal: (
            <ConfirmAutoPayOffModal
              isOpen={modalOpen}
              onClose={toggleModal}
              onConfirm={turnOffAutoPay}
            />
          ),
          saveDlocalCard: (
            <DLocalCardFlow
              currency={dlocalDetails?.currency}
              apiKey={dlocalDetails?.dlocalKey}
              show={!isEmpty(dlocalDetails?.dlocalKey)}
              onCancel={() => setDlocalDetails({})}
              onComplete={showAutoPaySuccess}
              partnerId={localStorage.adminPartnerId}
            />
          ),
          autoPaySuccess: (
            <AutoPayCardSuccessModal onClose={() => toggleModal()} />
          ),
        }[activeModal]}
    </>
  );
}

BillingDashboard.defaultProps = {
  fetchWallet,
  fetchPricingPlan,
  deactivateAutoPay,
};

export default BillingDashboard;
