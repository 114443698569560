import React from "react";

import styles from "./associations.module.css";

function Associations({ associations }) {
  return associations.map((association) => (
    <div className={styles.container}>
      <div>
        <header>
          <h6>Association type: {association.association_type}</h6>
          <h3>{association.name}</h3>
        </header>
      </div>

      <div className={styles.relationship}>
        <h6>Relationship type</h6>
        <p>{association.relationship}</p>
      </div>
    </div>
  ));
}

export default Associations;
