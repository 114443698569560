import React from "react";
import styles from "./pep.module.css";
import SourceLink from "./source_link";

function Pep({ pep }) {
  const { political_positions: politicalPositions } = pep;
  const getYear = (dateString) =>
    dateString ? new Date(dateString).getFullYear() : "";

  const pepLevels = {
    1: "High Risk",
    2: "Medium Risk",
    3: "Low Risk",
    "Former PEP": "Former PEP",
  };

  return (
    <div className={styles.container}>
      <div className={styles.rowFlex}>
        <header>
          <h6>PEP LEVEL</h6>
          <h3>{pepLevels[pep.pep_level] || "N/A"}</h3>
        </header>
      </div>
      {politicalPositions.map((position) => (
        <div className={styles.politicalPosition}>
          <div className={styles.rowFlex}>
            <div className={styles.country}>
              <h6>Country</h6>
              <span>{position.country}</span>
            </div>
            <div>
              <h6>Period</h6>
              <span>
                {getYear(position.from)} - {getYear(position.to) || "Present"}
              </span>
            </div>
          </div>
          <div>
            <h6>Position</h6>
            <span>{position.position}</span>
          </div>
        </div>
      ))}

      <div className={styles.source}>
        <div className={styles.itemBadge}>
          <p>Source(s)</p>
          <span>{pep.sources.length}</span>
        </div>
        <div className={styles.sourceLinks}>
          {pep.sources.map((source) => (
            <SourceLink name={source.source_name} link={source.source_link} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Pep;
