import { connect } from "react-redux";

import { fetchResources, removeReviewsState } from "actions/actions";
import CompletedReviews from "components/reusable/completed_reviews";

const mapDispatchToProps = (dispatch) => ({
  fetchResources: (table, sortObject) =>
    fetchResources(table, sortObject, dispatch),
  removeReviewsState: () => removeReviewsState(dispatch),
});

export default connect(null, mapDispatchToProps)(CompletedReviews);
