import React, { useRef, useEffect } from "react";

const ImageWithBoundingBox = ({ boundingBox, url, className }) => {
  const canvasRef = useRef(null);

  const x = boundingBox?.x_min ? parseFloat(boundingBox.x_min) : 0;
  const y = boundingBox?.y_min ? parseFloat(boundingBox.y_min) : 0;
  const width =
    boundingBox?.x_max && boundingBox?.x_min
      ? parseFloat(boundingBox.x_max) - parseFloat(boundingBox.x_min)
      : 1;
  const height =
    boundingBox?.y_max && boundingBox?.y_min
      ? parseFloat(boundingBox.y_max) - parseFloat(boundingBox.y_min)
      : 1;

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const image = new Image();
    image.src = url;

    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0, image.width, image.height);

      ctx.strokeStyle = "green";
      ctx.lineWidth = 5;

      ctx.strokeRect(x, y, width, height);
    };
  }, [canvasRef, x, y, width, height]);

  return (
    <canvas
      className={className}
      ref={canvasRef}
      data-testid="bounding-box-canvas"
    />
  );
};

export default ImageWithBoundingBox;
