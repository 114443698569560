import React from "react";
import styles from "./spotlight.module.css";

export default function Spotlight({
  icon,
  text,
  rate,
  rateClass,
  buttonText,
  buttonAction,
  loading,
}) {
  if (loading) {
    return (
      <div
        className="animate-shimmer"
        style={{ height: "5.7em", width: "16em", borderRadius: "1em" }}
      />
    );
  }
  return (
    <div className={styles.spotlight}>
      <img src={icon} alt="" />
      <div className={styles.spotlight_body}>
        <span className="spotlight-text">
          {text}
          <span className={`${styles.spotlight_percentage} ${rateClass}`}>
            {" "}
            {rate}
          </span>
        </span>
        {buttonAction && buttonText && (
          <button className={`btn btn-white ${styles.spotlight_action}`}>
            {buttonText}
          </button>
        )}
      </div>
    </div>
  );
}
