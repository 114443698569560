import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import PanZoom from "react-easy-panzoom";
import Modal from "components/modal/modal";
import ImageManipulationTools from "components/reusable/ImageManipulationTools";
import CloseIcon from "resources/img/icons/close-icon.svg";
import { ImageDescription, ImageDescriptionMap } from "../ImageDescriptions";

import "./DocumentImageViewer.scss";

const defaultFlip = { X: 1, Y: 1 };
type DefaultFlipType = typeof defaultFlip;

type PanZoomProps = React.ComponentProps<typeof PanZoom>;

export type ImageDescriptionMapType = keyof typeof ImageDescriptionMap;

type ImageViewerProps = PanZoomProps & {
  imageUrls: string[];
  imageType: ImageDescriptionMapType;
  label?: string;
  showFullScreenControls?: boolean;
  autoFocus?: boolean;
  source?: "selfie" | "agent";
  interval?: number;
  animate?: boolean;
  options?: object;
  handleKeyDown?: (event: React.KeyboardEvent) => void;
};

const DocumentImageViewer: React.FC<ImageViewerProps> = ({
  imageType,
  imageUrls,
  source,
  autoFocus = false,
  interval = 250,
  showFullScreenControls = true,
  ...props
}) => {
  const [showModal, setShowModal] = useState(false);
  const [filter, setFilter] = useState("none");
  const [loading, setLoading] = useState(true);
  const [isAnimating, setIsAnimating] = useState(props.animate || false);
  const [error, setError] = useState(false);
  const [flip, setFlip] = useState<DefaultFlipType>(defaultFlip);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const timer = useRef<ReturnType<typeof setInterval> | undefined>(undefined);
  const panZoomRef = useRef<PanZoomProps>(null);
  const classes = classNames(
    "document_file",
    filter,
    props.options?.panzoomClass,
    {
      "full-screen": !showFullScreenControls,
    },
  );
  const zoomInSpeed = 3;
  const rotateAngle = 30;

  const onHandleKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (typeof props.handleKeyDown === "function") {
      props.handleKeyDown(event);
    }
    const keyActions: { [key: string]: () => void } = {
      f: () => showFullScreenControls && setShowModal((prev) => !prev),
      c: onReset,
      r: onRotateRight,
      l: onRotateLeft,
      z: onZoomIn,
      x: onZoomOut,
      h: () => onFlip({ ...flip, X: flip.X * -1 }),
      v: () => onFlip({ ...flip, Y: flip.Y * -1 }),
    };
    const action = keyActions[event.key];
    if (typeof action === "function") {
      action();
    }
    setIsAnimating(false);
  }, []);

  useEffect(() => {
    if (!panZoomRef.current || !panZoomRef.current?.container?.current) {
      return;
    }

    if (autoFocus) {
      panZoomRef?.current?.container?.current?.focus();
    }
    panZoomRef.current?.container?.current?.addEventListener(
      "keydown",
      onHandleKeyDown,
    );

    return () => {
      panZoomRef.current?.container?.current?.removeEventListener(
        "keydown",
        onHandleKeyDown,
      );
    };
  }, [props.panZoom, props.handleKeyDown, autoFocus]);

  useEffect(() => {
    if (timer.current) clearTimeout(timer.current);
    if (!isAnimating || imageUrls?.length <= 1) return;

    timer.current = setInterval(nextImage, interval);

    return () => clearInterval(timer.current);
  }, [imageUrls, interval, isAnimating]);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
  };

  const handleImageLoad = () => {
    setLoading(false);
    if (panZoomRef.current) {
      panZoomRef.current.autoCenter();
    }
  };

  const handleImageError = () => {
    setLoading(false);
    setError(true);
  };

  const onZoomIn = () =>
    panZoomRef.current && panZoomRef.current.zoomIn(zoomInSpeed);
  const onZoomOut = () =>
    panZoomRef.current && panZoomRef.current.zoomOut(zoomInSpeed);
  const onReset = () => {
    if (panZoomRef.current) {
      panZoomRef.current.autoCenter();
      setFlip(defaultFlip);
    }
  };
  const onRotateLeft = () =>
    panZoomRef.current &&
    panZoomRef.current.rotate((prevAngle: number) => prevAngle - rotateAngle);
  const onRotateRight = () =>
    panZoomRef.current &&
    panZoomRef.current.rotate((prevAngle: number) => prevAngle + rotateAngle);

  const handleFilterChange = (newFilter: string) => {
    if (newFilter === "expand") {
      setShowModal(true);
      setFilter("none");
      setIsAnimating(false);
      return;
    }
    setFilter((current) => (current === newFilter ? "none" : newFilter));
  };

  const onFlip = (newFlip: DefaultFlipType) => {
    setFlip(newFlip);
  };

  const selectImage = (index: number) => {
    setIsAnimating(false);
    setCurrentImageIndex(index);
  };

  const handleButtonClick = () => {
    if (!props.animate) return;
    setIsAnimating(!isAnimating);
  };

  // when flipping the image, we need to adjust the scale to keep the image in the same position
  // without doing this the image will move out of frame when dragging
  const unFlipX = flip.X === -1 ? flip.X * -1 : flip.X;
  const unFlipY = flip.Y === -1 ? flip.Y * -1 : flip.Y;

  const src = imageUrls?.[currentImageIndex];

  return (
    <div className="document-review-image-viewer every_pixel">
      {showModal && (
        <DocumentReviewImageModal
          show={showModal}
          onClose={() => setShowModal(false)}
          src={src}
          imageType={imageType}
          label={props.label}
          options={props.options}
        />
      )}
      <div className={classNames("document image-viewer-container", imageType)}>
        <section className="header-viewer-header">
          <ImageDescription
            descriptionType={imageType}
            className="text-xs"
            options={{ descriptionClassName: imageType }}
            source={source}
            label={props.label}
          />
        </section>
        <section className="pan_zoom_image_container">
          <PanZoom
            ref={panZoomRef}
            style={{
              transform: `scaleX(${unFlipX}) scaleY(${unFlipY})`,
            }}
            data-testid="pan_zoom_image"
            disableKeyInteraction={false}
            minZoom={0.5}
            maxZoom={10}
            realPinch={false}
            className={classes}
            noStateUpdate
            keyMapping={{
              87: { x: 0, y: -1, z: 0 },
              83: { x: 0, y: 1, z: 0 },
              65: { x: -1, y: 0, z: 0 },
              68: { x: 1, y: 0, z: 0 },
            }}
            enableBoundingBox
            boundaryRatioVertical={1}
            boundaryRatioHorizontal={1}
            {...props.options?.panZoomProps}
            onKeyDown={props.onKeyDown}
          >
            {loading && (
              <div className="loader loading-data-spinner loading-data-spinner--photo" />
            )}
            {error && <div>Error loading {imageType.replace(/_/, " ")}</div>}
            {!error && (
              <img
                style={{
                  transform: `scaleX(${flip.X}) scaleY(${flip.Y})`,
                }}
                src={src}
                onLoad={handleImageLoad}
                onError={handleImageError}
                data-testid="document_file_image"
                className={classNames(
                  "document_file_image",
                  props.options?.imageClassName,
                )}
                alt={props.label || imageType}
                onClick={handleButtonClick}
                onContextMenu={(e) => {
                  e.preventDefault();
                  return false;
                }}
              />
            )}
          </PanZoom>
        </section>
        {imageUrls?.length > 1 && (
          <section className="thumbnail-viewer-footer">
            {imageUrls.map((url: string, index: number) => (
              <img
                key={url}
                src={url}
                data-testid={`thumbnail-${index}`}
                className={classNames("thumbnail", {
                  active: index === currentImageIndex,
                })}
                alt={`Thumbnail ${index}`}
                onClick={() => selectImage(index)}
              />
            ))}
          </section>
        )}
        <section className="image_tool_container">
          <ImageManipulationTools
            onZoomIn={onZoomIn}
            onZoomOut={onZoomOut}
            onReset={onReset}
            onRotateLeft={onRotateLeft}
            onRotateRight={onRotateRight}
            onFlipHorizontal={() => onFlip({ ...flip, X: flip.X * -1 })}
            onFlipVertical={() => onFlip({ ...flip, Y: flip.Y * -1 })}
            showFullScreen={showFullScreenControls}
            handleFilterChange={handleFilterChange}
          />
        </section>
      </div>
    </div>
  );
};

interface ReviewImageModalProps {
  show: boolean;
  src: string;
  imageType: string;
  label?: string;
  options?: object;
  onClose: () => void;
}

const DocumentReviewImageModal: React.FC<ReviewImageModalProps> = ({
  show,
  src,
  imageType,
  onClose,
  ...props
}) => {
  const navigationRef = useRef(null);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    },
    [onClose],
  );

  return (
    <Modal
      additionalClasses="modal new-styles document_review_image_modal"
      show={show}
    >
      <div ref={navigationRef}>
        <nav className="modal-navigation">
          <ul>
            <li>
              <button data-variant="ghost" type="button" onClick={onClose}>
                <img src={CloseIcon} alt="" />
                <span className="visually-hidden">Close Modal</span>
              </button>
            </li>
          </ul>
        </nav>
        <DocumentImageViewer
          showFullScreenControls={false}
          hasFocus
          imageUrls={[src]}
          options={props.options}
          imageType={imageType}
          onKeyDown={handleKeyDown}
          label={props.label}
        />
      </div>
    </Modal>
  );
};

export default DocumentImageViewer;
