import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

interface LogOutResponse {
  auth_token: string;
}

const logOut = async () => {
  const { data } = await axios.delete<LogOutResponse>("/auth");
  return data;
};

export const useLogOut = (
  options?: UseMutationOptions<
    LogOutResponse,
    AxiosError<{ errors: string[] }>
  >,
) =>
  useMutation({
    mutationFn: logOut,
    ...options,
  });
