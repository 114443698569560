import React from "react";
import { css } from "util/format_helpers";
import styles from "./pages.module.scss";

export function PageTitle({ children, className, style }) {
  return (
    <div style={style} className={css(styles.title, "h1", className)}>
      {children}
    </div>
  );
}

export function PageSubTitle({ children, style }) {
  return (
    <div className={css("h4", styles.subTitle)} style={style}>
      {children}
    </div>
  );
}

export function StandardTextBold({ children }) {
  return <div className={styles.standardTextBold}>{children}</div>;
}
