import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { omit } from "lodash";

interface LogInResponse {
  auth_token: string;
  pre_otp_user?: string;
}

interface LogInInput {
  email: string;
  password: string;
}

const logIn = async (input: LogInInput) => {
  const { data } = await axios.post<LogInResponse>("/auth", input);
  return data;
};

export const useLogIn = (
  options?: UseMutationOptions<
    LogInResponse,
    AxiosError<{ errors: string[] }>,
    LogInInput
  >,
) =>
  useMutation({
    mutationFn: logIn,
    onSuccess: (data, ...args) => {
      if (data.auth_token) {
        localStorage.setItem("token", data.auth_token);
      } else if (data.pre_otp_user) {
        localStorage.setItem("preOTPUser", JSON.stringify(data.pre_otp_user));
      }

      options?.onSuccess?.(data, ...args);
    },
    ...omit(options, ["onSuccess"]),
  });
