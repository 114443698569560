import React, { useEffect, useState } from "react";
import Continue from "assets/svgs/continue.svg";
import { Loader } from "components/reusable/Loader";
import { getTicketAttributes, createTicket } from "./api";

function Form({
  defaultTicket,
  hiddenFields,
  ticketTypeId,
  onSubmit,
  getTicketAttributes,
  createTicket,
  intro,
}) {
  const screens = ["loading", "error", "success"];
  const [screen, setScreen] = useState(screens[0]);
  const formStates = ["idle", "submitting", "error", "success"];
  const [formState, setFormState] = useState(formStates[0]);
  const [formError, setFormError] = useState();
  const [formInputs, setFormInputs] = useState();
  const [ticket, setTicket] = useState(defaultTicket);

  useEffect(() => {
    fetchTicketAttributes();
  }, []);

  const onChange = (event) => {
    setTicket((state) => ({
      ...state,
      [event.target.name]: event.target.value,
    }));
  };

  const getFormControl = (input) => {
    switch (input.input_type) {
      case "select":
        return (
          <div className="input-group" key={input.name}>
            <label htmlFor={input.name}>{input.label}</label>
            <select
              className="select"
              required={input.required}
              id={input.name}
              name={input.name}
              onChange={onChange}
              defaultValue=""
              value={ticket[input.name]}
            >
              <option value="">--Select one--</option>
              {input.options.map((option) => (
                <option
                  key={`${input.name}-${option.value}`}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        );
      case "text":
        return (
          <div className="input-group" key={input.name}>
            <label htmlFor={input.name}>{input.label}</label>
            <input
              required={input.required}
              type="text"
              id={input.name}
              onChange={onChange}
              name={input.name}
              value={ticket[input.name]}
            />
          </div>
        );
      case "textarea":
        return (
          <div className="input-group" key={input.name}>
            <label htmlFor={input.name}>{input.label}</label>
            <textarea
              required={input.required}
              onChange={onChange}
              name={input.name}
              value={ticket[input.name]}
            />
          </div>
        );
      default:
        throw new Error("input must have an input_type entry");
    }
  };

  const createFormInputs = (ticketAttributes) => {
    const result = ticketAttributes
      .filter((input) => !hiddenFields.includes(input.name))
      .map((input) => getFormControl(input));

    return <div className="stack">{result}</div>;
  };

  const fetchTicketAttributes = async () => {
    setScreen("loading");

    const response = await getTicketAttributes({
      ticket_type_id: ticketTypeId,
    });

    if (response.ticket_attributes) {
      const formControls = createFormInputs(response.ticket_attributes);
      setFormInputs(formControls);
      setScreen("success");
    } else {
      setScreen("error");
    }
  };

  const submitTicket = async (event) => {
    event.preventDefault();
    setFormError();
    setFormState("submitting");

    const payload = {
      ticket_attributes: ticket,
      ticket_type_id: ticketTypeId,
    };

    const response = await createTicket(payload);

    if (!response.error) {
      onSubmit();
    } else {
      setFormError(response?.error?.message);
      setFormState("error");
    }
  };

  return {
    loading: <Loader />,
    error: (
      <div className="stack">
        <p className="color-fail">Failed to load form</p>

        <p>
          <button
            data-variant="primary"
            type="button"
            onClick={fetchTicketAttributes}
          >
            <img src={Continue} alt="" />
            <span>Try again</span>
          </button>
        </p>
      </div>
    ),
    success: (
      <form className="stack" onSubmit={submitTicket}>
        <p>
          <strong>{intro}</strong>
        </p>

        {formInputs}

        {formState === "error" && <p className="color-fail">{formError}</p>}

        <p style={{ textAlign: "right" }}>
          <button
            type="submit"
            data-variant="primary"
            onClick={submitTicket}
            disabled={formState === "submitting"}
          >
            <img src={Continue} alt="" />
            <span>Submit</span>
          </button>
        </p>
      </form>
    ),
  }[screen];
}

Form.defaultProps = {
  hiddenFields: ["partner_id"],
  intro: "Give us some quick details",
  defaultTicket: {},
  onSubmit: () => {},
  getTicketAttributes,
  createTicket,
};

export default Form;
