import React from "react";

import DateFilter from "./date_filter";
import FilterDropdowns from "./filter_dropdowns";

function FilterJobTable(props) {
  const [filters, setFilters] = React.useState(
    props.filters.reduce(
      (acc, { filter, input }) => ({ ...acc, [filter]: input }),
      {},
    ),
  );

  React.useEffect(() => {
    const _filters = {};
    props.filters.forEach(({ filter, input }) => {
      _filters[filter] = input;
    });

    setFilters(_filters);
  }, [props.filters]);

  const onClearFilterClick = () => {
    props.removeSelectors();
    props.onClearFilter();
  };

  const onChangeFilter = (type, value) => {
    setFilters({ ...filters, [type]: value });
    props.changeFilter(type, value);
  };

  return (
    <div className="filters__container">
      <span className="filters__by">Filter by:</span>

      <div className="filters">
        <DateFilter
          startDate={props.startDate}
          endDate={props.endDate}
          searchByDate={props.searchByDate}
          handleDateChange={props.handleDateChange}
          fetchingData={props.fetchingData}
          userType={props.userType}
          onDateToggle={props.onDateToggle}
          dateToggle={props.dateToggle}
        />
        <FilterDropdowns
          filters={filters}
          changeFilter={onChangeFilter}
          pageName={props.pageName}
          smileSecureEnabled={props.smileSecureEnabled}
          selectedCountries={props.selectedCountries}
          onCountriesSelected={props.onCountriesSelected}
        />
        <div className="filters__clear">
          <button
            className="text-button"
            type="button"
            onClick={onClearFilterClick}
          >
            Reset Filters
          </button>
        </div>
      </div>
    </div>
  );
}

export default FilterJobTable;
