import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

interface EnqueueReviewResponse {
  enqueued: boolean;
}

interface EnqueueReviewInput {
  id: number;
  enabled: boolean;
}

const enqueueReview = async ({ id }: EnqueueReviewInput) => {
  const { data } = await axios.get(`/reviews/${id}/check_and_enqueue_review`);
  return data;
};

export const ENQUEUE_REVIEW_QUERY_KEY = "check_and_enqueue_review";

export const useEnqueueReview = (
  input: EnqueueReviewInput,
  options?: UseQueryOptions<
    EnqueueReviewResponse,
    AxiosError<{ errors: string[] }>
  >,
) =>
  useQuery({
    queryKey: [ENQUEUE_REVIEW_QUERY_KEY, input],
    queryFn: () => enqueueReview(input),
    enabled: !!input.id && input.enabled,
    ...options,
  });
