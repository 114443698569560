import React from "react";
import styles from "./news_media.module.css";

export interface AMLNewsMedia {
  newsCategory: string;
  newsCategoryLabel: string;
  numberOfArticles: number;
}

interface NewsMediaProps {
  posts: AMLNewsMedia[];
  onClick: (post: AMLNewsMedia) => void;
}

const NewsMedia: React.FC<NewsMediaProps> = ({ posts, onClick }) =>
  posts.map((post) => (
    <div className="new-styles" key={post.newsCategory}>
      <button
        data-variant="ghost"
        onClick={() => onClick(post)}
        className={styles.newsArticleButton}
      >
        {post.numberOfArticles} Article(s) related to {post.newsCategoryLabel}
      </button>
    </div>
  ));

export default NewsMedia;
