import React from "react";
import styles from "./modal.style.module.css";

function Modal({ show, children, additionalClasses = "" }) {
  return (
    show && (
      <div className={styles.modalBackdrop}>
        <div className={`${styles.container} ${additionalClasses}`}>
          {children}
        </div>
      </div>
    )
  );
}

export default Modal;
