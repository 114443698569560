import React, { useState, useEffect } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

type Props = {
  time: number;
  onTimeout: () => void;
  textColor: string;
  trailColor: string;
};

const CircleLoaderWithTimer = ({
  time,
  onTimeout,
  textColor,
  trailColor,
}: Props) => {
  const [timeLeft, setTimeLeft] = useState(time);

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timerId);
    }
    onTimeout();
  }, [timeLeft, onTimeout]);

  const color = timeLeft > 20 ? textColor : "#91190f";

  return (
    <div style={{ height: 65, width: 65 }}>
      <CircularProgressbar
        value={(timeLeft / time) * 100}
        text={`${timeLeft}`}
        styles={buildStyles({
          pathColor: color,
          rotation: 0,
          textColor: color,
          trailColor,
        })}
      />
    </div>
  );
};

export default CircleLoaderWithTimer;
