import React, { forwardRef, useEffect, useLayoutEffect, useState } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import DropDown from "components/reusable/dropdown";
import { Loader } from "components/reusable/Loader";
import CheckIcon from "resources/img/icons/bubble-check.svg";
import FilterIcon from "resources/img/icons/filter.svg";

export function FilterModal({ show, onClose, onClick }) {
  const [showSuccess, setShowSuccess] = useState(false);
  useLayoutEffect(() => {
    if (show) {
      document.body.classList.add("filter-modal-open");
    } else {
      document.body.classList.remove("filter-modal-open");
      setShowSuccess(false);
    }
  }, [show]);

  if (!show) {
    return null;
  }

  if (showSuccess) {
    return (
      <div className="modal-backdrop">
        <div className="smile-modal modal-style filter-modal">
          <SuccessMessage onClick={onClose} />
        </div>
      </div>
    );
  }

  return (
    <div className="modal-backdrop">
      <div className="smile-modal modal-style filter-modal">
        <FilterForm
          onClose={onClose}
          onClick={async (data) => {
            await onClick(data);
            setShowSuccess(true);
          }}
        />
      </div>
    </div>
  );
}

function FilterForm({ onClose, onClick }) {
  const [selectedOption, setSelectedOption] = useState();
  const [dropdownLabel, setDropdownLabel] = useState("Date");
  const [showCustomDateSelector, setShowCustomDateSelector] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [loading, setLoading] = useState(false);
  const [validationError, setValidationError] = useState();
  const [error, setError] = useState();

  const calculateDateRange = (value) => {
    const endDate = moment.utc();
    let startDate = moment.utc();
    if (value === "last_week") {
      startDate = startDate.subtract(1, "weeks");
    } else if (value === "last_month") {
      startDate = startDate.subtract(1, "months");
    } else if (value === "last_year") {
      startDate = startDate.subtract(1, "years");
    }
    return {
      startDate: startDate.startOf("days").toDate(),
      endDate: endDate.endOf("days").toDate(),
    };
  };

  const handleOptionSelection = (option) => {
    setDropdownLabel(option.label);
    setSelectedOption(option.value);
    if (option.value === "custom") {
      setShowCustomDateSelector(true);
    } else {
      setShowCustomDateSelector(false);
      const result = calculateDateRange(option.value);
      setStartDate(result.startDate);
      setEndDate(result.endDate);
    }
  };

  const onConfirmClick = async () => {
    try {
      setLoading(true);
      await onClick({ startDate, endDate });
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  };

  const validateDateRange = () => {
    if (selectedOption === undefined || !startDate || !endDate)
      return "Please select a start and end date";
    const numberOfDays = moment(endDate).diff(moment(startDate), "days");
    if (numberOfDays > 365) {
      return "Please select a date range that is within a 365 day period";
    }
    return undefined;
  };
  useEffect(
    () => setValidationError(validateDateRange()),
    [startDate, endDate],
  );
  return (
    <div className="filter-modal__form">
      <img className="icon--large" src={FilterIcon} alt="filter-icon" />
      <title className="filter-title">Export Job List CSV</title>
      <title className="filter-subtitle">
        Kindly select the timespan for the job summary
      </title>
      <div>
        <label>Select Range</label>
        <DropDown
          showFilterLabel
          className="smile-dropdown date-dropdown"
          label={dropdownLabel}
          selectedOption={selectedOption}
          options={[
            {
              label: "Last Week",
              value: "last_week",
            },
            {
              label: "Last Month",
              value: "last_month",
            },
            {
              label: "Last Year",
              value: "last_year",
            },
            {
              label: "Custom Range",
              value: "custom",
            },
          ]}
          handleOptionSelection={handleOptionSelection}
        />
        {showCustomDateSelector && (
          <DateRange
            onDateChange={(result) => {
              setStartDate(result.startDate);
              setEndDate(result.endDate);
            }}
          />
        )}
        {(validationError || error) && (
          <small className="message-text--error">
            {validationError || error}
          </small>
        )}
      </div>
      <div className="filter-modal__action-btn-group">
        <button className="btn cancel-btn" type="button" onClick={onClose}>
          Cancel
        </button>
        <button
          disabled={!!validationError || loading}
          onClick={onConfirmClick}
          className="btn btn-primary"
          type="button"
        >
          {loading && (
            <>
              <Loader />
              &nbsp;&nbsp;
            </>
          )}
          Confirm
        </button>
      </div>
    </div>
  );
}
function SuccessMessage({ onClick }) {
  return (
    <div className="filter-modal__success">
      <img className="icon--x-large" src={CheckIcon} alt="close-icon" />
      <div className="success-message">
        <h2>Job Summary Sent!</h2>
        <p>
          The job summary you requested for has been sent to your registered
          email.
        </p>
      </div>
      <div className="filter-modal__action-btn-group">
        <button onClick={onClick} className="btn btn-primary" type="button">
          Complete
        </button>
      </div>
    </div>
  );
}
function DateRange({ onDateChange }) {
  const [showStartDate, setShowStartDate] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState();
  const [showEndDate, setShowEndDate] = useState(false);
  const [selectedEndDate, setSelectedEndDate] = useState();

  return (
    <div className="date-range">
      <div className="date-picker-input">
        <label>Start Date</label>
        <DatePicker
          dropdownMode="select"
          selectsStart
          open={showStartDate}
          onClickOutside={() => setShowStartDate(false)}
          shouldCloseOnSelect={false}
          selected={selectedStartDate}
          popperClassName="popper-monkey-patch"
          startDate={selectedStartDate}
          endDate={selectedEndDate}
          maxDate={moment.utc().toDate()}
          onChange={(date) => {
            setSelectedStartDate(date);
            setShowStartDate(false);
            setShowEndDate(true);
            if (onDateChange) {
              onDateChange({
                startDate: date,
                endDate: selectedEndDate,
              });
            }
          }}
          onInputClick={() => {
            setShowStartDate(!showStartDate);
            setShowEndDate(false);
          }}
          dateFormat="d/MM/yyyy"
          customInput={<DateInput />}
        />
      </div>
      <div className="date-picker-input">
        <label>End Date</label>
        <DatePicker
          dropdownMode="select"
          selectsEnd
          onClickOutside={() => setShowEndDate(false)}
          open={showEndDate}
          shouldCloseOnSelect={false}
          startDate={selectedStartDate}
          minDate={selectedStartDate}
          selected={selectedEndDate}
          endDate={selectedEndDate}
          maxDate={moment.utc().toDate()}
          onChange={(value) => {
            const date = moment(value).utc().endOf("days").toDate();
            setSelectedEndDate(date);
            if (onDateChange) {
              onDateChange({
                endDate: date,
                startDate: selectedStartDate,
              });
            }
          }}
          dateFormat="d/MM/yyyy"
          onInputClick={() => {
            setShowStartDate(false);
            setShowEndDate(!showEndDate);
          }}
          popperClassName="popper-monkey-patch popper--triangle"
          customInput={<DateInput />}
        />
      </div>
    </div>
  );
}
const DateInput = forwardRef(({ onClick, ...props }, ref) => (
  <div className="date-input" onClick={onClick}>
    <input ref={ref} {...props} />
  </div>
));

DateInput.displayName = "DateInput";
