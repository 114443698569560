import React from "react";
import "./shimmer.css";

export default function BarChartShimmer() {
  return (
    <div className="shimmer-wrapper">
      <div className="horizontal-bar">
        <div className="bars">
          <div className="bar animate-shimmer" />
          <div className="bar animate-shimmer" style={{ height: "100px" }} />
          <div className="bar animate-shimmer" style={{ height: "60px" }} />
          <div className="bar animate-shimmer" style={{ height: "30px" }} />
        </div>
        <div className="shimmer-legend">
          <div
            className="shimmer-text br animate-shimmer"
            style={{ width: "10px" }}
          />
          <div
            className="shimmer-text br animate-shimmer"
            style={{ width: "15px" }}
          />
          <div
            className="shimmer-text br animate-shimmer"
            style={{ width: "10px" }}
          />
          <div
            className="shimmer-text br animate-shimmer"
            style={{ width: "15px" }}
          />
          <div
            className="shimmer-text br animate-shimmer"
            style={{ width: "10px" }}
          />
          <div
            className="shimmer-text br animate-shimmer"
            style={{ width: "15px" }}
          />
        </div>
      </div>
    </div>
  );
}
