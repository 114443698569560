export type EnvironmentContextType = {
  enabled: boolean;
  environment: string | null;
  onEnvironmentChange: (newEnvironment: boolean) => void;
  setEnabled: (enabled: boolean) => void;
  setViewAs?: (viewAs: "admin" | "partner") => void;
  viewAs?: "admin" | "partner";
};

export type VERIFICATION_RESULT =
  | "Exact Match"
  | "No Match"
  | "Partial Match"
  | "Transposed"
  | "Not Provided";

export enum VerificationResult {
  ExactMatch = "Exact Match",
  NoMatch = "No Match",
  PartialMatch = "Partial Match",
  Transposed = "Transposed",
  NotProvided = "Not Provided",
}
