import React from "react";
import IconKycSubmitted from "resources/img/icons/kyc_submitted.svg";
import styles from "./nin-v2-under-review.module.css";

function NINV2UnderReview() {
  return (
    <div className={styles.container}>
      <img src={IconKycSubmitted} alt="nin tokenization icon" />
      <p>Your information is under review by NIMC.</p>
      <p>
        We will send an email to the account owner once the review is completed.
      </p>
    </div>
  );
}

export default NINV2UnderReview;
