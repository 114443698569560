import React from "react";
import { useHistory } from "react-router-dom";
import { useQueryParams } from "./useQueryParams";

/**
 * A wrapper for reading and writing search parameters via the
 * URLSearchParams interface.
 * @param {object} initial - inital query
 * @returns {Array} search params and a function to set the url params
 */
export function useSearchQuery(initial) {
  const searchParams = {
    ...(initial || {}),
    ...useQueryParams(),
  };

  const history = useHistory();
  const setSearchParams = React.useCallback(
    (nextParams, navigateOptions) => {
      const newSearchParams = new URLSearchParams(nextParams);
      history.push(`?${newSearchParams}`, navigateOptions);
    },
    [history, searchParams],
  );

  return [searchParams, setSearchParams];
}
