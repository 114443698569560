import React, { useState } from "react";
import nextIcon from "resources/img/icons/next.svg";
import previousIcon from "resources/img/icons/previous.svg";
import styles from "./pagination.module.css";

function Pagination({ nextPage, activePage, onPageChange }) {
  const [page, setPage] = useState(activePage || 0);

  const handlePageChange = (mode) => {
    if (mode === "next" && page < nextPage) {
      setPage(page + 1);
      onPageChange(page + 1);
    }

    if (mode === "previous" && page > 1) {
      setPage(page - 1);
      onPageChange(page - 1);
    }
  };

  return (
    <div className={styles.pagination}>
      <button
        id="previous"
        className={styles.previous}
        disabled={page <= 1}
        onClick={() => handlePageChange("previous")}
      >
        <img src={previousIcon} alt="previous" />
        <div>Previous </div>
      </button>
      <div className={styles.number}>{page}</div>
      <button
        id="next"
        className={styles.next}
        disabled={page >= nextPage}
        onClick={() => handlePageChange("next")}
      >
        <div>Next</div>
        <img src={nextIcon} alt="next" />
      </button>
    </div>
  );
}

export default Pagination;
