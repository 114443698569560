import { ThemeConfig } from "antd";

const theme: ThemeConfig = {
  token: {
    fontFamily: "var(--font-default)",
    colorPrimary: "#151f72",
    fontSize: 16,
    colorPrimaryHover: "#1f2da7",
    colorPrimaryActive: "#151f72",
    colorLink: "#2f54eb",
    borderRadius: 6,
    colorPrimaryBg: "#cad6f5",
    colorSuccess: "#26a54f",
    colorSuccessBg: "#c9f5d0",
    colorSuccessBgHover: "#99eaa8",
    colorError: "#d1272a",
    colorWarning: "#e69c0a",
    borderRadiusLG: 16,
  },
  components: {
    Button: {
      borderRadiusSM: 12,
      borderRadius: 16,
      borderRadiusLG: 20,
    },
    DatePicker: {
      borderRadiusSM: 12,
      borderRadius: 16,
      borderRadiusLG: 20,
    },
    Input: {
      borderRadiusSM: 12,
      borderRadius: 16,
      borderRadiusLG: 20,
    },
    InputNumber: {
      borderRadiusSM: 12,
      borderRadius: 16,
      borderRadiusLG: 20,
    },
    Card: {
      borderRadiusLG: 16,
    },
    Modal: {
      borderRadiusLG: 16,
    },
    Checkbox: {
      borderRadiusSM: 4,
    },
    Select: {
      borderRadius: 36,
      borderRadiusSM: 4,
      borderRadiusLG: 8,
      paddingXXS: 6,
    },
    Segmented: {
      borderRadiusSM: 24,
      borderRadius: 24,
    },
    Tree: {
      borderRadiusSM: 4,
    },
    Layout: {
      headerBg: "#fff",
    },
    Menu: {
      darkItemBg: "#fff",
      darkItemSelectedBg: "rgb(206,222,244)",
    },
  },
};

export default theme;
