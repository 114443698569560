import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { removeEnterpriseState } from "actions/actions";
import { receiveToken } from "actions/session";
import { EnvironmentContextType } from "components/common/types";
import { DisplayEnvironment } from "contexts/displayEnvironment";
import useAuthenticatedUser from "hooks/useAuthenticatedUser";
import { useLogOut } from "mutations";
import logo from "resources/img/brand/smile-id__dark-horizontal.svg";
import chevronDown from "resources/img/icons/chevron-down.svg";
import chevronUp from "resources/img/icons/chevron-up.svg";
import { User } from "../../hooks/useAuthenticatedUser";

function EnvSlider() {
  /*
   *	APPROACH inspired by: https://www.sarasoueidan.com/blog/toggle-switch-design/
   */
  const { enabled, environment, onEnvironmentChange } =
    useContext<EnvironmentContextType>(DisplayEnvironment as never);

  const isProduction = String(environment) === "true";

  if (!enabled) return null;

  return (
    <div
      className="newsmile-header__env env-slider"
      key="environmentSlider"
      id="environmentSlider"
    >
      <fieldset aria-label="environment switcher" role="radiogroup">
        <legend className="visually-hidden">Change Environment</legend>
        <div className="switch">
          <label htmlFor="sandbox">
            {isProduction ? (
              <span className="">Toggle to Sandbox</span>
            ) : (
              <span className="selected selected--orange">
                <strong>You're in Sandbox</strong>
              </span>
            )}
          </label>
          <span className="switch__wrapper">
            <input
              onChange={() => onEnvironmentChange(false)}
              type="radio"
              name="environment"
              id="sandbox"
              checked={!isProduction}
            />
            <input
              onChange={() => onEnvironmentChange(true)}
              type="radio"
              name="environment"
              id="production"
              checked={isProduction}
            />
            <span aria-hidden="true" className="switch__background" />
            <span aria-hidden="true" className="switch__switcher" />
          </span>
          <label htmlFor="production">
            {isProduction ? (
              <span className="selected selected--green">
                You're in Production
              </span>
            ) : (
              <span className="">Toggle to Production</span>
            )}
          </label>
        </div>
      </fieldset>
    </div>
  );
}

interface MenuProps {
  userEmail: string | null;
}

function Menu({ userEmail }: MenuProps) {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const logOut = useLogOut({
    onSuccess: (data) => {
      localStorage.removeItem("token");
      localStorage.removeItem("adminPartnerId");
      sessionStorage.removeItem("url");
      dispatch(receiveToken(data));
      history.push("/");
    },
  });

  function toggleMenu() {
    setShowMenu(!showMenu);
  }

  return (
    <div key="menu">
      <div className="newsmile-header__user" onClick={toggleMenu} id="menu">
        {userEmail}
        <img
          className="newsmile-header__menu-toggle"
          alt="logo"
          src={showMenu ? chevronUp : chevronDown}
        />
      </div>

      {showMenu && (
        <div className="newsmile-header__menu" onMouseLeave={toggleMenu}>
          <div className="newsmile-header__menu-links">
            <a
              onClick={async () => {
                await logOut.mutateAsync();
                removeEnterpriseState(dispatch);
              }}
            >
              Logout
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

interface HeaderProps {
  isAuthenticated?: boolean;
}

function Header({ isAuthenticated = false }: HeaderProps) {
  const { userEmail, userType, permissionGroup }: User = useAuthenticatedUser();
  const homeUri =
    (typeof userType === "string" &&
      {
        admin:
          permissionGroup === "Operator"
            ? "/admin/reviews/completed"
            : "/admin/access_logs",
        partner: "/partner/dashboard",
      }[userType]) ||
    "/";

  return (
    <div className="newsmile-header">
      <div className="newsmile-header__left-container">
        <a href={homeUri}>
          <img className="newsmile-header__logo" src={logo} alt="logo" />
        </a>
      </div>
      {isAuthenticated && (
        <div className="newsmile-header__right-container">
          <EnvSlider />
          <Menu userEmail={userEmail} />
        </div>
      )}
    </div>
  );
}

export default Header;
