import DocumentNotClassifiedIcon from "./document-not-classified.svg";
import InformationMismatchIcon from "./information-mismatch.svg";
import SuspectedFraudIcon from "./suspected-fraud.svg";
import SuspectedPhotocopyIcon from "./suspected-photocopy.svg";
import UncertainTextExtractionIcon from "./uncertain-text-extraction.svg";

const iconMap: Record<string, string> = {
  "Uncertain text extraction": UncertainTextExtractionIcon,
  "Suspected Fraud": SuspectedFraudIcon,
  "Suspected Photocopy": SuspectedPhotocopyIcon,
  "Information mismatch": InformationMismatchIcon,
  "Document could not be classified": DocumentNotClassifiedIcon,
};

export default iconMap;
