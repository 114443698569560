import { createReducer } from "./create_reducer";

const initialState = { reviewsPending: 0, kycPending: 0 };

const reducer = createReducer(initialState, {
  PENDING_KYC_AND_REVIEW_COUNT: (state, action) => {
    const { humanReview, kycReview } = action;
    return {
      reviewsPending: humanReview.pending || initialState.reviewsPending,
      kycPending: kycReview.pending || initialState.kycPending,
    };
  },
});

export default reducer;
