import React from "react";
import classNames from "classnames";
import CloseIcon from "resources/img/icons/x.svg";

function Modal({
  children = undefined,
  content,
  customStyle,
  onClick = undefined,
  onClose,
  show,
}) {
  if (!show) {
    return null;
  }

  const modalClassNames = classNames("smile-modal", "modal-style", customStyle);

  return (
    <div
      className="modal-backdrop"
      data-testid="modal-backdrop"
      onClick={onClick ? onClose : () => {}}
    >
      <div className={modalClassNames} data-testid="modal-content">
        <div
          className="button-container--right smile-modal__close"
          data-testid="close-button"
        >
          <img
            alt="close-icon"
            className="icon--medium"
            data-testid="close-icon"
            onClick={onClose}
            src={CloseIcon}
          />
        </div>
        {children || content}
      </div>
    </div>
  );
}

export default Modal;
