import React from "react";
import styles from "./graph_pagination.module.css";

function GraphPagination(props) {
  const { nextPage, activePage, onPageChange } = props;
  const page = activePage || 0;

  const onPreviousPage = () => {
    if (page > 1) {
      onPageChange(page - 1);
    }
  };
  const onNextPage = () => {
    if (page < nextPage) {
      onPageChange(page + 1);
    }
  };

  const previousButtonDisabled = page <= 1;
  const nextIconDisabled = page >= nextPage;

  return (
    <div className={styles.pagination} style={props.style}>
      <button
        id="previous"
        className={styles.previous}
        disabled={previousButtonDisabled}
        onClick={onPreviousPage}
        aria-label="Previous Page"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.19 0H5.81C2.17 0 0 2.17 0 5.81V14.18C0 17.83 2.17 20 5.81 20H14.18C17.82 20 19.99 17.83 19.99 14.19V5.81C20 2.17 17.83 0 14.19 0ZM11.79 13C12.08 13.29 12.08 13.77 11.79 14.06C11.64 14.21 11.45 14.28 11.26 14.28C11.07 14.28 10.88 14.21 10.73 14.06L7.2 10.53C7.06052 10.3889 6.9823 10.1984 6.9823 10C6.9823 9.80157 7.06052 9.61114 7.2 9.47L10.73 5.94C11.02 5.65 11.5 5.65 11.79 5.94C12.08 6.23 12.08 6.71 11.79 7L8.79 10L11.79 13Z"
            fill={previousButtonDisabled ? "#E5E5E5" : "#9394AB"}
          />
        </svg>
        <span className="visually-hidden">Previous Page</span>
      </button>
      <button
        id="next"
        className={styles.next}
        disabled={page >= nextPage}
        onClick={onNextPage}
        aria-label="Next Page"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.19 0H5.81C2.17 0 0 2.17 0 5.81V14.18C0 17.83 2.17 20 5.81 20H14.18C17.82 20 19.99 17.83 19.99 14.19V5.81C20 2.17 17.83 0 14.19 0ZM12.79 10.53L9.26 14.06C9.11 14.21 8.92 14.28 8.73 14.28C8.54 14.28 8.35 14.21 8.2 14.06C8.06052 13.9189 7.9823 13.7284 7.9823 13.53C7.9823 13.3316 8.06052 13.1411 8.2 13L11.2 10L8.2 7C8.06052 6.85886 7.9823 6.66843 7.9823 6.47C7.9823 6.27157 8.06052 6.08114 8.2 5.94C8.49 5.65 8.97 5.65 9.26 5.94L12.79 9.47C13.09 9.76 13.09 10.24 12.79 10.53Z"
            fill={nextIconDisabled ? "#E5E5E5" : "#9394AB"}
          />
        </svg>
        <span className="visually-hidden">Next Page</span>
      </button>
    </div>
  );
}

export default GraphPagination;
