import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

interface Confirm2FAOTPResponse {
  auth_token: string;
  pre_otp_user: string;
}

interface Confirm2FAOTPInput {
  email: string;
  password: string;
}

const confirm2FAOTP = async (input: Confirm2FAOTPInput) => {
  const { data } = await axios.post<Confirm2FAOTPResponse>(
    "/two_factor_authentication/confirmation",
    input,
  );
  return data;
};

export const useConfirm2FAOTP = (
  options?: UseMutationOptions<
    Confirm2FAOTPResponse,
    AxiosError<{ errors: string[] }>,
    Confirm2FAOTPInput
  >,
) =>
  useMutation({
    mutationFn: confirm2FAOTP,
    ...options,
  });
