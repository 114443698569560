import moment from "moment";
// ID image logos

import GH_DRIVERS_LICENSE from "resources/img/id_provider_cards/GH/GH_DRIVERS_LICENSE.png";
import GH_PASSPORT from "resources/img/id_provider_cards/GH/GH_PASSPORT.png";
import GH_SSNIT from "resources/img/id_provider_cards/GH/GH_SSNIT.png";
import GH_VOTER_ID from "resources/img/id_provider_cards/GH/GH_VOTER_ID.png";

import KE_ALIEN_CARD from "resources/img/id_provider_cards/KE/KE_ALIEN_CARD.png";
import KE_NATIONAL_ID from "resources/img/id_provider_cards/KE/KE_NATIONAL_ID.png";
import KE_PASSPORT from "resources/img/id_provider_cards/KE/KE_PASSPORT.png";
import NG_BVN from "resources/img/id_provider_cards/NG/NG_BVN.png";
import NG_DRIVERS_LICENSE from "resources/img/id_provider_cards/NG/NG_DRIVERS_LICENSE.png";
import NG_NATIONAL_ID from "resources/img/id_provider_cards/NG/NG_NATIONAL_ID.png";
import NG_NIN from "resources/img/id_provider_cards/NG/NG_NIN.png";
import NG_PASSPORT from "resources/img/id_provider_cards/NG/NG_PASSPORT.png";
import NG_TIN from "resources/img/id_provider_cards/NG/NG_TIN.png";
import NG_VOTER_ID from "resources/img/id_provider_cards/NG/NG_VOTER_ID.png";

import UG_NATIONAL_ID from "resources/img/id_provider_cards/UG/UG_NATIONAL_ID.png";
import ZA_NATIONAL_ID from "resources/img/id_provider_cards/ZA/ZA_NATIONAL_ID.png";

import { unifyIDAPICountry } from "./actions_helpers";

// these are the sample cards for display in webapp
// the provider logos need to be updated separately in actions_helpers.js
export const ID_IMAGE_PROVIDERS = {
  NG_BVN,
  NG_NIN,
  NG_PASSPORT,
  NG_DRIVERS_LICENSE,
  NG_VOTER_ID,
  NG_NATIONAL_ID,
  NG_TIN,

  KE_ALIEN_CARD,
  KE_DRIVERS_LICENSE: KE_NATIONAL_ID,
  KE_KRA_PIN: KE_NATIONAL_ID,
  KE_NATIONAL_ID,
  KE_NATIONAL_ID_NO_PHOTO: KE_NATIONAL_ID,
  KE_PASSPORT,

  GH_DRIVERS_LICENSE,
  GH_PASSPORT,
  GH_SSNIT,
  GH_VOTER_ID,

  ZA_NATIONAL_ID,
  ZA_NATIONAL_ID_NO_PHOTO: ZA_NATIONAL_ID,

  UG_NATIONAL_ID_NO_PHOTO: UG_NATIONAL_ID,
};

export const getProviderImage = (country, idType) => {
  country = unifyIDAPICountry(country);
  if (country && idType) {
    return ID_IMAGE_PROVIDERS[
      `${country.toUpperCase()}_${idType.toUpperCase()}`
    ];
  }
};

export const getMonthsOptions = (format = "MMMM, YYYY") => {
  const monthsOptions = [];
  for (let i = 0; i <= 6; i += 1) {
    const date = moment().subtract(i, "months");
    const formattedDate = date.format(format);
    monthsOptions.push({
      value: formattedDate,
      label: formattedDate,
    });
  }
  return monthsOptions;
};

export const isValidHttpUrl = (string) => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
};
