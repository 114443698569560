import React from "react";
import SummaryHeaderShimmer from "./summary_header_shimmer";

export default function SummaryMetricsHeader({
  loading,
  title,
  value,
  icon,
  note,
  valueColor,
}) {
  if (loading) {
    return <SummaryHeaderShimmer />;
  }

  return (
    <div className="summary-metrics">
      <section className="summary-metrics__title">{title}</section>
      <section className="summary-metrics__value">
        {value && (
          <>
            <div className="summary-metrics__value_percentage">
              <div className={valueColor}>{value}</div>
              {icon}
            </div>
            <h5 className="mute-note">{note}</h5>
          </>
        )}
      </section>
    </div>
  );
}
