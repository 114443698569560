import React from "react";
import { createPortal } from "react-dom";
import BackArrowIcon from "assets/svgs/back-arrow.svg";
import Modal from "components/modal/modal";
import CloseIcon from "resources/img/icons/close-icon.svg";

function CustomModal({
  children,
  containerClassName = "modal",
  isOpen = false,
  onBackPressed = () => {},
  onClose,
  showBackButton = true,
  showNav = true,
  featureClassName = "auto-pay",
}) {
  if (!isOpen) {
    return null;
  }
  return createPortal(
    <div
      className={`legacy new-styles new-typography center ${featureClassName}`}
    >
      <Modal show={isOpen} additionalClasses={containerClassName}>
        {showNav && (
          <nav className="modal-navigation">
            <ul>
              <li>
                {showBackButton && (
                  <button
                    data-variant="ghost"
                    type="button"
                    onClick={onBackPressed}
                  >
                    <img src={BackArrowIcon} alt="" />
                    <span style={{ color: "var(--web-digital-blue)" }}>
                      Go Back
                    </span>
                  </button>
                )}
              </li>

              <li>
                <button data-variant="ghost" type="button" onClick={onClose}>
                  <img src={CloseIcon} alt="" />
                  <span className="visually-hidden">Close Modal</span>
                </button>
              </li>
            </ul>
          </nav>
        )}
        <div className="modal__content">{children}</div>
      </Modal>
    </div>,
    document.body,
  );
}

export default CustomModal;
