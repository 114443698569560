import React from "react";
import "./shimmer.css";

export default function AreaChartShimmer({ height, width }) {
  return (
    <div
      className="shimmer-wrapper shimmer-area-chart animate-shimmer"
      style={{
        height: `${height || "328px"}`,
        width: `${width || "100%"}`,
      }}
    />
  );
}
