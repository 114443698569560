import { createReducer } from "./create_reducer";

const MessagesReducer = createReducer("", {
  RECEIVE_REVIEWS: (state, action) => action.reviewsAndCount.message || "",
  RECEIVE_MESSAGE: (state, action) => action.message.message || "",
  RECEIVE_DELETEDJOB: (state, action) =>
    action.resultJobsAndMessage.message || "",
});

export default MessagesReducer;
