import {
  Button,
  Card,
  Col,
  Image,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import classNames from "classnames";
import moment from "moment";
import AddressMap from "components/AddressVerificationResult/AddressMap";
import LocationPin from "components/AddressVerificationResult/LocationPin";
import { VerificationResult } from "components/common/types";
import { Loader } from "components/reusable/Loader";

import useCopyToClipboard from "hooks/useCopyToClipboard";
import { useAddressVerificationResult } from "queries";
import CopyIcon from "resources/img/icons/copy.svg";
import MatchedLocationIcon from "resources/img/icons/matched-location.png";
import SearchAddressIcon from "resources/img/icons/searched-address.png";
import { Address } from "types/api/addressVerification";
import { countryFlags } from "util/flags";

const { Title, Text } = Typography;

interface AddressVerificationResultProps {
  jobId: number;
}
type statusMessage = "success" | "warning" | "danger" | "secondary";

const RESULT_CODE_MAPPING: Record<string, statusMessage> = {
  "1012": "success",
  "1013": "danger",
  "1014": "danger",
  "1015": "danger",
  "1017": "warning",
  "1021": "warning",
  "1022": "danger",
};

const MATCH_MAPPING: Record<string, statusMessage> = {
  [VerificationResult.ExactMatch]: "success",
  [VerificationResult.PartialMatch]: "warning",
  [VerificationResult.NoMatch]: "danger",
  [VerificationResult.NotProvided]: "secondary",
  [VerificationResult.Transposed]: "success",
};

const resultSummaryColumns = [
  {
    title: "Checks",
    dataIndex: "checks",
    key: "checks",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
];

const addressColumns = [
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
    width: "30%",
  },
  {
    title: "Country",
    dataIndex: "country",
    key: "country",
    width: "10%",
  },
  {
    title: "Proximity",
    dataIndex: "proximity",
    key: "proximity",
  },
  {
    title: "Phone Number",
    dataIndex: "phone_number",
    key: "phone_number",
  },
  {
    title: "Last Updated",
    dataIndex: "last_updated",
    key: "last_updated",
  },
];

const searchedAddressColumns = [
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
    width: "30%",
  },
  {
    title: "Country",
    dataIndex: "country",
    key: "country",
    width: "10%",
  },
  {
    title: "Search Number",
    dataIndex: "search_number",
    key: "search_number",
  },
];

const CountryTag = ({ country }: { country: string }) => {
  const countryFlag = countryFlags[country.toUpperCase()];

  return (
    <Tag icon={countryFlag} className="bg-neutral-off-white border-0">
      <Text className="text-md ml-1">{country}</Text>
    </Tag>
  );
};

const LastUpdated = ({ lastUpdated }: { lastUpdated: string }) => (
  <Space direction="vertical" className="flex">
    <Text className="text-md">
      {lastUpdated
        ? moment(lastUpdated).format("DD MMM, YYYY")
        : "Not Available"}
    </Text>
    <Text className="text-sm text-neutral-dark-grey">
      {moment(lastUpdated).format("h:mma [UTC]")}
    </Text>
  </Space>
);

const Proximity = ({ value }: { value: number }) => {
  const units = value >= 1000 ? "kilometers" : "meters";
  const proximity = value >= 1000 ? value / 1000 : value;

  const classes = classNames("text-md", {
    "text-primary-green": value < 1000,
    "text-primary-orange": value >= 1000 && value < 2000,
    "text-secondary-blood-orange": value >= 2000,
  });

  return (
    <Space direction="vertical" className="flex">
      <Text className={classes}>
        {proximity} {units}
      </Text>
      <Text className="text-sm text-neutral-dark-grey">
        to address provided
      </Text>
    </Space>
  );
};

const AddressTag = ({
  address,
  isSearched = false,
  count = 0,
}: {
  address: Partial<Address>;
  isSearched?: boolean;
  count?: number;
}) => {
  const icon_url = address.is_matched ? MatchedLocationIcon : SearchAddressIcon;
  const { address_line1, address_line2, address_line3, city } = address;

  const { copyToClipboard, copySuccess } = useCopyToClipboard();

  const addressParts = [
    address_line1,
    address_line2,
    address_line3,
    city,
  ].filter(Boolean);

  const addressLine = addressParts.join(", ");
  const HoverText = (
    <Space direction="vertical">
      <Text className="text-sm ">Address Line 1: {address_line1}</Text>
      {address_line2 && (
        <Text className="text-sm ">Address Line 2: {address_line2}</Text>
      )}
      {address_line3 && (
        <Text className="text-sm ">Address Line 3: {address_line3}</Text>
      )}
    </Space>
  );

  return (
    <Space direction="horizontal">
      {!address.is_matched && !isSearched ? (
        <LocationPin count={count} />
      ) : (
        <Image preview={false} width={24} height={31} src={icon_url} />
      )}
      <Space direction="vertical">
        <Tooltip color="#fff" title={HoverText}>
          <Text className="text-md text-neutral-off-black">{addressLine}</Text>
        </Tooltip>
        {(address.digital_address || address.latitude || address.longitude) && (
          <Space direction="horizontal">
            {address.latitude && (
              <Text className="text-sm text-neutral-dark-grey">
                Lat: <Text className="text-sm">{address.latitude}</Text>
              </Text>
            )}{" "}
            {address.longitude && (
              <Text className="text-sm  text-neutral-dark-grey">
                Long: <Text className="text-sm">{address.longitude}</Text>
              </Text>
            )}
            {address.digital_address && (
              <Text className="text-sm text-neutral-dark-grey">
                Digital Address: {address.digital_address}
              </Text>
            )}
          </Space>
        )}
        <Button
          icon={<img src={CopyIcon} alt="copy" className="w-4 h-4" />}
          type="link"
          className="text-primary-blue text-sm -ml-4"
          onClick={() => copyToClipboard(`${addressLine}, ${address.country}`)}
          disabled={copySuccess}
        >
          {copySuccess ? "Copied" : "Copy"}
        </Button>
      </Space>
    </Space>
  );
};

const PhoneNumber = ({
  line1,
  line2,
}: {
  line1: string;
  line2: string | null;
}) => (
  <Space direction="vertical" className="flex">
    <Text className="text-md text-neutral-off-black">
      {line1 || "Not Available"}
    </Text>
    {line2 && (
      <Text className="text-sm text-neutral-dark-grey">
        Line 2: <Text>{line2}</Text>{" "}
      </Text>
    )}
  </Space>
);

const SearchNumber = ({
  search_number,
  search_number_type,
}: {
  search_number: string;
  search_number_type?: string | null;
}) => (
  <Space direction="vertical" className="flex">
    <Text className="text-md text-neutral-off-black">{search_number}</Text>
    {search_number_type && (
      <Text className="text-sm text-neutral-dark-grey">
        Search Number Type: <Text>{search_number_type}</Text>
      </Text>
    )}
  </Space>
);

function AddressVerificationResult({ jobId }: AddressVerificationResultProps) {
  const { data, isLoading, error } = useAddressVerificationResult({
    id: jobId,
  });

  if (isLoading) {
    return <Loader size="xl" />;
  }

  const statusClass = RESULT_CODE_MAPPING[data?.result_code as string];
  const fullNameMatch =
    data?.result?.Actions?.fullNameMatch || VerificationResult.NotProvided;

  const otherAddresses = (data?.addresses || [])
    .filter((address) => !address.is_matched && !address.is_searched)
    .map((address: Address, idx) => ({
      key: address.id,
      address: <AddressTag address={address} count={idx + 1} />,
      country: <CountryTag country={address.country} />,
      proximity: <Proximity value={address.proximity} />,
      phone_number: (
        <PhoneNumber
          line1={address.phone_number_1}
          line2={address.phone_number_2}
        />
      ),
      last_updated: <LastUpdated lastUpdated={address.last_updated_at} />,
    }));

  const hasOtherAddresses = otherAddresses.length > 0;

  const matchedAddress = (data?.addresses || [])
    .filter((address: Address) => address.is_matched)
    .map((address: Address) => ({
      key: address.id,
      address: <AddressTag address={address} />,
      country: <CountryTag country={address.country} />,
      proximity: <Proximity value={address.proximity} />,
      phone_number: (
        <PhoneNumber
          line1={address.phone_number_1}
          line2={address.phone_number_2}
        />
      ),
      last_updated: <LastUpdated lastUpdated={address.last_updated_at} />,
    }));

  const hasMatchedAddress = matchedAddress.length > 0;

  const searchedAddress = (data?.addresses || [])
    .filter((address) => address.is_searched)
    .map((address) => ({
      key: address.id,
      address: (
        <AddressTag address={address} isSearched={address.is_searched} />
      ),
      country: <CountryTag country={data?.country as string} />,
      search_number: (
        <SearchNumber
          search_number={data?.search_number as string}
          search_number_type={data?.search_number_type}
        />
      ),
    }));

  const hasSearchedAddress = searchedAddress.length > 0;

  const createdAt = moment(data?.created_at).format("DD MMM, YYYY");

  const resultSummary = [
    {
      key: 1,
      checks: "Status",
      status: (
        <Text type={statusClass}>
          {data?.result_code} - {data?.result_message}
        </Text>
      ),
    },
    {
      key: 2,
      checks: "Address details found",
      status:
        data?.addresses?.length === 0
          ? "No"
          : `Yes - ${matchedAddress.length + otherAddresses.length} address(es)`,
    },
    {
      key: 3,
      checks: "Matched address",
      status: hasMatchedAddress ? "Yes" : "No",
    },
    {
      key: 4,
      checks: "Full name searched",
      status: data?.full_name || "Not Available",
    },
    {
      key: 5,
      checks: "Full name found",
      status: data?.result?.Actions?.fullNameFound || "Not Applicable",
    },
    {
      key: 6,
      checks: "Full name match",
      status: fullNameMatch ? (
        <Text className="text-sm" type={MATCH_MAPPING[fullNameMatch]}>
          {fullNameMatch}
        </Text>
      ) : (
        "Not Applicable"
      ),
    },
  ];

  const addressesFound = matchedAddress.length + otherAddresses.length;

  return (
    <Card>
      <Title level={2} className="text-primary-blue mt-0">
        Address Verification
      </Title>

      {error && (
        <div className="mt-2">
          <Text type="danger">{error?.response?.data.error}</Text>
        </div>
      )}
      {!error && (
        <Space direction="vertical" size="middle" className="flex">
          <div>
            <Row>
              <Col className="text-xs" span={8}>
                Job ID
              </Col>
              <Col className="text-xs" span={8}>
                User ID
              </Col>
              <Col className="text-xs" span={8}>
                Created At
              </Col>
            </Row>
            <Row>
              <Col span={8}>{data?.job_id}</Col>
              <Col span={8}>{data?.partner_user_id}</Col>
              <Col span={8}>{createdAt}</Col>
            </Row>
          </div>

          <div className="mt-12">
            <Title level={5} className="text-primary-blue mt-0">
              Results Summary:
            </Title>
            <Table
              pagination={false}
              dataSource={resultSummary}
              columns={resultSummaryColumns}
            />
          </div>

          {hasSearchedAddress && (
            <div className="mt-12">
              <Title level={5} className="text-primary-blue mt-0">
                Searched Address
              </Title>
              <Table
                pagination={false}
                dataSource={searchedAddress}
                columns={searchedAddressColumns}
              />
            </div>
          )}

          {hasMatchedAddress && (
            <div className="mt-8">
              <Title level={5} className="text-primary-blue mt-0">
                Matched Address
              </Title>
              <Table
                pagination={false}
                dataSource={matchedAddress}
                columns={addressColumns}
              />
            </div>
          )}

          {hasOtherAddresses && (
            <div className="mt-8">
              <Title level={5} className="text-primary-blue mt-0">
                Other Addresses
              </Title>
              <Table
                pagination={false}
                dataSource={otherAddresses}
                columns={addressColumns}
              />
            </div>
          )}
          <div className="flex flex-col mt-8">
            {addressesFound > 0 && (
              <Text className="text-primary-orange mt-0 mb-2 text-center text-sm">
                {addressesFound} Address(es) found
              </Text>
            )}
            <AddressMap addressVerification={data} />
          </div>
        </Space>
      )}
    </Card>
  );
}

export default AddressVerificationResult;
