import React, { useState } from "react";
import unknownImg from "resources/img/unknown_person.jpg";

function PhotoCard({ smileJobId, photos }) {
  const [imageStatus, setImageStatus] = useState("loading");

  const handleImageErrored = (event) => {
    event.target.src = unknownImg;
  };

  const hasImages = photos.some((photo) => photo.image);

  return (
    <div className="job-result__section job-result__section--photos--with--back--ID">
      <div className="photos__row">
        {!hasImages && (
          <div className="photos__container__wrapper align-item--center">
            <div className="photo__container">
              <img className="photo js-photo" src={unknownImg} />
            </div>
          </div>
        )}
        {photos.map((photo, index) => (
          <div
            key={`photos-${index}`}
            className={`photos__container__wrapper align-item--center ${photo.key}`}
          >
            <div className="photo__container">
              {imageStatus === "loading" && (
                <div className="loader loading-data-spinner loading-data-spinner--photo" />
              )}
              <img
                className={`photo js-photo-${photo.label}-${smileJobId}`}
                src={photo.image ?? ""}
                onLoad={() => setImageStatus("loaded")}
                onError={(event) => handleImageErrored(event)}
                alt={photo.label}
              />
              <div className="photo-tag">{photo.label}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function JobResultPhotos({ resultPayload, photos }) {
  return <PhotoCard smileJobId={resultPayload.smile_job_id} photos={photos} />;
}

export default JobResultPhotos;
