import React from "react";

import ErrorIcon from "resources/img/icons/error.svg";
import styles from "./error_modal.module.css";
import ModalWrapper from "./ModalWrapper";

export default function ErrorModal({ error, isOpen, onClose }) {
  return (
    <ModalWrapper
      icon={<ErrorIcon />}
      title="Warning"
      titleClass={styles.errorTitle}
      isOpen={isOpen}
      onClose={onClose}
      hideBackButton
      className
    >
      <div className={styles.message}>{error}</div>
    </ModalWrapper>
  );
}
