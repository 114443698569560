import React, { Component, Fragment } from "react";
import capitalize from "lodash/capitalize";

import { Link } from "react-router-dom";
import errorIcon from "resources/img/icons/error-icon.svg";
import successIcon from "resources/img/icons/success-icon.svg";
import {
  addToTeam,
  deleteTeamMember,
  editTeamMember,
  fetchTeam,
  resendInvitation,
  fetchCompany,
  updateContactInformation,
  update2FAConfiguration,
  fetch2FAConfiguration,
} from "util/api_util";
import { decode, userHasPermission } from "util/route_util";
import ErrorAndMessageBar from "./error_and_message_bar";
import Modal from "./modal";

class Team extends Component {
  constructor(props) {
    super(props);
    sessionStorage.setItem("url", "/team");
    this.state = {
      team: null,
      error: null,
      contact_errors: [],
      email: undefined,
      contact_success: false,
      account_owner_id: null,
      account_owner_name: "",
      account_owner_email: "",
      dev_id: null,
      dev_name: "",
      dev_email: "",
      billing_id: null,
      billing_name: "",
      billing_email: "",
      groupID: undefined,
      disabled: false,
      groups: [],
      editID: null,
      modal: false,
      modalMessage: undefined,
      modalCallback: undefined,
      is2FAEnabled: false,
    };
    this.deleteMember = this.deleteMember.bind(this);
    this.onInput = this.onInput.bind(this);
    this.addTeamMember = this.addTeamMember.bind(this);
    this.editTeamMember = this.editTeamMember.bind(this);
    this.deactivateMember = this.deactivateMember.bind(this);
    this.activateMember = this.activateMember.bind(this);
    this.changePermissions = this.changePermissions.bind(this);
    this.updateActiveStatus = this.updateActiveStatus.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.resend = this.resend.bind(this);
    this.toggle2FA = this.toggle2FA.bind(this);
  }

  componentDidMount() {
    try {
      fetchTeam().then((resp) => {
        this.setState({
          team: resp.team,
          error: resp.error,
          groups: resp.groups ? JSON.parse(resp.groups) : [],
        });
      });

      fetchCompany().then((resp) => {
        const { contact_errors, key_contacts } = resp;
        if (contact_errors)
          this.setState({ contact_errors: [...contact_errors] });
        this.mapResponseToState(key_contacts);
      });

      this.load2FAConfig();
    } catch (e) {
      console.error(e);
    }
  }

  mapResponseToState(key_contacts) {
    if (Array.isArray(key_contacts) && key_contacts.length > 0) {
      const updateState = {};
      key_contacts.forEach((elem) => {
        if (elem.key_contact_type === "dev") {
          const { id, name, email } = elem;
          updateState.dev_name = name;
          updateState.dev_email = email;
          updateState.dev_id = id;
        }
        if (elem.key_contact_type === "billing") {
          const { id, name, email } = elem;
          updateState.billing_name = name;
          updateState.billing_email = email;
          updateState.billing_id = id;
        }
        if (elem.key_contact_type === "account_owner") {
          const { id, name, email } = elem;
          updateState.account_owner_name = name;
          updateState.account_owner_email = email;
          updateState.account_owner_id = id;
        }
      });
      this.setState(Object.assign(this.state, updateState));
    }
  }

  closeModal() {
    this.setState({
      disabled: false,
      groupID: null,
      editID: null,
      modal: false,
      modalMessage: undefined,
      modalCallback: undefined,
    });
  }

  handleEvents = (property) => (event) => {
    this.setState({ [property]: event.target.value });
  };

  changePermissions(member) {
    if (!this.state.groupID) {
      return this.setState({
        error: "A role must be selected for each team member.",
      });
    }
    this.setState({
      disabled: true,
    });
    const json = {
      id: member.id,
      group_id: this.state.groupID,
    };
    let groupName;
    this.state.groups.forEach((group) => {
      if (group.id === parseInt(this.state.groupID, 10)) {
        groupName = group.name;
      }
    });
    this.setState({
      modal: true,
      modalMessage: `Change ${member.name}'s role to ${groupName}?`,
      modalCallback: () => {
        editTeamMember(json).then((resp) => {
          if (resp.error) {
            this.setState({
              disabled: false,
              error: resp.error,
            });
          } else {
            const { team } = this.state;
            team.map((teamMember) => {
              if (teamMember.id === member.id) {
                teamMember.group_id = this.state.groupID;
              }
              return teamMember;
            });
            this.setState({
              message: "Team member role changed successfully.",
              team,
              disabled: false,
              groupID: null,
              editID: null,
            });
          }
          this.closeModal();
        });
      },
    });
  }

  editTeamMember(id) {
    this.setState({
      editID: id,
    });
  }

  resend(teamMember) {
    resendInvitation({ id: teamMember.id }).then((resp) => {
      this.setState({
        error: resp.error,
        message: resp.message,
      });
    });
  }

  deleteMember(member) {
    this.setState({
      disabled: true,
    });
    this.setState({
      modal: true,
      modalMessage: `Permanently delete ${member.name}?`,
      modalCallback: () => {
        deleteTeamMember(member.id).then((resp) => {
          if (resp.error) {
            this.setState({
              error: resp.error,
              message: resp.error ? null : "Deletion successful.",
              disabled: false,
            });
          } else {
            fetchTeam().then((teamResp) => {
              this.setState({
                team: teamResp.team,
                error: teamResp.error,
                disabled: false,
              });
            });
          }
          this.closeModal();
        });
      },
    });
  }

  updateActiveStatus(teamMember, status) {
    this.setState({
      disabled: true,
    });
    const json = {
      id: teamMember.id,
      status,
    };
    this.setState({
      modal: true,
      modalMessage: `Change ${teamMember.name}'s status to ${status}?`,
      modalCallback: () => {
        editTeamMember(json).then((resp) => {
          if (resp.error) {
            this.setState({
              disabled: false,
              error: resp.error,
            });
          } else {
            const { team } = this.state;
            team.map((member) => {
              if (member.id === teamMember.id) {
                member.status = status;
              }
              return member;
            });
            this.setState({
              team,
              disabled: false,
            });
          }
        });
        this.closeModal();
      },
    });
  }

  deactivateMember(member) {
    this.updateActiveStatus(member, "deactivated");
  }

  activateMember(member) {
    this.updateActiveStatus(member, "active");
  }

  onInput(property) {
    return (e) => {
      this.setState({ [property]: e.target.value });
    };
  }

  validateEmail = (value) => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(value);
  };

  renderError = (arr) =>
    arr.map((text, index) => (
      <Fragment key={index}>
        <img src={errorIcon} />
        <span>{text}</span>
        <br />
      </Fragment>
    ));

  clientErrors = (obj) => {
    const errors = [];
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (Array.isArray(value) && value.length >= 1) {
        errors.push(
          `${capitalize(key).split("_").join(" ")} ${value.join(", ")}`,
        );
      }
    });
    return errors;
  };

  validateContactInformation = () => {
    const errors = {
      account_owner_name: [],
      account_owner_email: [],
      dev_name: [],
      dev_email: [],
      billing_name: [],
      billing_email: [],
    };
    const {
      account_owner_name,
      account_owner_email,
      dev_name,
      dev_email,
      billing_name,
      billing_email,
    } = this.state;
    if (!this.validateEmail(account_owner_email))
      errors.account_owner_email.push("is invalid");
    if (!account_owner_email) errors.account_owner_email.push("can't be blank");
    if (!account_owner_name) errors.account_owner_name.push("can't be blank");
    if (!this.validateEmail(dev_email)) errors.dev_email.push("is invalid");
    if (!dev_name) errors.dev_name.push("can't be blank");
    if (!dev_email) errors.dev_email.push("can't be blank");
    if (!this.validateEmail(billing_email))
      errors.billing_email.push("is invalid");
    if (!billing_email) errors.billing_email.push("can't be blank");
    if (!billing_name) errors.billing_name.push("can't be blank");
    const clientErrors = this.clientErrors(errors);
    this.setState({ contact_errors: clientErrors });
    if (clientErrors.length > 0) return;
    return true;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ contact_success: false });
    if (!this.validateContactInformation()) return;
    const data = this.transformData();
    updateContactInformation(data).then((resp) => {
      const { contact_errors, key_contacts } = resp;
      if (!contact_errors) {
        this.setState({ contact_success: true });
        setTimeout(() => {
          this.setState({ contact_success: false });
        }, 5000);
      }
      if (contact_errors) this.setState({ contact_errors: [contact_errors] });
      this.mapResponseToState(key_contacts);
    });
  };

  transformData() {
    const {
      dev_id,
      dev_name,
      dev_email,
      billing_id,
      billing_name,
      billing_email,
      account_owner_id,
      account_owner_name,
      account_owner_email,
    } = this.state;

    const data = [];
    let interim = {};
    interim = {
      name: account_owner_name,
      email: account_owner_email,
      key_contact_type: "account_owner",
    };
    if (account_owner_id) interim.id = account_owner_id;
    data.push(interim);
    interim = { name: dev_name, email: dev_email, key_contact_type: "dev" };
    if (dev_id) interim.id = dev_id;
    data.push(interim);
    interim = {
      name: billing_name,
      email: billing_email,
      key_contact_type: "billing",
    };
    if (billing_id) interim.id = billing_id;
    data.push(interim);
    return data;
  }

  addTeamMember(e) {
    e.preventDefault();
    const { name, groupID } = this.state;
    const email = this.state.email.trim();

    let error;

    if (!email) {
      error = "Email is required.";
    } else if (!this.validateEmail(email)) {
      error = "Invalid email.";
    }
    if (!name) {
      error = "Name is required.";
    }
    if (!groupID) {
      error = "Role is required.";
    }

    if (error) {
      return this.setState({ error });
    }

    this.setState({ disabled: true });

    const json = {
      name,
      email,
      group_id: groupID,
    };
    addToTeam(json).then((resp) => {
      this.setState({
        error: resp.error,
        message: resp.message,
        disabled: false,
      });

      if (!resp.error) {
        fetchTeam().then((resp) => {
          this.setState({
            team: resp.team,
            error: resp.error,
          });
        });
      }
    });
  }

  renderContactInformation = () => {
    const {
      dev_name,
      dev_email,
      billing_name,
      billing_email,
      account_owner_name,
      account_owner_email,
    } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        {this.state.contact_success && (
          <div className="contact__list__form-span">
            <div>
              <img src={successIcon} />
              <span>You have successfully added a contact.</span>
            </div>
          </div>
        )}
        <table>
          <tbody>
            <tr>
              <th id="contact__list__th" />
              <th id="contact__list__th">Name</th>
              <th id="contact__list__th">Email</th>
            </tr>
            <tr>
              <td>Account Owner</td>
              <td>
                <input
                  type="text"
                  name="account_owner_name"
                  value={account_owner_name}
                  placeholder="First Name Last Name"
                  autoFocus="autofocus"
                  onChange={this.handleEvents("account_owner_name")}
                />
              </td>
              <td>
                <input
                  type="text"
                  name="account_owner_email"
                  value={account_owner_email}
                  placeholder="Email"
                  autoFocus="autofocus"
                  onChange={this.handleEvents("account_owner_email")}
                />
              </td>
            </tr>
            <tr>
              <td>Dev</td>
              <td>
                <input
                  type="text"
                  name="dev_name"
                  value={dev_name}
                  placeholder="First Name Last Name"
                  autoFocus="autofocus"
                  onChange={this.handleEvents("dev_name")}
                />
              </td>
              <td>
                <input
                  type="text"
                  name="dev_email"
                  value={dev_email}
                  placeholder="Email"
                  autoFocus="autofocus"
                  onChange={this.handleEvents("dev_email")}
                />
              </td>
            </tr>
            <tr>
              <td>Billing</td>
              <td>
                <input
                  type="text"
                  name="billing_name"
                  value={billing_name}
                  placeholder="First Name Last Name"
                  autoFocus="autofocus"
                  onChange={this.handleEvents("billing_name")}
                />
              </td>
              <td>
                <input
                  type="text"
                  name="billing_email"
                  value={billing_email}
                  placeholder="Email"
                  autoFocus="autofocus"
                  onChange={this.handleEvents("billing_email")}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="contact__information-errors">
          {this.renderError(this.state.contact_errors)}
        </div>
        <p>
          NOTE: Your Billing Contact will get your usage and billing statements
        </p>
        <button type="submit" className="btn btn-primary" value="Submit">
          Submit
        </button>
      </form>
    );
  };

  toggle2FA() {
    this.setState({ is2FAEnabled: !this.state.is2FAEnabled });
    return update2FAConfiguration().then(() => this.load2FAConfig());
  }

  load2FAConfig() {
    fetch2FAConfiguration().then((resp) =>
      this.setState({ is2FAEnabled: resp.two_factor_required }),
    );
  }

  render() {
    const teamMembers = [];
    const hasPermissions = userHasPermission(["team write"]);
    const hasPartnerWrite = userHasPermission(["partner write"]);
    const groups = [];
    this.state.groups.forEach((group) => {
      groups.push(
        <option value={group.id} key={group.id}>
          {group.name}
        </option>,
      );
    });

    const permissionsSelect = (
      <div>
        <label className="settings__field-label">Role</label>
        <select
          id="groupType"
          name="groupType"
          onChange={this.onInput("groupID")}
          value={this.state.groupType}
        >
          <option value="" />
          {groups}
        </select>
      </div>
    );

    if (this.state.team) {
      this.state.team.forEach((teamMember) => {
        let group = {};
        this.state.groups.forEach((g) => {
          if (g.id === parseInt(teamMember.group_id, 10)) {
            group = g;
          }
        });

        let permissions;
        if (this.state.editID === teamMember.id) {
          permissions = (
            <td>
              <span className="team-table__td">
                <select
                  id="groupType"
                  name="groupType"
                  onChange={this.onInput("groupID")}
                  value={this.state.groupType}
                >
                  <option value="" />
                  {groups}
                </select>
                <span
                  className="team-table__link team-table__link-submit"
                  onClick={() => this.changePermissions(teamMember)}
                >
                  Submit
                </span>
              </span>
            </td>
          );
        } else {
          permissions = (
            <td>
              <span className="team-table__td">
                <span>{group.name}</span>
                {hasPermissions ? (
                  <span
                    className="team-table__link team-table__edit"
                    onClick={() => this.editTeamMember(teamMember.id)}
                    disabled={this.state.disabled}
                  >
                    Edit
                  </span>
                ) : null}
              </span>
            </td>
          );
        }

        let activationButton;
        if (teamMember.status === "deactivated") {
          activationButton = (
            <span
              className="team-table__link team-table__activate"
              onClick={() => this.activateMember(teamMember)}
              disabled={this.state.disabled}
            >
              Reactivate
            </span>
          );
        } else {
          activationButton = (
            <span
              className="team-table__link team-table__activate"
              onClick={() => this.deactivateMember(teamMember)}
              disabled={this.state.disabled}
            >
              Deactivate
            </span>
          );
        }

        const createdAt = `${new Date(
          teamMember.created_at,
        ).getMonth()}/${new Date(teamMember.created_at).getDay()}/${new Date(
          teamMember.created_at,
        ).getFullYear()}`;
        let resendEmailButton;
        if (teamMember.status === "invited") {
          resendEmailButton = (
            <div>
              <span
                className="team-table__link team-table__resend"
                onClick={() => this.resend(teamMember)}
              >
                Resend Invitation
              </span>
            </div>
          );
        }
        teamMembers.push(
          <tr key={teamMember.id}>
            <td>
              <span className="team-table__td">
                {teamMember.name || "\u00A0"}
              </span>
            </td>
            {permissions}
            <td>
              <span className="team-table__td">
                {teamMember.email || "\u00A0"}
              </span>
            </td>
            <td>
              <span className="team-table__td">
                {teamMember.status || "\u00A0"}
              </span>
              {resendEmailButton}
            </td>
            <td>
              <span className="team-table__td">{createdAt || "\u00A0"}</span>
            </td>
            <td>
              {hasPermissions ? activationButton : "\u00A0"}
              {hasPermissions ? (
                <span
                  className="team-table__link team-table__link-delete"
                  onClick={() => this.deleteMember(teamMember)}
                  disabled={this.state.disabled}
                >
                  Delete
                </span>
              ) : null}
            </td>
          </tr>,
        );
      });
    }

    const roles = [];
    this.state.groups.forEach((group) => {
      roles.push(
        <div className="role__list-item" key={group.id}>
          <div className="role__item">Name: {group.name}</div>
          <div className="role__item">Description: {group.description}</div>
        </div>,
      );
    });

    let modal;
    if (this.state.modal) {
      const content = (
        <div>
          <h4>Confirm Changes</h4>
          <p>{this.state.modalMessage}</p>
          <button
            className="btn btn-white inline"
            onClick={this.state.modalCallback}
          >
            Save Changes
          </button>
          <button className="btn btn-white inline" onClick={this.closeModal}>
            Cancel
          </button>
        </div>
      );
      modal = (
        <Modal
          show={this.state.modal}
          content={content}
          onClose={this.closeModal}
          customStyle="modal--short"
        />
      );
    }

    let breadcrumbs;
    if (decode(localStorage.token).type === "partner") {
      breadcrumbs = (
        <div className="breadcrumbs">
          <Link
            to="/partner/account_settings"
            className="breadcrumb-link breadcrumb-link--inactive"
          >
            Account Settings
          </Link>
          <span> &gt; </span>
          <span>Team Settings</span>
        </div>
      );
    }

    return (
      <div className="legacy">
        {breadcrumbs}

        <div>
          <ErrorAndMessageBar
            errors={this.state.error}
            messages={this.state.message}
          />
          {modal}

          {hasPermissions ? (
            <>
              {hasPartnerWrite && (
                <div className="cluster">
                  <label className="cluster">
                    <input
                      onChange={this.toggle2FA}
                      type="checkbox"
                      checked={this.state.is2FAEnabled}
                    />
                    <span>
                      Require two-factor authentication (2FA) for everyone in
                      this team
                    </span>
                  </label>
                </div>
              )}

              <form className="team__new-member-form" id="new_account">
                <div className="newsmile-heading-h1">Add Team Member</div>

                <div className="settings__field-box">
                  <label className="settings__field-label"> Name </label>
                  <input
                    className="settings__field-input input--line"
                    autoFocus="autofocus"
                    type="text"
                    id="name"
                    onChange={this.onInput("name")}
                    value={this.state.name}
                  />
                </div>

                <div className="settings__field-box">
                  <label className="settings__field-label"> Email </label>
                  <input
                    className="settings__field-input input--line"
                    autoFocus="autofocus"
                    type="email"
                    id="email"
                    onChange={this.onInput("email")}
                    value={this.state.email}
                  />
                </div>

                <div className="settings__field-box">{permissionsSelect}</div>

                <br />
                <div className="actions" onClick={this.addTeamMember}>
                  <button
                    className="btn btn-primary"
                    disabled={this.state.disabled}
                  >
                    Add Member
                  </button>
                </div>
              </form>
            </>
          ) : null}

          <div className="newsmile-container__bottom__section">
            <div className="team__roles">
              <div className="newsmile-heading-h1">Roles</div>
              <div className="role__list">{roles}</div>
            </div>

            <div className="contact__information">
              <div className="newsmile-heading-h1">Contact Information</div>
              <div className="contact__list">
                {userHasPermission(["team write"]) &&
                  this.renderContactInformation()}
              </div>
            </div>
          </div>

          <table className="team__table">
            <thead>
              <tr className="team-table__tr">
                <th>
                  <span className="team-table__td">Name</span>
                </th>
                <th>
                  <span className="team-table__td">Role</span>
                </th>
                <th>
                  <span className="team-table__td">Email</span>
                </th>
                <th>
                  <span className="team-table__td">Status</span>
                </th>
                <th>
                  <span className="team-table__td">Joined</span>
                </th>
                <th>
                  <span className="team-table__td">Actions</span>
                </th>
              </tr>
            </thead>
            <tbody>{teamMembers}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Team;
