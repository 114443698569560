import React, { useState } from "react";
import BackArrow from "assets/svgs/back-arrow.svg";
import Close from "assets/svgs/close.svg";
import Continue from "assets/svgs/continue.svg";
import PayWithEaseIcon from "assets/svgs/pay-with-ease.svg";
import Success from "assets/svgs/success.svg";
import {
  Form as IntercomForm,
  TICKET_TYPE_IDS as INTERCOM_TICKET_TYPE_IDS,
} from "../intercom-tickets";
import Modal from "../modal";
import "assets/scss/styles.scss";

function ChangeRequestForm({ onSubmit }) {
  return (
    <div
      className="split-pair"
      data-align="center"
      style={{ "--gap": "4.5rem" }}
    >
      <div className="stack">
        <h1>Request Additional Currency</h1>
        <IntercomForm
          onSubmit={onSubmit}
          ticketTypeId={INTERCOM_TICKET_TYPE_IDS.changeCurrency}
        />
      </div>

      <div className="box stack">
        <h2 className="font-heading">Currency Support</h2>
        <p className="space:zero color-green">
          Learn more about supported currencies here
        </p>

        <ul className="value-prop-list stack">
          <li className="value-prop">
            <img src={PayWithEaseIcon} alt="" />
            <div className="stack">
              <h3>More payment options</h3>
              <p className="color-green">
                With additional currency support, you can pay with more payment
                options such as mobile money.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

function ChangeRequestSubmitted({ close }) {
  return (
    <div className="center stack">
      <img src={Success} alt="" />
      <h1>Details submitted</h1>

      <p>You should receive an email response in 1-3 business days</p>

      <button data-variant="primary" type="button" onClick={close}>
        <img src={Continue} alt="" />
        <span>Continue</span>
      </button>
    </div>
  );
}

function CurrencyChangeModal({ open, closeModal }) {
  const screens = ["open", "changeRequestSubmitted"];
  const [screen, setScreen] = useState(screens[0]);

  const goToPreviousScreen = () => {
    const screenIndex = screens.indexOf(screen);
    if (screenIndex > 0) setScreen(screens[screenIndex - 1]);
    if (screenIndex === 0) closeModal();
  };

  const onChangeRequestSubmit = () => setScreen("changeRequestSubmitted");

  if (open) {
    return (
      <div className="legacy new-styles">
        <Modal show={open} additionalClasses="modal">
          <nav className="modal-navigation">
            <ul>
              <li>
                {screen !== "changeRequestSubmitted" && (
                  <button
                    data-variant="ghost"
                    type="button"
                    onClick={goToPreviousScreen}
                  >
                    <img src={BackArrow} alt="" />
                    <span style={{ color: "#151F72" }}>Go Back</span>
                  </button>
                )}
              </li>

              <li>
                <button data-variant="ghost" type="button" onClick={closeModal}>
                  <img src={Close} alt="" />
                  <span className="visually-hidden">
                    Close Payment Plan Modal
                  </span>
                </button>
              </li>
            </ul>
          </nav>
          <div className="modal__content">
            {
              {
                open: <ChangeRequestForm onSubmit={onChangeRequestSubmit} />,
                changeRequestSubmitted: (
                  <ChangeRequestSubmitted close={closeModal} />
                ),
              }[screen]
            }
          </div>
        </Modal>
      </div>
    );
  }
}

CurrencyChangeModal.defaultProps = {
  open: false,
};

export default CurrencyChangeModal;
