import React, { useLayoutEffect } from "react";
import * as d3 from "d3";
import moment from "moment";
import { formatLocalisedDate } from "util/format_helpers";

export function IdHistoryChart({ data }) {
  useLayoutEffect(() => {
    if (data && data.length === 0) return;
    const width = 700;

    d3.select("svg").selectAll("*").remove();
    d3.select(".legend").selectAll("*").remove();

    const xScale = d3
      .scaleTime()
      .domain([
        moment.utc(data[data.length - 1].date).local(),
        moment.utc(data[0].date).local(),
      ])
      .range([width - 150, 0]);

    const chart = d3.select("svg");

    const showTooltip = (datapoint, event) => {
      const mouseCoords = d3.pointer(event);
      let uptime;
      const colorMap = serviceMap(datapoint);
      if (colorMap.status === "No Data") {
        uptime = colorMap.status;
      } else if (datapoint.total_jobs) {
        const uptimePercentage = Math.floor(
          ((datapoint.total_jobs - datapoint.unavailable) /
            datapoint.total_jobs) *
            100,
        );
        uptime = `${uptimePercentage}% Availability`;
      } else {
        uptime = colorMap.status;
      }
      // inline style required for display on firefox
      const html = `<div xmlns='http://www.w3.org/1999/xhtml' class='status-chart__tooltip tooltip--info'><style>.tooltip--info{background-color: #004071}</style>${formatLocalisedDate(
        datapoint.date,
        "ddd MMM DD, YYYY HH:mm",
      )}<br /><span class='tooltip__status' style='background-color:${
        colorMap.color
      }'></span> ${uptime}</div>`;
      // The transform is based off of values set in our css. I could not find a way to determine these
      // on the fly rather than hard coding "magic numbers" ¯\_(ツ)_/¯
      tooltip
        .html(html)
        .attr(
          "transform",
          `translate(${mouseCoords[0] - 50}, ${mouseCoords[1] - 90})`,
        )
        .attr("width", 180)
        .attr("height", 80)
        .style("opacity", 0.9)
        .style("display", "block");
    };

    chart
      .selectAll("rect")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "status-bar")
      .attr("height", 60)
      .attr("width", 3)
      .attr("cursor", "pointer")
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("x", (datapoint) => xScale(moment.utc(datapoint.date).local()))
      .attr("y", 80)
      .attr("fill", (datapoint) => serviceMap(datapoint).color)
      .on("mouseenter", (event, datapoint) => {
        showTooltip(datapoint, event);
      })
      .on("mousemove", (event, datapoint) => {
        showTooltip(datapoint, event);
      })
      .on("mouseout", () => {
        tooltip.style("display", "none");
      });
    const aggregateData = {
      total_jobs: 0,
      unavailable: 0,
    };
    data.forEach((point) => {
      aggregateData.total_jobs += point.total_jobs;
      aggregateData.unavailable += point.unavailable;
    });

    const aggregatePercentage = Math.floor(
      ((aggregateData.total_jobs - aggregateData.unavailable) /
        aggregateData.total_jobs) *
        100,
    );
    const scaleData = [
      {
        label: "12 hours ago",
        color: serviceMap(data[0]).color,
      },
      {
        label: `<<    ${
          Number.isNaN(aggregatePercentage) ||
          !Number.isFinite(aggregatePercentage)
            ? ""
            : `${aggregatePercentage}% Availability`
        }    >>`,
        color: "#000",
      },
      {
        label: "Now",
        color: serviceMap(data[data.length - 1]).color,
      },
    ];

    chart
      .append("svg:foreignObject")
      .attr("class", "id-status__scale legend")
      .attr("y", 60)
      .attr("width", "100%")
      .attr("height", "1em").html(`
          <div style="color:${scaleData[0].color};float:left">${scaleData[0].label}</div>
          <div style="color:${scaleData[1].color};margin:auto">${scaleData[1].label}</div>
          <div style="color:${scaleData[2].color};float:right">${scaleData[2].label}</div>
        `);

    let tooltip = chart
      .append("svg:foreignObject")
      .attr("class", "tooltip")
      .style("display", "none");

    const legendData = [
      {
        label: "Available",
        color: "#17dc89",
      },
      {
        label: "Service Interruption",
        color: "#d9984f",
      },
      {
        label: "Service Outage",
        color: "#F15A5A",
      },
      {
        label: "No Data",
        color: "#aaa",
      },
    ];

    const legend = d3.select(".status-chart__legend");
    // Remove the existing legend items before recreating them
    legend.selectAll(".status-chart__legend-item").remove();

    const keys = legend
      .selectAll(".key")
      .data(legendData)
      .enter()
      .append("div")
      .attr("class", "status-chart__legend-item");

    keys
      .append("div")
      .attr("class", "symbol")
      .style("height", "10px")
      .style("width", "10px")
      .style("margin", "5px 5px")
      .style("background-color", (datapoint) => datapoint.color);

    keys
      .append("div")
      .attr("class", "name")
      .html((datapoint) => `${datapoint.label}`);
    keys.exit().remove();
  }, [data]);

  return <svg className="status-chart" width={560} height={150} />;
}

function serviceMap(data) {
  if (!data || !data.status) {
    return { color: "#aaa", status: "No Data", lightenedColor: "#f2f3f7" };
  }
  if (data.status === "online") {
    return {
      color: "#17dc89",
      status: "Available",
      lightenedColor: "#D3F8E8",
    };
  }
  if (data.status === "offline") {
    return {
      color: "#F15A5A",
      status: "Service Outage",
      lightenedColor: "#FDE0E0",
    };
  }
  return {
    color: "#d9984f",
    status: "Service Interruption",
    lightenedColor: "#FDF0DD",
  };
}
