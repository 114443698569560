import React from "react";
import styles from "./chips.module.css";

export function Chips({ children }) {
  return <div className={styles.chips}>{children}</div>;
}
export function Chip({ label, children, style }) {
  return (
    <div style={style} className={styles.chip}>
      {label || children}
    </div>
  );
}
