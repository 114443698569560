import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

interface LogInWithRecoveryCodeResponse {
  auth_token: string;
  pre_otp_user: string;
}

interface LogInWithRecoveryCodeInput {
  email: string;
  password: string;
  recovery_code: string;
}

const logInWithRecoveryCode = async (input: LogInWithRecoveryCodeInput) => {
  const { data } = await axios.post<LogInWithRecoveryCodeResponse>(
    "/auth/recovery",
    input,
  );
  return data;
};

export const useLogInWithRecoveryCode = (
  options?: UseMutationOptions<
    LogInWithRecoveryCodeResponse,
    AxiosError<{ errors: string[] }>,
    LogInWithRecoveryCodeInput
  >,
) =>
  useMutation({
    mutationFn: logInWithRecoveryCode,
    ...options,
  });
