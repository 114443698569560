import { connect } from "react-redux";

import { dispatchMessage, submitReview } from "actions/actions";
import DocVReview from "components/reusable/DocumentVerificationReview/DocVReview";

const mapDispatchToProps = (dispatch) => ({
  submitReview: (reviewInfo) => submitReview(reviewInfo, dispatch),
  dispatchMessage: (message) => dispatchMessage(message, dispatch),
});

export default connect(null, mapDispatchToProps)(DocVReview);
