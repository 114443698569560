import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useParams, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import PadLock from "resources/img/icons/padlock.svg";
import PaperClip from "resources/img/icons/paperclip.svg";
import Tag from "resources/img/icons/tag.svg";
import WhiteCheckBox from "resources/img/icons/white-checkbox.svg";
import { copy } from "util/format_helpers";
import Loader from "./loader";

export default function ShareLink() {
  const [linkCopied, setLinkCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userEmail, setUserEmail] = useState(null);
  const [cacheKey, setCacheKey] = useState(null);
  const { id } = useParams();
  const location = useLocation();
  const origin = new URLSearchParams(location.search).get("origin");
  const generatedLink = `${origin}/${id}`;

  useEffect(() => {
    const user = localStorage.token ? jwtDecode(localStorage.token) : undefined;
    if (user) {
      setCacheKey("cached");
      setUserEmail(user.email);
    }
    setIsLoading(false);
  }, [cacheKey]);

  const copyLink = () => {
    const copiedResult = copy("link-card-text", "Link copied to clipboard!");
    setLinkCopied(!!copiedResult.message);
    setTimeout(() => {
      setLinkCopied(false);
    }, 1000);
  };

  function sendToEmail() {
    window.location.href = `mailto:${userEmail}?subject=Your%20KYC%20Link&body=${generatedLink}`;
  }

  if (isLoading) {
    return <Loader loaderText="Fetching your link..." />;
  }

  return (
    <div className="legacy share-link-container">
      <div className="kyc-link-success-wrapper">
        <div className="ellipse-check">
          <img className="icon--small" src={WhiteCheckBox} alt="icon" />
        </div>
        <p className="desc">Your link was generated successfully</p>
        <p className="expiry">It expires in 72 hours</p>

        <div className="link-card">
          <div className="link-card-icon-left">
            <img className="icon--small" src={PadLock} alt="icon" />
          </div>
          <div className="link-card-text" id="link-card-text">
            {generatedLink}
          </div>
          <div className="link-card-icon-right">
            <img className="icon--small" src={Tag} alt="icon" />
          </div>
        </div>

        <p className="d-next">What to do next</p>
        <div className="d-next-actions">
          <div className="copy-link-wrapper">
            <img src={Tag} alt="icon" />
            <button onClick={copyLink}>Copy link to share</button>
          </div>
          <div className="copied-text-container">
            <CSSTransition
              in={linkCopied}
              timeout={500}
              classNames="fade"
              unmountOnExit
            >
              <p>Link successfully copied!</p>
            </CSSTransition>
          </div>
          <div className="send-link-to-email">
            <img src={PaperClip} alt="icon" />
            <button onClick={sendToEmail}>Send link to your email</button>
          </div>
        </div>
      </div>
    </div>
  );
}
