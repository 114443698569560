import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import type { Job } from "types/jobApiResponse";

type GetJobResponse = Job;

interface GetJobInput {
  id: string;
}

const getJob = async ({ id }: GetJobInput) => {
  const { data } = await axios.get<GetJobResponse>(`/jobs/${id}`);
  sessionStorage.setItem("url", `/job/${id}`);
  return data;
};

export const JOBS_QUERY_KEY = "jobs";

export const useJob = (
  input: GetJobInput,
  options?: Omit<
    UseQueryOptions<GetJobResponse, AxiosError<{ errors: string[] }>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery({
    queryKey: [JOBS_QUERY_KEY, input],
    queryFn: () => getJob(input),
    ...options,
  });
