import React from "react";
import classNames from "classnames";

function Sidebar({ step, onWizardStepClick }) {
  const steps = [
    { number: 1, name: "Choose a Service" },
    { number: 2, name: "Select an Action" },
    { number: 3, name: "Fill in User Details" },
  ];

  return (
    <div className="web-app__sidebar">
      {steps.map(({ number, name }) => (
        <div
          key={number}
          className={classNames("web-app__sidebar-item", {
            active: step === number,
            "is-clickable": step > number,
          })}
          onClick={() => onWizardStepClick(number)}
        >
          <div className="web-app__sidebar-item-name">
            {number}. {name}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Sidebar;
