import React from "react";
import Checkmark from "resources/img/icons/blue-filled-checkmark.svg";
import "./styles.scss";

function ToggleButton({ pressed = false, onClick, children, ...props }) {
  return (
    <button
      type="button"
      className="toggle-button"
      onClick={onClick}
      aria-pressed={pressed}
      data-testid={props["data-testid"]}
    >
      {pressed && <img src={Checkmark} alt="" />}
      {children}
    </button>
  );
}

export default ToggleButton;
