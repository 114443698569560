import React from "react";

const rejectionReasons = [
  "Cropped Document",
  "Expired Document",
  "Not A Document",
  "Potential Fraud Attempt",
  "Photocopied Document",
  "Unreadable Document",
  "Unsupported Document",
  "Wrong Document Type",
];

const RejectionReason = ({
  completed,
  disabled,
  rejectionReason,
  handleChange,
}) => {
  const rejectionOptions = rejectionReasons.map((reason) => (
    <option key={reason} value={reason}>
      {reason}
    </option>
  ));

  const allOptions = [
    <option key="Select Outcome" value="Select Outcome">
      Select Outcome
    </option>,
    <option key="Approved" value="">
      Approved
    </option>,
    ...rejectionOptions,
  ];
  let selectOrReason;
  if (completed) {
    selectOrReason = <label>{rejectionReason || "Approved"}</label>;
  } else {
    selectOrReason = (
      <select
        data-testid="rejection_reason"
        name="rejection_reason"
        className="h4 review_form_input"
        value={rejectionReason}
        disabled={disabled}
        onChange={handleChange}
      >
        {allOptions}
      </select>
    );
  }

  return (
    <div className="review_form_input_group">
      <label className="h3 review_text review_form_label">Outcome</label>
      <div>{selectOrReason}</div>
    </div>
  );
};

export default RejectionReason;
