import React, { useEffect } from "react";

import Card from "components/reusable/card";
import Team from "resources/img/icons/user-wifi.svg";
import PersonalInfo from "resources/img/icons/user.svg";
import { canUserView, userHasPermission } from "util/route_util";

function AccountSettings() {
  useEffect(() => {
    sessionStorage.setItem("url", `/partner/account_settings`);
  });

  const hasPermission = userHasPermission(["team full_read"]);
  const userHasTeamsPermission = canUserView([
    "/partner/account_settings",
    "/partner/settings/teams",
  ]);

  return (
    <div className="smile-card__container get-started__card-container legacy">
      <Card
        cardClass="smile-card--centered"
        heading="Personal Info"
        icon={PersonalInfo}
        text="User Information and password"
        buttonText="Edit"
        href="/settings"
      />
      {userHasTeamsPermission && (
        <Card
          cardClass="smile-card--centered"
          heading="Team"
          icon={Team}
          text="Manage your team and permissions"
          buttonText="Manage"
          disabled={!hasPermission}
          href={hasPermission ? "/settings/team" : "/partner/account_settings"}
          dataTooltip={
            hasPermission
              ? undefined
              : "You do not have permission to access this feature"
          }
        />
      )}
    </div>
  );
}

export default AccountSettings;
