import { createGlobalStyle } from "styled-components";

// NOTE:(solo) We will build global app styles here
// if styles.ts is modified we should also modify colors.css

const GlobalStyles = createGlobalStyle`
    html{
        /* brand colors */
        --color-primary-blue: #151F72;
        --color-primary-green: #2CC05C;
        --color-primary-orange: #FF9B00;

        --color-secondary-sky-blue: #7FCBF5;
        --color-secondary-dark-green: #0A462F;
        --color-secondary-blood-orange: #FF5805;
        --color-secondary-red: #91190F;
        --color-secondary-pink: #FBD1EE;
        --color-secondary-sand: #DBDBC4;

        --color-neutral-white:   #FFFFFF;
        --color-neutral-off-black: #2D2B2A;
        --color-neutral-off-white: #F9F0E7;
        --color-neutral-warm-grey: #848282;


        --color-web-digital-blue: #001096;

    }
`;

export default GlobalStyles;
