import React from "react";
import { FileTextOutlined } from "@ant-design/icons";
import JsonView from "@uiw/react-json-view";
import { useBoolean } from "ahooks";
import { Button } from "antd";

import Modal from "./modal";

interface JsonModalProps {
  name?: React.ReactNode;
  jsonValue: object;
}

const JsonModal: React.FC<JsonModalProps> = ({
  name = "Prettify",
  jsonValue,
}) => {
  const [modalOpen, { toggle: toggleModalOpen, setFalse: closeModal }] =
    useBoolean();

  return (
    <div className="overflow-hidden">
      <Button
        icon={<FileTextOutlined />}
        type="primary"
        onClick={toggleModalOpen}
      >
        {name}
      </Button>
      <Modal
        content={<JsonView value={jsonValue} />}
        customStyle="modal--medium"
        onClose={closeModal}
        show={modalOpen}
      />
    </div>
  );
};

export default JsonModal;
