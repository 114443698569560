import React from "react";
import { Chart as ChartJS, TimeScale, TimeSeriesScale } from "chart.js";
import AreaChartShimmer from "components/reusable/shimmers/area_chart_shimmer";

// import { formatLocalisedDate } from "util/format_helpers";
import AnalyticsLegend from "./analytics_legend";
import StackedAreaChart from "./stacked_area_chart";
import "chartjs-adapter-moment";

ChartJS.register(TimeScale, TimeSeriesScale);

export default function JobVolumeGraph({ loading, chartData }) {
  if (loading) {
    return <AreaChartShimmer />;
  }

  // group by same date
  const result = chartData?.reduce((r, a) => {
    const created_at = a.created_at;
    r[created_at] = r[created_at] || [];
    r[created_at].push(a);
    return r;
  }, Object.create(null));

  const labels = [];

  const commonOptions = {
    fill: true,
    borderWidth: 0,
    showLine: false,
    cubicInterpolationMode: "monotone",
  };
  const failedDatasets = {
    ...commonOptions,
    label: "Fail",
    data: [],
    backgroundColor: "rgb(245, 169, 147)",
  };

  const passedDatasets = {
    ...commonOptions,
    label: "Pass",
    data: [],
    backgroundColor: "rgb(10, 204, 148)",
  };

  const erroredDatasets = {
    ...commonOptions,
    label: "Error",
    data: [],
    backgroundColor: "rgb(145, 25, 15)",
  };

  let maxFailed = 0;
  let maxPassed = 0;
  let maxErrored = 0;

  Object.keys(result).forEach((createdAtKey) => {
    labels.push(createdAtKey);
    const arrayOfObjectsForGivenDate = result[createdAtKey];

    let totalFailedForGivenDate = 0;
    let totalPassedForGivenDate = 0;
    let totalErroredForGivenDate = 0;

    arrayOfObjectsForGivenDate.forEach((elem) => {
      totalFailedForGivenDate += parseInt(elem.failed_jobs, 10);
      totalPassedForGivenDate += parseInt(elem.passed_jobs, 10);
      totalErroredForGivenDate += parseInt(elem.errored_jobs, 10);

      maxFailed = Math.max(totalFailedForGivenDate, maxFailed);
      maxPassed = Math.max(totalPassedForGivenDate, maxPassed);
      maxErrored = Math.max(totalErroredForGivenDate, maxErrored);
    });

    failedDatasets.data = failedDatasets.data.concat([totalFailedForGivenDate]);
    passedDatasets.data = passedDatasets.data.concat([totalPassedForGivenDate]);
    erroredDatasets.data = erroredDatasets.data.concat([
      totalErroredForGivenDate,
    ]);
  });

  const dataSets = {
    [maxFailed]: failedDatasets,
    [maxErrored]: erroredDatasets,
    [maxPassed]: passedDatasets,
  };

  const sorted = [maxErrored, maxFailed, maxPassed].sort((a, b) => a - b);

  const data = {
    labels,
    datasets: [dataSets[sorted[0]], dataSets[sorted[1]], dataSets[sorted[2]]],
  };

  return (
    <div className="stacked-bar-container">
      <h5 className="mute-note">Job Volume Distributions</h5>
      <StackedAreaChart
        data={data}
        height={270}
        width={456}
        legend={{ display: false }}
        xAxis={{
          type: "timeseries",
          time: {
            displayFormats: {
              millisecond: "MMM D",
              second: "MMM D",
              hour: "MMM D",
              minute: "MMM D",
              day: "MMM D",
              week: "MMM D",
              month: "MMM D",
            },
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 6,
            major: {
              enabled: true,
            },
          },
        }}
      />
      <AnalyticsLegend />
    </div>
  );
}
