import React, { Component } from "react";
import CloseIcon from "resources/img/icons/x.svg";
import { decode } from "util/route_util";

import ReviewImage from "./review_image";

class UpdateImageReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accept: null,
      footer: false,
    };
    this.toggleAccept = this.toggleAccept.bind(this);
    this.toggleFooter = this.toggleFooter.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  toggleAccept(value) {
    this.setState({
      accept: value,
      footer: true,
    });
  }

  toggleFooter() {
    const { footer } = this.state;
    this.setState({
      footer: !footer,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const APIform = { review: {} };
    APIform.review.accept = this.state.accept;
    APIform.id = this.props.review.id;
    if (this.props.endpoint) {
      this.props.endpoint(APIform).then((resp) => {
        this.props.callback?.(resp, APIform.id);
        this.props.next();
      });
    } else {
      this.props.submitReview(APIform).then((resp) => {
        this.props.callback?.(resp, APIform.id);
        this.props.next();
      });
    }
  }

  render() {
    let confirmModal;
    let result;
    let author;
    if (this.state.footer) {
      confirmModal = (
        <div id="footer--modal-Enroled">
          <div className="update-image__prompt">
            <div className="newsmile-heading-h2">
              Are you sure you want to{" "}
              <span
                className={this.state.accept ? "confidence5" : "confidence1"}
              >
                {this.state.accept ? "accept" : "reject"}
              </span>{" "}
              the new photo?
            </div>
            <div className="update-image__prompt-buttons">
              <button
                className="btn btn-link "
                id="no"
                onClick={this.toggleFooter}
              >
                No
              </button>
              <button
                className="btn btn-primary "
                id="yes"
                onClick={this.handleSubmit}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      );
    }

    if (this.props.completed) {
      result = (
        <div className="block--decision">
          <p
            className={
              this.props.review.smile_reference.result.ConfidenceValue === "100"
                ? "success"
                : "failure"
            }
          >
            {this.props.review.smile_reference.result.ResultText}
          </p>
        </div>
      );
    }

    if (this.props.completed) {
      author = this.props.review.author;
    } else {
      author = decode(localStorage.token).email;
    }

    return (
      <div
        style={{ display: this.props.completed ? "none" : "block" }}
        className="modal-backdrop"
      >
        <div className="smile-modal modal-style modal--x-large">
          <div className="button-container--right smile-modal__close">
            <img
              className={`icon--medium $(this.props.completed ? 'hidden' : '')`}
              onClick={this.props.callback}
              src={CloseIcon}
              alt="close-icon"
            />
          </div>
          <div className="update-image__container">
            <div className="newsmile-heading-h1 update-image__header">
              Update Enrolled Photo on File
            </div>
            <div className="update-image__details">
              <div className="update-image__detail">
                <div className="update-image__label">User ID</div>
                <div>
                  {this.props.user_id ||
                    this.props.review.job.partner_params.user_id}
                </div>
              </div>

              <div className="update-image__detail">
                <div className="update-image__label">Partner</div>
                <div>{this.props.review.job.partner_id}</div>
              </div>

              <div className="update-image__detail">
                <div className="update-image__label">Job ID</div>
                <div>{this.props.review.job.job_id}</div>
              </div>

              <div className="update-image__detail">
                <div className="update-image__label">Created at</div>
                <div>
                  {new Date(this.props.review.created_at).toLocaleString()}
                </div>
              </div>

              <div className="update-image__detail">
                <div className="update-image__label">Author</div>
                <div>{author}</div>
              </div>
            </div>
            <div className="comparison__content">
              <div className="block--decision">
                <div>
                  <h4>
                    {this.props.completed ? "Previous Photo" : "Current Photo"}
                  </h4>
                  <ReviewImage left source={this.props.review.source} />
                </div>
                {this.props.completed ? null : (
                  <button
                    className="btn btn-secondary"
                    onClick={() => this.toggleAccept(false)}
                  >
                    Keep this photo
                  </button>
                )}
              </div>
              <div className="block--decision">
                <div>
                  <h4>
                    {this.props.completed ? "Current Photo" : "New Photo"}
                  </h4>
                  <ReviewImage left={false} source={this.props.review.target} />
                </div>
                {this.props.completed ? null : (
                  <button
                    className="btn btn-primary"
                    onClick={() => this.toggleAccept(true)}
                  >
                    Replace Current Photo with new Photo
                  </button>
                )}
              </div>
            </div>
          </div>
          {confirmModal}
          {result}
        </div>
      </div>
    );
  }
}

export default UpdateImageReview;
