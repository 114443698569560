import React from "react";
import ModalWrapper from "components/reusable/web_links/ModalWrapper";
import { StyledButton } from "components/reusable/web_links/StyledButton";
import WarningIcon from "resources/img/icons/out-of-credits-warning.svg";
import "./out_of_credits_modal.scss";

function OutOfCreditsModal({ isOpen, onClose }) {
  return (
    <ModalWrapper hideBackButton isOpen={isOpen} onClose={() => onClose(false)}>
      <div className="out-of-credits-modal new-typography">
        <img src={WarningIcon} alt="" />
        <p className="h2">You are out of credits.</p>
        <p>Add funds to your wallet to run more verifications</p>
        <div className="modal__actions">
          <StyledButton defaultButton onClick={() => onClose(false)}>
            Cancel
          </StyledButton>
          <StyledButton isActive href="billing/payment">
            Add funds
          </StyledButton>
        </div>
      </div>
    </ModalWrapper>
  );
}

export default OutOfCreditsModal;
