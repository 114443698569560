export const countryFlags: Record<string, string> = {
  DZ: String.fromCodePoint(0x1f1e9) + String.fromCodePoint(0x1f1ff), // Algeria
  AO: String.fromCodePoint(0x1f1e6) + String.fromCodePoint(0x1f1f4), // Angola
  BJ: String.fromCodePoint(0x1f1e7) + String.fromCodePoint(0x1f1ef), // Benin
  BW: String.fromCodePoint(0x1f1e7) + String.fromCodePoint(0x1f1fc), // Botswana
  BF: String.fromCodePoint(0x1f1e7) + String.fromCodePoint(0x1f1eb), // Burkina Faso
  BI: String.fromCodePoint(0x1f1e7) + String.fromCodePoint(0x1f1ee), // Burundi
  CV: String.fromCodePoint(0x1f1e8) + String.fromCodePoint(0x1f1fb), // Cape Verde
  CM: String.fromCodePoint(0x1f1e8) + String.fromCodePoint(0x1f1f2), // Cameroon
  CF: String.fromCodePoint(0x1f1e8) + String.fromCodePoint(0x1f1eb), // Central African Republic
  TD: String.fromCodePoint(0x1f1f9) + String.fromCodePoint(0x1f1e9), // Chad
  KM: String.fromCodePoint(0x1f1f0) + String.fromCodePoint(0x1f1f2), // Comoros
  CG: String.fromCodePoint(0x1f1e8) + String.fromCodePoint(0x1f1ec), // Congo (Brazzaville)
  CD: String.fromCodePoint(0x1f1e8) + String.fromCodePoint(0x1f1e9), // Congo (Kinshasa)
  DJ: String.fromCodePoint(0x1f1e9) + String.fromCodePoint(0x1f1ef), // Djibouti
  EG: String.fromCodePoint(0x1f1ea) + String.fromCodePoint(0x1f1ec), // Egypt
  GQ: String.fromCodePoint(0x1f1ec) + String.fromCodePoint(0x1f1f6), // Equatorial Guinea
  ER: String.fromCodePoint(0x1f1ea) + String.fromCodePoint(0x1f1f7), // Eritrea
  SZ: String.fromCodePoint(0x1f1f8) + String.fromCodePoint(0x1f1ff), // Eswatini
  ET: String.fromCodePoint(0x1f1ea) + String.fromCodePoint(0x1f1f9), // Ethiopia
  GA: String.fromCodePoint(0x1f1ec) + String.fromCodePoint(0x1f1e6), // Gabon
  GM: String.fromCodePoint(0x1f1ec) + String.fromCodePoint(0x1f1f2), // Gambia
  GH: String.fromCodePoint(0x1f1ec) + String.fromCodePoint(0x1f1ed), // Ghana
  GN: String.fromCodePoint(0x1f1ec) + String.fromCodePoint(0x1f1f3), // Guinea
  GW: String.fromCodePoint(0x1f1ec) + String.fromCodePoint(0x1f1fc), // Guinea-Bissau
  KE: String.fromCodePoint(0x1f1f0) + String.fromCodePoint(0x1f1ea), // Kenya
  LS: String.fromCodePoint(0x1f1f1) + String.fromCodePoint(0x1f1f8), // Lesotho
  LR: String.fromCodePoint(0x1f1f1) + String.fromCodePoint(0x1f1f7), // Liberia
  LY: String.fromCodePoint(0x1f1f1) + String.fromCodePoint(0x1f1fe), // Libya
  MG: String.fromCodePoint(0x1f1f2) + String.fromCodePoint(0x1f1ec), // Madagascar
  MW: String.fromCodePoint(0x1f1f2) + String.fromCodePoint(0x1f1fc), // Malawi
  ML: String.fromCodePoint(0x1f1f2) + String.fromCodePoint(0x1f1f1), // Mali
  MR: String.fromCodePoint(0x1f1f2) + String.fromCodePoint(0x1f1f7), // Mauritania
  MU: String.fromCodePoint(0x1f1f2) + String.fromCodePoint(0x1f1fa), // Mauritius
  MA: String.fromCodePoint(0x1f1f2) + String.fromCodePoint(0x1f1e6), // Morocco
  MZ: String.fromCodePoint(0x1f1f2) + String.fromCodePoint(0x1f1ff), // Mozambique
  NA: String.fromCodePoint(0x1f1f3) + String.fromCodePoint(0x1f1e6), // Namibia
  NE: String.fromCodePoint(0x1f1f3) + String.fromCodePoint(0x1f1ea), // Niger
  NG: String.fromCodePoint(0x1f1f3) + String.fromCodePoint(0x1f1ec), // Nigeria
  RW: String.fromCodePoint(0x1f1f7) + String.fromCodePoint(0x1f1fc), // Rwanda
  ST: String.fromCodePoint(0x1f1f8) + String.fromCodePoint(0x1f1f9), // São Tomé and Príncipe
  SN: String.fromCodePoint(0x1f1f8) + String.fromCodePoint(0x1f1f3), // Senegal
  SC: String.fromCodePoint(0x1f1f8) + String.fromCodePoint(0x1f1e8), // Seychelles
  SL: String.fromCodePoint(0x1f1f8) + String.fromCodePoint(0x1f1f1), // Sierra Leone
  SO: String.fromCodePoint(0x1f1f8) + String.fromCodePoint(0x1f1f4), // Somalia
  ZA: String.fromCodePoint(0x1f1ff) + String.fromCodePoint(0x1f1e6), // South Africa
  SS: String.fromCodePoint(0x1f1f8) + String.fromCodePoint(0x1f1f8), // South Sudan
  SD: String.fromCodePoint(0x1f1f8) + String.fromCodePoint(0x1f1e9), // Sudan
  TZ: String.fromCodePoint(0x1f1f9) + String.fromCodePoint(0x1f1ff), // Tanzania
  TG: String.fromCodePoint(0x1f1f9) + String.fromCodePoint(0x1f1ec), // Togo
  TN: String.fromCodePoint(0x1f1f9) + String.fromCodePoint(0x1f1f3), // Tunisia
  UG: String.fromCodePoint(0x1f1fa) + String.fromCodePoint(0x1f1ec), // Uganda
  ZM: String.fromCodePoint(0x1f1ff) + String.fromCodePoint(0x1f1f2), // Zambia
  ZW: String.fromCodePoint(0x1f1ff) + String.fromCodePoint(0x1f1fc), // Zimbabwe
};
