import { useContext, useEffect } from "react";
import { useParams, generatePath, useHistory } from "react-router-dom";
import { DisplayEnvironment } from "contexts/displayEnvironment";

function useEnvironment(url) {
  const params = useParams();
  const { environment } = useContext(DisplayEnvironment);
  const history = useHistory();
  const env = { false: "sandbox", true: "production" }[environment];
  const path = generatePath(url, {
    environment: env,
  });
  useEffect(() => {
    if (env == params.environment) {
      return;
    }
    history.push(path);
  }, [env]);

  function setPath(newEnv) {
    const newPath = generatePath(url, {
      environment: newEnv,
    });
    history.push(newPath);
  }

  function buildEnvPath(newPath) {
    return generatePath(newPath, {
      environment: env,
    });
  }

  return { path, buildEnvPath, setPath };
}

export default useEnvironment;
