import { useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { App } from "antd";
import axios from "axios";

interface Props {
  children: React.ReactNode;
}

const CustomQueryClientProvider: React.FC<Props> = ({ children }) => {
  const { notification } = App.useApp();
  const [queryClient] = useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: 2 * 60 * 1000,
          gcTime: 5 * 60 * 1000,
        },
        mutations: {
          onError: (error) => {
            if (axios.isAxiosError(error) && !error.response) {
              notification.error({
                message: "Network error",
                description: "Unable to connect to the server",
              });
            }
          },
        },
      },
    }),
  );

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default CustomQueryClientProvider;
