export const PLANS = {
  pay_as_you_go: "Pay-As-You-Go",
  contract: "Enterprise",
};

export const EDV_IDS = {
  Ghana: ["DRIVERS_LICENSE", "PASSPORT", "SSNIT", "VOTER_ID", "NEW_VOTER_ID"],
  Kenya: ["ALIEN_CARD", "NATIONAL_ID", "NATIONAL_ID_NO_PHOTO", "PASSPORT"],
  Nigeria: ["DRIVERS_LICENSE", "VOTER_ID"],
  "South Africa": ["NATIONAL_ID", "NATIONAL_ID_NO_PHOTO"],
};
