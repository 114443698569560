import React, { useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import GraphPagination from "components/pagination/graph_pagination";
import StackedBarChart from "components/reusable/charts/stacked_bar_chart";
import BarChartShimmer from "components/reusable/shimmers/bar_chart_shimmer";
import { AnalyticsContext } from "contexts/analytics_context";
import { fetchPartnerAnalytics } from "util/api_util";
import { ISOCountryCodeToFullNameMap } from "util/format_helpers";
import AnalyticsLegend from "./analytics_legend";

const determineBorderRadius = (context) => {
  const numDatasets = context.chart.data.datasets.length;
  let showBorder = false;

  if (!context?.parsed) {
    return 0;
  }

  if (context.datasetIndex === numDatasets - 1) {
    // Last dataset, always a yes
    showBorder = true;
  } else if (context?.parsed.y !== 0) {
    // We show a radius when we're the highest value in a positive stack
    // or the lowest value in a negative stack
    const sign = Math.sign(context.parsed.y);
    let matches = false;

    // Look for any other non-0 values in the same stack that have the same sign
    for (let i = context.datasetIndex + 1; i < numDatasets; i += 1) {
      const val = context.parsed._stacks.y[i];
      if (val && Math.sign(val) == sign) {
        matches = true;
        break;
      }
    }

    showBorder = !matches;
  }

  if (!showBorder) {
    return 0;
  }

  const radius = 0;
  if (context.parsed.y > 0) {
    return {
      topLeft: 10,
      topRight: 10,
    };
  }

  if (context.parsed.y < 0) {
    return {
      bottomLeft: 10,
      bottomRight: 10,
    };
  }
  return radius;
};

// Overide long product names
export const shortIDNames = {
  DRIVERS_LICENSE: "D._LICENSE",
  NATIONAL_ID_NO_PHOTO: "N._ID_NO_PHOTO",
};

export default function IdVolumesGraph() {
  const [idData, setIdData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const { filters, partnerId } = useContext(AnalyticsContext);
  const pageSize = 4;

  useEffect(() => {
    if (partnerId == null) {
      return;
    }
    setPage(1);
    const abortController = new AbortController();
    setLoading(true);
    fetchPartnerAnalytics(
      "/metrics/id_volumes_analytics",
      partnerId,
      filters,
      abortController,
    )
      .then((response) => {
        setIdData(response);
        setLoading(false);
      })
      .catch((e) => {
        if (e.name !== "AbortError") {
          console.error(e);
          Sentry.captureException(e);
        }
      });

    return () => abortController.abort();
  }, [partnerId, filters]);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const labels = [];
  const countryTotalJobs = {};
  // group by same id type and country
  const result = idData.reduce((r, a) => {
    let [, id_type] = (a.id_type || "").split("-");
    id_type = shortIDNames[id_type] || id_type || "Not Available";
    const country =
      ISOCountryCodeToFullNameMap[a.country] || a.country || "Not Available";
    const label = `${country}\n${id_type?.split("_")?.join(" ")}`;
    countryTotalJobs[label] ||= 0;
    countryTotalJobs[label] += parseInt(a.total_jobs, 10);
    r[label] = r[label] || [];
    r[label].push(a);
    return r;
  }, Object.create(null));

  const largestPoint = Math.max(...Object.values(countryTotalJobs));

  const dataSetOptions = {
    borderRadius: determineBorderRadius,
  };

  const failDatasets = {
    fill: true,
    label: "Fail",
    data: [],
    backgroundColor: "rgb(145, 25, 15)",
    ...dataSetOptions,
  };
  const passDatasets = {
    fill: true,
    label: "Pass",
    data: [],
    backgroundColor: "rgb(10, 204, 148)",
    ...dataSetOptions,
  };
  const errorDatasets = {
    fill: true,
    label: "Error",
    data: [],
    backgroundColor: "rgb(245, 169, 147)",
    ...dataSetOptions,
  };

  let nextPage = 0;
  let paginatedKeys = [];
  const result_keys = Object.keys(result);
  const offest = (page - 1) * pageSize;
  const end_at = offest + pageSize;

  if (end_at < result_keys.length) nextPage = page + 1;

  paginatedKeys = result_keys.slice(offest, end_at);

  paginatedKeys.forEach((product) => {
    labels.push(product.split("\n"));
    const arrayOfObjectsForGivenDate = result[product];

    let totalFailedForGivenCountry = 0;
    let totalPassedForGivenCountry = 0;
    let totalErroredForGivenCountry = 0;

    arrayOfObjectsForGivenDate.forEach((elem) => {
      totalFailedForGivenCountry += parseInt(elem.failed_jobs, 10);
      totalPassedForGivenCountry += parseInt(elem.passed_jobs, 10);
      totalErroredForGivenCountry += parseInt(elem.errored_jobs, 10);
    });

    failDatasets.data = failDatasets.data.concat([totalFailedForGivenCountry]);
    passDatasets.data = passDatasets.data.concat([totalPassedForGivenCountry]);
    errorDatasets.data = errorDatasets.data.concat([
      totalErroredForGivenCountry,
    ]);
  });

  const data = {
    labels,
    datasets: [passDatasets, errorDatasets, failDatasets],
  };

  if (loading) {
    return <BarChartShimmer />;
  }

  return (
    <>
      <GraphPagination
        style={{ marginBottom: "60px", marginTop: "36px", marginRight: "30px" }}
        nextPage={nextPage}
        iconsOnly
        activePage={+page}
        onPageChange={handlePageChange}
      />
      <StackedBarChart
        data={data}
        yMax={largestPoint}
        legend={{ display: false }}
      />
      <AnalyticsLegend />
    </>
  );
}
