import React from "react";
import { AdvancedMarker, Map } from "@vis.gl/react-google-maps";
import { Image } from "antd";
import LocationPin from "components/AddressVerificationResult/LocationPin";
import MatchedLocationIcon from "resources/img/icons/matched-location.png";
import SearchAddressIcon from "resources/img/icons/searched-address.png";
import { AddressVerification } from "types/api/addressVerification";

interface Props {
  addressVerification?: AddressVerification;
}

function CustomMarker({ isMatched }: { isMatched: boolean }) {
  const icon_url = isMatched ? MatchedLocationIcon : SearchAddressIcon;
  return <Image preview={false} width={24} height={31} src={icon_url} />;
}

function AddressMap(props: Props) {
  const id = React.useId();
  const addresses =
    (props.addressVerification?.addresses || []).filter(
      (addr) => addr.latitude && addr.longitude,
    ) || [];

  if (addresses.length === 0) {
    return null;
  }

  const address = addresses.find((addr) => addr.is_matched) || addresses[0];
  const position = {
    lat: (address?.latitude as number) ?? 0,
    lng: (address?.longitude as number) ?? 0,
  };

  let locationPinCount = 0;

  return (
    <div className="h-[500px]">
      <Map defaultCenter={position} defaultZoom={15} mapId={id}>
        {addresses.map((address) => {
          const isMatchedOrSearched = address.is_matched || address.is_searched;
          if (!isMatchedOrSearched) {
            locationPinCount += 1;
          }

          return (
            <AdvancedMarker
              key={address.id}
              position={{
                lat: address.latitude,
                lng: address.longitude,
              }}
            >
              {isMatchedOrSearched ? (
                <CustomMarker isMatched={address.is_matched} />
              ) : (
                <LocationPin count={locationPinCount} />
              )}
            </AdvancedMarker>
          );
        })}
      </Map>
    </div>
  );
}

export default AddressMap;
