import React, {
  useState,
  useEffect,
  createRef,
  useRef,
  useLayoutEffect,
} from "react";
import CloseIcon from "resources/img/icons/x.svg";

const ESCAPE_KEY = 27;

const rootDOMNode = document.getElementById("si-fullscreen-root");

function ReviewImage({
  jobID,
  label,
  source,
  variant,
  zoom: showZoomActions = false,
  pan: showPanActions = false,
  left: toolBarPositionedOnLeft = true,
  showResetZoom = false,
}) {
  const ref = createRef(null);
  const closeIconRef = createRef(null);

  const [fullScreen, setFullScreen] = useState(false);
  const [rotationAngle, setRotationAngle] = useState(0);
  const [translateX, setTranslateX] = useState(0);
  const [translateY, setTranslateY] = useState(0);
  const [scale, setScale] = useState(1);
  const [referenceNode, setReferenceNode] = useState();
  const [originalImageHeight, setOriginalImageHeight] = useState();
  const [originalImageWidth, setOriginalImageWidth] = useState();
  const [imageHeight, setImageHeight] = useState();
  const [imageWidth, setImageWidth] = useState();
  const imageRef = useRef(null);

  const _handleKeyDown = (event) => {
    if (event.keyCode === ESCAPE_KEY && fullScreen) {
      setFullScreen(!fullScreen);
    }
  };

  const toggleFullScreen = () => {
    resetZoom();
    setFullScreen(!fullScreen);
  };

  useEffect(() => {
    if (!closeIconRef.current) {
      return;
    }
    closeIconRef.current.addEventListener("click", toggleFullScreen);

    return () => {
      closeIconRef?.current?.removeEventListener("click", toggleFullScreen);
    };
  }, [fullScreen]);

  useEffect(() => {
    document.addEventListener("keydown", _handleKeyDown);
    return () => {
      document.removeEventListener("keydown", _handleKeyDown);
    };
  }, [fullScreen]);

  useEffect(() => {
    const imageContainer = ref.current;

    if (imageContainer) {
      if (fullScreen) {
        if (!referenceNode) setReferenceNode(ref.current.nextSibling);
        rootDOMNode.append(imageContainer);
      } else if (referenceNode && imageContainer) {
        referenceNode.parentNode.insertBefore(imageContainer, referenceNode);
        setReferenceNode();
      }
    }
  }, [ref, fullScreen]);

  useLayoutEffect(() => {
    if (!imageRef.current) return;

    if (fullScreen) {
      let width = "50%";
      if (variant === "id_card_review") {
        const submittedIdCardReviewNode = document.getElementById(
          "submitted_id_card_review",
        );
        const paddings = 132; // This is the sum of padding main container padding, image review padding.
        width = `${submittedIdCardReviewNode.offsetWidth + paddings}px`;
      }
      if (originalImageHeight > originalImageWidth) {
        imageRef.current.style.setProperty("height", "100vh", "important");
        imageRef.current.style.setProperty("width", "auto", "important");
      } else {
        imageRef.current.style.setProperty("width", width, "important");
        imageRef.current.style.setProperty("height", "auto", "important");
      }
      const newHeight = imageRef.current.offsetHeight;
      const newWidth = imageRef.current.offsetWidth;
      let translateX = 0;
      let translateY = 0;
      const calcTranslate = Math.abs((newHeight - newWidth) / 2);
      if (![0, 180].includes(Math.abs(rotationAngle))) {
        if (newWidth > newHeight) {
          translateY = calcTranslate;
        } else {
          translateX = calcTranslate * (toolBarPositionedOnLeft ? 1 : -1);
        }
      }
      const translate = `translateX(${translateX}px) translateY(${translateY}px)`;
      const transform = `${translate} rotate(${rotationAngle}deg) scalex(${scale}) scaleY(${scale})`;
      imageRef.current.style.setProperty("transform", transform);
    } else {
      imageRef.current.style.setProperty(
        "height",
        `${imageHeight}px`,
        "important",
      );
      imageRef.current.style.setProperty(
        "width",
        `${imageWidth}px`,
        "important",
      );

      const translate =
        Math.abs(scale) !== 1
          ? `translateX(${translateX}%) translateY(${translateY}%)`
          : `translateX(${translateX}px) translateY(${translateY}px)`;
      const transform = `${translate} rotate(${rotationAngle}deg) scalex(${scale}) scaleY(${scale})`;
      imageRef.current.style.setProperty("transform", transform);
    }
  }, [
    imageRef,
    scale,
    imageHeight,
    imageWidth,
    translateX,
    translateY,
    rotationAngle,
    fullScreen,
  ]);

  const rotateLeft = () => {
    const angle = (rotationAngle - 90) % 360;
    setRotationAngle(angle);
    reCalculateImageSize(angle);
  };

  const rotateRight = () => {
    const angle = (rotationAngle + 90) % 360;
    setRotationAngle(angle);
    reCalculateImageSize(angle);
  };

  const flip = () => {
    setScale(-scale);
  };

  const zoomIn = () => {
    setScale(scale * 2);
  };

  const zoomOut = () => {
    setScale(scale / 2);
  };

  const resetZoom = () => {
    setScale(1);
  };

  const panLeft = () => {
    setTranslateX(translateX + 20);
  };

  const panRight = () => {
    setTranslateX(translateX - 20);
  };

  const panUp = () => {
    setTranslateY(translateY + 20);
  };

  const panDown = () => {
    setTranslateY(translateY - 20);
  };

  const reCalculateImageSize = (angle) => {
    if ([0, 180].includes(Math.abs(angle))) {
      setImageWidth(originalImageWidth);
      setImageHeight(originalImageHeight);
      setTranslateX(0);
      setTranslateY(0);
      return;
    }
    const maxHeight = 240;
    let width;
    let height;
    let translateX;
    let translateY;

    if (originalImageWidth > originalImageHeight) {
      width = originalImageHeight;
      height = (originalImageHeight * width) / originalImageWidth;
      translateX = 0;
      translateY = Math.abs((width - height) / 2);
    } else {
      height = maxHeight;
      width = (height * originalImageWidth) / originalImageHeight;
      translateY = 0;
      translateX = Math.abs((width - height) / 2);
    }
    setImageWidth(width);
    setImageHeight(height);
    setTranslateX(translateX);
    setTranslateY(translateY);
  };

  let close;
  let divID = "";
  let divClass = "img--nofocus";

  if (fullScreen) {
    close = (
      <img
        ref={closeIconRef}
        className={`fullscreen__close icon--medium ${
          toolBarPositionedOnLeft ? "left" : "right"
        }`}
        src={CloseIcon}
        alt="close-icon"
      />
    );

    divID += "full-screen";

    if (toolBarPositionedOnLeft) {
      divID += "-left";
    } else {
      divID += "-right";
    }

    divClass = `${divClass} ${divID}`;
  }

  // find the max width to use as the container width, in other to prevent resizing of container with when we change
  // orientation image. Fixing the width x height of container allow the img tag to properly resize and maintain
  // aspect ratio without cropping out part of the image.
  const maxWidth = 480;
  const minWidth = 275; // toolbar width
  const containerWidth = Math.min(
    Math.max(
      imageHeight,
      imageWidth,
      originalImageHeight,
      originalImageWidth,
      minWidth,
    ),
    maxWidth,
  );
  const containerStyle = { width: `${containerWidth}px`, height: `240px` };

  return (
    <div className="review-image">
      {label && <p className="review-image__label">{label}</p>}
      <div
        ref={ref}
        className="review-image__picture"
        style={{
          "--picture-max-width": `${maxWidth}px`,
          ...containerStyle,
        }}
      >
        <img
          ref={imageRef}
          key={jobID}
          alt={`for ${jobID}`}
          src={source}
          className={divClass}
          id={divID}
          onContextMenu={(e) => {
            e.preventDefault();
            return false;
          }}
          onLoad={(e) => {
            setOriginalImageWidth(e.target.width);
            setOriginalImageHeight(e.target.height);
            setImageWidth(e.target.width);
            setImageHeight(e.target.height);
          }}
        />
        {close}
      </div>
      <div className="review-image__toolbar">
        <ul
          className={`photo-toolbar ${
            toolBarPositionedOnLeft ? "left" : "right"
          }`}
        >
          {showZoomActions && (
            <>
              <li>
                <button
                  type="button"
                  disabled={fullScreen}
                  className="btn-toolbar"
                  title="zoom in"
                  onClick={zoomIn}
                >
                  <i className="icon si-zoom-in" />
                </button>
              </li>
              <li>
                <button
                  type="button"
                  disabled={fullScreen}
                  className="btn-toolbar"
                  title="zoom out"
                  onClick={zoomOut}
                >
                  <i className="icon si-zoom-out" />
                </button>
              </li>
            </>
          )}
          {showResetZoom && (
            <li>
              <button
                type="button"
                disabled={fullScreen}
                className="btn-toolbar"
                title="reset"
                onClick={resetZoom}
              >
                <i className="icon si-zoom-reset" />
              </button>
            </li>
          )}
          {showPanActions && (
            <>
              <li>
                <button
                  type="button"
                  disabled={scale === 1 || fullScreen}
                  className="btn-toolbar"
                  title="up"
                  onClick={panUp}
                >
                  <i className="icon si-pan-up" />
                </button>
              </li>
              <li>
                <button
                  type="button"
                  disabled={scale === 1 || fullScreen}
                  className="btn-toolbar"
                  title="down"
                  onClick={panDown}
                >
                  <i className="icon si-pan-down" />
                </button>
              </li>
              <li>
                <button
                  type="button"
                  disabled={scale === 1 || fullScreen}
                  className="btn-toolbar"
                  title="left"
                  onClick={panLeft}
                >
                  <i className="icon si-pan-left" />
                </button>
              </li>
              <li>
                <button
                  type="button"
                  disabled={scale === 1 || fullScreen}
                  className="btn-toolbar"
                  title="right"
                  onClick={panRight}
                >
                  <i className="icon si-pan-right" />
                </button>
              </li>
            </>
          )}
          <li>
            <button
              type="button"
              disabled={fullScreen}
              className="btn-toolbar"
              title="expand"
              onClick={toggleFullScreen}
            >
              <i className="icon si-expand" />
            </button>
          </li>
          <li>
            <button
              type="button"
              disabled={fullScreen}
              className="btn-toolbar"
              title="rotate left"
              onClick={rotateLeft}
            >
              <i className="icon si-rotate-ccw" />
            </button>
          </li>
          <li>
            <button
              type="button"
              disabled={fullScreen}
              className="btn-toolbar"
              title="rotate right"
              onClick={rotateRight}
            >
              <i className="icon si-rotate-cw" />
            </button>
          </li>
          <li>
            <button
              type="button"
              disabled={fullScreen}
              className="btn-toolbar"
              title="flip"
              onClick={flip}
            >
              <i className="icon si-flip" />
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ReviewImage;
