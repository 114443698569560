import { createReducer } from "./create_reducer";

const ErrorsReducer = createReducer("", {
  RECEIVE_LOGS: (state, action) => action.logsAndCount.error || "",
  RECEIVE_REFERENCES: (state, action) => action.referencesAndCount.error || "",
  RECEIVE_RESULTS: (state, action) => action.resultsAndCount.error || "",
  RECEIVE_REVIEWS: (state, action) => action.reviewsAndCount.error || "",
  RECEIVE_RESULTJOBS: (state, action) => action.resultJobsAndCount.error || "",
  RECEIVE_DELETEDJOB: (state, action) =>
    action.resultJobsAndMessage.error || "",
  RECEIVE_JOB: (state, action) => action.job.error || "",
  RECEIVE_COMPANIES: (state, action) => action.companies.error || "",
  RECEIVE_JOBS: (state, action) => action.jobs.error || "",
  RECEIVE_ERROR: (state, action) => action.error || "",
});

export default ErrorsReducer;
