import React from "react";
import BarChart from "components/reusable/charts/bar_graph";
import styles from "./product_overview_graph.module.css";

const dataSetOptions = {
  borderRadius: { topLeft: 5, topRight: 5 },
};

export default function ProductOverviewGraph(props) {
  let totalFailed = 0;
  let totalPassed = 0;
  let totalError = 0;
  let totalChange = 0;
  let passPercentage = 0;
  let failPercentage = 0;
  let errorPercentage = 0;
  const graphData = props.data || [];

  graphData.forEach((product) => {
    totalFailed += parseInt(product.failed_jobs, 10);
    totalPassed += parseInt(product.passed_jobs, 10);
    totalError += parseInt(product.errored_jobs, 10);
    totalChange += parseInt(product.change, 10);
  });

  const productTotal = totalPassed + totalError + totalFailed;

  let overallPercentage = 0;

  if (productTotal > 0) {
    passPercentage = ((totalPassed / productTotal) * 100).toFixed(1);
    failPercentage = ((totalFailed / productTotal) * 100).toFixed(1);
    errorPercentage = ((totalError / productTotal) * 100).toFixed(1);
  }

  if (props.overallTotal > 0) {
    overallPercentage = ((productTotal / props.overallTotal) * 100).toFixed();
  }

  const totalJobs = totalPassed + totalError + totalFailed;

  const data = {
    labels: [
      new Intl.NumberFormat().format(totalPassed),
      new Intl.NumberFormat().format(totalError),
      new Intl.NumberFormat().format(totalFailed),
    ],
    datasets: [
      {
        data: [totalPassed, totalError, totalFailed],
        legend: ["Success", "Error", "Fail"],
        datalabels: [
          `${passPercentage}%`,
          `${errorPercentage}%`,
          `${failPercentage}%`,
        ],
        borderColor: [
          "rgba(10, 204, 148, 1)",
          "rgba(245, 169, 147, 1)",
          "rgb(145, 25, 15)",
        ],
        backgroundColor: [
          "rgba(10, 204, 148, 1)",
          "rgba(245, 169, 147, 1)",
          "rgb(145, 25, 15)",
        ],
        ...dataSetOptions,
      },
    ],
  };
  return (
    <div className={styles.bargraph_container}>
      <div className={styles.percentage_of_job}>
        {overallPercentage}% of jobs
      </div>
      <div className={styles.volume_increase}>
        <div className={styles.volume}>
          {new Intl.NumberFormat().format(totalJobs)}
        </div>
        <div className={styles.increase}>
          {totalChange > 0 && <span>{totalChange}</span>}
        </div>
      </div>
      <div className={styles.job_type}>{props.product}</div>
      <BarChart
        data={data}
        height={30}
        width={15}
        showLegend={false}
        padding={{ bottom: 40, top: 15 }}
        extraOptions={{
          layout: {
            padding: 20,
          },
          maintainAspectRatio: false,
          responsive: false,
        }}
        datalabelOptions={{
          align: "top",
          anchor: "end",
          offset: 1,
          color: "white",
        }}
      />
    </div>
  );
}
