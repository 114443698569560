import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

interface GetJobFileLinksResponse {
  files: { name: string; url: string }[];
}

interface GetJobFileLinksInput {
  id: string;
}

const getJobFileLinks = async ({ id }: GetJobFileLinksInput) => {
  const { data } = await axios.get(`/jobs/files/${id}`);
  return data;
};

export const JOB_FILE_LINKS_QUERY_KEY = "job-file-links";

export const useJobFileLinks = (
  input: GetJobFileLinksInput,
  options?: Omit<
    UseQueryOptions<GetJobFileLinksResponse, AxiosError<{ errors: string[] }>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery({
    queryKey: [JOB_FILE_LINKS_QUERY_KEY, input],
    queryFn: () => getJobFileLinks(input),
    ...options,
  });
