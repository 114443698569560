import React from "react";
import { format, parseJSON } from "date-fns";
import TimeoutReview from "components/reusable/TimeoutReview";
import { getEnvironment } from "contexts/displayEnvironment";
import { Review } from "types/review";
import "components/reusable/ReviewHeader.scss";

type ReviewHeaderProps = {
  review: Review;
  completed: boolean;
  title: string;
  onNextReview?: () => void;
};

const ReviewHeader: React.FC<ReviewHeaderProps> = ({
  title,
  review,
  completed,
  onNextReview = () => {},
}) => {
  if (completed) {
    return (
      <ul className="sidebar_jobid">
        <li>
          <span>{title}</span>
        </li>
        <li>
          <label>Job id</label>
          <span id="jobid_number">{`${review.job.partner_id}-${review.job.job_id}`}</span>
        </li>
        <li>
          <label>First sent at</label>
          <span id="jobid_firstsentat">
            {new Date(review.first_sent_at).toLocaleString()}
          </span>
        </li>
        <li>
          <label>Author</label>
          <span id="jobid_autor">{review.author}</span>
        </li>
      </ul>
    );
  }

  return (
    <section className="review_header-summary new-typography">
      <div>
        <h4 className="h4">Job ID:</h4>
        <div className="h4">
          <a
            href={`/admin/job/${getEnvironment()}/${review.job_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {review.job.job_id}
          </a>
        </div>
      </div>
      <div>
        <h4 className="h4">Partner ID:</h4>
        <div className="h4">{review.job.partner_id}</div>
      </div>
      <div>
        <h4 className="h4">First Sent Date:</h4>
        <div className="h4">
          {format(parseJSON(review.created_at), "d MMM, yyyy hh:mm a")}
        </div>
      </div>
      {review.wait_time && (
        <div>
          <h4 className="h4">Time left on review:</h4>
          <TimeoutReview
            waitTime={review.wait_time}
            onNextReview={onNextReview}
            reviewId={review.id}
          />
        </div>
      )}
    </section>
  );
};

export default ReviewHeader;
