import React, { useMemo } from "react";
import styles from "./tabs.module.css";

export function Tab({ children, isActive, ...props }) {
  const className = isActive
    ? [styles.tab, styles.active].join(" ")
    : styles.tab;

  return (
    <a role="tab" className={className} {...props}>
      {children}
    </a>
  );
}

function Tabs({ children, activeTab, onTabChange, variant }) {
  const map = useMemo(
    () =>
      children.reduce((acc, child) => {
        if (!child) {
          return acc;
        }
        return {
          ...acc,
          [child.props.id]: {
            children: child.props.children,
            title: child.props.title,
          },
        };
      }, {}),
    [children],
  );

  const ids = useMemo(() => Object.keys(map), [map]);
  const content = map[activeTab]?.children || map[ids[0]]?.children;

  return (
    <>
      <div role="tablist" className={styles[variant]}>
        {ids.map((id) => (
          <Tab
            key={id}
            onClick={() => onTabChange(id)}
            isActive={id === activeTab}
          >
            {map[id].title}
          </Tab>
        ))}
      </div>
      <div role="tabpanel">{content}</div>
    </>
  );
}

export default Tabs;
