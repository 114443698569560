import React from "react";
import BackIcon from "resources/img/icons/back-icon.svg";
import { css } from "util/format_helpers";
import styles from "./back_button.module.css";

export default function BackButton({ onClick, className, label, style }) {
  return (
    <button
      className={css(styles.backButton, className, "h3")}
      style={style}
      onClick={onClick}
    >
      <img src={BackIcon} alt="" />{" "}
      <div className="h3">{label || "Go back"}</div>
    </button>
  );
}
