import React from "react";
import { css } from "util/format_helpers";
import styles from "./link_action.module.css";

export default function LinkAction({
  icon,
  actionText,
  onClick,
  href,
  target,
  style,
  iconStyle,
  wrapperClassName,
  iconClassName,
}) {
  const content = (
    <>
      <div className={css(styles.action, iconClassName)} style={iconStyle}>
        <img src={icon} alt="" />
      </div>
      <div>{actionText}</div>
    </>
  );

  if (!href) {
    return (
      <button
        className={css(styles.actionWrapper, wrapperClassName)}
        style={style}
        onClick={onClick}
      >
        {content}
      </button>
    );
  }

  return (
    <a
      href={href}
      className={css(styles.actionWrapper, wrapperClassName)}
      style={style}
      target={target}
      onClick={onClick}
    >
      {content}
    </a>
  );
}
