import React from "react";
import Pagination from "components/pagination/pagination";
import ItemsPerPage from "./items_per_page";

export default function Paginated({
  wrapperClassName,
  dropdownClassName,
  activePage,
  itemsCountPerPage,
  totalItems,
  handlePageChange,
  changeNumberOfItems,
}) {
  return (
    <div className={`pagination__container ${wrapperClassName || ""}`}>
      <Pagination
        key={`pagination-${itemsCountPerPage}`}
        nextPage={totalItems}
        activePage={+activePage}
        onPageChange={handlePageChange}
      />
      <ItemsPerPage
        dropdownClassName={`pagination-dropdown ${dropdownClassName || ""}`}
        itemsCountPerPage={itemsCountPerPage}
        onChange={changeNumberOfItems}
      />
    </div>
  );
}
