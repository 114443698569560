import React, { createContext, useState, useEffect } from "react";
import { useSessionStorageState } from "ahooks";

import usePartnerInfo from "hooks/usePartnerInfo";

export const getEnvironment = () =>
  ({
    true: "production",
    false: "sandbox",
  })[sessionStorage.getItem("displayEnvironment")] || "production";

const initial = {
  environment: undefined,
  enabled: false,
  viewAs: undefined, // 'admin' or 'partner'
  setEnabled: () => {},
  onEnvironmentChange: () => {},
};

export const createInitialEnvironment = () => {
  const baseState = sessionStorage.getItem("displayEnvironment");

  let onLoadState = baseState;

  // If this is a new window/tab, We check the url for the environment
  // we set the session storage
  // if no environment is found, we defer the initialization to {@link getProductionEnabled}
  const inSandbox = Boolean(window.location?.pathname?.includes("sandbox"));
  const inProduction = Boolean(
    window.location?.pathname?.includes("production"),
  );
  if (inSandbox || inProduction) {
    onLoadState = inProduction;
    sessionStorage.setItem("displayEnvironment", String(onLoadState));
  }

  return { ...initial, environment: onLoadState };
};

export const DisplayEnvironment = createContext(createInitialEnvironment());

export function DisplayEnvironmentProvider({ children }) {
  const [environment, setEnvironment] = useSessionStorageState(
    "displayEnvironment",
    {
      defaultValue: false,
      listenStorageChange: true,
    },
  );
  const [enabled, setEnabled] = useState(false);
  const [viewAs, setViewAs] = useState(undefined);
  const onEnvironmentChange = (bool) => {
    // only allow toggling between environment when enabled
    if (enabled) {
      setEnvironment(bool);

      if (bool && window?.analytics?.track) {
        window.analytics.track("Toggle to Production");
      }
    }
  };

  const { partnerInfo } = usePartnerInfo();

  useEffect(() => {
    if (localStorage.token && partnerInfo) {
      (async function getProductionEnabled() {
        try {
          const { partner } = partnerInfo;
          const isProductionEnabled = partner?.production_enabled;
          let onLoadState = environment;
          // if this is a page being freshly navigated to, show sandbox rather
          // production if production has not been enabled for that partner
          if (environment === null) {
            onLoadState = isProductionEnabled === true;
          }
          const inSandbox = Boolean(
            window.location?.pathname?.includes("sandbox"),
          );
          const inProduction = Boolean(
            window.location?.pathname?.includes("production"),
          );
          if (inSandbox || inProduction) {
            onLoadState = inProduction === true;
          }
          setEnvironment(onLoadState);
        } catch (error) {
          console.error(error);
          console.info(
            "[DisplayEnvironment] - Error: Failed to fetch partner info",
          );
        }
      })();
    }
  }, [localStorage.token, partnerInfo]);

  return (
    <DisplayEnvironment.Provider
      value={{
        environment,
        enabled,
        setEnabled,
        viewAs,
        setViewAs,
        onEnvironmentChange,
      }}
    >
      {children}
    </DisplayEnvironment.Provider>
  );
}
