import { createReducer } from "./create_reducer";

const PaginationReducer = createReducer(1, {
  RECEIVE_ACCESS_LOGS: (state, action) => action.logsAndCount.pagination || {},
  RECEIVE_REFERENCES: (state, action) =>
    action.referencesAndCount.pagination || {},
  RECEIVE_RESULTS: (state, action) => action.resultsAndCount.pagination || {},
  RECEIVE_REVIEWS: (state, action) => action.reviewsAndCount.pagination || {},
  RECEIVE_RESULTJOBS: (state, action) =>
    action.resultJobsAndCount.pagination || {},
  RECEIVE_JOBS: (state, action) => action.jobs.pagination || {},
});

export default PaginationReducer;
