import { createReducer } from "./create_reducer";

const PartnersReducer = createReducer([], {
  RECEIVE_ACCESS_LOGS: (state, action) => action.logsAndCount.partner_ids || [],
  RECEIVE_REFERENCES: (state, action) =>
    action.referencesAndCount.partner_ids || [],
  RECEIVE_RESULTS: (state, action) => action.resultsAndCount.partner_ids || [],
  RECEIVE_JOBS: (state, action) => action.jobs.partner_ids || [],
  RECEIVE_PARTNERS: (state, action) => action.partners.partner_ids || [],
});

export default PartnersReducer;
