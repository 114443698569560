import React from "react";
import { useDeepCompareEffect } from "ahooks";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import Select, { SingleValue } from "react-select";

import styles from "components/reusable/reviews.module.css";
import useAuthenticatedUser from "hooks/useAuthenticatedUser";
import { usePartnerIds } from "queries";
import { buildUrl } from "util/api_util";
import { getJobTypes } from "util/selectors";

interface SelectOption {
  value: string;
  label: string;
}

export interface QueryState {
  job_type?: string;
  partner_id?: string;
}

interface Props {
  query: QueryState;
  setQuery: (query: QueryState) => void;
}

function PartnerSelector({ query, setQuery }: Props) {
  const { userType } = useAuthenticatedUser();

  const history = useHistory();
  const jobTypes: SelectOption[] = getJobTypes();
  const [selectedJobType, setSelectedJobType] = React.useState(
    jobTypes.find((jobType) => jobType.value === query.job_type) || null,
  );
  const { data: partnerIds, isLoading: isLoadingPartnerIds } = usePartnerIds({
    enabled: userType === "admin",
  });
  const [partnerId, setSelectedPartner] = React.useState<string | undefined>(
    query?.partner_id,
  );

  useDeepCompareEffect(() => {
    const queryParams: QueryState = {
      ...(partnerId && { partner_id: partnerId }),
      ...(selectedJobType?.value && { job_type: selectedJobType.value }),
    };

    setQuery(queryParams);
    history.push(`/admin${buildUrl("reviews", queryParams)}`);
  }, [selectedJobType, partnerId]);

  const partnerOptions =
    partnerIds?.partnerIds.map((partner) => ({
      value: partner.split(" - ")[0],
      label: partner,
    })) || [];

  const selectedPartner = partnerOptions.find(
    (option) => option.value === partnerId,
  );

  const onPartnerChange = (option: SingleValue<SelectOption>) =>
    setSelectedPartner(option?.value || "");

  const onJobTypeChange = (option: SingleValue<SelectOption>) =>
    setSelectedJobType(option);

  if (userType !== "admin") return null;

  return (
    <div className={classNames("legacy", styles.filtersContainer)}>
      <div className="head-link inline">Filter by</div>
      <Select<SelectOption>
        aria-label="Partner"
        isClearable
        isSearchable
        isLoading={isLoadingPartnerIds}
        onChange={onPartnerChange}
        options={partnerOptions}
        placeholder="All Partners"
        styles={{
          option: (provided) => ({ ...provided, color: "black" }),
        }}
        value={selectedPartner}
      />
      <Select<SelectOption>
        aria-label="Job Type"
        isClearable
        isSearchable
        onChange={onJobTypeChange}
        options={jobTypes}
        placeholder="All Job Types"
        styles={{
          option: (provided) => ({ ...provided, color: "black" }),
        }}
        value={selectedJobType}
      />
    </div>
  );
}

export default PartnerSelector;
