import React from "react";
import { jwtDecode } from "jwt-decode";
import { useHistory } from "react-router-dom";

import LockIcon from "resources/img/icons/lock.svg";
import { fetchWebAppServices } from "util/api_util";

import { getAdminPartnerId } from "util/selectors";
import WebAppForm from "./web_app/web_app_form";
import WebAppService from "./web_app/web_app_service";
import WebAppSidebar from "./web_app/web_app_sidebar";

function WebApp() {
  const user = jwtDecode(localStorage.token);
  const history = useHistory();

  const [wizardStep, setWizardStep] = React.useState(1);
  const [data, setData] = React.useState({
    services: null,
    bankCodes: null,
    idTypes: null,
    kybTypes: null,
    amlTypes: null,
    error: null,
    loading: false,
  });
  const [service, setService] = React.useState(null);
  const [action, setAction] = React.useState(null);

  React.useEffect(() => {
    // admin should not be allowed to run a job as a partner
    if (user.type === "admin" && getAdminPartnerId() !== undefined) {
      localStorage.removeItem("adminPartnerId");
      history.replace("/admin/web_app");
    } else {
      const fetchData = async () => {
        await getServices();
      };
      fetchData();
    }
  }, []);

  const getServices = async () => {
    setData((prevState) => ({ ...prevState, loading: true }));
    try {
      const resp = await fetchWebAppServices();
      setData({
        services: resp.services,
        bankCodes: resp.bank_codes,
        idTypes: resp.id_types,
        kybTypes: resp.kyb_types,
        amlTypes: resp.aml_types,
        loading: false,
        error: resp.error ? resp.error : null,
      });
    } catch (error) {
      setData((prevState) => ({
        ...prevState,
        loading: false,
        error: error.toString(),
      }));
    }
  };

  const serviceClicked = (service) => {
    setService(service);
    setAction(null);
    setWizardStep(2);
  };

  const actionClicked = (action) => {
    setAction(action);
    setWizardStep(wizardStep + 1);
  };

  const onWizardStepClick = (goToStep) => {
    if (wizardStep > goToStep) {
      setWizardStep(goToStep);
    }
  };

  const onBackButton = () => {
    setWizardStep(wizardStep - 1);
  };
  const { idTypes, loading, kybTypes, amlTypes, services, bankCodes, error } =
    data;

  return (
    <div className="web-app__container legacy">
      <div className="web-app__container-header">
        <div className="web-app__container-header-name">
          <img
            className="web-app__header-icon icon--large"
            src={LockIcon}
            alt="lock"
          />
          <h1 className="metrics__header">Run a Quick Verification</h1>
        </div>
        <div className="metrics__sub-header-container">
          <div className="metrics__product-name">SmartCheck&trade;</div>
          <div className="metrics__sub-header">
            Try out an identity action on our platform
          </div>
        </div>
      </div>

      <div className="web-app__container-body">
        <WebAppSidebar
          step={wizardStep}
          onWizardStepClick={onWizardStepClick}
        />

        <div className="web-app__wizard">
          {wizardStep === 1 && (
            <div className="web-app__wizard-header-container">
              <div className="newsmile-heading-h2 web-app__wizard-header">
                Choose a Service
              </div>
              <div className="metrics__sub-header">
                Click on any of the services below
              </div>
            </div>
          )}
          {wizardStep === 2 && (
            <div className="web-app__wizard-header-container">
              <div className="newsmile-heading-h2 web-app__wizard-header">
                {service?.name} Actions
              </div>
              <div className="metrics__sub-header">
                Please select one action
              </div>
            </div>
          )}
          {wizardStep === 3 && (
            <div className="web-app__wizard-header-container">
              <div className="newsmile-heading-h2 web-app__wizard-header">
                Fill in the Required Info
              </div>
              <div className="metrics__sub-header">
                The details are used to perform {action.name} on the user
              </div>
            </div>
          )}

          {loading && (
            <div className="loader__container ">
              <div className="loader loader--centered" />
            </div>
          )}

          {error}

          {services && (
            <div className="web-app__wizard-body">
              {wizardStep === 1 && (
                <div>
                  {services.map((service) => (
                    <div
                      key={service.service_id}
                      onClick={() => serviceClicked(service)}
                    >
                      <WebAppService service={service} serviceCollection />
                    </div>
                  ))}
                </div>
              )}
              {wizardStep === 2 && (
                <WebAppService
                  service={service}
                  serviceCollection={false}
                  action={action}
                  actionClicked={actionClicked}
                />
              )}
              {wizardStep === 3 && (
                <WebAppForm
                  action={action}
                  onBackButton={onBackButton}
                  history={history}
                  amlTypes={amlTypes}
                  idTypes={idTypes}
                  kybTypes={kybTypes}
                  bankCodes={bankCodes}
                />
              )}
            </div>
          )}

          {wizardStep === 2 && (
            <div className="web-app__wizard-buttons">
              <button
                className="btn btn-primary--outline"
                type="button"
                onClick={onBackButton}
              >
                Go Back
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default WebApp;
