import React from "react";
import { SMILE_PRODUCTS_MAP } from "util/constants";
import { normalizeString } from "util/format_helpers";
import { Chip, Chips } from "./Chip";
import styles from "./id_card.module.css";

export default function IdItem({ onClick, idType }) {
  return (
    <div className={styles.itemWrapper} onClick={onClick}>
      <div className={styles.body}>
        <div className={styles.title}>{normalizeString(idType.name)} </div>
        <Chips>
          {idType.verification_methods.map((method) => (
            <Chip key={method}>{SMILE_PRODUCTS_MAP[method].name}</Chip>
          ))}
        </Chips>
      </div>
    </div>
  );
}
