import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

interface LogInWithOTPResponse {
  auth_token: string;
  pre_otp_user: string;
}

interface LogInWithOTPInput {
  email: string;
  password: string;
  otp_code: string;
}

const logInWithOTP = async (input: LogInWithOTPInput) => {
  const { data } = await axios.post<LogInWithOTPResponse>("/auth/otp", input);
  return data;
};

export const useLogInWithOTP = (
  options?: UseMutationOptions<
    LogInWithOTPResponse,
    AxiosError<{ errors: string[] }>,
    LogInWithOTPInput
  >,
) =>
  useMutation({
    mutationFn: logInWithOTP,
    ...options,
  });
