import React from "react";
import { Link } from "react-router-dom";
import forwardIcon from "resources/img/icons/forward-arrow.svg";
import styles from "./breadcrumbs.style.module.css";

function Breadcrumbs({ links }) {
  return (
    <div>
      {links.map((link) => (
        <>
          <Link
            to={link.href}
            key={link.title}
            className={styles.item}
            onClick={() => sessionStorage.setItem("url", link.href)}
          >
            <span>{link.title}</span>
          </Link>
          <span className={styles.separator}>
            <img src={forwardIcon} alt="forward-icon" />
          </span>
        </>
      ))}
    </div>
  );
}

export default Breadcrumbs;
