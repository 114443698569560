import React from "react";
import BackButton from "components/reusable/web_links/BackButton";
import { css } from "util/format_helpers";
import { PageSubTitle, PageTitle } from "./Pages";
import styles from "./pages.module.scss";

export default function PageHeader({
  title,
  subTitle,
  titleClassName,
  containerClassName,
  showBack,
  link,
  onBackPressed,
}) {
  return (
    <div className={css(containerClassName, styles.container)}>
      {showBack && (
        <div style={{ marginBottom: "1rem" }}>
          <BackButton style={{ flexFlow: "nowrap" }} onClick={onBackPressed} />
        </div>
      )}
      <PageTitle style={{ marginBottom: "0.5rem" }} className={titleClassName}>
        {title}
      </PageTitle>
      {subTitle && (
        <div className={css(styles.subTitleContainer)}>
          {subTitle && <PageSubTitle>{subTitle}</PageSubTitle>}
          {link && (
            <a
              href={link}
              className={css("h3", styles.learnMore, styles.underline)}
              target="_blank"
              rel="noreferrer"
            >
              Learn More
            </a>
          )}
        </div>
      )}
    </div>
  );
}
