import React from "react";
import { css } from "util/format_helpers";
import styles from "./medium_card.module.css";
import { StyledButton } from "./StyledButton";

export function MediumCard({
  onClick,
  label,
  buttonText,
  containerClass,
  href,
}) {
  return (
    <div className={css(styles.mediumCard, "center", containerClass)}>
      <div className={css(styles.mediumCardBody)}>{label}</div>
      {buttonText && (
        <StyledButton onClick={onClick} isActive href={href}>
          {buttonText}
        </StyledButton>
      )}
    </div>
  );
}
