import { createReducer } from "./create_reducer";

const _null_selected = {
  company: {},
};

const SelectedReducer = createReducer(_null_selected, {
  RECEIVE_COMPANY: (state, action) => ({ ...state, company: action.company }),
});

export default SelectedReducer;
