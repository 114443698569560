import React from "react";
import moment from "moment";
import styles from "./sanctions.module.css";

function Sanctions({ sanctions }) {
  return sanctions.map((sanction) => (
    <div className={styles.container}>
      <div className="details">
        <h3>Details</h3>
        <div>
          <h6>date of birth</h6>
          <span>
            {sanction.date_of_birth
              ? moment(sanction.date_of_birth).format("DD MMM YYYY")
              : "N/A"}
          </span>
        </div>
        <div>
          <h6>nationality</h6>
          <span>{sanction.nationality || "N/A"}</span>
        </div>
      </div>

      <div className={styles.source}>
        <div className={styles.rowFlex}>
          <div>
            <h6>source name</h6>
            <span>{sanction?.source_details?.source_name}</span>
          </div>
          <div>
            <h6>source type</h6>
            <span>{sanction?.source_details?.source_type}</span>
          </div>
        </div>

        <div>
          <div className={styles.rowFlex}>
            <div>
              <h6>listed date:</h6>
              <span>
                {sanction?.source_details?.listed_date
                  ? moment(sanction.source_details.listed_date).format(
                      "DD MMMM YYYY",
                    )
                  : "N/A"}
              </span>
            </div>
          </div>
          <div className={styles.rowFlex}>
            <div>
              <h6>source link</h6>
              {sanction?.source_details?.source_link?.map((link) => (
                <a href={link} target="_blank" rel="noreferrer" key={link}>
                  {link}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  ));
}

export default Sanctions;
