import React, { Component } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Reaptcha from "reaptcha";
import Logo from "resources/img/brand/logo-white-horizontal.svg";
import CEOSignature from "resources/img/ceo_signature.png";
import groups from "resources/img/icons/groups.svg";
import info from "resources/img/icons/info--grey.svg";
import Splash from "resources/img/splash.svg";
import { createAccount } from "util/api_util";

const VALIDATION_ERRORS = {
  email: "Please add a valid email address.",
  phone: "Please enter a valid phone number.",
  user_name: "Please enter your name and surname.",
  partner_name: "Please enter an organisation name.",
  policies_acceptance:
    "Please accept the terms and conditions, privacy policy, and data processing agreement",
};

class CreateAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      partner_name: "",
      user_name: "",
      email: "",
      phone: "",
      products: this.getUrlProductParams(props.location.search),
      message: "",
      messageType: null,
      formIsValid: false,
      formErrors: {
        user_name: null,
        email: null,
        phone: null,
        partner_name: null,
        policies_acceptance: null,
      },
      loading: false,
      verified: false,
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onPhoneNumberBlur = this.onPhoneNumberBlur.bind(this);
  }

  onVerify = (recaptcha_response) => {
    this.setState({
      verified: recaptcha_response,
    });
  };

  getUrlProductParams(query) {
    const params = query
      ? query.split("&").reduce((params, param) => {
          const [key, value] = param.split("=");
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, " "))
            : "";
          return params;
        }, {})
      : {};

    const products = [];
    Object.keys(params).forEach((key) => {
      if (key.includes("product")) {
        products.push({ sku: params[key] });
      }
    });
    return products;
  }

  checkFieldsValid() {
    const { partner_name, user_name, email, phone, policies_acceptance } =
      this.state;
    this.validateField("partner_name", partner_name);
    this.validateField("user_name", user_name);
    this.validateField("email", email);
    this.validateField("phone", phone);
    this.validateField("policies_acceptance", policies_acceptance);
  }

  onFormSubmit(e) {
    e.preventDefault();
    this.checkFieldsValid();
    const valid = this.checkIfFormValid();
    if (!valid) {
      return;
    }
    this.setState({ loading: true });

    const currentTimeInISO = new Date().toISOString();

    const companyConfig = {
      partner_name: this.state.partner_name,
      primary_user: {
        name: this.state.user_name,
        email: this.state.email,
        phone: this.state.phone,
      },
      products: this.state.products,
      ts_and_cs: {
        general: currentTimeInISO,
        data_processing_agreement: currentTimeInISO,
        privacy_policy: currentTimeInISO,
      },
      recaptcha_response: this.state.verified,
    };
    this.postDetails(companyConfig);
  }

  postDetails(companyConfig) {
    createAccount(companyConfig).then((resp) => {
      // TODO: move into a messaging componnet
      if (resp.error) {
        this.setState({
          message: resp.error,
          messageType: "error",
          loading: false,
        });
      } else {
        this.setState({
          message:
            "Successful, please wait for an email confirmation to set your password and log in.",
          messageType: "success",
          loading: false,
        });
      }
    });
  }

  onInputChange(e) {
    if (e.target.name === "policies_acceptance") {
      this.setState({ [e.target.name]: e.target.checked });
      this.validateField(e.target.name, e.target.checked);
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }

    this.setState({
      message: "",
      messageType: null,
    });
  }

  onInputBlur(e) {
    const { name } = e.target;
    const { value } = e.target;

    this.validateField(name, value);
  }

  onPhoneNumberBlur() {
    this.validateField("phone", this.state.phone);
  }

  validateField(name, value) {
    switch (name) {
      case "user_name":
        this.validateUserName(name, value);
        break;
      case "email":
        this.validateEmail(name, value);
        break;
      case "phone":
        this.validatePhoneNumber(name, value);
        break;
      case "partner_name":
        this.validatePartnerName(name, value);
        break;
      case "policies_acceptance":
        this.validatePoliciesAcceptance(name, value);
        break;
      default:
    }

    this.setState({
      formIsValid: this.checkIfFormValid(),
    });
  }

  checkIfFormValid() {
    const errors = this.state.formErrors;
    for (const key in errors) {
      if (errors[key] !== "") {
        return false;
      }
    }
    return true;
  }

  validateUserName(name, value) {
    if (!value.trim().includes(" ")) {
      this.showError(name);
    } else {
      this.removeError(name);
    }
  }

  validateEmail(name, value) {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regex.test(value)) {
      this.removeError(name);
    } else {
      this.showError(name);
    }
  }

  validatePhoneNumber(name, value) {
    if (value.trim().length < 6) {
      this.showError(name);
    } else {
      this.removeError(name);
    }
  }

  validatePartnerName(name, value) {
    if (value.trim().length === 0) {
      this.showError(name);
    } else {
      this.removeError(name);
    }
  }

  validatePoliciesAcceptance(name, checked) {
    if (checked) {
      this.removeError(name);
    } else {
      this.showError(name);
    }
  }

  showError(name) {
    const { formErrors } = this.state;
    formErrors[name] = VALIDATION_ERRORS[name];

    this.setState({
      formErrors,
    });
  }

  removeError(name) {
    const { formErrors } = this.state;
    formErrors[name] = "";

    this.setState({
      formErrors,
    });
  }

  render() {
    return (
      <div className="signup-page legacy">
        <div className="marketing-website__header d-flex justify-content-between align-items-center justify-content-center">
          <a href="https://usesmileid.com">
            <img
              className="marketing-website__header-logo"
              src={Logo}
              alt="Smile ID Logo"
            />
          </a>
          <div className="marketing-website__header-links">
            <a className="marketing-website__header-link" href="/login">
              Login
            </a>
          </div>
        </div>

        <div className="signup">
          <div
            className={`message signup__message ${
              this.state.messageType === "success"
                ? "message--success"
                : "message--error"
            }`}
            style={{
              display: this.state.message ? "block" : "none",
            }}
          >
            {this.state.message}
          </div>

          <div className="signup__container">
            <div className="signup__header">
              <img className="icon icon--medium" src={groups} alt="team" />
              <div className="newsmile-heading-h1 signup__header-text">
                Sign Up for a Team Account
              </div>
            </div>

            <div className="signup__content">
              <div className="signup__note">
                <div className="signup__note-text">
                  <p>Thank you for choosing Smile ID!</p>
                  <p>
                    Everyday we help businesses across Africa validate the
                    identity of their customers. Our products allow lenders,
                    telecoms and distributed workforces to provide access to a
                    richer, digital lifestyle.
                  </p>
                  <p>
                    If you are a developer building a solution that relies on
                    true identity or an enterprise that needs custom solutions,
                    I'd love to hear from you.
                  </p>
                  <p>Our support team is here to help.</p>
                  <div className="signature">
                    <p>With a smile, </p>
                    <img
                      className="icon--medium"
                      src={CEOSignature}
                      alt="ceo-signature"
                    />
                    <div>Mark Straub</div>
                    <div>CEO, Smile ID</div>
                    <div>
                      <a href="mailto:info@usesmileid.com">
                        info@usesmileid.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="signup__form-container">
                <form className="signup__form needs-validation" noValidate>
                  <div className="signup__row">
                    <div>
                      <label className="signup__label" htmlFor="user_name">
                        Full name
                      </label>
                      <input
                        className="signup__input input--line"
                        type="text"
                        name="user_name"
                        id="user_name"
                        value={this.state.user_name}
                        onInput={this.onInputChange}
                        onBlur={this.onInputBlur}
                        autoComplete="off"
                        required
                      />
                      <div
                        className="invalid-feedback"
                        style={{
                          display: this.state.formErrors.user_name
                            ? "block"
                            : "none",
                        }}
                      >
                        {this.state.formErrors.user_name}
                      </div>
                    </div>
                  </div>

                  <div className="signup__row">
                    <div>
                      <label className="signup__label" htmlFor="email">
                        Email
                      </label>
                      <input
                        className="signup__input input--line"
                        type="text"
                        name="email"
                        id="email"
                        value={this.state.email}
                        onInput={this.onInputChange}
                        onBlur={this.onInputBlur}
                        autoComplete="off"
                        required
                      />
                      <div
                        className="invalid-feedback"
                        style={{
                          display: this.state.formErrors.email
                            ? "block"
                            : "none",
                        }}
                      >
                        {this.state.formErrors.email}
                      </div>
                    </div>
                  </div>

                  <div className="signup__row">
                    <div>
                      <label className="signup__label" htmlFor="phone">
                        Phone Number
                      </label>
                      <PhoneInput
                        country="ng"
                        value={this.state.phone}
                        onChange={(...data) =>
                          this.setState({ phone: data[3] })
                        }
                        onBlur={this.onPhoneNumberBlur}
                      />
                      <div
                        className="invalid-feedback"
                        style={{
                          display: this.state.formErrors.phone
                            ? "block"
                            : "none",
                        }}
                      >
                        {this.state.formErrors.phone}
                      </div>
                    </div>
                  </div>

                  <div className="signup__row">
                    <div>
                      <label
                        className="signup__label signup__label--organisation"
                        htmlFor="partner_name"
                      >
                        Organisation
                        <div
                          className="signup__label-tooltip"
                          data-tooltip="This will create an account for your organisation, you will be the first member."
                        >
                          <img alt="info" src={info} />
                        </div>
                      </label>
                      <input
                        className="signup__input input--line"
                        type="text"
                        name="partner_name"
                        id="partner_name"
                        value={this.state.partner_name}
                        onInput={this.onInputChange}
                        autoComplete="off"
                        onBlur={this.onInputBlur}
                        required
                      />
                      <div
                        className="invalid-feedback"
                        style={{
                          display: this.state.formErrors.partner_name
                            ? "block"
                            : "none",
                        }}
                      >
                        {this.state.formErrors.partner_name}
                      </div>
                    </div>
                  </div>
                  <div className="signup__row">
                    <Reaptcha
                      sitekey="6Le7azolAAAAAGVMhEntsfzuTtIHGatXG_Za6QAq"
                      onVerify={this.onVerify}
                    />
                  </div>

                  <div className="smile-field--checkbox">
                    <input
                      className="signup__input"
                      type="checkbox"
                      name="policies_acceptance"
                      id="policies_acceptance"
                      onChange={this.onInputChange}
                      required
                    />
                    <label
                      htmlFor="policies_acceptance"
                      className="label--checkbox"
                    >
                      I agree to the SmileIdentity{" "}
                      <a
                        className="get-started__terms-link"
                        href="https://usesmileid.com/legal/terms-and-conditions"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms of Service
                      </a>
                      ,{" "}
                      <a
                        className="get-started__terms-link"
                        href="https://usesmileid.com/legal/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </a>
                      , and{" "}
                      <a
                        className="get-started__terms-link"
                        href="https://usesmileid.com/data-processing-agreement"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Data Processing Agreement
                      </a>
                    </label>
                  </div>
                  {this.state.formErrors.policies_acceptance && (
                    <div
                      className="invalid-feedback"
                      style={{
                        display: "block",
                      }}
                    >
                      You must agree before submitting.
                    </div>
                  )}

                  <button
                    className={`btn btn-primary signup__button ${
                      !this.state.verified || this.state.loading
                        ? "disabled"
                        : ""
                    }`}
                    type="submit"
                    disabled={this.state.loading || !this.state.verified}
                    onClick={this.onFormSubmit}
                  >
                    {this.state.loading ? (
                      <div className="btn--loader" />
                    ) : (
                      "Create Team Account"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

        <img className="fixed-image--bottom-right" src={Splash} alt="splash" />
      </div>
    );
  }
}
export default CreateAccount;
