import { connect } from "react-redux";

import { fetchUser, updateUser } from "actions/actions";
import Settings from "components/reusable/settings";

// everything that happens from here on down is the redux boilerplate.
// it has been left in should we need to gain that functionality in the future.
// However are the user state does not currently need to be used in any other
// components, there is no reason to use redux here.

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  fetchUser: () => fetchUser(dispatch),
  updateUser: (id, data) => updateUser(id, data, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
