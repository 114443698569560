import React from "react";
import NotFoundIcon from "resources/img/icons/not_found_icon.svg";

const NotFound: React.FC = () => (
  <div className="legacy not-found">
    <div>
      <div className="smile-login__icon-container">
        <img
          className="smile-login__icon"
          src={NotFoundIcon}
          alt="smile logo"
        />
      </div>
      <h1>404, Page not found.</h1>
      <p>Sorry, we can't find the page you are looking for.</p>
    </div>
  </div>
);

export default NotFound;
