import React, { useEffect, useState } from "react";

import { isValid, parse } from "date-fns";
import Hint from "components/hint/Hint";
import { ISOCountryCodeToFullNameMap } from "util/format_helpers";
import { Toast } from "util/Toast";

import { StyledButton } from "../web_links/StyledButton";
import RejectionReason from "./RejectionReason";
import TextualComparisonRow from "./TextualComparisonRow";

// we need to sort the comparison keys based on the review team preference
// typing fields first and clickable fields last
const keysRank = [
  "full_name",
  "dob",
  "id_number",
  "expiration_date",
  "issuance_date",
  "secondary_id_number",
  "country",
  "id_type",
  "gender",
  "address",
  "phone_number",
  "phone_number2",
];

/**
 * Validates the form by ensuring that all textual comparisons have values
 * and that certain fields have valid formats. Also checks for required fields
 * if the rejection reason is "EXPIRED".
 * @param {{ key: string, value: string }[]} textualComparisons - Key-value pairs from review.links.textual_comparisons.
 * @param {string} rejectionReason - Rejection reason, must be one of the predefined reasons in `rejectionReasons`.
 * @returns {{ valid: boolean, error?: string }} An object indicating whether the form is valid and, if not, the error message.
 */
const validateForm = (textualComparisons, rejectionReason) => {
  const formatKey = (key) => key.replace("_", " ").toUpperCase();
  for (const { key, value } of textualComparisons) {
    if (!value) {
      return {
        valid: false,
        error: `A decision on ${formatKey(key)} is required`,
      };
    }
    if (["dob", "expiration_date"].includes(key)) {
      if (
        value !== "Not Available" &&
        !isValid(parse(value, "yyyy-MM-dd", new Date()))
      ) {
        return {
          valid: false,
          error: `Invalid date format for ${formatKey(
            key,
          )}. Format must be "YYYY-MM-DD"`,
        };
      }
    }
  }
  if (rejectionReason === "Select Outcome") {
    return {
      valid: false,
      error: "Please select an outcome",
    };
  }

  if (rejectionReason === "Expired Document") {
    const requiredFields = ["full_name", "id_number", "expiration_date"];
    for (const field of requiredFields) {
      const { value } =
        textualComparisons.find(({ key }) => key === field) ?? {};
      if (!value || value === "Not Available") {
        return {
          valid: false,
          error: `Field ${formatKey(
            field,
          )} is required for Expired Document rejection`,
        };
      }
    }
  }
  return { valid: true };
};

const DocumentReviewForm = ({ review, completed, next, ...props }) => {
  const [rejectionReason, setRejectionReason] = useState("Select Outcome");
  const [disabled, setDisabled] = useState(false);
  const [comparisonKeys, setComparisonKeys] = useState([]);
  const [textualComparisons, setTextualComparisons] = useState({});

  useEffect(() => {
    const getComparisons = (review) =>
      review.complete
        ? review.smile_reference.internal.Payload.Comparisons || []
        : review.links.textual_comparisons || [];

    const docComparisonKeys = [];
    const newTextualComparisons = {};
    if (review.complete) {
      setRejectionReason(
        review.smile_reference.internal.Payload.RejectionReason,
      );
    }

    getComparisons(review).forEach((comparison) => {
      newTextualComparisons[comparison.key] = comparison;
      docComparisonKeys.push(comparison.key);
    });

    setComparisonKeys(docComparisonKeys);
    setTextualComparisons(newTextualComparisons);
  }, [review.complete]);

  const country =
    ISOCountryCodeToFullNameMap[textualComparisons.country?.value] ||
    textualComparisons.country?.value;
  const idType = textualComparisons.id_type?.value?.split("_")?.join(" ");

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisabled(true);
    const ApiForm = {
      review: {
        textual_comparisons: comparisonKeys.map((key) => {
          const comparison = textualComparisons[key];
          if (
            !comparison.value ||
            comparison.value.trim().length === 0 ||
            comparison.value === "Not Available"
          ) {
            comparison.value = "Not Available";
          } else {
            comparison.value = comparison.value.trim().toUpperCase();
          }
          return comparison;
        }),
        rejection_reason: rejectionReason || null,
      },
      id: review.id,
    };

    const { valid, error } = validateForm(
      ApiForm.review.textual_comparisons,
      rejectionReason,
    );
    if (!valid) {
      setDisabled(false);
      Toast.fire({ title: error });
      return;
    }

    if (props.endpoint) {
      props.endpoint(ApiForm).then((resp) => {
        props.callback?.(resp, ApiForm.id);
        next();
      });
    } else {
      props.submitReview(ApiForm).then((resp) => {
        props.callback?.(resp, ApiForm.id);
        next();
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "rejection_reason") {
      setRejectionReason(value);
      return;
    }
    const newTextualComparisons = {
      ...textualComparisons,
      [name]: { key: name, value },
    };
    setTextualComparisons(newTextualComparisons);
  };
  return (
    <>
      <h2 className="review_text h2">
        {country} {idType}
      </h2>
      <div className="hint-wrapper">
        {review.creation_reasons
          ?.filter((reason) => reason !== "Randomly Selected")
          .map((reason, index) => (
            <Hint key={index} text={reason} />
          ))}
      </div>
      {Object.keys(textualComparisons).length > 0 &&
        keysRank.map((key) => (
          <TextualComparisonRow
            key={key}
            name={key}
            textualComparisons={textualComparisons}
            completed={completed}
            handleChange={handleChange}
          />
        ))}
      <RejectionReason
        completed={completed}
        rejectionReason={rejectionReason}
        handleChange={handleChange}
      />
      <div className="review__actions new-styles">
        {!completed && (
          <StyledButton
            variant="outline"
            className="review-button"
            onClick={handleSubmit}
            disabled={disabled}
          >
            {disabled ? "Submitting..." : "Submit Review"}
          </StyledButton>
        )}
      </div>
    </>
  );
};

export default DocumentReviewForm;

export { validateForm };
