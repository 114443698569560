import React, { useEffect, useState } from "react";
import classNames from "classnames";
import moment from "moment";
import drawerStyles from "pages/aml/aml-results/aml_results.module.css";
import CloseIcon from "resources/img/icons/close-icon.svg";
import fileTextIcon from "resources/img/icons/file-text.svg";
import newsGraphicIcon from "resources/img/icons/news_graphic.svg";
import newsPaperIcon from "resources/img/icons/newspaper.svg";
import personCircleIcon from "resources/img/icons/person-circle.svg";
import protectionIcon from "resources/img/icons/protection.svg";
import twoPeopleIcon from "resources/img/icons/two-people.svg";
import styles from "./aml_person_found.module.css";

import Associations from "./associations";
import Enforcement from "./enforcement";
import Media from "./media";
import NewsArticles from "./NewsArticles";
import NewsMedia from "./NewsMedia";
import Pep from "./pep";
import Sanctions from "./sanctions";
import Summary from "./summary";

function AmlPersonFound({ person, onClose }) {
  const minimumItemsToShow = {
    addresses: 2,
    aliases: 10,
  };
  const [addressesToShow, setAddressesToShow] = useState(
    minimumItemsToShow.addresses,
  );
  const [aliasesToShow, setAliasesToShow] = useState(
    minimumItemsToShow.aliases,
  );
  const [newsArticlesDrawer, setNewsArticlesDrawer] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState({});

  const showMediaDrawer = (post) => {
    setSelectedArticle(post);
    setNewsArticlesDrawer(true);
  };

  useEffect(() => {
    setNewsArticlesDrawer(false);
  }, [person]);

  const toggleAddressesToShow = () => {
    if (addressesToShow === person.addresses.length) {
      setAddressesToShow(minimumItemsToShow.addresses);
    } else {
      setAddressesToShow(person.addresses.length);
    }
  };

  const toggleAliasesToShow = () => {
    if (aliasesToShow === person.aliases.length) {
      setAliasesToShow(minimumItemsToShow.aliases);
    } else {
      setAliasesToShow(person.aliases.length);
    }
  };

  const showMore = (totalItems, itemsToShow, minimumItemsToShow, onToggle) =>
    totalItems.length > minimumItemsToShow && (
      <button onClick={onToggle} className={styles.showMore}>
        {itemsToShow === totalItems.length
          ? "Click for Less"
          : "Click for more"}
      </button>
    );

  return (
    <div className={styles.container}>
      {newsArticlesDrawer && (
        <div className={drawerStyles.amlPersonFoundDrawer}>
          <NewsArticles
            post={selectedArticle}
            onClose={() => setNewsArticlesDrawer(false)}
          />
        </div>
      )}
      <div className={classNames("new-styles", styles.navigation)}>
        <button data-variant="ghost" className={styles.close} onClick={onClose}>
          <img src={CloseIcon} alt="" />
          <span className="visually-hidden">Close Modal</span>
        </button>
      </div>
      <div>
        <h2>{person.name}</h2>
        <div className={styles.aliasesContainer}>
          <h4>Aliases</h4>
          <div className={styles.aliases}>
            <ul>
              {person.aliases.slice(0, aliasesToShow).map((alias) => (
                <li key={alias} className={styles.border}>
                  {alias}
                </li>
              ))}
            </ul>
            {showMore(
              person.aliases,
              aliasesToShow,
              minimumItemsToShow.aliases,
              toggleAliasesToShow,
            )}
          </div>
        </div>

        <div className={styles.details}>
          <div className={styles.information}>
            <h2 className="font-medium text-base">Information</h2>
            <div className={styles.datesOfBirth}>
              <h6> Dates of Birth </h6>
              {person.dates_of_birth.length > 0 ? (
                <ul>
                  {person.dates_of_birth.map((dob) => (
                    <li>{moment(dob).format("DD MMMM, YYYY")}</li>
                  ))}
                </ul>
              ) : (
                "N/A"
              )}
            </div>
            <div>
              <h6>Nationalities</h6>
              <ul>
                {person.nationalities?.map((nationality) => (
                  <li>{nationality}</li>
                ))}
              </ul>
            </div>
          </div>

          <div className={styles.addresses}>
            <header>
              <div className="font-medium text-base"> Addresses </div>
              <span> {person.addresses.length} </span>
            </header>
            {person.addresses.slice(0, addressesToShow).map((address) => (
              <h3>{address}</h3>
            ))}

            {showMore(
              person.addresses,
              addressesToShow,
              minimumItemsToShow.addresses,
              toggleAddressesToShow,
            )}
          </div>
        </div>
      </div>

      <div className="stack">
        <Summary
          icon={fileTextIcon}
          title="Sanctions"
          indicatorCount={person.sanctions?.length}
          noHits={!person.sanctions?.length}
        >
          <Sanctions sanctions={person.sanctions} />
        </Summary>
        <Summary
          icon={protectionIcon}
          title="Enforcement Action"
          indicatorCount={person.enforcement_actions?.length}
          noHits={!person.enforcement_actions?.length}
        >
          <Enforcement enforcementActions={person.enforcement_actions} />
        </Summary>
        <Summary
          icon={personCircleIcon}
          title="PEP"
          indicatorCount={person.pep?.political_positions?.length}
          noHits={!person.pep?.political_positions?.length}
        >
          <Pep pep={person.pep} />
        </Summary>
        <Summary
          icon={twoPeopleIcon}
          title="Associations"
          indicatorCount={person.associations?.length}
          noHits={!person.associations?.length}
        >
          <Associations associations={person.associations} />
        </Summary>
        <Summary
          icon={newsPaperIcon}
          title="Adverse Media Publication"
          indicatorCount={person.adverse_media?.length}
          noHits={!person.adverse_media?.length}
        >
          <Media posts={person.adverse_media} />
        </Summary>
        <Summary
          icon={newsGraphicIcon}
          title="News Media"
          indicatorCount={person.news_summary?.length}
          noHits={!person.news_summary?.length}
        >
          <NewsMedia
            posts={person.news_summary}
            person={person}
            onClick={(post) => showMediaDrawer(post)}
          />
        </Summary>
      </div>
    </div>
  );
}

export default AmlPersonFound;
