import React from "react";
import "./shimmer.css";

const NewsArticlesShimmer: React.FC<{ columnCount?: number }> = ({
  columnCount = 5,
}) => {
  const renderBody = (count: number) => {
    const body = Array(count).fill("");

    return body.map((_val, index) => (
      <div
        key={index}
        style={{
          marginBottom: "2rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <div
          className="animate-shimmer shimmer-text"
          style={{
            height: "16px",
            borderRadius: "20px",
            width: "90%",
          }}
        />
        <div
          className="animate-shimmer shimmer-text"
          style={{
            height: "9px",
            borderRadius: "20px",
            width: "80%",
          }}
        />
        <div
          className="animate-shimmer shimmer-text"
          style={{
            height: "9px",
            borderRadius: "20px",
            width: "70%",
          }}
        />
      </div>
    ));
  };
  return (
    <div className="shimmer-wrapper margin-top-30">
      <span data-testid="article-shimmer" className="visually-hidden">
        Loading new articles
      </span>
      {renderBody(columnCount)}
    </div>
  );
};

export default NewsArticlesShimmer;
