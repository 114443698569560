import React from "react";
import HelpIcon from "resources/img/icons/help-icon.svg";
import { css } from "util/format_helpers";
import styles from "./text_input.module.css";

export default function InputLabel({
  label,
  className,
  wrapperClassName,
  toolTip,
}) {
  return (
    <div className={css(styles.inputLabelWrapper, wrapperClassName)}>
      <span className={css("h3", className)}>{label}</span>
      {toolTip && (
        <span data-tooltip={toolTip} className={styles.inputLabelIcon}>
          <img src={HelpIcon} alt="" />
        </span>
      )}
    </div>
  );
}
