import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Carousel({ centerMode = true, infinite = true, children }) {
  let content = children;
  if (!Array.isArray(children)) {
    content = [children];
  }
  const settings = {
    className: "variable-width",
    centerMode,
    infinite,
    centerPadding: "60px",
    slidesToShow: content.length < 3 ? content.length : 3,
    speed: 100,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: content.length < 3 ? content.length : 3,
          slidesToScroll: 3,
          infinite,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: content.length < 2 ? content.length : 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  if (content.length === 0) {
    return <div id="slick-smile" />;
  }
  return (
    <div id="slick-smile">
      <Slider {...settings}>{content}</Slider>
    </div>
  );
}

export default Carousel;
