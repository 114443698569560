import React from "react";

import * as RadixAccordion from "@radix-ui/react-accordion";
import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import styled, { css } from "styled-components";

type Props = {
  children: React.ReactNode;
  className?: string;
  defaultValue?: string;
  collapsible?: boolean;
  value?: string;
  onValueChange?: (value: string) => void;
  disabled?: boolean;
};

const StyledAccordionRoot = styled(RadixAccordion.Root)`
  background-color: var(--color-neutral-white);
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
`;

const StyledAccordionItem = styled(RadixAccordion.Item)`
  margin-top: 1px;
  overflow: hidden;

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin-top: 0;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:focus-within {
    position: relative;
    z-index: 1;
  }
`;

const StyledAccordionHeader = styled(RadixAccordion.Header)`
  display: flex;
`;

const StyledAccordionTrigger = styled(RadixAccordion.Trigger)`
  align-items: center;
  background-color: var(--color-neutral-white);
  color: var(--color-primary-blue);
  display: flex;
  flex: 1;
  font-family: inherit;
  font-size: 16px;
  height: 45px;
  justify-content: space-between;
  line-height: 24px;
  padding: 0 20px;
`;

const StyledAccordionContent = styled(RadixAccordion.Content)`
  background-color: var(--color-neutral-white);
  font-size: 15px;
  overflow: hidden;

  &[data-state="open"] {
    animation: slideDown 10ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  &[data-state="closed"] {
    animation: slideUp 10ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  @keyframes slideDown {
    from {
      height: 0;
    }
    to {
      height: 300px;
    }
  }

  @keyframes slideUp {
    from {
      height: 300px;
    }
    to {
      height: 0;
    }
  }
`;

const ContentDiv = styled.div`
  padding: 15px 20px;
`;

const chevronIconStyles = css`
  background-color: var(--color-primary-orange);
  border-radius: 50%;
  color: var(--color-web-digital-blue);
  height: 24px;
  width: 24px;
`;

const StyledChevronDownIcon = styled(ChevronDownIcon)`
  ${chevronIconStyles}
`;

const StyledChevronUpIcon = styled(ChevronUpIcon)`
  ${chevronIconStyles}
`;

const Accordion: React.FC<Props> & { Item: React.FC<ItemProps> } = ({
  children,
  className,
  defaultValue = undefined,
  collapsible = true,
  disabled = false,
  ...props
}) => (
  <StyledAccordionRoot
    className={className}
    type="single"
    defaultValue={defaultValue}
    collapsible={collapsible}
    disabled={disabled}
    {...props}
  >
    {children}
  </StyledAccordionRoot>
);

type DefaultProps = {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  showIcon?: boolean;
  icon?: React.ReactNode;
};

const AccordionTrigger = React.forwardRef(
  (
    { children, className, showIcon = true, ...props }: DefaultProps,
    forwardedRef: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    const [open, setOpen] = React.useState(false);

    const onClick = () => {
      setOpen((prev) => !prev);
      if (props.onClick) {
        props.onClick();
      }
    };

    const renderIcon = () => {
      if (!showIcon) return null;

      return (
        props.icon ||
        (open ? (
          <StyledChevronUpIcon aria-hidden />
        ) : (
          <StyledChevronDownIcon aria-hidden />
        ))
      );
    };

    return (
      <StyledAccordionHeader>
        <StyledAccordionTrigger
          {...props}
          className={className}
          onClick={onClick}
          ref={forwardedRef}
        >
          {children}
          {renderIcon()}
        </StyledAccordionTrigger>
      </StyledAccordionHeader>
    );
  },
);

AccordionTrigger.displayName = "AccordionTrigger";

const AccordionContent = React.forwardRef(
  (
    { children, className, ...props }: DefaultProps,
    forwardedRef: React.ForwardedRef<HTMLDivElement>,
  ) => (
    <StyledAccordionContent className={className} {...props} ref={forwardedRef}>
      <ContentDiv>{children}</ContentDiv>
    </StyledAccordionContent>
  ),
);

AccordionContent.displayName = "AccordionContent";

type ItemProps = DefaultProps & {
  onTriggerClick?: () => void;
  trigger: React.ReactNode;
  value: string;
  showIcon?: boolean;
};

const Item = React.forwardRef(
  (
    {
      children,
      className,
      value,
      trigger,
      showIcon = true,
      onTriggerClick,
      ...props
    }: ItemProps,
    forwardedRef: React.ForwardedRef<HTMLDivElement>,
  ) => (
    <StyledAccordionItem
      ref={forwardedRef}
      className={className}
      value={value}
      {...props}
    >
      <AccordionTrigger showIcon={showIcon} onClick={onTriggerClick} {...props}>
        {trigger}
      </AccordionTrigger>
      <AccordionContent>{children}</AccordionContent>
    </StyledAccordionItem>
  ),
);

Item.displayName = "Item";

Accordion.Item = Item;
export default Accordion;
