import React from "react";

const errorAndMessageBar = ({ messages, errors }) => {
  if (errors || messages) {
    return (
      <p className={`notice ${errors ? "error" : "success"}`}>
        {errors || messages}
      </p>
    );
  }
  return <div />;
};

export default errorAndMessageBar;
