import React from "react";

function SearchInput({
  loading,
  search,
  placeholder,
  onSearchInput,
  searchTerm,
  clearSearch,
}) {
  return (
    <div className="joblist-search__container search__container search__container--attached">
      <form onSubmit={search}>
        <input
          className="search__input"
          type="text"
          placeholder={placeholder}
          onChange={(e) => onSearchInput(e)}
          value={searchTerm || ""}
        />
      </form>

      <div>
        {loading ? <div className="loader loading-data-spinner" /> : ""}
      </div>
      <div className="search__clear">
        {loading ? null : (
          <button className="text-button" type="button" onClick={clearSearch}>
            Clear Search
          </button>
        )}
      </div>
    </div>
  );
}

export default SearchInput;
