import { combineReducers } from "redux";

import CompaniesReducer from "./companies_reducer";
import EnterpriseReducer from "./enterprise_reducer";
import ErrorsReducer from "./errors_reducer";
import LogsReducer from "./logs_reducer";
import MessagesReducer from "./message_reducer";
import PaginationReducer from "./pagination_reducer";
import PartnersReducer from "./partners_reducer";
import pendingKYCAndReviewReducer from "./pending_kyc_and_review_count_reducer";
import ReviewsReducer from "./reviews_reducer";
import SelectedReducer from "./selected_reducer";
import SessionReducer from "./sessions_reducer";

const RootReducer = combineReducers({
  companies: CompaniesReducer,
  enterprise: EnterpriseReducer,
  pending: pendingKYCAndReviewReducer,
  errors: ErrorsReducer,
  logs: LogsReducer,
  messages: MessagesReducer,
  pagination: PaginationReducer,
  partners: PartnersReducer,
  reviews: ReviewsReducer,
  selected: SelectedReducer,
  session: SessionReducer,
});

export default RootReducer;
