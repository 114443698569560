import React from "react";
import {
  ArrowsAltOutlined,
  RetweetOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SunOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import {
  IconBrightnessFilled,
  IconFlipHorizontal,
  IconFlipVertical,
} from "@tabler/icons-react";
import { Button, Tooltip } from "antd";

interface ImageManipulationToolsProps {
  onZoomIn?: () => void;
  onZoomOut?: () => void;
  onReset?: () => void;
  onRotateLeft?: () => void;
  onRotateRight?: () => void;
  onFlipHorizontal?: () => void;
  onFlipVertical?: () => void;
  handleFilterChange: (filter: string) => void;
  showFullScreen?: boolean;
}

const ImageManipulationTools: React.FC<ImageManipulationToolsProps> = ({
  onZoomIn,
  onZoomOut,
  onReset,
  onRotateLeft,
  onRotateRight,
  onFlipHorizontal,
  onFlipVertical,
  handleFilterChange,
  showFullScreen,
}) => (
  <>
    {onZoomIn && (
      <Tooltip title="Zoom In">
        <Button
          onClick={onZoomIn}
          icon={<ZoomInOutlined role="presentation" />}
          aria-label="Zoom In"
        />
      </Tooltip>
    )}
    {onZoomOut && (
      <Tooltip title="Zoom Out">
        <Button
          onClick={onZoomOut}
          icon={<ZoomOutOutlined role="presentation" />}
          aria-label="Zoom Out"
        />
      </Tooltip>
    )}
    {onReset && (
      <Tooltip title="Reset">
        <Button
          onClick={onReset}
          icon={<RetweetOutlined role="presentation" />}
          aria-label="Reset"
        />
      </Tooltip>
    )}
    {onRotateLeft && (
      <Tooltip title="Rotate Left">
        <Button
          onClick={onRotateLeft}
          icon={<RotateLeftOutlined role="presentation" />}
          aria-label="Rotate Left"
        />
      </Tooltip>
    )}
    {onRotateRight && (
      <Tooltip title="Rotate Right">
        <Button
          onClick={onRotateRight}
          icon={<RotateRightOutlined role="presentation" />}
          aria-label="Rotate Right"
        />
      </Tooltip>
    )}
    {onFlipHorizontal && (
      <Tooltip title="Flip Horizontally">
        <Button
          onClick={onFlipHorizontal}
          icon={
            <IconFlipVertical
              className="align-middle"
              role="presentation"
              strokeWidth={1.5}
            />
          }
          aria-label="Flip Horizontally"
        />
      </Tooltip>
    )}
    {onFlipVertical && (
      <Tooltip title="Flip Vertically">
        <Button
          onClick={onFlipVertical}
          icon={
            <IconFlipHorizontal
              className="align-middle"
              role="presentation"
              strokeWidth={1.5}
            />
          }
          aria-label="Flip Vertically"
        />
      </Tooltip>
    )}
    <Tooltip title="Invert Colors">
      <Button
        onClick={() => handleFilterChange("img-invert")}
        icon={
          <IconBrightnessFilled
            className="align-middle"
            role="presentation"
            strokeWidth={1.5}
          />
        }
        aria-label="Invert Colors"
      />
    </Tooltip>
    <Tooltip title="Change Brightness">
      <Button
        onClick={() => handleFilterChange("img-brightness")}
        icon={<SunOutlined role="presentation" />}
        aria-label="Change Brightness"
      />
    </Tooltip>
    {showFullScreen && (
      <Tooltip title="Fullscreen">
        <Button
          onClick={() => handleFilterChange("expand")}
          icon={<ArrowsAltOutlined role="presentation" />}
          aria-label="Fullscreen"
        />
      </Tooltip>
    )}
  </>
);

export default ImageManipulationTools;
