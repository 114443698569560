import React from "react";

export default function SummaryHeaderShimmer() {
  return (
    <div className="shimmer-wrapper">
      <div
        className="animate-shimmer shimmer-text summary-metrics__title"
        style={{ width: "40%", height: "30px" }}
      />
      <div
        className="animate-shimmer shimmer-text summary-metrics__value"
        style={{ width: "20%" }}
      />
    </div>
  );
}
