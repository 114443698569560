import React from "react";

export function Loader({ size = "" }) {
  return (
    <div className={`lds-ring ${size}`}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}
