import React from "react";
import { useHistory } from "react-router-dom";
import CustomModal from "components/auto_pay/CustomAutoPayModal";
import CircleLoaderWithTimer from "components/reusable/CircleTimer";
import useAuthenticatedUser from "hooks/useAuthenticatedUser";
import { useEnqueueReview } from "queries";
import { StyledButton } from "./web_links/StyledButton";

type Props = {
  onNextReview: () => void;
  reviewId: number;
  waitTime: number;
};

function TimeoutReview({ reviewId, onNextReview, waitTime }: Props) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { userType } = useAuthenticatedUser();
  const history = useHistory();
  const [hasTimedOut, setHasTimedOut] = React.useState(false);

  const { data } = useEnqueueReview({
    enabled: hasTimedOut,
    id: reviewId,
  });

  const exitReview = () => {
    setIsModalOpen(false);
    const url =
      userType === "admin"
        ? "/admin/reviews/completed"
        : "/reviewer/reviews/completed";
    history.push(url);
  };

  const onTimeout = () => {
    setHasTimedOut(true);
  };

  return (
    <>
      <div className="h4" style={{ marginTop: -20 }}>
        <CircleLoaderWithTimer
          onTimeout={onTimeout}
          time={waitTime}
          textColor="#ffa500"
          trailColor="#2d2b2a"
        />
      </div>
      <CustomModal
        showBackButton={false}
        containerClassName="small-modal"
        isOpen={!isModalOpen && hasTimedOut && data?.enqueued}
        onClose={exitReview}
      >
        <div className="review-assigned-modal">
          <h1>Review Reassigned</h1>
          <p>Do you want to continue with another review?</p>
          <div className="review-assigned-modal--actions">
            <StyledButton
              variant="outline"
              defaultButton
              onClick={exitReview}
              isActive
            >
              No
            </StyledButton>
            <StyledButton
              onClick={onNextReview}
              variant="primary"
              isActive
              defaultButton
            >
              Yes
            </StyledButton>
          </div>
        </div>
      </CustomModal>
    </>
  );
}

export default TimeoutReview;
