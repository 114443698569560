import React from "react";
import "./shimmer.css";

export default function TableShimmer({ columnCount = 5 }) {
  const renderBody = (count) => {
    const body = Array(count).fill("");

    return body.map((_val, index) => (
      <div key={index}>
        <div
          className="bar animate-shimmer"
          style={{ marginTop: "10px", marginBottom: "10px", height: "1px" }}
        />
        <div className="shimmer-table-body">
          <div
            className="animate-shimmer shimmer-text"
            style={{ width: "20%" }}
          />
          <div
            className="animate-shimmer shimmer-text"
            style={{ width: "20%" }}
          />
          <div
            className="animate-shimmer shimmer-text"
            style={{ width: "1%" }}
          />
          <div
            className="animate-shimmer shimmer-text"
            style={{ width: "20%" }}
          />
        </div>
      </div>
    ));
  };
  return (
    <div className="shimmer-wrapper margin-top-30">
      <div className="shimmer-table-header">
        <div
          className="animate-shimmer shimmer-text"
          style={{ width: "30%" }}
        />
        <div
          className="animate-shimmer shimmer-text"
          style={{ width: "20%" }}
        />
        <div
          className="animate-shimmer shimmer-text"
          style={{ width: "20%" }}
        />
      </div>
      {renderBody(columnCount)}
    </div>
  );
}
