import React from "react";

export default function Loader({ loaderText }) {
  return (
    <div id="page-loader-wrapper">
      <div id="loader-animation" />
      <p id="loader-text">{loaderText}</p>
    </div>
  );
}
