import React from "react";
import { Popover } from "antd";

function ErrorsControl({ open, name, errors, setSource, children }) {
  const id = React.useId();

  const handleChange = (event) => {
    const { checked, value } = event.target;
    setSource((prevSelectedItems) => {
      const updatedSet = new Set(prevSelectedItems);
      if (checked) {
        updatedSet.add(value);
      } else {
        updatedSet.delete(value);
      }
      return updatedSet;
    });
  };

  const options = [
    { value: "too_light_dark", label: "Too Light/Too Dark" },
    { value: "fraud", label: "Fraud" },
    { value: "blurry", label: "Photo Blurry" },
    { value: "image_unusable", label: "Photo Unusable" },
  ];

  const content = options.map((option, index) => (
    <div key={`cb${index + 1}-${id}`} className="flex items-center space-x-2">
      <input
        type="checkbox"
        id={`cb${index + 1}-${id}`}
        value={option.value}
        name={name}
        checked={errors.has(option.value)}
        onChange={handleChange}
        label={option.label}
        className="h-5 w-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
      />
      <label
        htmlFor={`cb${index + 1}-${id}`}
        className="text-gray-700 text-sm font-medium"
      >
        {option.label}
      </label>
    </div>
  ));

  return (
    <Popover content={content} open={open}>
      {children}
    </Popover>
  );
}

export default ErrorsControl;
