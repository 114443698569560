import React from "react";

const useCopyToClipboard = () => {
  const [copySuccess, setCopySuccess] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);

  const copyToClipboard = (textToCopy: string) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          setCopySuccess(true);
          setError(null);
        })
        .catch((err) => {
          setCopySuccess(false);
          setError("Failed to copy text.");
          console.error("Clipboard API Error:", err);
        });
    } else {
      setError("Clipboard API is not supported on this browser.");
    }
  };

  React.useEffect(() => {
    // Reset copySuccess after 5 seconds to allow re-copying if needed
    if (copySuccess) {
      const timer = setTimeout(() => {
        setCopySuccess(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [copySuccess]);

  return { copyToClipboard, copySuccess, error };
};

export default useCopyToClipboard;
