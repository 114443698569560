import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

interface LoginResponse {
  auth_token: string;
  pre_otp_user: string;
}

interface LoginInput {
  email: string;
  password: string;
}

const slackAuth = async (input: LoginInput) => {
  const { data } = await axios.post<LoginResponse>("/slack_auth", input);
  return data;
};

export const useSlackAuth = (
  options?: UseMutationOptions<
    LoginResponse,
    AxiosError<{ errors: string[] }>,
    LoginInput
  >,
) =>
  useMutation({
    mutationFn: slackAuth,
    ...options,
  });
