import { connect } from "react-redux";

import { dispatchMessage, submitReview } from "actions/actions";
import IDCardReview from "components/reusable/id_card_review";

const mapDispatchToProps = (dispatch) => ({
  submitReview: (reviewInfo) => submitReview(reviewInfo, dispatch),
  dispatchMessage: (message) => dispatchMessage(message, dispatch),
});

export default connect(null, mapDispatchToProps)(IDCardReview);
