import React, { useLayoutEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

const rootDOMNode = document.getElementById("si-dropdown-root");

const Options = ({ children }) => createPortal(children, rootDOMNode);

// TODO: Improve accessibility by adding the following
//
// keyboard navigation: focus management, roving tabindex pattern to cycle
// though options
//
// focus management: which closes dropdown on navigation to another element

function DropDown({
  label,
  options,
  selectedOption,
  handleOptionSelection,
  className = "",
  showFilterLabel = false,
  disabled = false,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const triggerEl = useRef(null);

  useLayoutEffect(() => {
    if (isOpen && triggerEl.current) {
      // note: move listbox next to button
      const triggerPosition = triggerEl.current.getBoundingClientRect();

      rootDOMNode.setAttribute("style", `width:${triggerPosition.width}px;`);
      rootDOMNode.style.display = "block";
      rootDOMNode.style.top = `${triggerPosition.bottom}px`;
      rootDOMNode.style.left = `${triggerPosition.left}px`;
    } else {
      rootDOMNode.style.display = "none";
    }
  }, [isOpen]);

  const toggleOpenState = () => setIsOpen(!isOpen);

  return (
    <>
      <button
        disabled={disabled}
        ref={triggerEl}
        className={`si-dropdown ${className}`}
        onClick={toggleOpenState}
      >
        {selectedOption?.icon && (
          <>
            <img
              height={10}
              src={selectedOption.icon}
              alt={selectedOption.label}
            />
            {"  "}
          </>
        )}
        {label || selectedOption?.label || ""}
      </button>
      {isOpen && (
        <Options>
          {showFilterLabel && <p>Filter By:</p>}
          <ul role="listbox">
            {options.map((option, idx) => (
              <li
                role="option"
                key={idx}
                tabIndex={idx === 0 ? "0" : "-1"}
                aria-selected={
                  selectedOption === option.value ||
                  selectedOption?.value === option.value
                }
                onClick={() => {
                  handleOptionSelection(option);
                  setIsOpen(false);
                }}
                name={option.value}
              >
                {option.icon && (
                  <>
                    <img height={10} src={option.icon} alt={option.label} />
                    {"  "}
                  </>
                )}
                {option.label}
              </li>
            ))}
          </ul>
        </Options>
      )}
    </>
  );
}

export default DropDown;
