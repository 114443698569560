import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

interface Enable2FAResponse {
  auth_token: string;
  pre_otp_user: string;
}

interface Enable2FAInput {
  email: string;
  password: string;
}

const enable2FA = async (input: Enable2FAInput) => {
  const { data } = await axios.post<Enable2FAResponse>(
    "/two_factor_authentication",
    input,
  );
  return data;
};

export const useEnable2FA = (
  options?: UseMutationOptions<
    Enable2FAResponse,
    AxiosError<{ errors: string[] }>,
    Enable2FAInput
  >,
) =>
  useMutation({
    mutationFn: enable2FA,
    ...options,
  });
