import { createReducer } from "./create_reducer";

const _null_data = {
  partnerInfo: {
    company: {},
    products: [],
  },
  sortingParams: {},
};

const EnterpriseReducer = createReducer(_null_data, {
  RECEIVE_PARTNER_INFO: (state, action) => ({
    ...state,
    partnerInfo: action.partnerInfo,
  }),
  CLEAR_ENTERPRISE_STATE: () => _null_data,
  RECEIVE_SORTING_PARAMS: (state, action) => ({
    ...state,
    sortingParams: { ...action.sortingParams, ...state.sortingParams },
  }),
});

export default EnterpriseReducer;
