import React, { useState, useEffect } from "react";
import { useDeepCompareEffect } from "ahooks";
import { useHistory } from "react-router-dom";
import BackArrow from "assets/svgs/back-arrow.svg";
import Close from "assets/svgs/close.svg";
import Continue from "assets/svgs/continue.svg";
import DedicatedIntegration from "assets/svgs/dedicated-integration.svg";
import InstantConnection from "assets/svgs/instant-connection.svg";
import PayWithEaseIcon from "assets/svgs/pay-with-ease.svg";
import Success from "assets/svgs/success.svg";
import VolumeDiscounts from "assets/svgs/volume-discounts.svg";
import { Loader } from "components/reusable/Loader";
import { buildState } from "util/api_util";
import {
  Form as IntercomForm,
  TICKET_TYPE_IDS as INTERCOM_TICKET_TYPE_IDS,
} from "../intercom-tickets";
import Modal from "../modal";
import { PLANS } from "./constants";
import PayNow from "./PayNow";
import PricingInfo from "./PricingInfo";
import "assets/scss/styles.scss";

function PayAsYouGoPlan({ currentPlan, selectPlan }) {
  const buttonText =
    currentPlan === "pay_as_you_go" ? "Top up" : "Contact Sales";

  return (
    <div className="box stack">
      <h2 className="font-heading">Pay-As-You-Go Plan</h2>
      <p className="space:zero">
        Recommended for startups looking for a flexible solution
      </p>

      <ul className="value-prop-list stack">
        <li className="value-prop">
          <div>
            <img src={InstantConnection} alt="" />
          </div>
          <div className="stack">
            <h3>Get Instant Access</h3>
            <p>
              Add funds to your wallet and use them to access all Smile ID
              products
            </p>
          </div>
        </li>

        <li className="value-prop">
          <div>
            <img src={PayWithEaseIcon} alt="" />
          </div>
          <div className="stack">
            <h3>Use Your Preferred Payment Method</h3>
            <p>
              Pay with ease with your international cards, and bank accounts
            </p>
          </div>
        </li>
      </ul>

      {selectPlan && (
        <p style={{ textAlign: "right" }}>
          <button
            data-variant="primary"
            type="button"
            onClick={() => selectPlan("pay_as_you_go")}
          >
            <img src={Continue} alt="" />
            <span>{buttonText}</span>
          </button>
        </p>
      )}
    </div>
  );
}

function ContractPlan({ selectPlan }) {
  return (
    <div className="box stack">
      <h2 className="font-heading">Enterprise Plan</h2>

      <p className="space:zero">
        A contracted plan recommended for businesses verifying 5000+ users per
        month
      </p>

      <ul className="value-prop-list stack">
        <li className="value-prop">
          <div>
            <img src={VolumeDiscounts} alt="" />
          </div>
          <div className="stack">
            <h3>Volume discounts</h3>
            <p>Get volume based discounted pricing</p>
          </div>
        </li>

        <li className="value-prop">
          <div>
            <img src={DedicatedIntegration} alt="" />
          </div>
          <div className="stack">
            <h3>Dedicated integration</h3>
            <p>
              Dedicated account management with round the clock help and
              integration support
            </p>
          </div>
        </li>
      </ul>

      {selectPlan && (
        <p style={{ textAlign: "right" }}>
          <button
            data-variant="primary"
            type="button"
            onClick={() => selectPlan("contract")}
          >
            <img src={Continue} alt="" />
            <span>Contact Sales</span>
          </button>
        </p>
      )}
    </div>
  );
}

function Plans({ currentPlan = "You have no plan selected", selectPlan }) {
  return (
    <div className="stack">
      <h1 className="font-large">Choose the best plan for your business</h1>

      <p>
        Your current plan: <strong>{PLANS[currentPlan]}</strong>
      </p>

      <div
        className="split-pair"
        data-align="stretch"
        style={{ "--gap": "4.5rem" }}
      >
        <PayAsYouGoPlan currentPlan={currentPlan} selectPlan={selectPlan} />

        <ContractPlan selectPlan={selectPlan} />
      </div>
    </div>
  );
}

function PlanChangeRequestForm({
  onSubmit,
  currentPlan,
  designatedCurrency,
  requestedPlan,
  autoPay,
  onCreateInvoiceResponse,
}) {
  const history = useHistory();
  const queryParams = buildState(history.location.search);

  const shouldContactSales =
    queryParams.step !== "add-funds" &&
    (currentPlan !== requestedPlan || requestedPlan === "contract");
  const [loading, setLoading] = useState(!shouldContactSales);

  useDeepCompareEffect(() => {
    if (!shouldContactSales && autoPay.enabledCurrencies) {
      setLoading(false);
    }
  }, [autoPay]);

  if (loading) {
    return (
      <div className="split-pair" style={{ "--gap": "4.5rem" }}>
        <Loader size="xl" />
      </div>
    );
  }

  return (
    <div className="split-pair" style={{ "--gap": "4.5rem" }}>
      {shouldContactSales ? (
        <div className="stack">
          <h1>Request {PLANS[requestedPlan]}</h1>
          <IntercomForm
            onSubmit={onSubmit}
            defaultTicket={{ requested_plan: requestedPlan }}
            hiddenFields={["partner_id", "requested_plan"]}
            ticketTypeId={INTERCOM_TICKET_TYPE_IDS.changePaymentPlan}
          />
        </div>
      ) : (
        <PayNow
          autoPay={autoPay}
          designatedCurrency={designatedCurrency}
          onCreateInvoiceResponse={onCreateInvoiceResponse}
        />
      )}
      {requestedPlan === "contract" ? <ContractPlan /> : <PayAsYouGoPlan />}
    </div>
  );
}

function ChangeRequestSubmitted({ close }) {
  return (
    <div className="center stack">
      <img src={Success} alt="" />
      <h1>Details submitted</h1>

      <p>You should receive an email response in 1-3 business days</p>

      <button data-variant="primary" type="button" onClick={close}>
        <img src={Continue} alt="" />
        <span>Continue</span>
      </button>
    </div>
  );
}

function PaymentPlanModal({
  isOpen = false,
  paymentPlan,
  autoPay,
  designatedCurrency,
  onCreateInvoiceResponse,
}) {
  const history = useHistory();
  const queryParams = buildState(history.location.search);

  const screens = [
    "planSelection",
    "selected",
    "changeRequestSubmitted",
    "pricingInfo",
  ];
  const [screen, setScreen] = useState(screens[0]);
  const [showBackButton, setShowBackButton] = useState(true);

  const plans = ["pay_as_you_go", "contract"];
  const [selectedPlan, setSelectedPlan] = useState(
    plans.includes(paymentPlan) ? paymentPlan : plans[0],
  );

  const selectPlan = (plan) => {
    setSelectedPlan(plan);
    setScreen("selected");
  };

  useEffect(() => {
    if (queryParams.step === "add-funds") {
      setScreen("selected");
      setShowBackButton(false);
    }
  }, [queryParams.step]);

  const onClose = () => {
    history.replace("/partner/billing");
  };

  const goToPreviousScreen = () => {
    const screenIndex = screens.indexOf(screen);
    if (screen === "pricingInfo") {
      setScreen("selected");
      return;
    }
    if (screenIndex > 0) setScreen(screens[screenIndex - 1]);
    if (screenIndex === 0) onClose();
  };

  const onChangeRequestSubmit = () => setScreen("changeRequestSubmitted");
  const onPricingInfoView = () => setScreen("pricingInfo");
  const enabledCurrencies = autoPay.enabledCurrencies || [];
  const enabledCurrenciesIsOnlyUsd = enabledCurrencies.every(
    (currency) => currency === "USD",
  );

  if (isOpen) {
    return (
      <div className="legacy new-styles">
        <Modal show={isOpen} additionalClasses="modal">
          <nav className="modal-navigation">
            <ul>
              <li>
                {showBackButton && screen !== "changeRequestSubmitted" && (
                  <button
                    data-variant="ghost"
                    type="button"
                    onClick={goToPreviousScreen}
                  >
                    <img src={BackArrow} alt="" />
                    <span style={{ color: "#151F72" }}>Go Back</span>
                  </button>
                )}
              </li>
              <li>
                <button data-variant="ghost" type="button" onClick={onClose}>
                  <img src={Close} alt="" />
                  <span className="visually-hidden">
                    Close Payment Plan Modal
                  </span>
                </button>
              </li>
            </ul>
          </nav>
          <div className="modal__content">
            {
              {
                planSelection: (
                  <Plans currentPlan={paymentPlan} selectPlan={selectPlan} />
                ),
                selected: (
                  <PlanChangeRequestForm
                    autoPay={autoPay}
                    currentPlan={paymentPlan}
                    requestedPlan={selectedPlan}
                    onSubmit={onChangeRequestSubmit}
                    designatedCurrency={designatedCurrency}
                    onCreateInvoiceResponse={onCreateInvoiceResponse}
                  />
                ),
                changeRequestSubmitted: (
                  <ChangeRequestSubmitted close={onClose} />
                ),
                pricingInfo: <PricingInfo />,
              }[screen]
            }
          </div>
          {screen === "selected" &&
            designatedCurrency === "USD" &&
            selectedPlan === "pay_as_you_go" &&
            paymentPlan === "pay_as_you_go" &&
            enabledCurrenciesIsOnlyUsd && (
              <div className="modal__footer pricing-info">
                <div className="grid">
                  <div>
                    <h3>AML Checks</h3>
                    <p>Available in all markets</p>
                  </div>

                  <div>
                    <h3>Government ID Checks</h3>
                    <p>Specific Markets</p>
                  </div>

                  <div>
                    <h3>Document Verification</h3>
                    <p>Available in all markets</p>
                  </div>

                  <div>
                    <button
                      data-variant="outline"
                      type="button"
                      onClick={onPricingInfoView}
                      className=""
                    >
                      <img src={Continue} alt="" />
                      <span>View Pricing</span>
                    </button>
                  </div>
                </div>
              </div>
            )}
        </Modal>
      </div>
    );
  }
}

export default PaymentPlanModal;
