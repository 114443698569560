import { connect } from "react-redux";

import Header from "components/reusable/Header";

const mapStateToProps = (state: {
  session: { currentUser: { email: string } };
}) => ({
  currentUser: state.session.currentUser,
});

export default connect(mapStateToProps)(Header);
