import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

interface RerunJobResponse {}

interface RerunJobInput {
  id: string;
  forceCharge: boolean;
}

const rerunJob = async ({ id, forceCharge }: RerunJobInput) => {
  const { data } = await axios.put<RerunJobResponse>(`/jobs/${id}`, {
    force_charge: forceCharge,
  });
  return data;
};

export const useJobRerun = (
  options?: UseMutationOptions<
    RerunJobResponse,
    AxiosError<{ errors: string[] }>,
    RerunJobInput
  >,
) =>
  useMutation({
    mutationFn: rerunJob,
    ...options,
  });
