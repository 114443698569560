import { createReducer } from "./create_reducer";

const SessionReducer = createReducer(
  {},
  {
    RECEIVE_TOKEN: (state, action) => ({
      ...state,
      token: action.token.auth_token,
      preOTPUser: action.token.pre_otp_user,
      errors: action.token.errors,
    }),
  },
);

export default SessionReducer;
