import React from "react";
import moment from "moment";
import DateSelector from "../reusable/date_selector";

function DateFilter(props) {
  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const startDate = props.startDate ? moment(props.startDate) : undefined;
  const endDate = props.endDate ? moment(props.endDate) : undefined;

  const start = startDate ? startDate.format("MMM DD") : "Start Date";
  const end = endDate ? endDate.format("MMM DD") : "End Date";

  const {
    onDateToggle,
    dateToggle,
    handleDateChange,
    fetchingData,
    userType,
    searchByDate,
  } = props;

  return (
    <div
      className="filters__date datepicker js-btn--datepicker"
      onClick={onDateToggle}
    >
      <p>
        {start} - {end}
      </p>

      {dateToggle && (
        <div
          data-testid="date-filter"
          className="filters__date-card"
          onClick={stopPropagation}
        >
          <DateSelector
            startDate={startDate}
            endDate={endDate}
            handleDateChange={handleDateChange}
            fetchingData={fetchingData}
            searchByDate={searchByDate}
            userType={userType}
            onCancel={onDateToggle}
            jobList
          />
        </div>
      )}
    </div>
  );
}

export default DateFilter;
