import React, { Component } from "react";
import classNames from "classnames";

import SpoofReview from "components/reusable/SpoofReview";
import CompareReviewContainer from "containers/reusable/compare_review_container";
import DocVReviewContainer from "containers/reusable/DocVReviewContainer";
import IDCardReviewContainer from "containers/reusable/id_card_review_container";
import UpdateImageReviewContainer from "containers/reusable/update_image_review_container";
import { getEditReview, adjustReview } from "util/api_util";
import ErrorAndMessageBar from "./error_and_message_bar";

class ReviewsShow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaderStyle: "loader",
      lastReviewID: null,
      reviews: [],
      timers: [],
      error: null,
      message: null,
    };

    this.setLastReviewID = this.setLastReviewID.bind(this);
    this.removeTimers = this.removeTimers.bind(this);
    this.nextReview = this.nextReview.bind(this);
  }

  componentDidMount() {
    // fetch review on initial mount
    this.props.fetchReviews();
    this.setState({
      loaderStyle: "",
    });
  }

  componentWillUnmount() {
    this.removeTimers();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // when the component receives new reviews, this lifecycle method will be called
    if (nextProps.data.length === 0 && !this.props.error) {
      this.setState({
        reviews: [],
      });
    } else {
      // scroll to top
      window.scrollTo(0, 0);
      this.removeTimers();
      this.setState({
        reviews: nextProps.data,
      });
    }
  }

  removeTimers() {
    // helper function for killing the polling
    this.state.timers.map((timer) => clearInterval(timer));
    this.setState({
      timers: [],
    });
  }

  nextReview() {
    this.removeTimers();
    this.setState({
      reviews: [],
    });
    this.props.fetchReviews();
  }

  setLastReviewID(resp, id) {
    if (resp.reviewsAndCount) {
      this.setState({
        lastReviewID: id,
        error: resp.reviewsAndCount.error,
        message: resp.reviewsAndCount.message,
      });
    } else if (resp.error) {
      this.setState({
        error: resp.error,
      });
    } else {
      const { reviews } = this.state;
      for (const i in reviews) {
        if (reviews[i].id === id) {
          reviews.splice(i, 1);
        }
        this.setState({
          lastReviewID: null,
          message: resp.message,
        });
      }
    }
  }

  render() {
    const reviewContainer = [];
    let parentClass;
    if (this.state.reviews.length > 0) {
      for (let i = 0; i < this.state.reviews.length; i += 1) {
        const review = this.state.reviews[i];

        let reviewForm;
        if (review.purpose === "compare") {
          parentClass = "review";
          // check to see if this a completed review or not. true means the review has been completed
          if (review.id === this.state.lastReviewID) {
            reviewForm = (
              <CompareReviewContainer
                review={review}
                callback={this.setLastReviewID}
                endpoint={adjustReview}
                next={this.nextReview}
              />
            );
          } else {
            reviewForm = (
              <CompareReviewContainer
                review={review}
                callback={this.setLastReviewID}
                next={this.nextReview}
              />
            );
          }
        } else if (review.purpose === "update_image") {
          if (review.id === this.state.lastReviewID) {
            reviewForm = (
              <UpdateImageReviewContainer
                review={review}
                callback={this.setLastReviewID}
                endpoint={adjustReview}
                next={this.nextReview}
              />
            );
          } else {
            reviewForm = (
              <UpdateImageReviewContainer
                review={review}
                callback={this.setLastReviewID}
                next={this.nextReview}
              />
            );
          }
        } else if (review.purpose === "id_card") {
          parentClass = "review open-review";
          if (review.id === this.state.lastReviewID) {
            reviewForm = (
              <IDCardReviewContainer
                review={review}
                callback={this.setLastReviewID}
                endpoint={adjustReview}
                next={this.nextReview}
              />
            );
          } else {
            reviewForm = (
              <IDCardReviewContainer
                review={review}
                callback={this.setLastReviewID}
                next={this.nextReview}
              />
            );
          }
        } else if (review.purpose === "docv") {
          parentClass = "review open-review";
          const isLastReview = review.id === this.state.lastReviewID;
          reviewForm = (
            <DocVReviewContainer
              review={review}
              callback={this.setLastReviewID}
              endpoint={isLastReview ? adjustReview : undefined}
              next={this.nextReview}
            />
          );
        } else {
          // do spoof review
          parentClass = "spoof-container review";
          if (review.id === this.state.lastReviewID) {
            reviewForm = (
              <SpoofReview
                review={review}
                callback={this.setLastReviewID}
                endpoint={adjustReview}
                next={this.nextReview}
              />
            );
          } else {
            reviewForm = (
              <SpoofReview
                review={review}
                callback={this.setLastReviewID}
                next={this.nextReview}
              />
            );
          }
        }

        reviewContainer.push(<div key={i + review.target}>{reviewForm}</div>);
      }
    }

    if (this.state.reviews.length === 0) {
      if (this.state.loaderStyle.length === 0) {
        return (
          <div className={this.state.loaderStyle}>
            <ErrorAndMessageBar
              messages={this.state.message}
              errors={this.props.error || this.state.error}
            />
            {!this.props.isLoading && !this.props.error && (
              <p>No reviews were found.</p>
            )}
          </div>
        );
      }
      return <div className={this.state.loaderStyle} />;
    }
    return (
      <div className={classNames("legacy", parentClass)}>
        <ErrorAndMessageBar
          messages={this.state.message}
          errors={this.props.error || this.state.error}
        />
        {reviewContainer}
      </div>
    );
  }
}

export default ReviewsShow;
