import React, { useState, useEffect, useContext } from "react";
import * as Sentry from "@sentry/react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { fetchPartners } from "actions/actions";
import CustomSelect from "components/reusable/custom_select";
import SummaryTable from "containers/admin/analytics_dashboard/SummaryTable";
import { AnalyticsProvider } from "contexts/analytics_context";
import { DisplayEnvironment } from "contexts/displayEnvironment";

import "./analytics_styles.css";
import { useQueryParams } from "hooks/useQueryParams";

import { useSearchQuery } from "hooks/useSearchQuery";
import ResetIcon from "resources/img/icons/reset-icon.svg";
import { fetchPartnerAnalytics, fetchWebAppServices } from "util/api_util";
import { ISOCountryCodeToFullNameMap } from "util/format_helpers";
import { getJobTypes, getUserPartnerID, getUserType } from "util/selectors";
import IdVolumesGraph from "./id_volumes_graph";
import JobVolumeGraph from "./job_volume_graph";
import ProductOverviewGraphs from "./product_overview_graphs";
import SpotlightContainer from "./spotlight_container";
import SummaryMetricsHeader from "./summary_metrics_header";
import UsageByCountry from "./usage_by_country";
import WeeklyIdJobsTable from "./weekly_id_jobs_table";

const dateFilters = [
  { label: "Last 7 days", value: "7_days", display_period: "day" },
  { label: "Last month", value: "30_days", display_period: "week" },
  { label: "Last 3 Months", value: "90_days", display_period: "month" },
  { label: "Last 12 Months", value: "365_days", display_period: "month" },
  { label: "Month to date", value: "all_month", display_period: "week" },
  { label: "Year to date", value: "all_year", display_period: "month" },
  { label: "All time", value: "all_time", display_period: "month" },
];

const resultFilters = [
  { label: "Pass", value: "Pass" },
  { label: "Error", value: "Error" },
  { label: "Fail", value: "Fail" },
  { label: "Processing", value: "Processing" },
];

const countryFilters = Object.keys(ISOCountryCodeToFullNameMap).map(
  (countryCode) => ({
    value: countryCode,
    label: ISOCountryCodeToFullNameMap[countryCode],
  }),
);

const buildDateFilter = ({ value, display_period }) => {
  const [duration, period] = value.split("_");
  const [start_date, end_date] = evaluateStartDate(duration, period);
  return {
    start_date,
    end_date,
    display_period,
    date_filter: value,
  };
};

const evaluateStartDate = (duration, period) => {
  if (period === "time") return [];

  let endDate;
  // Getting the date x period in the past excluding today
  let diff = parseInt(duration, 10) - 1;
  if (duration === "all") {
    const currentDate = moment();
    // the diff is 0 to get the start of month/year
    diff = 0;
    endDate = currentDate.utc().format("YYYY-MM-D");
  }

  const startDate = moment()
    .startOf(period)
    .utc()
    .subtract(diff, period)
    .format("YYYY-MM-D");

  return [startDate || "", endDate || ""];
};

const defaultFilters = {
  ...buildDateFilter(dateFilters[1]),
};

export default function AnalyticsDashboard() {
  const { environment } = useContext(DisplayEnvironment);

  const [partners, setPartners] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [idTypeFilters, setIdTypeFilters] = useState([]);
  const [loading, setLoading] = useState({ stackedGraph: true, idType: true });
  const dispatch = useDispatch();
  const [search, setSearch] = useSearchQuery(defaultFilters);
  const filterObject = useQueryParams();
  let filters = new URLSearchParams(filterObject).toString(); // used for api calls in useEffect
  const products = getJobTypes();
  let partner = {};
  let selectedDate = dateFilters[1];
  if (filterObject.start_date === undefined || filterObject.start_date === "") {
    const newfilters = {
      ...filterObject,
      ...buildDateFilter(selectedDate),
    };
    filterObject.start_date = newfilters.start_date;
    filterObject.end_date = newfilters.end_date;
    filterObject.display_period = newfilters.display_period;
    filters = new URLSearchParams(newfilters).toString();
  }

  if (getUserType() === "admin") {
    const currentPartner = partners.find((item) =>
      item.includes(filterObject.partner_id),
    );
    partner = { value: filterObject.partner_id, label: currentPartner };
  } else {
    partner.value = getUserPartnerID();
  }

  useEffect(() => {
    if (getUserType() !== "admin") {
      return;
    }
    setLoading((current) => ({ ...current, partners: true }));
    fetchPartners(dispatch).then((result) => {
      const {
        partners: { partner_ids: partnerIDs },
      } = result;
      setPartners(partnerIDs);
      setLoading((current) => ({ ...current, partners: false }));
      if (partnerIDs.length > 0) {
        updateFilters({
          partner_id: filterObject.partner_id || partnerIDs[0].split(" - ")[0],
        });
      }
    });
  }, [environment]);

  useEffect(() => {
    setLoading((current) => ({ ...current, idType: true }));
    fetchWebAppServices().then((result) => {
      setLoading((current) => ({ ...current, idType: false }));
      const idTypes = [];

      Object.keys(result.id_types).forEach((country) => {
        const countryIdTypes = result.id_types[country];
        Object.keys(countryIdTypes).forEach((id) => {
          idTypes.push({
            value: `${country}-${id}`,
            label: `${country} ${id.split("_").join(" ")}`,
          });
        });
      });
      setIdTypeFilters(idTypes);
    });
  }, [environment]);

  useEffect(() => {
    if (!partner.value) {
      return;
    }
    const abortController = new AbortController();
    setLoading({ ...loading, stackedGraph: true });
    fetchPartnerAnalytics(
      "/metrics/partner_analytics",
      partner.value,
      filters,
      abortController,
    )
      .then((data) => {
        setChartData(data);
        setLoading((current) => ({ ...current, stackedGraph: false }));
      })
      .catch((e) => {
        if (e.name !== "AbortError") {
          console.error(e);
          Sentry.captureException(e);
        }
      });

    return () => abortController.abort();
  }, [filters]);

  // Filters
  const selectedCountry = countryFilters.find(
    (country) =>
      country.value.toLowerCase() === filterObject.country?.toLowerCase(),
  );

  selectedDate = dateFilters.find(
    (dateFilter) =>
      dateFilter.value.toLowerCase() ===
      (filterObject.date_filter?.toLowerCase() || selectedDate.value),
  );

  const selectedResults = resultFilters.filter((result) =>
    filterObject.results?.split(",")?.includes(result.value),
  );

  const selectedIdTypes = idTypeFilters.filter((result) =>
    filterObject.id_types?.split(",")?.includes(result.value),
  );

  const selectedProducts = products.filter((product) =>
    filterObject.products?.split(",")?.includes(product.value),
  );

  const displayFormat = "D MMM YY";
  const startDate = filterObject.start_date
    ? moment(filterObject.start_date, "YYYY-MM-D")
    : moment().subtract(30, "days"); // we want 30 days, thus 29 prior days + today
  const endDate = filterObject.end_date
    ? moment(filterObject.end_date, "YYYY-MM-D")
    : moment();
  const lastUpdated = moment().subtract(2, "hours").format("hh:mm");

  const partnerOptions = partners.map((partner) => ({
    value: partner.split(" - ")[0],
    label: partner,
  }));

  const onPartnerChange = (option) => {
    const newFilter = { ...filterObject, partner_id: option?.value ?? "" };
    updateFilters(newFilter);
  };

  const totalFailed =
    chartData.length > 0
      ? chartData.reduce((a, b) => a + +b.failed_jobs, 0)
      : 0;
  const totalError =
    chartData.length > 0
      ? chartData.reduce((a, b) => a + +b.errored_jobs, 0)
      : 0;

  const totalPassJobs =
    chartData.length > 0
      ? chartData.reduce((a, b) => a + +b.passed_jobs, 0)
      : 0;
  const totalJobs = totalFailed + totalError + totalPassJobs;
  const passRate = totalPassJobs / totalJobs || 0;

  const onDateChange = (selected) => {
    updateFilters(buildDateFilter(selected));
  };

  const onMultiSelectChange = (field, items) => {
    const values = items.map((item) => item.value).join(",");
    updateFilters({ [field]: values });
  };

  const onCountryChange = ({ value }) => {
    updateFilters({ country: value });
  };

  const resetFilters = () => {
    const newfilters = {
      ...defaultFilters,
    };
    if (filterObject.partner_id) {
      newfilters.partner_id = search.partner_id;
    }

    setSearch(newfilters);
  };

  const updateFilters = (filter) => {
    const newfilters = { ...filterObject, ...filter };
    setSearch(newfilters);
  };

  return (
    <AnalyticsProvider initialFilters={filters} partnerId={partner?.value}>
      <div className="analytics-container">
        {getUserType() === "admin" && (
          <div>
            <CustomSelect
              value={partner}
              onChange={onPartnerChange}
              options={partnerOptions}
              styles={{
                option: (provided) => ({ ...provided, color: "black" }),
                control: (provided) => ({ ...provided, width: 300 }),
              }}
              isClearable
              isLoading={loading.partner}
              placeholder="All Partners"
              isSearchable
            />
          </div>
        )}
        <div className="time-period">
          <h5 className="description">Data Period</h5>
          {selectedDate.value === "all_time" && (
            <h4 className="date-range">All time</h4>
          )}
          {selectedDate.value !== "all_time" && (
            <h4 className="date-range">
              {startDate.format(displayFormat)} -{" "}
              {endDate.format(displayFormat)}
            </h4>
          )}
          <h5 className="mute-note">
            Data recent up to {lastUpdated} GMT Today
          </h5>
        </div>
        <section className="analytics-filters">
          <CustomSelect
            placeholder="Select Date"
            onChange={onDateChange}
            value={selectedDate}
            options={dateFilters}
          />

          <CustomSelect
            placeholder="Result Type"
            onChange={(items) => onMultiSelectChange("results", items)}
            value={selectedResults}
            options={resultFilters}
            isMulti
          />

          <CustomSelect
            placeholder="All Products"
            onChange={(items) => onMultiSelectChange("products", items)}
            isSearchable
            isMulti
            value={selectedProducts}
            options={products}
          />

          <CustomSelect
            placeholder="All ID Types"
            onChange={(items) => onMultiSelectChange("id_types", items)}
            pluralText="ID Type(s)"
            isSearchable
            isMulti
            value={selectedIdTypes}
            isLoading={loading.idType}
            options={idTypeFilters}
          />

          <CustomSelect
            placeholder="All Countries"
            onChange={onCountryChange}
            hasEmpty
            isSearchable
            value={selectedCountry}
            options={countryFilters}
          />
        </section>
        <button
          type="button"
          className="btn btn-white analytics-reset"
          onClick={resetFilters}
        >
          <img src={ResetIcon} /> Reset Filters
        </button>
        <section className="analytics-section">
          <div className="section-header">
            <span>Spotlight </span>
            {selectedDate.value == "all_time" && (
              <span className="section-date">All time</span>
            )}
            {selectedDate.value !== "all_time" && (
              <span className="section-date">
                {startDate.format(displayFormat)} -{" "}
                {endDate.format(displayFormat)}
              </span>
            )}
          </div>
          <SpotlightContainer data={chartData} />
        </section>
        <section className="analytics-section">
          <div className="section-header">Jobs Overview</div>
          <div className="analytics-body">
            <div className="graph-container">
              <div className="summary-container">
                <SummaryMetricsHeader
                  title="Total Job Volume"
                  value={new Intl.NumberFormat().format(totalJobs)}
                  loading={loading.stackedGraph}
                />
                <SummaryMetricsHeader
                  title="Average Pass Rate"
                  valueColor="color-success"
                  value={`${Math.ceil(passRate * 100)}%`}
                  loading={loading.stackedGraph}
                />
              </div>
              <JobVolumeGraph
                loading={loading.stackedGraph}
                chartData={chartData}
                period={filterObject.display_period}
              />
            </div>
            <div className="graph-container">
              <SummaryTable />
            </div>
          </div>
        </section>
        <section className="analytics-section">
          <div className="section-header">
            <span>Product and ID Insights </span>
            {selectedDate.value == "all_time" && (
              <span className="section-date">All time</span>
            )}
            {selectedDate.value !== "all_time" && (
              <span className="section-date">
                {startDate.format(displayFormat)} -{" "}
                {endDate.format(displayFormat)}
              </span>
            )}
          </div>
          <div className="analytics-body">
            <div className="graph-container">
              <SummaryMetricsHeader
                title="ID Volumes"
                loading={loading.stackedGraph}
              />
              <div className="summary-table-container legacyTable">
                <IdVolumesGraph />
              </div>
            </div>
            <div className="graph-container">
              <WeeklyIdJobsTable partnerId={partner?.value} />
            </div>
            <div className="graph-container">
              <SummaryMetricsHeader
                title="Product Overview"
                loading={loading.stackedGraph}
              />
              <div className="summary-table-container legacyTable">
                <ProductOverviewGraphs partnerId={partner?.value} />
              </div>
            </div>
            <div className="graph-container">
              <SummaryMetricsHeader
                title="Product Usage by Country"
                loading={loading.stackedGraph}
              />
              <div className="summary-table-container">
                <UsageByCountry />
              </div>
            </div>
          </div>
        </section>
      </div>
    </AnalyticsProvider>
  );
}
