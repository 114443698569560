import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { omit } from "lodash";

interface SignUpResponse {
  auth_token: string;
  pre_otp_user: string;
}

interface SignUpInput {
  email: string;
  password: string;
  name: string;
  invitation_id?: string;
}

const signUp = async (input: SignUpInput) => {
  const { data } = await axios.post<SignUpResponse>("/signup", input);
  return data;
};

export const useSignUp = (
  options?: UseMutationOptions<
    SignUpResponse,
    AxiosError<{ errors: string[] }>,
    SignUpInput
  >,
) =>
  useMutation({
    mutationFn: signUp,
    onSuccess: (data, ...args) => {
      if (data.auth_token) {
        localStorage.setItem("token", data.auth_token);
      } else if (data.pre_otp_user) {
        localStorage.setItem("preOTPUser", JSON.stringify(data.pre_otp_user));
      }

      options?.onSuccess?.(data, ...args);
    },
    ...omit(options, ["onSuccess"]),
  });
