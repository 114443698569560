import React from "react";
import { useParams } from "react-router-dom";
import "components/reusable/esignature/esignature.scss";
import {
  BodyItem,
  HeaderItem,
  Table,
  TableBody,
  TableCaption,
  TableHeader,
  TableRow,
} from "components/reusable/web_links/Table";
import {
  TableAction,
  TableActions,
} from "components/reusable/web_links/table_actions";
import ViewIcon from "resources/img/icons/view.svg";
import { formatLocalisedDate } from "util/format_helpers";
import {
  getUserPartnerID as getUserPartnerId,
  getUserType,
} from "util/selectors";

export default function ESignatureTable({ partnerId, signatures, userType }) {
  const { environment } = useParams();
  return (
    <section className="document-list">
      <Table>
        <TableCaption>{signatures?.length} Related Jobs</TableCaption>
        <TableHeader>
          <HeaderItem>#</HeaderItem>
          <HeaderItem>Job ID</HeaderItem>
          <HeaderItem>Signed Name</HeaderItem>
          <HeaderItem>Doc Count</HeaderItem>
          <HeaderItem>Date Added</HeaderItem>
          <HeaderItem>Actions</HeaderItem>
        </TableHeader>
        <TableBody>
          {signatures.map((signature, index) => (
            <TableRow key={index}>
              <BodyItem>{index + 1}</BodyItem>
              <BodyItem>{signature.job_obj_id}</BodyItem>
              <BodyItem>{signature.name}</BodyItem>
              <BodyItem>{signature.doc_count}</BodyItem>
              <BodyItem>
                {formatLocalisedDate(signature.date_added, "DD MMM, YYYY")}
              </BodyItem>
              <BodyItem>
                <TableActions>
                  <TableAction
                    label="View"
                    labelClassName="action-buttons"
                    icon={ViewIcon}
                    onClick={() =>
                      window.open(
                        `/${
                          userType === "admin"
                            ? `admin/${partnerId}`
                            : "partner"
                        }/job_results/${environment}/${signature.job_obj_id}`,
                        "_blank",
                        "noreferrer",
                      )
                    }
                  />
                </TableActions>
              </BodyItem>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </section>
  );
}

ESignatureTable.defaultProps = {
  partnerId: getUserPartnerId(),
  signatures: [],
  userType: getUserType(),
};
