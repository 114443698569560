import React, { useState, useEffect, useContext } from "react";
import * as Sentry from "@sentry/react";
import GraphPagination from "components/pagination/graph_pagination";
import BarChart from "components/reusable/charts/bar_graph";
import AreaChartShimmer from "components/reusable/shimmers/area_chart_shimmer";
import { AnalyticsContext } from "contexts/analytics_context";
import { fetchPartnerAnalytics } from "util/api_util";
import { ISOCountryCodeToFullNameMap } from "util/format_helpers";
import styles from "./usage_by_country_graph.module.css";

export default function UsageByCountry() {
  const [usageData, setUsageData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const { filters, partnerId } = useContext(AnalyticsContext);
  const pageSize = 4;

  useEffect(() => {
    if (!partnerId) {
      return;
    }
    setPage(1);
    const abortController = new AbortController();
    setLoading(true);
    fetchPartnerAnalytics(
      "/metrics/product_by_country_analytics",
      partnerId,
      filters,
      abortController,
    )
      .then((data) => {
        setUsageData(data);
        setLoading(false);
      })
      .catch((e) => {
        if (e.name !== "AbortError") {
          console.error(e);
          Sentry.captureException(e);
        }
      });

    return () => abortController.abort();
  }, [partnerId, filters]);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const labels = [];
  const dataset = [];
  const datalabels = [];

  // group by same id type and country
  const result = usageData.reduce((r, a) => {
    const country =
      ISOCountryCodeToFullNameMap[a.country] || a.country || "Not Available";
    r[country] = r[country] || 0;
    r[country] += parseInt(a.total_jobs, 10);
    return r;
  }, Object.create(null));

  let nextPage = 0;
  let paginatedKeys = [];
  const result_keys = Object.keys(result);
  const offset = (page - 1) * pageSize;
  const end_at = offset + pageSize;

  if (end_at < result_keys.length) nextPage = page + 1;

  paginatedKeys = result_keys.slice(offset, end_at);

  paginatedKeys.forEach((product) => {
    labels.push(product);
    dataset.push(result[product]);
    datalabels.push(result[product]);
  });
  const largestPoint = Math.max(...Object.values(result));

  const data = {
    labels,
    datasets: [
      {
        label: labels,
        datalabels,
        legend: labels,
        data: dataset,
        backgroundColor: "rgba(0, 22, 61, 1)",
        borderRadius: { topRight: 5, bottomRight: 5 },
        barPercentage: 1,
        categoryPercentage: 0.5,
      },
    ],
  };

  if (loading) {
    return <AreaChartShimmer height="220px" width="100%" />;
  }

  return (
    <>
      <GraphPagination
        nextPage={nextPage}
        iconsOnly
        activePage={+page}
        onPageChange={handlePageChange}
        style={{ marginBottom: "1.3em", marginRight: "30px" }}
      />
      <div className={styles.bargraph_container}>
        <BarChart
          data={data}
          type="horizontal"
          height={200}
          width={100}
          displayYAxis
          showLegend={false}
          displayXAxis={false}
          extraOptions={{ offset: true }}
          yTicks={{
            font: {
              size: 15,
            },
            color: "#00163D",
          }}
          xAxis={{ offset: true, suggestedMax: largestPoint }}
          yAxis={{
            border: {
              display: false,
            },
          }}
          datalabelOptions={{
            align: "end",
            anchor: "end",
            offset: 7,
            color: "#00163D",
            font: {
              size: 14,
            },
          }}
          padding={{
            top: 10,
            right: 130,
            bottom: 13,
            left: 0,
          }}
        />
      </div>
    </>
  );
}
