import React from "react";
import { NavLink } from "react-router-dom";
import { canUserView } from "util/route_util";

export function SidebarLink(props) {
  const {
    children,
    className = "",
    allowed = true,
    to,
    count = 0,
    iconSrc = null,
    iconSize = "x-small",
    path,
  } = props;
  if (!allowed) return null;
  const badgeClass = count > 0 ? "pending" : "pending--empty";

  let size = iconSize;
  if (
    !["x-small", "small", "medium", "large", "x-large", "xx-large"].includes(
      iconSize,
    )
  ) {
    size = "small";
  }
  const containerClassName = [
    "sidebar__nav-element-container sidebar-element--inactive",
    className /* count > 0 ? 'block__badge': '' */,
  ].join(" ");
  // When the path contains a variable, such as those pointing to smile_links
  // we need to explicitly add that in order to run this check.
  if (!canUserView([path || to])) {
    return;
  }
  return (
    <NavLink
      to={to}
      className={containerClassName}
      activeClassName="sidebar-element--active"
      data-badge={count}
      exact
    >
      {iconSrc && (
        <img
          className={`icon--${size} sidebar__nav-element-icon`}
          src={iconSrc}
          alt="icon"
        />
      )}
      <span className="sidebar__nav-element">{children}</span>
      <span className={badgeClass}>{count}</span>
    </NavLink>
  );
}
