import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

function DateSelector({
  onCancel,
  jobList,
  startDate,
  endDate,
  searchByDate,
  handleDateChange,
  fetchingData,
}) {
  const onClickOutside = (e) => {
    if (e.target.getElementsByClassName("js-btn--datepicker").length > 0) {
      return onCancel();
    }
  };

  const momentToDate = (date) => (date ? date.toDate() : date);

  const dateFormat = "dd/MM/yyyy";

  return (
    <div className="block__datepicker">
      {!jobList && (
        <div className="date-label">
          <span>FILTER JOBS BY</span>
        </div>
      )}
      <div className="input-group input-daterange">
        <DatePicker
          selectsStart
          inline={jobList}
          onClickOutside={(e) => onClickOutside(e)}
          startOpen={jobList}
          shouldCloseOnSelect={false}
          className="form-control"
          selected={momentToDate(startDate)}
          popperClassName="popper-monkey-patch"
          startDate={momentToDate(startDate)}
          endDate={momentToDate(endDate)}
          maxDate={momentToDate(moment().add(1, "days"))}
          onChange={(e) => handleDateChange("startDate", e)}
          dateFormat={dateFormat}
        />
        <DatePicker
          selectsEnd
          inline={jobList}
          onClickOutside={(e) => onClickOutside(e)}
          shouldCloseOnSelect={false}
          className="form-control"
          selected={momentToDate(endDate)}
          popperClassName="popper-monkey-patch popper--triangle"
          startDate={momentToDate(startDate)}
          endDate={momentToDate(endDate)}
          maxDate={momentToDate(moment().add(1, "days"))}
          onChange={(e) => handleDateChange("endDate", e)}
          dateFormat={dateFormat}
        />
      </div>

      {jobList ? (
        <div>
          <div
            className="filters-date-card__action filters-date-card__action--apply"
            onClick={searchByDate}
          >
            Apply
          </div>
          <div
            className="filters-date-card__action filters-date-card__action--cancel"
            onClick={onCancel}
          >
            Cancel
          </div>
        </div>
      ) : (
        <div className="block__chart">
          <a
            className="btn btn-primary job-list-button"
            onClick={searchByDate}
            role="button"
          >
            SEARCH
          </a>
          {fetchingData && <div className="loader loading-data-spinner" />}
        </div>
      )}
    </div>
  );
}

export default DateSelector;
