import React from "react";
import Warning from "resources/img/icons/error-icon.svg";
import GreenCheck from "resources/img/icons/green-check.svg";

interface Props {
  readonly monitored: boolean;
  readonly noOfUpdates: number;
}

function AmlMonitoringCard({ monitored, noOfUpdates }: Props) {
  return (
    <div className="new-styles">
      <div className="color-dark">
        {monitored && (
          <p className="info">
            <img alt="" src={GreenCheck} width="24" />
            <span>AML Monitoring enabled. Total updates: </span>
            <span className="count">
              {noOfUpdates > 99 ? "99+" : noOfUpdates}
            </span>
          </p>
        )}
        {!monitored && (
          <p className="info">
            <img alt="" src={Warning} width="24" />
            <span>AML Monitoring disabled</span>
          </p>
        )}
      </div>
    </div>
  );
}

export default AmlMonitoringCard;
