import React, { useState } from "react";
import moment from "moment";
import { DEFAULT_EXPIRY_DAYS } from "components/reusable/web_links/constants";

const SmileLinksFormContext = React.createContext();
const defaultData = {
  idTypes: [],
  expiresAt: moment().add(DEFAULT_EXPIRY_DAYS, "days").format("DD-MM-YYYY"),
  isSingleUse: false,
};

export function SmileLinksFormProvider({ children }) {
  const [formFields, setFormFields] = useState(defaultData);
  const resetData = () => {
    setFormFields(defaultData);
  };

  const handleChange = (name, value) => {
    setFormFields((current) => ({
      ...current,
      [name]: value,
    }));
  };

  return (
    <SmileLinksFormContext.Provider
      value={{ formFields, handleChange, resetData, setFormFields }}
    >
      {children}
    </SmileLinksFormContext.Provider>
  );
}

export { SmileLinksFormContext };
