import React from "react";
import { Link } from "react-router-dom";
import { css } from "util/format_helpers";
import styles from "./manage_links.module.css";

function TableActions({ children }) {
  return <div className={styles.tableActionsWrapper}>{children}</div>;
}
function TableAction({
  label,
  icon,
  labelClassName,
  labelStyles,
  onClick,
  dataTestId,
  disabled,
  href,
  type,
  ...rest
}) {
  const disabledClassNames = disabled
    ? styles.actionLabelDisabled
    : styles.actionLabelActive;
  const content = (
    <>
      {icon && <img src={icon} alt="" height={12} width={12} />}
      <span
        className={css(labelClassName, styles.actionLabel)}
        style={labelStyles}
      >
        {label}
      </span>
    </>
  );
  if (type === "link" && href && !disabled) {
    return (
      <a
        href={href}
        {...rest}
        className={`${styles.tableAction} ${disabledClassNames}`}
        onClick={onClick}
      >
        {content}
      </a>
    );
  }
  if (href && !disabled) {
    return (
      <Link
        to={href}
        className={`${styles.tableAction} ${disabledClassNames}`}
        onClick={onClick}
      >
        {content}
      </Link>
    );
  }

  return (
    <button
      className={`${styles.tableAction} ${disabledClassNames}`}
      onClick={onClick}
      disabled={disabled}
      data-testid={dataTestId}
    >
      {content}
    </button>
  );
}

export { TableActions, TableAction };
