import React from "react";

function Tooltip({ component, children }) {
  const isValidComponent = component ? component.type === "button" : true;

  if (component && !isValidComponent)
    throw new Error("Tooltip Error: `component` must be a `button`");

  return (
    <span className="tooltip-container">
      {!component && (
        <button className="button icon-btn tooltip" type="button">
          <svg
            aria-hidden="true"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10C7.76142 10 10 7.76142 10 5C10 3.67392 9.47322 2.40215 8.53553 1.46447C7.59785 0.526784 6.32608 0 5 0ZM5.5 6.74997C5.5 6.88804 5.38807 6.99997 5.25 6.99997H4.75C4.61193 6.99997 4.5 6.88804 4.5 6.74997V5.24997C4.5 5.1119 4.61193 4.99997 4.75 4.99997H5.25C5.38807 4.99997 5.5 5.1119 5.5 5.24997V6.74997ZM5.25 4C5.38807 4 5.5 3.88807 5.5 3.75V3.25C5.5 3.11193 5.38807 3 5.25 3H4.75C4.61193 3 4.5 3.11193 4.5 3.25V3.75C4.5 3.88807 4.61193 4 4.75 4H5.25Z"
              fill="currentColor"
            />
          </svg>
        </button>
      )}
      {component && isValidComponent && component}
      <span role="tooltip">{children}</span>
    </span>
  );
}

export default Tooltip;
