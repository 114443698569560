import React from "react";
import ChatBubble from "resources/img/icons/chat_bubble.svg";
import RightArrowWhite from "resources/img/icons/right-arrow-white.svg";

function SupportSplash() {
  return (
    <main className="flow">
      {/* eslint-disable-next-line react/no-unknown-property */}
      <img chat="splash-image" src={ChatBubble} alt="chat bubble icon" />

      <h1>Smile ID Is Here To Help</h1>

      <p className="info">
        Access our support window at the bottom right of any page throughout the
        portal
      </p>

      <p className="info">
        Expand the chat widget to search our Help Center or ask a question
      </p>

      <a
        data-variant="large"
        className="btn btn-primary btn-with-icon"
        id="open-intercom"
        href="#open-intercom"
      >
        <span>Open Chat</span>
        <img
          className="icon"
          width="10"
          height="16"
          src={RightArrowWhite}
          alt="right arrow icon"
        />
      </a>
    </main>
  );
}

export default SupportSplash;
