import React, { useContext, useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { useHistory, useLocation } from "react-router-dom";
import { DisplayEnvironment } from "contexts/displayEnvironment";
import { SmileLinksFormContext } from "contexts/SmileLinksFormContext";
import EditIcon from "resources/img/icons/pencil-icon.svg";
import RemoveIcon from "resources/img/icons/remove-icon.svg";
import {
  buildState,
  fetchSmileLinksProductsConfig,
  fetchSmileLink,
  updateSmileLink,
} from "util/api_util";
import { SMILE_PRODUCTS_MAP } from "util/constants";
import { css } from "util/format_helpers";
import { Toast } from "util/Toast";
import PageHeader from "../../page_header/PageHeader";
import AddButton from "./AddButton";
import { Chips, Chip } from "./Chip";
import ErrorModal from "./ErrorModal";
import styles from "./manage_links.module.css";
import { MediumCard } from "./MediumCard";
import NewIDModal from "./new_id_modal";
import { StyledButton } from "./StyledButton";
import {
  BodyItem,
  HeaderItem,
  Table,
  TableBody,
  TableCaption,
  TableHeader,
  TableRow,
} from "./Table";
import { TableAction, TableActions } from "./table_actions";

export default function AddManageLinkIds() {
  const location = useLocation();
  const [showNewIdModal, setShowNewIdModal] = useState(false);
  const [idTypes, setIdTypesInfo] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const [modalState, setModalState] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState({ refreshing: true });
  const { environment } = useContext(DisplayEnvironment);
  const { handleChange, formFields, setFormFields, resetData } = useContext(
    SmileLinksFormContext,
  );
  const selectedIds = formFields.idTypes ?? [];
  const history = useHistory();
  const { refId } = buildState(location.search);

  const user = localStorage.token ? jwtDecode(localStorage.token) : undefined;
  useEffect(() => {
    if (selectedIds.length == 0) {
      return;
    }
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  });

  useEffect(() => {
    if (!user) return;
    fetchSmileLinksProductsConfig(user.partner_id)
      .then((config) => {
        setIdTypesInfo(config.link_id_types);
        const {
          callback_url: callbackUrl,
          company_name: companyName,
          data_privacy_policy_url: dataPrivacyPolicyUrl,
          logo_url: logoUrl,
        } = config.product_config || {};

        const logoName = logoUrl?.split("/")?.pop();

        setFormFields((current) => ({
          previewImage: logoUrl,
          logoUrl,
          callbackUrl,
          companyName,
          dataPrivacyPolicyUrl,
          logoName,
          ...current,
        }));
      })
      .catch(() => {});
  }, [user.partner_id, environment]);

  useEffect(() => {
    const { refId } = buildState(location.search);
    if (idTypes.length == 0) {
      return;
    }
    if (!refId) {
      setLoading({ refreshing: false });
      return;
    }
    setLoading({ refreshing: true });
    fetchSmileLink(refId).then((result) => {
      setLoading({ refreshing: false });
      if (result.error) {
        setErrorMessage({ linkError: result.error });
        return;
      }
      const {
        company_name: companyName,
        callback_url: callbackUrl,
        data_privacy_policy_url: dataPrivacyPolicyUrl,
        id_types,
        name,
        ref_id: refId,
        disabled_at: disabledAt,
        is_single_use: isSingleUse,
      } = result;

      const linkCountries = id_types.map((val) => val.country);

      const linkIdTypes = idTypes.filter((id) =>
        linkCountries.includes(id.country_code),
      );

      const formattedIdTypes = id_types.map(
        ({ country: countryCode, id_type, verification_method }) => {
          const country = linkIdTypes.find(
            (id) => id.country_code == countryCode,
          );
          const idType = country.id_types.find((elem) => elem.code === id_type);
          const linkTypes = {
            country: country.country_name,
            country_code: countryCode,
            idTypeLabel: idType?.name || "",
            countryData: country,
            idType: id_type,
            verificationMethod: verification_method,
            verificationMethods: idType?.verification_methods || [],
          };

          return linkTypes;
        },
      );

      setFormFields({
        refId,
        companyName,
        callbackUrl,
        dataPrivacyPolicyUrl,
        idTypes: formattedIdTypes,
        name,
        disabledAt,
        isEditState: true,
        isSingleUse,
      });
      handleChange("previewImage", result.logo_url);
      handleChange("logoName", result.logo_url?.split("/")?.pop());
    });
  }, [location.search, idTypes]);

  const onContinue = () => {
    history.push(
      `settings${
        formFields.dataPrivacyPolicyUrl && formFields.companyName
          ? "?step=2"
          : ""
      }`,
    );
  };
  const onUpdate = () => {
    setLoading((current) => ({ ...current, submitting: true }));
    const isDisabled = formFields.disabledAt != null;
    const ids = formFields.idTypes.map((id) => ({
      country: id.country_code,
      id_type: id.idType,
      verification_method: id.verificationMethod,
    }));
    updateSmileLink(refId, { is_disabled: isDisabled, id_types: ids }).then(
      () => {
        setLoading((current) => ({ ...current, submitting: false }));
        Toast.fire({ title: "Link updated successfully" });
        history.push(`settings?refId=${refId}`);
      },
    );
  };

  const showErrorIfDuplicate = (id) => {
    const ids = selectedIds.filter(
      (currentId) =>
        currentId.idType === id.idType && currentId.country === id.country,
    );
    if (ids.length > 0) {
      setErrorMessage(
        "You can't have two verification methods for the same ID type in a single link",
      );
      return true;
    }

    return false;
  };
  const onIdAdded = (id) => {
    setShowNewIdModal(false);

    if (showErrorIfDuplicate(id)) return;
    handleChange("idTypes", [...selectedIds, id]);
  };
  const onIdUpdated = (newId, oldId) => {
    setShowNewIdModal(false);

    const id = selectedIds.find(
      (currentId) =>
        currentId.idType === oldId.idType &&
        currentId.country === oldId.country &&
        currentId.verificationMethod === oldId.verificationMethod,
    );
    const ids = [...selectedIds];
    ids[ids.indexOf(id)] = newId;
    handleChange("idTypes", ids);
  };

  const removeId = (id) => {
    const ids = selectedIds.filter(
      (currentId) =>
        !(
          currentId.idType === id.idType &&
          currentId.country === id.country &&
          currentId.verificationMethod === id.verificationMethod
        ),
    );

    handleChange("idTypes", ids);
  };

  const idCount = selectedIds.reduce(
    (reducer, value) => reducer.add(value.idType),
    new Set(),
  ).size;
  const countryCount = selectedIds.reduce(
    (reducer, value) => reducer.add(value.country),
    new Set(),
  ).size;

  if (loading.refreshing) {
    return (
      <div className={css("new-typography", styles.manageWebLinkWrapper)}>
        <div className={styles.manageHeader}>
          <PageHeader
            title="Add & Manage IDs"
            showBack
            onBackPressed={() => {
              resetData();
              history.goBack();
            }}
          />
        </div>
        <LinksLoading />
      </div>
    );
  }

  return (
    <div className={css("new-typography legacy", styles.manageWebLinkWrapper)}>
      <div className={styles.manageHeader}>
        <PageHeader
          title="Add & Manage IDs"
          showBack
          onBackPressed={() => {
            resetData();
            history.goBack();
          }}
        />
      </div>
      <div>
        <Table>
          <TableCaption>
            {idCount} ID{idCount > 1 ? "s" : ""} from {countryCount}{" "}
            {countryCount > 1 ? "Countries" : "Country"}{" "}
          </TableCaption>
          <TableHeader>
            <HeaderItem>#</HeaderItem>
            <HeaderItem>Country</HeaderItem>
            <HeaderItem>ID Type</HeaderItem>
            <HeaderItem>Verification Method</HeaderItem>
            <HeaderItem>Actions</HeaderItem>
          </TableHeader>
          <TableBody>
            {selectedIds.map((verificationId, index) => (
              <TableRow key={index}>
                <BodyItem>{index + 1}</BodyItem>
                <BodyItem>{verificationId.country}</BodyItem>
                <BodyItem>
                  <span style={{ textTransform: "capitalize" }}>
                    {verificationId.idTypeLabel}
                  </span>
                </BodyItem>
                <BodyItem>
                  <Chips>
                    <Chip
                      key={verificationId.verificationMethod}
                      label={
                        SMILE_PRODUCTS_MAP[verificationId.verificationMethod]
                          ?.name
                      }
                    />
                  </Chips>
                </BodyItem>
                <BodyItem>
                  <TableActions>
                    <TableAction
                      label="Edit"
                      icon={EditIcon}
                      onClick={() => {
                        setShowNewIdModal(true);
                        setModalState("update");
                        setSelectedId(verificationId);
                      }}
                    />
                    <TableAction
                      label="Remove"
                      labelClassName={styles.removeAction}
                      icon={RemoveIcon}
                      onClick={() => removeId(verificationId)}
                    />
                  </TableActions>
                </BodyItem>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {selectedIds.length > 0 && (
          <AddButton
            label="Add another ID"
            icon
            wrapperClassName={styles.contentCenter}
            onClick={() => setShowNewIdModal(true)}
          />
        )}
        {selectedIds.length === 0 && (
          <MediumCard
            containerClass={css("center", styles.addIdCard)}
            label="Start by adding all the ID types you want to verify with this Smile Link"
            buttonText="Add an ID"
            onClick={() => setShowNewIdModal(true)}
          />
        )}
        <NewIDModal
          isOpen={showNewIdModal}
          idTypes={idTypes}
          selectedId={selectedId}
          modalState={modalState}
          showBack={modalState !== "update"}
          onComplete={(newId) => {
            onIdAdded(newId);
          }}
          onUpdated={(newId, oldId) => {
            onIdUpdated(newId, oldId);
            setModalState();
          }}
          onClose={() => {
            setShowNewIdModal(false);
            setModalState();
            setSelectedId();
          }}
        />
        <ErrorModal
          error={errorMessage}
          titleClass={styles.errorClass}
          isOpen={errorMessage.length > 0}
          onClose={() => setErrorMessage("")}
        />
      </div>
      {selectedIds.length > 0 && !refId && (
        <StyledButton
          className={styles.continueButton}
          isActive
          onClick={onContinue}
        >
          Continue
        </StyledButton>
      )}
      {selectedIds.length > 0 && refId && (
        <StyledButton
          className={styles.continueButton}
          disabled={loading.submitting}
          isActive
          onClick={onUpdate}
        >
          Update
        </StyledButton>
      )}
    </div>
  );
}

function LinksLoading() {
  return (
    <div className="shimmer-wrapper legacy">
      <div
        className="animate-shimmer shimmer-text"
        style={{ width: "10rem", height: "1rem", marginBottom: "1rem" }}
      />
      {[...Array(5)].map((_, index) => (
        <div
          key={`shimmer-${index}`}
          className="flex"
          style={{ marginBottom: "2rem" }}
        >
          <div
            className="animate-shimmer shimmer-text"
            style={{ width: "5rem", height: "1rem", marginRight: "1rem" }}
          />
          <div
            className="animate-shimmer shimmer-text"
            style={{ width: "10rem", height: "1rem", marginRight: "1rem" }}
          />
          <div
            className="animate-shimmer shimmer-text"
            style={{ width: "10rem", height: "1rem", marginRight: "1rem" }}
          />
          <div
            className="animate-shimmer shimmer-text"
            style={{ width: "10rem", height: "1rem", marginRight: "1rem" }}
          />
          <div
            className="animate-shimmer shimmer-text"
            style={{ width: "10rem", height: "1rem", marginRight: "1rem" }}
          />
          <div
            className="animate-shimmer shimmer-text"
            style={{ width: "10rem", height: "1rem" }}
          />
        </div>
      ))}
      <div
        className="animate-shimmer shimmer-text"
        style={{ width: "90%", height: "1rem", marginBottom: "1rem" }}
      />
    </div>
  );
}
