import React, { useEffect, useState } from "react";
import { SMILE_PRODUCTS_MAP } from "util/constants";
import IdItem from "./id_item";
import InputLabel from "./InputLabel";
import ModalWrapper from "./ModalWrapper";
import styles from "./new_id_modal.module.css";
import TextInput from "./TextInput";
import VerificationMethod from "./verification_method";

export default function NewIDModal({
  idTypes,
  selectedId,
  modalState,
  onUpdated,
  onComplete,
  isOpen,
  onClose,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState({});
  const [countryFilter, setCountryFilter] = useState("");

  useEffect(() => {
    if (modalState) {
      setCurrentPage(3);
      setData(selectedId);
    } else {
      setCurrentPage(1);
    }
  }, [modalState, selectedId]);

  const next = () => {
    setCurrentPage((current) => {
      if (current === 3) {
        return 1;
      }
      return current + 1;
    });
  };
  const previous = () => {
    setCurrentPage((current) => {
      if (current === 1) {
        return 1;
      }
      return current - 1;
    });
  };

  const onFinish = (completeData) => {
    if (typeof onComplete === "function") {
      onComplete(completeData);
    }
    setCurrentPage(1);
  };
  const onEdit = (completeData) => {
    if (typeof onUpdated === "function") {
      onUpdated(completeData, selectedId);
    }
    setCurrentPage(1);
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      wrapperClassName={styles.modalWrapper}
      onClose={onClose}
      onBackPressed={previous}
      hideBackButton={currentPage === 1 || modalState === "update"}
      titleClass={styles.title}
      title="Add ID Type"
    >
      <div className={styles.container}>
        <div className="web-links-content">
          {currentPage === 1 && (
            <CountrySelectForm
              countries={idTypes}
              formData={[]}
              filter={countryFilter}
              onFilter={setCountryFilter}
              onClick={(countryData) => {
                setData((current) => ({
                  ...current,
                  countryData,
                  country: countryData.country_name,
                  country_code: countryData.country_code,
                }));
                next();
              }}
            />
          )}
          {currentPage === 2 && (
            <IdSelectForm
              idTypes={data.countryData?.id_types}
              formData={[data]}
              onClick={({
                name,
                code,
                verification_methods: verificationMethods,
              }) => {
                setData((current) => ({
                  ...current,
                  idType: code,
                  idTypeLabel: name,
                  verificationMethods,
                }));
                next();
              }}
            />
          )}
          {currentPage === 3 && (
            <VerificationMethodForm
              verificationMethods={data?.verificationMethods}
              onClick={(verificationMethod) => {
                const newData = {
                  ...data,
                  verificationMethod: verificationMethod.name,
                };
                if (modalState === "update") {
                  onEdit(newData);
                  return;
                }
                onFinish(newData);
              }}
            />
          )}
        </div>
      </div>
    </ModalWrapper>
  );
}

function CountrySelectForm({ countries, onClick, filter, onFilter }) {
  const handleFilterChange = (value) => {
    onFilter(value);
  };
  const filteredCountries = countries.filter(
    (country) =>
      country.country_code.toLowerCase().includes(filter.toLowerCase()) ||
      country.country_name.toLowerCase().includes(filter.toLowerCase()),
  );
  const onclick = (country) => {
    if (typeof onClick === "function") {
      onClick(country);
    }
  };

  if (!countries || countries.length === 0) {
    const shimmers = Array(5).fill("");
    return (
      <div className="shimmer-wrapper margin-top-30">
        <div
          className="animate-shimmer shimmer-text"
          style={{ width: "40%", height: "1.5rem" }}
        />
        <div
          className="animate-shimmer shimmer-text"
          style={{
            width: "100%",
            height: "2.5rem",
            marginTop: "1rem",
            marginBottom: "2rem",
          }}
        />
        {shimmers.map((_, index) => (
          <div
            key={index}
            className="animate-shimmer shimmer-text"
            style={{ width: "100%", height: "1.5rem", marginTop: "1rem" }}
          />
        ))}
      </div>
    );
  }

  return (
    <div className={styles.countrySelectForm}>
      <TextInput
        label="Select Country"
        placeholder="Type country to search"
        value={filter}
        onChange={handleFilterChange}
        hideIcon
      />

      <div>
        <div className={styles.modalBody}>
          {filteredCountries?.map((country) => (
            <div
              key={country.country_name}
              className={styles.country__item}
              onClick={() => onclick(country)}
            >
              {country.country_name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
function IdSelectForm({ idTypes = [], onClick }) {
  const onclick = (idType) => {
    if (typeof onClick === "function") {
      onClick(idType);
    }
  };

  return (
    <div>
      <InputLabel className={styles.idLabel} label="Select ID Type" />
      <div className={styles.modalBody}>
        {idTypes.map((idType) => (
          <IdItem
            key={idType.code}
            onClick={() => onclick(idType)}
            idType={idType}
          />
        ))}
      </div>
    </div>
  );
}
function VerificationMethodForm({ verificationMethods, onClick }) {
  const methods = verificationMethods?.map((method) => ({
    name: method,
    description: SMILE_PRODUCTS_MAP[method].long_description,
  }));
  return (
    <div>
      <InputLabel label="Select Verification Method" />
      <div className={styles.modalBody}>
        {methods.map((method) => (
          <VerificationMethod
            key={method.name}
            onClick={() => onClick(method)}
            verificationMethod={method}
          />
        ))}
      </div>
    </div>
  );
}
