import React from "react";
import BiometricIcon from "resources/img/icons/biometric-icon.svg";
import DocVIcon from "resources/img/icons/docv-icon.svg";
import EnhancedDocVIcon from "resources/img/icons/enhanced-docv-icon.svg";
import KycIcon from "resources/img/icons/kyc-icon.svg";
import { SMILE_PRODUCTS_MAP } from "util/constants";
import productStyles from "./product_card.module.css";

export default function VerificationMethod({ verificationMethod, onClick }) {
  const onclick = (method) => {
    if (typeof onClick === "function") {
      onClick(method);
    }
  };
  const icons = {
    biometric_kyc: BiometricIcon,
    doc_verification: DocVIcon,
    enhanced_document_verification: EnhancedDocVIcon,
    enhanced_kyc: KycIcon,
  };

  return (
    <div
      className={productStyles.itemWrapper}
      onClick={() => onclick(verificationMethod)}
    >
      <div className={productStyles.iconWrapper}>
        <img
          src={icons[verificationMethod.name] || DocVIcon}
          alt={verificationMethod.name}
        />
      </div>
      <div className={productStyles.body}>
        <div className={productStyles.title}>
          {SMILE_PRODUCTS_MAP[verificationMethod.name].name}
        </div>
        <div className={productStyles.description}>
          {SMILE_PRODUCTS_MAP[verificationMethod.name].long_description}
        </div>
        <a
          className={productStyles.learnMore}
          target="_blank"
          href={SMILE_PRODUCTS_MAP[verificationMethod.name].link}
          rel="noreferrer"
        >
          Learn more about this product{" "}
        </a>
      </div>
    </div>
  );
}
