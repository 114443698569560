import React from "react";
import styles from "./ripple-loader.module.css";

export function RippleLoader({ size }) {
  return (
    <div className={[styles.rippleLoader, styles[size] ?? ""].join(" ")}>
      <div />
      <div />
    </div>
  );
}
