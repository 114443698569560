import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,
);
ChartJS.defaults.scales.linear.min = 0;
ChartJS.defaults.font.family = "Nunito"; // , style: 'normal', weight: '600'};

export default function StackedBarChart({ data, yMax, legend }) {
  const options = {
    plugins: {
      datalabels: {
        display: false,
      },
      title: {
        display: false,
      },
      legend: {
        position: "bottom",
        align: "center",
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          font: {
            size: 12,
          },
        },
        padding: {
          left: 300,
        },
        ...legend,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
          drawTicks: false,
        },
        ticks: {
          align: "center",
          maxRotation: 0,
        },
        border: {
          display: false,
        },
      },
      y: {
        border: {
          display: false,
        },
        stacked: true,
        grid: {
          display: false,
          drawTicks: false,
          drawOnChartArea: false,
        },
        ticks: {
          callback: (value) => {
            if (value >= 1000000000 || value <= -1000000000) {
              return `${value / 1e9}B`;
            }
            if (value >= 1000000 || value <= -1000000) {
              return `${value / 1e6}M`;
            }
            if (value >= 1000 || value <= -1000) {
              return `${value / 1e3}K`;
            }
            return value;
          },
        },
        suggestedMax: yMax,
      },
    },
  };

  return <Bar options={options} data={data} height={270.85} width={456} />;
}
