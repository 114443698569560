import React, { useState, useEffect, useContext } from "react";
import * as Sentry from "@sentry/react";
import AreaChartShimmer from "components/reusable/shimmers/area_chart_shimmer";
import { AnalyticsContext } from "contexts/analytics_context";
import { fetchPartnerAnalytics } from "util/api_util";
import AnalyticsLegend from "./analytics_legend";
import ProductOverviewGraph from "./product_overview_graph";
import styles from "./product_overview_graph.module.css";

export default function ProductOverviewGraphs() {
  const [summaries, setSummaries] = useState([]);
  const [loading, setLoading] = useState(true);
  const { filters, partnerId } = useContext(AnalyticsContext);

  useEffect(() => {
    if (partnerId == null) {
      return;
    }
    const abortController = new AbortController();
    setLoading(true);
    fetchPartnerAnalytics(
      "/metrics/product_overview_analytics",
      partnerId,
      filters,
      abortController,
    )
      .then((response) => {
        setSummaries(response);
        setLoading(false);
      })
      .catch((e) => {
        if (e.name !== "AbortError") {
          console.error(e);
          Sentry.captureException(e);
        }
      });

    return () => abortController.abort();
  }, [partnerId, filters]);

  let overallTotal = 0;
  const result = summaries.reduce((reducer, current) => {
    overallTotal += parseInt(current.total_jobs, 10);
    reducer[current.product] = reducer[current.product] || [];
    reducer[current.product].push(current);
    return reducer;
  }, Object.create({}));

  const products = Object.keys(result).map((product) => (
    <ProductOverviewGraph
      key={product}
      overallTotal={overallTotal}
      product={product}
      data={result[product]}
    />
  ));
  if (loading) {
    const count = Array(4).fill("");
    return (
      <div className={`${styles.flex} margin-top-30`}>
        {count.map((_val, index) => (
          <AreaChartShimmer height="133px" width="133px" key={index} />
        ))}
      </div>
    );
  }

  return (
    <div>
      <AnalyticsLegend />
      <div className={styles.flex}>{products}</div>
    </div>
  );
}
