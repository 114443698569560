import React, { useEffect, useState } from "react";
import * as Tabs from "@radix-ui/react-tabs";
import AmlCheckLogo from "assets/svgs/aml-check.svg";
import BusinessVerificationLogo from "assets/svgs/business-verification.svg";
import Continue from "assets/svgs/continue.svg";
import DocumentVerificationLogo from "assets/svgs/document-verification.svg";
import DocVAuthenticity from "assets/svgs/docv-authenticity.svg";
import DocVFaceMatch from "assets/svgs/docv-face-match.svg";
import DocVFrontAndBack from "assets/svgs/docv-front-and-back.svg";
import { Loader } from "components/reusable/Loader";
import { fetchPartnerInfo as fetchPartnerInfoApi } from "util/api_util";
import { getAdminPartnerId } from "util/selectors";
import { EDV_IDS } from "./constants";

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const getPricingInfo = (productInfo) => {
  const result = {};

  let documentVerificationPrice;
  productInfo.forEach((product) => {
    if (product.computer_name === "aml_check") {
      result[product.name] = Math.min(
        ...Object.values(product.components).map(
          (component) => component.price,
        ),
      );
    } else if (product.computer_name === "doc_verification") {
      documentVerificationPrice =
        Math.min(
          ...Object.values(product.components).map((component) =>
            parseFloat(component.price),
          ),
        ) + parseFloat(product.regions.Africa.price);

      result[product.name] = documentVerificationPrice;
    } else if (product.computer_name === "authentication") {
      result.Others = {
        "User Authentication": product.components["2"].price,
        "Register a Selfie": product.components["4"].price,
      };
    } else if (product.computer_name === "business_verification") {
      result[product.name] = Object.entries(
        product.business_verification,
      ).reduce((accumulator, [countryName, value]) => {
        accumulator[countryName] = Object.entries(value).reduce(
          (idTypes, [idName, idValue]) => {
            const idLabel = idName
              .toLowerCase()
              .split("_")
              .map((w) => `${w[0].toUpperCase()}${w.substring(1)}`)
              .join(" ");

            idTypes[idLabel] =
              parseFloat(product.components["7"].price) +
              parseFloat(idValue.data_return.price);

            return idTypes;
          },
          {},
        );
        return accumulator;
      }, {});
    } else if (product.computer_name === "id_validation") {
      const output = {
        basic: {},
        enhanced: {},
        biometric: {},
        enhanced_doc_v: {},
      };

      const abbreviations = [
        "SSNIT",
        "ID",
        "BVN",
        "MFA",
        "TIN",
        "NIN",
        "KRA",
        "PIN",
      ];
      result["KYC Check"] = Object.entries(product.id_validation).reduce(
        (accumulator, [countryName, value]) => {
          if (value.included && !value.price) {
            accumulator.basic[countryName] = Object.entries(value).reduce(
              (idTypes, [idName, idValue]) => {
                if (idValue.included && idValue.verification.included) {
                  const idLabel = idName
                    .toLowerCase()
                    .split("_")
                    .map((w) =>
                      abbreviations.includes(w.toUpperCase())
                        ? w.toUpperCase()
                        : `${w[0].toUpperCase()}${w.substring(1)}`,
                    )
                    .join(" ");

                  idTypes[idLabel] =
                    parseFloat(product.components["5"].price) +
                    parseFloat(idValue.data_return.price);
                }

                return idTypes;
              },
              {},
            );

            accumulator.enhanced[countryName] = Object.entries(value).reduce(
              (idTypes, [idName, idValue]) => {
                if (idValue.included && idValue.data_return.included) {
                  const idLabel = idName
                    .toLowerCase()
                    .split("_")
                    .map((w) =>
                      abbreviations.includes(w.toUpperCase())
                        ? w.toUpperCase()
                        : `${w[0].toUpperCase()}${w.substring(1)}`,
                    )
                    .join(" ");

                  idTypes[idLabel] =
                    parseFloat(product.components["5"].price) +
                    parseFloat(idValue.data_return.price);
                }

                return idTypes;
              },
              {},
            );

            accumulator.biometric[countryName] = Object.entries(value).reduce(
              (idTypes, [idName, idValue]) => {
                if (
                  idValue.included &&
                  idValue.face_compare.included &&
                  idValue.data_return.included
                ) {
                  const idLabel = idName
                    .toLowerCase()
                    .split("_")
                    .map((w) =>
                      abbreviations.includes(w.toUpperCase())
                        ? w.toUpperCase()
                        : `${w[0].toUpperCase()}${w.substring(1)}`,
                    )
                    .join(" ");

                  idTypes[idLabel] =
                    parseFloat(idValue.face_compare.price) +
                    parseFloat(idValue.data_return.price);
                }

                return idTypes;
              },
              {},
            );

            accumulator.enhanced_doc_v[countryName] = Object.entries(
              value,
            ).reduce((idTypes, [idName, idValue]) => {
              if (
                idValue.included &&
                idValue.data_return.included &&
                EDV_IDS[countryName]?.includes(idName)
              ) {
                const idLabel = idName
                  .toLowerCase()
                  .split("_")
                  .map((w) =>
                    abbreviations.includes(w.toUpperCase())
                      ? w.toUpperCase()
                      : `${w[0].toUpperCase()}${w.substring(1)}`,
                  )
                  .join(" ");

                idTypes[idLabel] =
                  documentVerificationPrice +
                  parseFloat(idValue.data_return.price);
              }

              return idTypes;
            }, {});
          }

          return accumulator;
        },
        output,
      );
    }
  });

  return result;
};

function DocumentVerification({ price }) {
  return (
    <div className="split-pair pricing-info">
      <div>
        <p>Available in all markets</p>

        <p>
          <span className="prices" data-variant="block">
            {currencyFormatter.format(price.toString())}
          </span>
          <span data-variant="block">Per Query</span>
        </p>
      </div>

      <div className="stack">
        <div className="value-prop">
          <img alt="" src={DocumentVerificationLogo} />
          <div className="stack">
            <h2 className="font-body">Document Verification</h2>
            <p>
              Verify your user's identity using a government-issued ID document
              and a selfie.
            </p>
          </div>
        </div>

        <div className="stack value-prop-list" data-variant="small">
          <div className="value-prop">
            <img data-variant="small" alt="" src={DocVFrontAndBack} />
            <div className="stack">
              <p>Verify front and back of IDs</p>
            </div>
          </div>

          <div className="value-prop">
            <img data-variant="small" alt="" src={DocVAuthenticity} />
            <div className="stack">
              <p>Authenticity checks of documents</p>
            </div>
          </div>

          <div className="value-prop">
            <img data-variant="small" alt="" src={DocVFaceMatch} />
            <div className="stack">
              <p>Validate ID photo information with selfies</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function BusinessVerification({ price }) {
  return (
    <div className="split-pair pricing-info">
      <div className="stack">
        {Object.entries(price).map(([country, idTypes]) => (
          <table key={`busV-${country}`}>
            <thead>
              <tr>
                <th>{country}</th>
                <th>Fee</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(idTypes).map(([idType, cost]) => (
                <tr key={`busV-${country}-${idType}`}>
                  <td>{idType}</td>

                  <td className="prices">
                    {currencyFormatter.format(cost.toString())}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ))}
      </div>

      <div>
        <div className="value-prop">
          <img alt="" src={BusinessVerificationLogo} />
          <div className="stack">
            <h2>Business Verification</h2>
            <p>
              Verify business and beneficial owner information with real-time
              checks of government registration databases.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function AmlCheck({ price }) {
  return (
    <div className="split-pair pricing-info">
      <div>
        <p>Available in all markets</p>

        <p>
          <span className="prices" data-variant="block">
            {currencyFormatter.format(price.toString())}
          </span>
          <span data-variant="block">Per Query</span>
        </p>
      </div>

      <div>
        <div className="value-prop">
          <img alt="" src={AmlCheckLogo} />
          <div className="stack">
            <h2>AML Check</h2>
            <p>
              Screen new and existing users against over 1100 global and African
              sanctions list, PEP and adverse media watchlists.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function KycCheck({ price }) {
  return (
    <Tabs.Root defaultValue="basic" className="pricing-info">
      <Tabs.List data-variant="material">
        <Tabs.Trigger value="basic">Basic KYC</Tabs.Trigger>

        <Tabs.Trigger value="enhanced">Enhanced KYC</Tabs.Trigger>

        <Tabs.Trigger value="biometric">Biometric KYC</Tabs.Trigger>

        <Tabs.Trigger value="mixed">
          Enhanced Document Verification
        </Tabs.Trigger>
      </Tabs.List>

      <Tabs.Content value="basic">
        <p>Match personal information against official government records</p>

        <div className="country-tables">
          {Object.keys(price.basic)
            .sort()
            .map((country) => (
              <table key={`kycCheck-basic-${country}`}>
                <thead>
                  <tr>
                    <th>{country}</th>
                    <th>Fee</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(price.basic[country])
                    .sort()
                    .map((idType) => (
                      <tr key={`kycCheck-basic-${country}-${idType}`}>
                        <td>{idType}</td>

                        <td className="prices">
                          {currencyFormatter.format(
                            price.basic[country][idType].toString(),
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            ))}
        </div>
      </Tabs.Content>

      <Tabs.Content value="enhanced">
        <p>Retrieve personal information from official government records</p>

        <div className="country-tables">
          {Object.keys(price.enhanced)
            .sort()
            .map((country) => (
              <table key={`kycCheck-enhanced-${country}`}>
                <thead>
                  <tr>
                    <th>{country}</th>
                    <th>Fee</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(price.enhanced[country])
                    .sort()
                    .map((idType) => (
                      <tr key={`kycCheck-enhanced-${country}-${idType}`}>
                        <td>{idType}</td>

                        <td className="prices">
                          {currencyFormatter.format(
                            price.enhanced[country][idType].toString(),
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            ))}
        </div>
      </Tabs.Content>

      <Tabs.Content value="biometric">
        <p>
          Retrieve personal information from government records and match a
          selfie to official ID photo
        </p>

        <div className="country-tables">
          {Object.keys(price.biometric)
            .sort()
            .map((country) => (
              <table key={`kycCheck-biometric-${country}`}>
                <thead>
                  <tr>
                    <th>{country}</th>
                    <th>Fee</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(price.biometric[country])
                    .sort()
                    .map((idType) => (
                      <tr key={`kycCheck-biometric-${country}-${idType}`}>
                        <td>{idType}</td>

                        <td className="prices">
                          {currencyFormatter.format(
                            price.biometric[country][idType].toString(),
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            ))}
        </div>
      </Tabs.Content>

      <Tabs.Content value="mixed">
        <p>
          Combine document verification with government records and match
          selfies in a single check.
        </p>

        <div className="country-tables">
          {Object.keys(price.enhanced_doc_v)
            .sort()
            .map((country) =>
              Object.keys(price.enhanced_doc_v[country]).length ? (
                <table key={`kycCheck-enhanced-docv-${country}`}>
                  <thead>
                    <tr>
                      <th>{country}</th>
                      <th>Fee</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(price.enhanced_doc_v[country])
                      .sort()
                      .map((idType) => (
                        <tr key={`kycCheck-enhanced-docv-${country}-${idType}`}>
                          <td>{idType}</td>

                          <td className="prices">
                            {currencyFormatter.format(
                              price.enhanced_doc_v[country][idType].toString(),
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              ) : null,
            )}
        </div>
      </Tabs.Content>
    </Tabs.Root>
  );
}

function Others({ price }) {
  return (
    <div className="split-pair pricing-info">
      <table>
        <thead>
          <tr>
            <th>Service</th>
            <th>Fee</th>
          </tr>
        </thead>

        <tbody className="pricing-info">
          {Object.entries(price).map(([name, cost]) => (
            <tr key={name}>
              <td>{name}</td>

              <td className="prices">
                {currencyFormatter.format(cost.toString())}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function PricingInfo({
  fetchPartnerInfo = fetchPartnerInfoApi,
  pricingInfo: data,
}) {
  const adminPartnerId = getAdminPartnerId();
  const screens = ["loading", "loaded", "error"];
  const [screen, setScreen] = useState(screens[0]);
  const [pricingInfo, setPricingInfo] = useState(data);

  const fetchPricingInfo = async () => {
    const payload = {};
    if (adminPartnerId) {
      payload.partner = adminPartnerId;
    }

    const response = await fetchPartnerInfo(payload);

    if (response?.partner?.products) {
      const result = getPricingInfo(response?.partner?.products);
      setPricingInfo(result);
      setScreen("loaded");
    } else {
      setScreen("error");
    }
  };

  useEffect(() => {
    if (data) {
      setScreen("loaded");
    } else {
      fetchPricingInfo();
    }
  }, [data]);

  return {
    loading: <Loader />,
    loaded: (
      <Tabs.Root defaultValue="amlCheck">
        <Tabs.List data-variant="pills">
          <Tabs.Trigger value="amlCheck">AML Check</Tabs.Trigger>

          <Tabs.Trigger value="businessVerification">
            Business Verification
          </Tabs.Trigger>

          <Tabs.Trigger value="documentVerification">
            Document Verification
          </Tabs.Trigger>

          <Tabs.Trigger value="kycCheck">Government KYC Check</Tabs.Trigger>

          <Tabs.Trigger value="others">Others</Tabs.Trigger>
        </Tabs.List>

        <Tabs.Content value="amlCheck">
          <AmlCheck price={pricingInfo && pricingInfo["AML Check"]} />
        </Tabs.Content>

        <Tabs.Content value="businessVerification">
          <BusinessVerification
            price={pricingInfo && pricingInfo["Business Verification"]}
          />
        </Tabs.Content>

        <Tabs.Content value="documentVerification">
          <DocumentVerification
            price={pricingInfo && pricingInfo["Document Verification"]}
          />
        </Tabs.Content>

        <Tabs.Content value="kycCheck">
          <KycCheck price={pricingInfo && pricingInfo["KYC Check"]} />
        </Tabs.Content>

        <Tabs.Content value="others">
          <Others price={pricingInfo && pricingInfo.Others} />
        </Tabs.Content>
      </Tabs.Root>
    ),
    error: (
      <div className="stack">
        <p className="color-fail">Failed to load your pricing</p>

        <p>
          <button
            data-variant="primary"
            type="button"
            onClick={fetchPricingInfo}
          >
            <img src={Continue} alt="" />
            <span>Try again</span>
          </button>
        </p>
      </div>
    ),
  }[screen];
}

export default PricingInfo;
