import React from "react";
import styles from "./switch.module.css";

function SwitchButton({
  id,
  onColor,
  isOn,
  onChange,
  size,
  isDisabled = false,
  customStyles = {},
}) {
  const onCheckChange = (event) => {
    if (onChange) {
      onChange(event, event.target.checked);
    }
  };

  const toggleStyles = {
    ...(size ? { height: size * 0.4 } : {}),
    ...(size ? { width: size * 0.4 } : {}),
  };
  const containerStyles = {
    ...{ background: isOn && (onColor || "#2cc05c") },
    ...(size ? { height: size * 0.48 } : {}),
    ...(size ? { width: size } : {}),
    ...customStyles,
  };

  return (
    <>
      <input
        className={styles.switch_checkbox}
        id={id || "switch-new"}
        checked={isOn}
        type="checkbox"
        disabled={isDisabled}
        onChange={(event) => onCheckChange(event)}
      />
      <label
        style={containerStyles}
        className={styles.switch_label}
        htmlFor={id || "switch-new"}
      >
        <span className={styles.switch_button} style={toggleStyles} />
      </label>
    </>
  );
}

export default SwitchButton;
