import React, { useState, useEffect } from "react";
import { RippleLoader } from "components/partner/billing/ripple_loader";
import BubbleCancelIcon from "resources/img/icons/bubble-cancel.svg";
import BubbleCheckIcon from "resources/img/icons/bubble-check.svg";
import IconFile from "resources/img/icons/file.svg";
import RedXIcon from "resources/img/icons/x--red.svg";
import {
  fetchNINDetails,
  submitNINDetails,
  updateNINDetails,
} from "util/api_util";
import styles from "./nin-v2-form.module.css";

function NinV2Form({ onSubmitted }) {
  useEffect(() => {
    fetchNINDetails().then((res) => {
      // check number of directors saved previously and set the drirectors with nin dropdown
      if (res.director_2_user_id) {
        res.directors_with_nin = "2";
      } else {
        const directors_with_nin = [
          res.director_1_user_id,
          res.director_2_user_id,
        ].filter((id) => id);
        res.directors_with_nin =
          directors_with_nin.length == 0
            ? ""
            : directors_with_nin.length.toString();
      }
      setNin(res);
    });
  }, []);

  const [nin, setNin] = useState({
    articles_of_association: "",
    data_protection_policy: "",
    director_1_user_id: "",
    director_2_user_id: "",
    industry: "",
    itf_compliance_certificate: "",
    logo: "",
    office_address: "",
    tax_clearance_certificate: "",
    directors_with_nin: "",
  });

  const [fileSizeErrors, setFileSizeErrors] = useState({
    articles_of_association: false,
    data_protection_policy: false,
    logo: false,
    itf_compliance_certificate: false,
  });

  const [isSaving, setIsSaving] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mode, setMode] = useState("");

  const onInput = ({ target }) => {
    setNin({ ...nin, [target.name]: target.value });
  };

  const buildForm = () => {
    const formData = new FormData();
    for (const key in nin) {
      formData.set(key, nin[key]);
    }
    return formData;
  };

  const onSubmit = async (e) => {
    setMode("submit");
    setIsModalOpen(true);
    setIsSubmitting(true);
    e.preventDefault();
    const form = buildForm();
    await submitNINDetails(form);
    setIsSubmitting(false);
    onSubmitted();
  };
  const onSave = async (e) => {
    setMode("save");
    setIsModalOpen(true);
    setIsSaving(true);
    e.preventDefault();
    const form = buildForm();
    await updateNINDetails(form);
    setIsSaving(false);
  };

  const handleFile = ({ target }) => {
    const twoMB = 2 * 1024 * 1024;
    if (target.files[0].size > twoMB) {
      setFileSizeErrors({
        ...fileSizeErrors,
        [target.name]: true,
      });
      return;
    }

    setFileSizeErrors({
      ...fileSizeErrors,
      [target.name]: false,
    });

    setNin({ ...nin, [target.name]: target.files[0] });
  };

  const removeFile = (name) => () => {
    setNin({ ...nin, [name]: "" });
  };

  const fileSizeLimitMessage = "size should be less 2 MB";

  const hasFileSizeErrors = Object.values(fileSizeErrors).some(
    (error) => error,
  );
  return (
    <form onSubmit={onSubmit}>
      <div className={styles.container}>
        <p>
          You can automatically create an account on NIMC’s tokenization
          platform using Smile ID.
          <br />
          This enables you comply with NIMC’s recommended verification approach
          when performing KYC using Nigerian National ID Numbers (NINs).
        </p>
        <p>
          To complete the onboarding process, NIMC requires more information in
          addition to the details you provided us during the partner KYC
          process.
        </p>
        <br />
        <p>
          <span className={styles.red}>*</span> denotes required fields
        </p>

        <div className={styles.sectionHeader}>
          <h3>Company Details</h3>
          <hr />
        </div>
        <div className={styles.row}>
          <div>
            Office Address <span className={styles.red}>*</span>
          </div>
          <div>
            <input
              value={nin.office_address}
              name="office_address"
              onChange={onInput}
              required
            />
          </div>
        </div>

        <div className={styles.row}>
          <div>
            Industry <span className={styles.red}>*</span>
          </div>
          <div>
            <input
              value={nin.industry}
              name="industry"
              onChange={onInput}
              required
            />
          </div>
        </div>

        <div className={styles.row}>
          <div>
            Articles of association <span className={styles.red}>*</span>
            <div className={styles.pdfLabel}>
              (.pdf, {fileSizeLimitMessage})
            </div>
          </div>
          <div>
            <FileUpload
              name="articles_of_association"
              state={nin}
              onFileChange={handleFile}
              onRemoveFile={removeFile}
              accept="application/pdf"
              fileSizeErrors={fileSizeErrors}
              required
            />
          </div>
        </div>

        <div className={styles.row}>
          <div>
            Tax clearance certificate
            <div className={styles.pdfLabel}>
              (.pdf, {fileSizeLimitMessage})
            </div>
          </div>
          <div>
            <FileUpload
              name="tax_clearance_certificate"
              state={nin}
              onFileChange={handleFile}
              onRemoveFile={removeFile}
              fileSizeErrors={fileSizeErrors}
              accept="application/pdf"
            />
          </div>
        </div>

        <div className={styles.row}>
          <div>
            ITF compliance certificate
            <div className={styles.pdfLabel}>
              (.pdf, {fileSizeLimitMessage})
            </div>
          </div>
          <div>
            <FileUpload
              name="itf_compliance_certificate"
              state={nin}
              onFileChange={handleFile}
              onRemoveFile={removeFile}
              fileSizeErrors={fileSizeErrors}
              accept="application/pdf"
            />
          </div>
        </div>

        <div className={styles.row}>
          <div>
            Data protection policy <span className={styles.red}>*</span>
            <div className={styles.pdfLabel}>
              (.pdf, {fileSizeLimitMessage})
            </div>
          </div>
          <div>
            <FileUpload
              name="data_protection_policy"
              state={nin}
              onFileChange={handleFile}
              onRemoveFile={removeFile}
              accept="application/pdf"
              fileSizeErrors={fileSizeErrors}
              required
            />
          </div>
        </div>

        <div className={styles.row}>
          <div>
            Logo <span className={styles.red}>*</span>
            <div className={styles.pdfLabel}>
              (.png, {fileSizeLimitMessage})
            </div>
          </div>
          <div>
            <FileUpload
              state={nin}
              onFileChange={handleFile}
              onRemoveFile={removeFile}
              name="logo"
              accept="image/png"
              fileSizeErrors={fileSizeErrors}
              required
            />
          </div>
        </div>
        <div className={styles.sectionHeader}>
          <h3>Director User IDs</h3>
          <hr />
        </div>
        <div className={styles.row}>
          <div>
            How many of your directors have a Nigerian NIN?{" "}
            <span className={styles.red}>*</span>
          </div>
          <div>
            <select
              className="input"
              value={nin.directors_with_nin || ""}
              name="directors_with_nin"
              onChange={onInput}
              required
            >
              <option value="">Select an option</option>
              <option value="0">None</option>
              <option value="1">1</option>
              <option value="2">2 or more</option>
            </select>
            {!nin.directors_with_nin && (
              <div className={styles.errorMessage}>Please select an option</div>
            )}
          </div>
        </div>
        {["1", "2"].includes(nin.directors_with_nin) && (
          <p>
            The user ID of {nin.directors_with_nin} director(s) in your
            organization. The user ID is generated using NIMC’s mobile ID app
            available on the{" "}
            <a href="https://apps.apple.com/us/app/mws-nimc-personal-id/id1500621463">
              Appstore
            </a>{" "}
            and{" "}
            <a href="https://play.google.com/store/apps/details?id=com.nimcmobile">
              Playstore.
            </a>
          </p>
        )}
        {["1", "2"].includes(nin.directors_with_nin) && (
          <div className={styles.row}>
            <div>
              {nin.directors_with_nin === "1" ? "Director's" : "Director 1's"}{" "}
              user ID <span className={styles.red}>*</span>
            </div>
            <div>
              <input
                value={nin.director_1_user_id}
                name="director_1_user_id"
                onChange={onInput}
                required
              />
              {!nin.director_1_user_id && (
                <div className={styles.errorMessage}>
                  Please enter{" "}
                  {nin.directors_with_nin === "1"
                    ? "Director's"
                    : "Director 1's"}{" "}
                  user ID
                </div>
              )}
            </div>
          </div>
        )}
        {nin.directors_with_nin == "2" && (
          <div className={styles.row}>
            <div>
              Director 2's user ID <span className={styles.red}>*</span>
            </div>
            <div>
              <input
                value={nin.director_2_user_id}
                name="director_2_user_id"
                onChange={onInput}
                required
              />
              {!nin.director_2_user_id && (
                <div className={styles.errorMessage}>
                  Please enter Director 2's user ID
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {isModalOpen && (
        <PleaseWaitModal
          loading={isSaving || isSubmitting}
          setIsModalOpen={setIsModalOpen}
          mode={mode}
        />
      )}
      <footer>
        <button
          className={styles.saveButton}
          onClick={onSave}
          disabled={isSaving}
        >
          {isSaving ? "Saving ..." : "Save"}
        </button>
        <button type="submit" disabled={hasFileSizeErrors || isSubmitting}>
          {isSubmitting ? "Submitting ..." : "Submit"}
        </button>
      </footer>
    </form>
  );
}

function FileUpload({
  name,
  state,
  accept,
  onRemoveFile,
  onFileChange,
  fileSizeErrors,
  required,
}) {
  const isOverSizeLimit = fileSizeErrors[name];
  return (
    <DisplayUpload
      name={name}
      state={state}
      inputComponent={
        <div>
          <input
            type="file"
            accept={accept}
            name={name}
            onChange={onFileChange}
            required={required}
          />
          {isOverSizeLimit && (
            <div className={styles.overSizeLimit}>
              The file needs to be less than 2 MB.
            </div>
          )}
        </div>
      }
      onRemoveFile={onRemoveFile}
    />
  );
}

function DisplayUpload({ state, name, onRemoveFile, inputComponent }) {
  if (state[name] && !state[name].size) {
    return (
      <div className={styles.uploadedFile}>
        <a href={state[name].link}>
          <img src={IconFile} alt="file icon" />
          <span>{state[name].name}</span>
        </a>
        <button aria-label={`remove ${name} doc`} onClick={onRemoveFile(name)}>
          <img alt="remove" src={RedXIcon} />
        </button>
      </div>
    );
  }
  return inputComponent;
}

function PleaseWaitModal({ loading, error, setIsModalOpen, mode }) {
  // auto close modal after 1 seconds
  if (!loading) {
    setTimeout(() => {
      setIsModalOpen(false);
    }, 1000);
  }

  return (
    <div className="modal-backdrop">
      <div className="smile-modal modal-style filter-modal center">
        {loading ? (
          <>
            <h3>Please wait while we process your request</h3>
            <RippleLoader />
          </>
        ) : (
          <>
            <h3>
              Request {mode === "save" ? "Saved" : "Submitted"} successfully
            </h3>
            <img
              className="icon--x-large"
              src={error ? BubbleCancelIcon : BubbleCheckIcon}
              alt="close-icon"
            />
          </>
        )}
      </div>
    </div>
  );
}

export default NinV2Form;
