import React from "react";
import { jwtDecode } from "jwt-decode";
import get from "lodash/get";
import moment from "moment";
import { Link } from "react-router-dom";
import { JobTypes } from "./constants";

const jobTypeToNameMap = Object.freeze({
  1: "Biometric KYC",
  2: "SmartSelfie™ Auth",
  3: "SmartSelfie™ Compare",
  4: "User Registration",
  5: "Enhanced KYC",
  6: "Document Verification",
  7: "Business Verification",
  8: "Update Selfie",
  9: "Compare User Info",
  10: "AML",
  11: "Enhanced Document Verification",
  12: "Electronic Signature",
  13: "Phone Number Verification",
  14: "Address Verification",
});

/**
 * Jobs that have zip files associated with them. These go through
 * prep-upload and unzipper lambda functions.
 * @type {number[]}
 */
export const biometricJobTypes = Object.freeze([1, 2, 4, 6, 8, 11]);

export const jobTypeToProductNameMap = (() => {
  const nameMap = Object.fromEntries(
    Object.entries(jobTypeToNameMap).map(([key, value]) => [
      `jt_${key}`,
      value,
    ]),
  );
  nameMap.jt_5_iv = "Basic KYC";
  nameMap.jt_5_e_kyc = "Enhanced KYC";
  return Object.freeze(nameMap);
})();

// TODO: clean up this file to deal with a specific use case (like products)

// helper function to inject button into table
export const createButton = (title, url, table, disabledFlag = false) => {
  let buttonStyle = "btn";
  if (table === "companies" || table === "jobs") {
    buttonStyle += " btn-white";
    return (
      <Link className="transparent-link" to={url}>
        <button className={buttonStyle}>{title}</button>
      </Link>
    );
  }
  buttonStyle += " btn-default btn-xs";
  return (
    <Link className={buttonStyle} disabled={disabledFlag} to={url}>
      {title}
    </Link>
  );
};

// used for dynamic filtering
export const categoryMap = {
  gender: "details",
  make: "phone",
  model: "phone",
  age: "details",
  first_name: "details",
};

export const dynamicSort = (property) => {
  let sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  if (/make/g.exec(property) || /first_name/g.exec(property)) {
    return (a, b) => {
      const result =
        a[categoryMap[property]][property] < b[categoryMap[property]][property]
          ? -1
          : a[categoryMap[property]][property] >
              b[categoryMap[property]][property]
            ? 1
            : 0;
      return result * sortOrder;
    };
  }
  return (a, b) => {
    const result =
      get(a, property) < get(b, property)
        ? -1
        : get(a, property) > get(b, property)
          ? 1
          : 0;
    return result * sortOrder;
  };
};

export const jobTypeLookup = (jobType) => {
  if (jobTypeToProductNameMap[jobType]) {
    return jobTypeToProductNameMap[jobType];
  }
  if (jobType) {
    return jobTypeToNameMap[jobType];
  }
};

// NOTE: use entered in info.json, or the target will contain the original SID_IDCard or just follow the action like Selfie_To_ID_Card_Compare
export const isEnrollWithIDCardImage = (
  jobType,
  { entered = "", url = "", action = "Not Applicable" } = {},
) =>
  parseInt(jobType, 10) === 1 &&
  (url?.includes("SID_IDCard") ||
    action !== "Not Applicable" ||
    entered === "false");

export const constructJobInfoPayload = (job, resultCodes) => {
  // TODO: migrate to BE
  const constructPayload = {};
  if (resultCodes[job?.result_code]) {
    constructPayload.job_code = job.result_code;
    constructPayload.job_type_number = setJobTypeNumber(job);
    constructPayload.job_result = resultCodes[job.result_code].result;
    constructPayload.job_type_number = setJobTypeNumber(job);
    // this is a hack. Because '0812' means provisional approval under review to other
    // job types we need to check it here and overwrite where it means rejected.
    // Once business logic lived in lambda and we no longer have need of provisionally
    // approved results we can axe this check.
    if (
      job.result_code === "0812" &&
      [
        JobTypes.DOCUMENT_VERIFICATION,
        JobTypes.ENHANCED_DOCUMENT_VERIFICATION,
      ].includes(constructPayload.job_type_number)
    ) {
      constructPayload.job_result = "Rejected";
    }

    constructPayload.job_message =
      job.result_message || resultCodes[job.result_code].message;
    constructPayload.job_type =
      resultCodes[job.result_code].type || setJobType(job);
    if (resultCodes[job.result_code].type === "Any") {
      constructPayload.job_type = setJobType(job);
    }
  } else {
    const asyncJob = job.source.includes("async");
    // within the last 12 hours
    const notRecent =
      Date.now() - new Date(job.job_created_at) < 1000 * 60 * 60 * 12;
    constructPayload.job_type = setJobType(job);
    constructPayload.job_type_number = setJobTypeNumber(job);
    constructPayload.job_result = "Processing";
    if (!job.sr_count && !(asyncJob && notRecent)) {
      if (
        constructPayload.job_type_number &&
        constructPayload.job_type_number.toString() === "5"
      ) {
        constructPayload.job_result = "Could not complete";
        constructPayload.job_message = "";
      } else {
        constructPayload.job_message = "Waiting for zip file";
      }
    }
  }

  return constructPayload;
};

export const setJobType = (job) => {
  if (job.ran_as_job_type) {
    return jobTypeLookup(job.ran_as_job_type);
  }
  if (job.partner_params) {
    return jobTypeLookup(job.partner_params.job_type);
    // TODO: once we remove classifyJob we can remove the job.result['PartnerParams']
  }
  if (job.result.PartnerParams) {
    return jobTypeLookup(job.result.PartnerParams.job_type);
  }
};

export const setJobTypeNumber = (job) =>
  job.ran_as_job_type ||
  (job.partner_params && job.partner_params.job_type) ||
  (job.result && job.result.PartnerParams && job.result.PartnerParams.job_type);

export const formatDashboardJobs = (jobs, resultCodes) => {
  const clonedJobsPayload = [];

  try {
    for (let i = 0; i < jobs.length; i += 1) {
      const job = jobs[i];

      const jobInfo = constructJobInfoPayload(job, resultCodes);

      let enrollee;
      if (job.user_id) {
        enrollee = job.user_id;
      } else if (!job.enrollee) {
        if (!job.partner_params.user_id) {
          enrollee = "No partner_uid on file";
        } else {
          enrollee = job.partner_params.user_id;
        }
      } else {
        enrollee = job.partner_uid;
      }

      let created_at;
      let duration;
      if (job.final_result_created_at && job.sr_count && !job.pii_deleted_at) {
        created_at = new Date(job.final_result_created_at);
        duration =
          (Date.parse(job.final_result_created_at) -
            Date.parse(job.job_created_at)) /
          1000;
      } else {
        created_at = new Date(job.job_created_at);
        duration = "";
      }

      /**
       * Constructs full name from job user details.
       * @param {object} job - Job object  from db
       * @param {string} job.first_name - First name
       * @param {string} job.last_name - Last name
       * @param {string} job.name - Full name (if first and last name are not provided)
       * @returns {string} - Fullname
       */
      const getFullName = ({
        first_name: firstName,
        last_name: lastName,
        name,
      }) => {
        if (firstName === "Provided" || firstName === "Not Provided") {
          return firstName;
        }
        if (firstName) {
          return `${firstName || ""} ${lastName || ""}`;
        }
        return name || "";
      };

      let jobPayload = {
        id: job.id,
        smile_job_id: job.smile_job_id,
        id_type: job.id_type,
        country: job.country,
        enrollee_id: enrollee,
        created_at,
        duration,
        sdk: job.sdk,
        full_name: getFullName(job),
        product: jobTypeToProductNameMap[job.product_type],
      };
      jobPayload = { ...jobPayload, ...jobInfo };
      clonedJobsPayload.push(jobPayload);
    }
  } catch (e) {
    console.error(e);
  }

  return clonedJobsPayload;
};

export const formatPartnerInfo = (partnerInfo) => {
  if (!partnerInfo) {
    return {};
  }
  const info = partnerInfo.partner;
  info.partner_name = info.company.name;
  return info;
};

export const formatDuration = (dur) => {
  if (dur === "") {
    return "-";
  }

  const duration = moment.duration(dur, "seconds");
  let format = "";

  if (duration.hours() > 0) {
    let hours = 0;
    if (duration.days() > 0) {
      hours = duration.days() * 24;
    }
    format += `${hours + duration.hours()}h `;
  }

  if (duration.minutes() > 0) {
    format += `${duration.minutes()}m `;
  }

  format += `${duration.seconds()}s`;
  return format;
};

export const calculateDuration = (from, to) => {
  if (from && to) {
    const momentTo = moment(to);
    const momentFrom = moment(from);
    return formatDuration(momentTo.diff(momentFrom) / 1000);
  }
};

export const semverSort3 = (versions) => {
  const obj = {
    android: [],
    legacy: [],
    ios: [],
  };
  const sortedArray = semverSort(versions);
  sortedArray.forEach((link) => {
    if (link.match("/Legacy/") != null) {
      obj.legacy.push(link);
    } else if (link.match("/IOS/") != null) {
      obj.ios.push(link);
    } else {
      obj.android.push(link);
    }
  });

  return obj;
};

export const semverSort = (versions) => {
  const compare = (a, b) => {
    if (a < b) {
      return 1;
    }
    if (a === b) {
      return 0;
    }
    return -1;
  };

  const getSemver = (url) => {
    const keys = url.split("/");
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      if (key.match(/[0-9]+\.[0-9]+\.[0-9]+/)) {
        return key;
      }
    }
  };

  return versions
    .sort((a, b) =>
      compare(
        parseInt(getSemver(a).split(".")[2], 10),
        parseInt(getSemver(b).split(".")[2], 10),
      ),
    )
    .sort((a, b) =>
      compare(
        parseInt(getSemver(a).split(".")[1], 10),
        parseInt(getSemver(b).split(".")[1], 10),
      ),
    )
    .sort((a, b) =>
      compare(
        parseInt(getSemver(a).split(".")[0], 10),
        parseInt(getSemver(b).split(".")[0], 10),
      ),
    );
};

export const getCurrentUser = () =>
  localStorage.token ? jwtDecode(localStorage.token) : undefined;

export const getUserType = () => {
  const user = localStorage.token ? jwtDecode(localStorage.token) : undefined;
  let userType;
  if (user) {
    userType = user.type;
  }
  return userType;
};

export const getUserPartnerID = () => {
  const user = localStorage.token ? jwtDecode(localStorage.token) : undefined;
  let partnerID;
  if (user) {
    partnerID = user.partner_id;
  }
  return partnerID;
};

export const setAdminPartnerId = (value) => {
  localStorage.setItem("adminPartnerId", value);
};

export const getAdminPartnerId = () => localStorage.adminPartnerId;

export const setShowNewAnalytics = (value) => {
  localStorage.setItem("showNewAnalytics", value);
};

// showing the new analytics by default
export const getShowNewAnalytics = () =>
  localStorage.showNewAnalytics !== "false";

export const renderJobTypeOptions = () =>
  getJobTypes().map(({ label, value }) => (
    <option key={value} value={value}>
      {" "}
      {label}
    </option>
  ));

export const getJobTypes = () =>
  [
    "jt_1",
    "jt_2",
    "jt_3",
    "jt_4",
    "jt_5_iv",
    "jt_5_e_kyc",
    "jt_6",
    "jt_7",
    "jt_8",
    "jt_9",
    "jt_10",
    "jt_11",
    "jt_12",
    "jt_13",
    "jt_14",
  ].map((jobType) => ({ label: jobTypeLookup(jobType), value: jobType }));
