import React from "react";
import companyInfoIcon from "resources/img/icons/kyb/kyb-company-info.svg";
import directorsIcon from "resources/img/icons/kyb/kyb-directors.svg";
import documentsIcon from "resources/img/icons/kyb/kyb-documents.svg";
import fiduciaryIcon from "resources/img/icons/kyb/kyb-fiduciaries.svg";
import proprietorIcon from "resources/img/icons/kyb/kyb-proprietor.svg";
import shareholdersIcon from "resources/img/icons/kyb/kyb-shareholders.svg";
import { formatLocalisedDate } from "util/format_helpers";

function CompanyInfo({ info }) {
  return (
    <details>
      <summary>
        <div className="cluster">
          <img src={companyInfoIcon} alt="" />
          <p>Company Information</p>
        </div>
      </summary>

      <div className="box">
        <div className="job-field__container">
          <div className="job-field__label">Name:</div>
          <div className="job-field__value job-field__value-bold">
            {info.legal_name}
          </div>
        </div>

        <div className="job-field__container">
          <div className="job-field__label">Registration Number</div>
          <div className="job-field__value">
            {info.registration_number || "N/A"}
          </div>
        </div>

        <div className="job-field__container">
          <div className="job-field__label">Tax Id:</div>
          <div className="job-field__value">{info.tax_id || "N/A"}</div>
        </div>

        <div className="job-field__container">
          <div className="job-field__label">Country:</div>
          <div className="job-field__value">{info.country}</div>
        </div>

        <div className="job-field__container">
          <div className="job-field__label">Address:</div>
          <div className="job-field__value">{info.address || "N/A"}</div>
        </div>

        <div className="job-field__container">
          <div className="job-field__label">Company Type:</div>
          <div className="job-field__value">{info.company_type || "N/A"}</div>
        </div>

        <div className="job-field__container">
          <div className="job-field__label">Registration Date:</div>
          <div className="job-field__value">
            {formatLocalisedDate(info.registration_date, "DD MMM YYYY") ||
              "N/A"}
          </div>
        </div>

        <div className="job-field__container">
          <div className="job-field__label">State:</div>
          <div className="job-field__value">{info.state}</div>
        </div>

        <div className="job-field__container">
          <div className="job-field__label">Status:</div>
          <div className="job-field__value">{info.status}</div>
        </div>

        <div className="job-field__container">
          <div className="job-field__label">Email:</div>
          <div className="job-field__value">{info.email}</div>
        </div>

        <div className="job-field__container">
          <div className="job-field__label">Phone:</div>
          <div className="job-field__value">{info.phone}</div>
        </div>

        <div className="job-field__container">
          <div className="job-field__label">Authorized Share Capital:</div>
          <div className="job-field__value">
            {new Intl.NumberFormat().format(info.authorized_shared_capital)}
          </div>
        </div>

        <div className="job-field__container">
          <div className="job-field__label">
            Authorized Share Capital Breakdown:
          </div>
          <div className="job-field__value">
            {info.authorized_shared_capital_breakdown?.map((share, index) => (
              <div className="share-breakdown" key={index}>
                <div className="share-type">Type: {share.type}</div>
                <div className="share-details">
                  <div className="share-num">
                    Number of Shares:{" "}
                    {new Intl.NumberFormat().format(share.number_of_shares)}
                  </div>
                  <div className="share-nominal">
                    Nominal Value:{" "}
                    {new Intl.NumberFormat().format(share.nominal_value)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </details>
  );
}

function Director({ director }) {
  return (
    <div className="box | card">
      <div className="job-field__container">
        <div className="job-field__label">Name:</div>
        <div className="job-field__value job-field__value-bold">
          {director.name}
        </div>
      </div>
      <div className="job-field__container">
        <div className="job-field__label">Gender:</div>
        <div className="job-field__value">{director.gender || "N/A"}</div>
      </div>
      <div className="job-field__container">
        <div className="job-field__label">Date of Birth:</div>
        <div className="job-field__value">
          {formatLocalisedDate(director.date_of_birth, "DD MMM YYYY") || "N/A"}
        </div>
      </div>
      <div className="job-field__container">
        <div className="job-field__label">Address:</div>
        <div className="job-field__value">{director.address}</div>
      </div>
      <div className="job-field__container">
        <div className="job-field__label">Id Number:</div>
        <div className="job-field__value">{director.id_number || "N/A"}</div>
      </div>
      <div className="job-field__container">
        <div className="job-field__label">Phone Number:</div>
        <div className="job-field__value">{director.phone_number || "N/A"}</div>
      </div>
      <div className="job-field__container">
        <div className="job-field__label">Nationality:</div>
        <div className="job-field__value">{director.nationality || "N/A"}</div>
      </div>
      <div className="job-field__container">
        <div className="job-field__label">Occupation:</div>
        <div className="job-field__value">{director.occupation || "N/A"}</div>
      </div>
    </div>
  );
}

function Directors({ directors }) {
  return (
    <details>
      <summary>
        <div className="cluster">
          <img src={directorsIcon} alt="" />
          <p>Directors</p>
        </div>
      </summary>

      {directors.map((director, index) => (
        <Director key={index} director={director} />
      ))}
    </details>
  );
}

function Proprietor({ proprietor }) {
  return (
    <div className="box | card">
      <div className="job-field__container">
        <div className="job-field__label">Name:</div>
        <div className="job-field__value job-field__value-bold">
          {proprietor.name}
        </div>
      </div>
      <div className="job-field__container">
        <div className="job-field__label">Gender:</div>
        <div className="job-field__value">{proprietor.gender || "N/A"}</div>
      </div>
      <div className="job-field__container">
        <div className="job-field__label">Date of Birth:</div>
        <div className="job-field__value">
          {formatLocalisedDate(proprietor.date_of_birth, "DD MMM YYYY") ||
            "N/A"}
        </div>
      </div>
      <div className="job-field__container">
        <div className="job-field__label">ID Type:</div>
        <div className="job-field__value">{proprietor.id_type || "N/A"}</div>
      </div>
      <div className="job-field__container">
        <div className="job-field__label">ID Number:</div>
        <div className="job-field__value">{proprietor.id_number || "N/A"}</div>
      </div>
      <div className="job-field__container">
        <div className="job-field__label">Address:</div>
        <div className="job-field__value">{proprietor.address || "NA"}</div>
      </div>
      <div className="job-field__container">
        <div className="job-field__label">Phone Number:</div>
        <div className="job-field__value">
          {proprietor.phone_number || "N/A"}
        </div>
      </div>
      <div className="job-field__container">
        <div className="job-field__label">Nationality:</div>
        <div className="job-field__value">
          {proprietor.nationality || "N/A"}
        </div>
      </div>
      <div className="job-field__container">
        <div className="job-field__label">Occupation:</div>
        <div className="job-field__value">{proprietor.occupation || "N/A"}</div>
      </div>
    </div>
  );
}

function Proprietors({ proprietors }) {
  return (
    <details>
      <summary>
        <div className="cluster">
          <img src={proprietorIcon} alt="" />
          <p>Proprietors</p>
        </div>
      </summary>

      {proprietors.map((proprietor, index) => (
        <Proprietor key={index} proprietor={proprietor} />
      ))}
    </details>
  );
}

function Fiduciary({ fiduciary }) {
  return (
    <div className="box | card">
      <div className="job-field__container">
        <div className="job-field__label">Name:</div>
        <div className="job-field__value job-field__value-bold">
          {fiduciary.name}
        </div>
      </div>
      <div className="job-field__container">
        <div className="job-field__label">Address:</div>
        <div className="job-field__value">{fiduciary.address || "N/A"}</div>
      </div>
      <div className="job-field__container">
        <div className="job-field__label">Fiduciary Type:</div>
        <div className="job-field__value">
          {fiduciary.fiduciary_type || "N/A"}
        </div>
      </div>
      <div className="job-field__container">
        <div className="job-field__label">Registration Number:</div>
        <div className="job-field__value">{fiduciary.registration_number}</div>
      </div>
      <div className="job-field__container">
        <div className="job-field__label">Status:</div>
        <div className="job-field__value">{fiduciary.status || "N/A"}</div>
      </div>
    </div>
  );
}

function Fiduciaries({ fiduciaries }) {
  return (
    <details>
      <summary>
        <div className="cluster">
          <img src={fiduciaryIcon} alt="" />
          <p>Fiduciaries</p>
        </div>
      </summary>

      {fiduciaries.map((fiduciary, index) => (
        <Fiduciary key={index} fiduciary={fiduciary} />
      ))}
    </details>
  );
}

function ShareHolder({ owner }) {
  return (
    <div className="box | card">
      <div className="job-field__container">
        <div className="job-field__label">Name:</div>
        <div className="job-field__value job-field__value-bold">
          {owner.name}
        </div>
      </div>
      <div className="job-field__container">
        <div className="job-field__label">ShareHolder Type:</div>
        <div className="job-field__value">
          {owner.shareholder_type || "N/A"}
        </div>
      </div>
      <div className="job-field__container">
        <div className="job-field__label">ShareHoldings:</div>
        <div className="job-field__value">
          {new Intl.NumberFormat().format(owner.shareholdings) || "N/A"}
        </div>
      </div>
      <div className="job-field__container">
        <div className="job-field__label">ShareHoldings Breakdown:</div>
        <div className="job-field__value">
          {owner.shareholding_breakdown?.map((share, index) => (
            <div className="share-breakdown" key={index}>
              <div className="share-type">Type: {share.type}</div>
              <div className="share-details">
                <div className="share-num">
                  Number of Shares:{" "}
                  {new Intl.NumberFormat().format(share.number_of_shares)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="job-field__container">
        <div className="job-field__label">Address:</div>
        <div className="job-field__value">{owner.address}</div>
      </div>
      <div className="job-field__container">
        <div className="job-field__label">Gender:</div>
        <div className="job-field__value">{owner.gender || "N/A"}</div>
      </div>
      <div className="job-field__container">
        <div className="job-field__label">Phone Number:</div>
        <div className="job-field__value">{owner.phone_number || "N/A"}</div>
      </div>
      <div className="job-field__container">
        <div className="job-field__label">Registration Number:</div>
        <div className="job-field__value">
          {owner.registration_number || "N/A"}
        </div>
      </div>
      <div className="job-field__container">
        <div className="job-field__label">Nationality:</div>
        <div className="job-field__value">{owner.nationality || "N/A"}</div>
      </div>
    </div>
  );
}

function ShareHolders({ owners }) {
  return (
    <details>
      <summary>
        <div className="cluster">
          <img src={shareholdersIcon} alt="" />
          <p>Shareholders</p>
        </div>
      </summary>

      {owners.map((owner, index) => (
        <ShareHolder key={index} owner={owner} />
      ))}
    </details>
  );
}

function Documents({ documents }) {
  return (
    <details>
      <summary>
        <div className="cluster">
          <img src={documentsIcon} alt="" />
          <p>Documents</p>
        </div>
      </summary>

      {Object.entries(documents).map((document, index) => (
        <a key={index} href={document[1]} download>
          {document[0]}
        </a>
      ))}
    </details>
  );
}

function BusinessVerificationInfo(props) {
  const {
    company_information: companyInformation,
    beneficial_owners: beneficialOwners,
    directors,
    documents,
    fiduciaries,
    proprietors,
  } = props.payload;

  return (
    <div className="stack | business-verification-info">
      <CompanyInfo info={companyInformation} />

      {directors.length > 0 && <Directors directors={directors} />}

      {proprietors.length > 0 && <Proprietors proprietors={proprietors} />}

      {beneficialOwners.length > 0 && (
        <ShareHolders owners={beneficialOwners} />
      )}

      {fiduciaries.length > 0 && <Fiduciaries fiduciaries={fiduciaries} />}

      {Object.keys(documents).length > 0 && <Documents documents={documents} />}
    </div>
  );
}

export default BusinessVerificationInfo;
