import React from "react";
import { css } from "util/format_helpers";
import InputLabel from "./InputLabel";
import styles from "./text_input.module.css";

export default function TextInput({
  value,
  label,
  name,
  type,
  icon,
  placeholder,
  wrapperClassName,
  inputClassName,
  onChange,
  optional,
  disabled,
  toolTip,
  error,
  dataTestId,
}) {
  const onTextChange = (event) => {
    if (typeof onChange === "function") {
      onChange(event.target.value, event);
    }
  };

  return (
    <div className={wrapperClassName}>
      <div className={styles.container}>
        <div className={styles.inputWrapper}>
          {label && (
            <div className={css("flex", styles.labelWrapper)}>
              <InputLabel label={label} icon={icon} toolTip={toolTip} />{" "}
              {optional && "optional"}
            </div>
          )}
          <input
            type={type || "text"}
            value={value}
            name={name}
            placeholder={placeholder}
            className={css(styles.input, inputClassName)}
            onChange={onTextChange}
            disabled={disabled}
            data-testid={dataTestId}
          />
        </div>
      </div>
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
}
