import React, { useState, useEffect, useContext } from "react";
import * as Sentry from "@sentry/react";
import { format, parseISO } from "date-fns";
import GraphPagination from "components/pagination/graph_pagination";
import TableShimmer from "components/reusable/shimmers/table_shimmer";
import { AnalyticsContext } from "contexts/analytics_context";
import ArrowDown from "resources/img/icons/arrow_down.svg";
import ArrowUp from "resources/img/icons/arrow_up.svg";
import { fetchPartnerAnalytics } from "util/api_util";
import SummaryHeaderShimmer from "./summary_header_shimmer";
import SummaryMetricsHeader from "./summary_metrics_header";

const summaryPeriodMap = {
  day: "Day-on-Day",
  week: "Week-on-Week",
  month: "Month-on-Month",
  year: "Year-on-Year",
};

export default function SummaryTable() {
  const [summaries, setSummaries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const { filters, partnerId } = useContext(AnalyticsContext);
  const filterObject = new URLSearchParams(`?${filters}`);

  const pageSize = 4;

  useEffect(() => {
    if (partnerId == null) {
      return;
    }
    setPage(1);
    const abortController = new AbortController();

    setLoading(true);
    fetchPartnerAnalytics(
      "/metrics/job_summaries",
      partnerId,
      filters,
      abortController,
    )
      .then((response) => {
        setSummaries(response);
        setLoading(false);
      })
      .catch((e) => {
        if (e.name !== "AbortError") {
          console.error(e);
          Sentry.captureException(e);
        }
      });

    return () => abortController.abort();
  }, [partnerId, filters]);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  // group by same date
  const jobsByDate = summaries?.reduce(
    (r, { created_at, change, passed_jobs, total_jobs }) => {
      r[created_at] = r[created_at] || {
        passed_jobs: 0,
        total_jobs: 0,
        change: 0,
        numberOfRecords: 0,
        created_at,
      };
      r[created_at].numberOfRecords += 1;
      r[created_at].change += +change;
      r[created_at].passed_jobs += +passed_jobs;
      r[created_at].total_jobs += +total_jobs;
      return r;
    },
    Object.create(null),
  );

  const result = Object.keys(jobsByDate).reduce((r, a) => {
    const job = jobsByDate[a];
    job.pass_rate = (job.passed_jobs * 1.0) / job.total_jobs || 0;
    r[a] = job;
    return r;
  }, Object.create(null));

  let nextPage = 0;
  const result_keys = Object.keys(result);
  const offset = (page - 1) * pageSize;
  const end_at = offset + pageSize;

  let averageChange = 0;
  if (result_keys.length > 0) {
    const finalJobs = jobsByDate[result_keys[0]];
    const initialJobs = jobsByDate[result_keys[result_keys.length - 1]];
    averageChange =
      ((finalJobs.total_jobs - initialJobs.total_jobs) /
        initialJobs.total_jobs) *
      100;
    averageChange = averageChange || 0;
  }

  if (end_at < result_keys.length) nextPage = page + 1;

  const paginatedKeys = result_keys.slice(offset, end_at);

  const renderItem = (item) => (
    <div key={item.created_at} className="border-bottom summary-table-row">
      <div className="date">{format(parseISO(item.created_at), "MMM d")}</div>
      <div className="flex-item color-primary flex">
        <div className="flex-item">
          {new Intl.NumberFormat().format(item.total_jobs)}
        </div>
        <div className="flex-item rate-arrow">
          {item.change >= 0 ? (
            <img src={ArrowUp} alt="" />
          ) : (
            <img src={ArrowDown} alt="" />
          )}
        </div>
      </div>
      <div className="flex-item color-success summary-table__pass-rate">
        {(item.pass_rate * 100).toFixed()}%
      </div>
    </div>
  );

  if (loading) {
    return (
      <>
        <SummaryHeaderShimmer />
        <TableShimmer />
      </>
    );
  }

  return (
    <>
      <SummaryMetricsHeader
        title={`${
          summaryPeriodMap[filterObject.get("display_period")] ||
          summaryPeriodMap.week
        } Summary`}
        value={`${new Intl.NumberFormat().format(
          Math.abs(averageChange.toFixed(1)),
        )}%`}
        note={`Average change for each ${filterObject.get("display_period")}`}
        icon={<img src={averageChange < 0 ? ArrowDown : ArrowUp} alt="" />}
        loading={loading.stackedGraph}
      />
      <div className="summary-table-container legacyTable">
        <GraphPagination
          nextPage={nextPage}
          iconsOnly
          activePage={+page}
          onPageChange={handlePageChange}
          style={{ marginBottom: "1.3em", marginRight: "30px" }}
        />
        <div className="border-bottom table-header">
          <h6 className="header-item date">Timeline</h6>
          <h6 className="header-item volume-trends">Volume Trend</h6>
          <h6 className="header-item">Pass Rate</h6>
        </div>
        <div className="table-body">
          {paginatedKeys?.map((item) => renderItem(result[item]))}
        </div>
      </div>
    </>
  );
}
