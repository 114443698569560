import React, { Component } from "react";
import * as d3 from "d3";
import * as d3Axis from "d3-axis";
import moment from "moment";

class BarChart extends Component {
  componentDidMount() {
    this.drawChart();
  }

  componentDidUpdate() {
    this.drawChart();
  }

  drawChart() {
    const { width } = this.props;
    const { height } = this.props;
    const data = this.props.data.points;

    const chart = d3.select("svg");
    chart.selectAll("*").remove();
    d3.select(".legend").selectAll("*").remove();

    const barWidth = width / data.length - width / data.length / 2;

    const stack = d3
      .stack()
      .keys(this.props.keys)
      .order(d3.stackOrderNone)
      .offset(d3.stackOffsetNone);

    const series = stack(data);

    const xScale = d3
      .scaleBand()
      .domain(series[0].map((d) => moment(d.data.date)))
      .rangeRound([0, width]);

    const yScale = d3
      .scaleLinear()
      .rangeRound([height, 0])
      .domain([0, d3.max(series[series.length - 1], (d) => d[0] + d[1])])
      .nice();

    const color = d3
      .scaleOrdinal()
      .range(this.props.range)
      .domain(this.props.keys);

    const layer = chart
      .selectAll(".stack")
      .data(series)
      .enter()
      .append("g")
      .attr("class", "stack")
      .style("fill", (d, i) => color(i));

    const tooltip = d3
      .select("svg")
      .append("svg:foreignObject")
      .attr("class", "tooltip")
      .attr("width", "180px")
      .attr("height", "80px")
      .style("display", "none");

    layer
      .selectAll("rect")
      .data((d) => d)
      .enter()
      .append("rect")
      .attr("class", "barchart__stack")
      .attr("x", (d) => xScale(moment(d.data.date)) + barWidth / 2)
      .attr("width", barWidth)
      .attr("y", (d) => yScale(d[1]))
      .attr("height", (d) => yScale(d[0]) - yScale(d[1]))
      .on("mousemove", (event, datapoint) => {
        this.showTooltip(tooltip, datapoint, event);
      })
      .on("mouseout", () => {
        d3.select(".tooltip").style("display", "none").html("");
      });

    this.addAxis(chart, xScale, yScale, height, barWidth, data);
    this.appendGridLines(chart, height, width, xScale, yScale);
    this.addLegend(color);
  }

  appendGridLines(chart, height, width, xScale, yScale) {
    chart
      .append("g")
      .attr("class", "grid")
      .attr("transform", `translate(0,${height})`)
      .call(this.makeXGridlines(xScale).tickSize(-height).tickFormat(""));

    chart
      .append("g")
      .attr("class", "grid")
      .call(this.makeYGridlines(yScale).tickSize(-width).tickFormat(""));
  }

  makeXGridlines(xScale) {
    return d3.axisBottom(xScale);
  }

  makeYGridlines(yScale) {
    return d3.axisLeft(yScale);
  }

  addAxis(chart, xScale, yScale, height, barWidth, data) {
    const tickValCalc = parseInt(data.length / 7, 10);
    const xAxisOpts = {
      orient: "Bottom",
      scale: xScale,
      tickSize: 10,
      translate: `translate(0, ${height})`,
      tickFormat: d3.timeFormat("%a, %d %b"),
      tickValues: xScale.domain().filter((d, i) => !(i % tickValCalc)),
    };
    const yAxisOpts = {
      orient: "Left",
      scale: yScale,
      tickSize: 10,
      translate: `translate(0, 0)`,
      tickFormat: null,
      tickValues: null,
    };

    const renderAxis = (options) => {
      const axisType = `axis${options.orient}`;
      const axis = d3Axis[axisType]()
        .scale(options.scale)
        .tickSize(options.tickSize)
        .tickPadding([5])
        .tickFormat(options.tickFormat)
        .tickValues(options.tickValues);

      chart
        .append("svg:g")
        .attr("class", "axis")
        .attr("transform", options.translate)
        .call(axis);
    };
    [xAxisOpts, yAxisOpts].forEach((opts) => {
      renderAxis(opts);
    });
  }

  showTooltip(tooltip, datapoint, event) {
    let label;
    const count = datapoint[1] - datapoint[0];
    const mouseCoords = d3.pointer(event);
    Object.keys(datapoint.data).forEach((key) => {
      if (datapoint.data[key] === count) {
        const words = key.split("_");
        words.forEach((word) => {
          word[0].toUpperCase();
        });
        label = words.join(" ");
      }
    });
    tooltip
      .style("display", "inline-block")
      .attr(
        "transform",
        `translate(${mouseCoords[0] - 60}, ${mouseCoords[1] - 105})`,
      )
      .html(
        `<div class='tooltip--info'><span class="tooptip--capatilize"><style>.tooltip--info{background-color: #004071}</style>${label}</span><br />Count: ${count}<br />Date: ${moment(
          datapoint.data.date,
        ).format("ddd, DD MMM YYYY")}</div>`,
      );
  }

  addLegend(color) {
    const legend = d3.select(".legend");
    const keys = legend
      .selectAll(".key")
      .data(this.props.label)
      .enter()
      .append("div")
      .attr("class", "key");

    keys
      .append("div")
      .attr("class", "symbol")
      .style("background-color", (datapoint, i) => color(i));

    keys
      .append("div")
      .attr("class", "name")
      .html((datapoint) => `${datapoint}`);
    keys.exit().remove();
  }

  render() {
    const { width } = this.props;
    const { height } = this.props;

    return <svg className="barchart" width={width} height={height} />;
  }
}

export default BarChart;
