import React, { useContext } from "react";
import moment from "moment";
import { DisplayEnvironment } from "contexts/displayEnvironment";
import { getUserType } from "util/selectors";
import styles from "./matching_user.module.css";

const mapResultToIcons = (result) => {
  const icons = {
    "No Match": (
      <>
        <div className={styles.noMatch} />
        No Match
      </>
    ),
    "Partial Match": (
      <>
        <div className={styles.partialMatch} />
        Partial Match
      </>
    ),
    Match: (
      <>
        <div className={styles.match} />
        Match
      </>
    ),
    "Not Available": (
      <>
        <div className={styles.notAvailable} />
        Not Available
      </>
    ),
  };

  return icons[result];
};

function getUserUrl({ partnerId, partnerUId, environment }) {
  const env = environment ? "production" : "sandbox";
  const userType = getUserType();
  if (userType === "admin") {
    return `/admin/${partnerId}/kyc/${env}/${partnerUId}`;
  }
  return `/partner/kyc/${env}/${partnerUId}`;
}

function MatchingUser({ match, partnerId }) {
  const { environment } = useContext(DisplayEnvironment);

  const date = new Date(match.enrolled_at);
  const userUrl = getUserUrl({
    partnerId,
    partnerUId: match.partner_uid,
    environment,
  });

  return (
    <div className={[styles.flex, styles.container].join(" ")}>
      <div className={[styles.flex, styles.imageContainer].join(" ")}>
        <img src={match.selfie} alt="Selfie" />
        <div>
          <h3>
            <a href={userUrl}>{match.partner_uid}</a>
          </h3>
          <div className={styles.dateContainer}>
            <div>{moment(date).format("DD-MM-YYYY")}</div>
            <div>{moment(date).format("h:mm a")}</div>
          </div>
        </div>
      </div>
      <div className={[styles.details].join(" ")}>
        <div className={styles.flex}>
          <div>
            <h4>Selfie Photo</h4>
            <div className={styles.checkmarkContainer}>
              <div className={styles.match} />
              Match
            </div>
          </div>

          <div>
            <h4>ID Type</h4>
            <div className={styles.checkmarkContainer}>
              {mapResultToIcons(match.id_type_match)}
            </div>
          </div>

          <div>
            <h4>ID Number</h4>
            <div className={styles.checkmarkContainer}>
              {mapResultToIcons(match.id_number_match)}
            </div>
          </div>
        </div>
        <div className={styles.flex}>
          <div>
            <h4>Full Name</h4>
            <div className={styles.checkmarkContainer}>
              {mapResultToIcons(match.name_match)}
            </div>
          </div>

          <div>
            <h4>Date of Birth</h4>
            <div className={styles.checkmarkContainer}>
              {mapResultToIcons(match.dob_match)}
            </div>
          </div>

          <div>
            <h4>Country</h4>
            <div className={styles.checkmarkContainer}>
              {mapResultToIcons(match.country_match)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MatchingUser;
