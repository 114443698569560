import React, { Component } from "react";
import * as d3 from "d3";
import * as d3Axis from "d3-axis";
import moment from "moment";

class BarChart extends Component {
  componentDidMount() {
    this.drawChart();
  }

  componentDidUpdate() {
    this.drawChart();
  }

  drawChart() {
    const { width } = this.props;
    const { height } = this.props;
    const data = this.props.data.points;

    const chart = d3.select("svg");
    chart.selectAll("*").remove();
    d3.select(".legend").selectAll("*").remove();

    const barWidth = width / data.length - width / data.length / 2;

    const stack = d3.stack().keys(this.props.keys);

    const series = stack(data);

    const uniqueDates = [...new Set(data.map((item) => moment(item.date)))];
    const xScale = d3
      .scaleBand()
      .domain(uniqueDates)
      .range([0, width])
      .padding(0.05);

    const yScale = d3
      .scaleLinear()
      .range([height, 0])
      .domain([this.props.data.y_min, this.props.data.y_max])
      .nice();

    const color = d3
      .scaleOrdinal()
      .range(this.props.range)
      .domain(this.props.keys);

    const layer = chart
      .selectAll(".stack")
      .data(series)
      .enter()
      .append("g")
      .attr("class", "stack")
      .style("fill", (d, i) => color(i));

    const tooltip = d3
      .select("svg")
      .append("svg:foreignObject")
      .attr("class", "tooltip")
      .attr("width", "180px")
      .attr("height", "150px")
      .style("display", "none");

    layer
      .append("defs")
      .append("pattern")
      .attr("id", "negativeDiagonalHatch")
      .attr("patternUnits", "userSpaceOnUse")
      .attr("width", 4)
      .attr("height", 16)
      .attr("patternTransform", "rotate(-45 2 2)")
      .append("path")
      .attr("d", "M -1,2 l 6,0")
      .attr("stroke", "#bb514d")
      .attr("stroke-width", 5);

    layer
      .selectAll("rect")
      .data((d) => d)
      .enter()
      .append("g")
      .append("rect")
      .attr("class", "barchart__stack")
      .attr("transform", (d) => `translate( ${xScale(moment(d.data.date))}, 0)`)
      .attr("y", (d) => yScale(Math.max(d[0], d[1])))
      .attr("height", (d) => Math.abs(yScale(d[1]) - yScale(d[0])))
      .attr("width", xScale.bandwidth());

    layer
      .selectAll("g")
      .append("rect")
      .attr("class", (d) =>
        d.data.status === "failures"
          ? "barchart__stack--negative"
          : "barchart__stack--positive",
      )
      .attr("transform", (d) => `translate( ${xScale(moment(d.data.date))}, 0)`)
      .attr("y", (d) => yScale(Math.max(d[0], d[1])))
      .attr("height", (d) => Math.abs(yScale(d[1]) - yScale(d[0])))
      .attr("width", xScale.bandwidth())
      .on("mousemove", (event, datapoint) => {
        this.showTooltip(tooltip, datapoint, event);
      })
      .on("mouseout", () => {
        d3.select(".tooltip").style("display", "none").html("");
      });

    this.appendGridLines(chart, height, width, xScale, yScale);
    this.addAxis(chart, xScale, yScale, height, barWidth, data);
    this.addLegend(color);
  }

  appendGridLines(chart, height, width, xScale, yScale) {
    chart
      .append("g")
      .attr("class", "grid")
      .attr("transform", `translate(0,${height})`)
      .call(this.makeXGridlines(xScale).tickSize(-height).tickFormat(""));

    chart
      .append("g")
      .attr("class", "grid")
      .call(this.makeYGridlines(yScale).tickSize(-width).tickFormat(""));
  }

  makeXGridlines(xScale) {
    return d3.axisBottom(xScale);
  }

  makeYGridlines(yScale) {
    return d3.axisLeft(yScale);
  }

  addAxis(chart, xScale, yScale, height, barWidth, data) {
    // NOTE: we divide the data.length in two because we have two entries per
    // date. one for successes, and another for failures.
    const tickValCalc = parseInt(data.length / 2 / 7, 10);

    const xAxisOpts = {
      orient: "Bottom",
      scale: xScale,
      tickSize: 10,
      translate: `translate(0, ${yScale(yScale.domain()[0])})`,
      tickFormat: d3.timeFormat("%a, %d %b"),
      tickValues: xScale.domain().filter((d, i) => !(i % tickValCalc)),
    };
    const yAxisOpts = {
      orient: "Left",
      scale: yScale,
      tickSize: 10,
      translate: `translate(0, 0)`,
      tickFormat: null,
      tickValues: null,
    };

    const renderAxis = (options) => {
      const axisType = `axis${options.orient}`;
      const axis = d3Axis[axisType]()
        .scale(options.scale)
        .tickSize(options.tickSize)
        .tickPadding([5])
        .tickFormat(options.tickFormat)
        .tickValues(options.tickValues);

      chart
        .append("svg:g")
        .attr("class", `axis axis--${options.orient}`)
        .attr("transform", options.translate)
        .call(axis);
    };
    [xAxisOpts, yAxisOpts].forEach((opts) => {
      renderAxis(opts);
    });
  }

  showTooltip(tooltip, datapoint, event) {
    const mouseCoords = d3.pointer(event);
    tooltip
      .style("display", "inline-block")
      .attr(
        "transform",
        `translate(${mouseCoords[0] - 80}, ${mouseCoords[1] - 180})`,
      )
      .html(
        `<p style='background-color: #004071; padding-inline: 10px; border-radius: 4px'>
                <span>Status: ${datapoint.data.status}</span> <br/>
                <span>Nigeria: ${Math.abs(datapoint.data.NG)}</span> <br/>
                <span>Kenya: ${Math.abs(datapoint.data.KE)}</span><br/>
                <span>Ghana: ${Math.abs(datapoint.data.GH)}</span><br/>
                <span>South Africa: ${Math.abs(datapoint.data.ZA)}</span><br/>
                <span>Uganda: ${Math.abs(datapoint.data.UG)}</span><br/>
                <span>Date: ${moment(datapoint.data.date).format("ddd, DD MMM YYYY")}</span>
              </p>`,
      );
  }

  addLegend(color) {
    const legend = d3.select(".legend");
    const keys = legend
      .selectAll(".key")
      .data(this.props.label)
      .enter()
      .append("button")
      .attr(
        "class",
        (label) => `key ${label === this.props.selectedLabel ? "active" : ""}`,
      )
      .on("click", (_, datapoint) => {
        this.props.legendClick(datapoint);
      });

    keys
      .append("span")
      .attr("aria-hidden", true)
      .attr("class", "symbol")
      .style("background-color", (datapoint, i) => color(i));

    keys
      .append("span")
      .attr("class", "name")
      .html((datapoint) => `${datapoint}`);
    keys.exit().remove();
  }

  render() {
    const { width } = this.props;
    const { height } = this.props;

    return (
      <div>
        <svg className="barchart" width={width} height={height} />
      </div>
    );
  }
}

export default BarChart;
