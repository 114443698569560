import { legacy_createStore as createStore } from "redux";

import RootReducer from "reducers/root_reducer";

// this file sets up the redux store. this function is used in index.js
// and the store is passed down into the components in App.js

const configureStore = (preloadedState = {}) =>
  createStore(RootReducer, preloadedState);

export default configureStore;
