import React, { createContext } from "react";

export type AmlNewsContextTypes = {
  selectedPerson: { [key: string]: string } | undefined;
  userId: string;
  smileJobId: string;
};
export const AmlNewsContext = createContext<AmlNewsContextTypes>(
  {} as AmlNewsContextTypes,
);

const NewsMediaProvider = ({
  children,
  data,
}: {
  children: JSX.Element;
  data: object;
}) => (
  <AmlNewsContext.Provider
    value={{ selectedPerson: undefined, userId: "", smileJobId: "", ...data }}
  >
    {children}
  </AmlNewsContext.Provider>
);

export default NewsMediaProvider;
