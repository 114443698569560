import React from "react";
import styles from "./source_link.module.css";

/**
 * Gets the file type of a link based on the last part of the link
 * @param {string} link - the link to the source
 * @returns {string} - the file type of the source
 * @example
 * getFileType("https://www.google.com") // returns "html"
 * getFileType("https://www.google.com/image.png") // returns "png"
 * getFileType("https://www.google.com/image") // returns "html"
 */
function getFileType(link) {
  const linkParts = link.split("/");
  const lastPart = linkParts[linkParts.length - 1];
  const fileParts = lastPart.split(".");
  if (fileParts.length < 2) {
    return "html";
  }
  const fileType = fileParts[fileParts.length - 1];
  return fileType;
}

function SourceLink({ link, name }) {
  const fileType = getFileType(link);
  return (
    <div className={styles.container}>
      <div className={styles.sourceHeader}>
        <div>
          <h6>source name: </h6>
          <h4>{name}</h4>
        </div>
        <div>
          <h6>file type:</h6>
          <h4>{fileType}</h4>
        </div>
      </div>
      <div>
        <h6>source link:</h6>
        <a href={link} target="_blank" rel="noreferrer">
          {link}
        </a>
      </div>
    </div>
  );
}

export default SourceLink;
