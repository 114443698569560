import React, { useState } from "react";
import Select from "react-select";

function CountryPicker({ currency, callback, customStyles }) {
  const currencyOptions = [
    {
      value: "USD",
      label: `${
        String.fromCodePoint(0x1f1fa) + String.fromCodePoint(0x1f1f8)
      } USD`,
    },
    {
      value: "NGN",
      label: `${
        String.fromCodePoint(0x1f1f3) + String.fromCodePoint(0x1f1ec)
      } NGN`,
    },
    {
      value: "KES",
      label: `${
        String.fromCodePoint(0x1f1f0) + String.fromCodePoint(0x1f1ea)
      } KES`,
    },
    {
      value: "ZAR",
      label: `${
        String.fromCodePoint(0x1f1ff) + String.fromCodePoint(0x1f1e6)
      } ZAR`,
    },
  ];

  const initialCurrency = currencyOptions.find(
    (option) => option.value === currency,
  );
  const [selectedCurrency, setCurrency] = useState(initialCurrency || "USD");

  const handleSelection = (option) => {
    setCurrency(option);
    callback(option.value);
    sessionStorage.setItem("currency", option.value);
  };

  const defaultStyles = {
    container: (base) => ({
      ...base,
      display: "inline-block",
      width: 120,
      color: "#242f40",
    }),
    valueContainer: (base) => ({
      ...base,
      maxHeight: 48,
      marginTop: -2,
    }),
  };

  return (
    <Select
      styles={customStyles || defaultStyles}
      options={currencyOptions}
      value={selectedCurrency}
      onChange={handleSelection}
    />
  );
}

export default CountryPicker;
