import React from "react";
import Viewer from "react-viewer";

type Props = {
  source: string;
  className?: string;
  alt?: string;
};

function ImageViewer({
  source,
  className,
  alt,
  ...rest
}: Props): React.ReactNode {
  const [show, setShow] = React.useState(false);
  return (
    <>
      <img
        data-testid="ui-image-viewer"
        className={className}
        src={source}
        onClick={() => setShow(!false)}
        style={{ cursor: "pointer" }}
        {...rest}
      />
      <Viewer
        changeable={false}
        visible={show}
        onClose={() => setShow(!show)}
        images={[{ src: source, alt }]}
        noNavbar
      />
    </>
  );
}

export default ImageViewer;
