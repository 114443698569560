import React, { useState } from "react";
import QRCode from "react-qr-code";
import EmailIcon from "resources/img/icons/email-icon.svg";
import LinkIcon from "resources/img/icons/link-bookmark-icon.svg";
import CopyLinkIcon from "resources/img/icons/link-icon.svg";
import ShareIcon from "resources/img/icons/share-icon.svg";
import TelegramIcon from "resources/img/icons/telegram-icon.svg";
import WhatsappIcon from "resources/img/icons/whatsapp-icon.svg";
import { css } from "util/format_helpers";
import { Toast } from "util/Toast";
import styles from "./link_action.module.css";
import LinkAction from "./LinkAction";

export default function GeneratedLink({ link, wrapperClassName }) {
  const copyLink = async () => {
    Toast.fire({
      icon: "success",
      title: "Link copied successfully",
    });
    if ("clipboard" in navigator) {
      return navigator.clipboard.writeText(link);
    }

    document.execCommand("copy", true, link);
  };

  return (
    <div className={styles.container}>
      <div className={styles.qrContainer}>
        <QRCode value={link} />
      </div>
      <div className={styles.linkContainer}>
        <div>
          <img src={CopyLinkIcon} alt="" />
        </div>
        <div className={css("h4", styles.linkUrl)}>{link}</div>
      </div>
      <div className={css(styles.wrapper, wrapperClassName)}>
        <LinkAction actionText="Copy" icon={LinkIcon} onClick={copyLink} />
        <ShareLinkButton link={link} />
      </div>
    </div>
  );
}

function ShareLinkButton({ link }) {
  const [showShareModal, setShowShareModal] = useState(false);

  const encodedLink = encodeURIComponent(link);
  const onShareLink = () => {
    setShowShareModal((current) => !current);
  };

  const shareLink = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          url: link,
        });
      } else {
        onShareLink();
      }
    } catch (error) {
      // Handle any errors that might occur during sharing
      console.error("Error sharing:", error);
    }
  };
  const style = {
    display: "flex",
    flexDirection: "row",
    padding: "10px",
    gap: "10px",
  };
  const iconStyle = {
    height: "20px",
    width: "20px",
    backgroundColor: "transparent",
  };
  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <LinkAction actionText="Share" icon={ShareIcon} onClick={shareLink} />
      {showShareModal && (
        <div className={styles.shareContent}>
          <LinkAction
            iconStyle={iconStyle}
            iconClassName={styles.shareIcon}
            actionText="WhatsApp"
            href={`https://api.whatsapp.com/send?text=${encodedLink}`}
            style={style}
            icon={WhatsappIcon}
            target="_blank"
            onClick={() => setShowShareModal(false)}
          />
          <LinkAction
            iconStyle={iconStyle}
            iconClassName={styles.shareIcon}
            actionText="Telegram"
            href={`https://t.me/share/url?url=${encodedLink}`}
            style={style}
            icon={TelegramIcon}
            target="_blank"
            onClick={() => setShowShareModal(false)}
          />
          <LinkAction
            iconStyle={iconStyle}
            iconClassName={styles.shareIcon}
            actionText="Email"
            href={`mailto:?body=${encodedLink}`}
            style={style}
            icon={EmailIcon}
            target="_blank"
            onClick={() => setShowShareModal(false)}
          />
        </div>
      )}
    </div>
  );
}
