import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  background: "var(--neutral-off-black)",
  color: "var(--basic-white)",
  customClass: {
    container: "new-typography",
    title: "h4",
    icon: "hidden",
  },
  showClass: {
    popup: "fade",
    icon: "fade",
  },
  showCloseButton: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export { Toast };
