import React, { useState } from "react";

export default function ItemsPerPage({
  onChange,
  itemsCountPerPage,
  dropdownClassName,
}) {
  const [perPage, setPerPage] = useState(itemsCountPerPage || 20);

  const onPageChange = (event) => {
    const newPageNo = event.target.value;
    onChange(newPageNo);
    setPerPage(newPageNo);
  };

  const paginationDropdown = [10, 20, 50, 100].map((value, index) => (
    <option key={index} value={value}>
      {value}
    </option>
  ));

  return (
    <label className={dropdownClassName || ""}>
      Items per page: &nbsp;
      <select
        data-testid="items-per-page"
        onChange={onPageChange}
        value={+perPage}
      >
        {paginationDropdown}
      </select>
    </label>
  );
}
