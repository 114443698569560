import React, { Component } from "react";
import { Link } from "react-router-dom";

import IconAlertLarge from "resources/img/icons/alert-large.svg";
import IconWarn from "resources/img/icons/error-icon.svg";
import IconApproved from "resources/img/icons/green-check.svg";
import { fetch2FAConfiguration, disable2FA } from "util/api_util";
import { decode } from "util/route_util";

import Modal from "../modal";
import SetUpTFA from "../unauthenticated/set_up_tfa";
import Tooltip from "./tooltip";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.fetchUser = props.fetchUser.bind(this);
    this.updateUser = props.updateUser.bind(this);
    this.state = {
      user: undefined,
      newPassword: "",
      confirmPassword: "",
      email: "",
      name: "",
      phone: "",
      disableSubmit: false,
      orgRequires2FA: false,
      showDisableModal: false,
      showEnableModal: false,
    };
    this.onInput = this.onInput.bind(this);
    this.callUpdateUser = this.callUpdateUser.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.openDisableModal = this.openDisableModal.bind(this);
    this.closeDisableModal = this.closeDisableModal.bind(this);
    this.openEnableModal = this.openEnableModal.bind(this);
    this.closeEnableModal = this.closeEnableModal.bind(this);
    this.disable2FA = this.disable2FA.bind(this);
  }

  UNSAFE_componentWillMount() {
    sessionStorage.setItem("url", `/settings`);

    const that = this;
    try {
      this.fetchUser().then((json) => {
        that.setState({
          user: json.user,
          email: json.user.email,
          name: json.user.name,
          phone: json.user.phone,
        });
      });
      fetch2FAConfiguration().then((json) => {
        that.setState({
          orgRequires2FA: json.two_factor_required,
        });
      });
    } catch (e) {
      console.error(e);
    }
  }

  callUpdateUser() {
    const tokenInfo = decode(localStorage.token);
    const data = {
      account_type: tokenInfo.type,
    };
    if (this.state.name) {
      data.name = this.state.name;
    }
    if (this.state.phone) {
      data.phone = this.state.phone;
    }
    if (this.state.email) {
      data.email = this.state.email;
    }
    const that = this;
    this.updateUser(this.state.user.id, data).then(
      (action) => {
        if (action) {
          const { user } = action;
          if (user.message) {
            that.setState({
              error: user.message,
              message: undefined,
            });
          } else {
            that.setState({
              disableSubmit: false,
              email: user.email,
              name: user.name || "",
              phone: user.phone || "",
              message: "User information updated.",
              error: undefined,
            });
          }
        }
      },
      (error) => {
        if (error) {
          that.setState({
            error: "An error occurred saving your information.",
            message: undefined,
          });
        }
      },
    );
  }

  changePassword() {
    this.setState({
      disableSubmit: true,
    });
    const tokenInfo = decode(localStorage.token);
    const data = {
      account_type: tokenInfo.type,
    };
    if (this.state.newPassword !== this.state.confirmPassword) {
      return this.setState({
        error: "passwords must match",
        message: undefined,
      });
    }
    if (this.state.confirmPassword.length > 0) {
      data.password = this.state.confirmPassword;
    }
    const that = this;
    this.updateUser(this.state.user.id, data).then(
      (action) => {
        if (action) {
          that.setState({
            message: "Password changed.",
            error: undefined,
          });
        }
      },
      (error) => {
        if (error) {
          that.setState({
            error: "An error occurred saving your password.",
            message: undefined,
          });
        }
      },
    );
  }

  onCheck(property) {
    return (e) => {
      this.setState({ [property]: e.target.checked });
    };
  }

  onInput(property) {
    return (e) => {
      this.setState({ [property]: e.target.value });
    };
  }

  openDisableModal() {
    this.setState({
      showDisableModal: true,
    });
  }

  closeDisableModal() {
    this.setState({
      showDisableModal: false,
    });
  }

  openEnableModal() {
    this.setState({
      showEnableModal: true,
    });
  }

  async closeEnableModal(TFAUpdated = false) {
    if (TFAUpdated) {
      const json = await this.fetchUser();

      this.setState({
        user: json.user,
      });
    }

    this.setState({
      showEnableModal: false,
      showForm: false,
    });
  }

  async disable2FA() {
    const response = await disable2FA();

    const json = await this.fetchUser();

    this.setState({
      user: json.user,
      message: response.message,
      showDisableModal: false,
    });
  }

  render() {
    let error = null;
    if (this.state.error) {
      error = <p className="failure">{this.state.error}</p>;
    }
    let message = null;
    if (this.state.message) {
      message = <p className="success">{this.state.message}</p>;
    }
    const breadcrumbs = (
      <div className="breadcrumbs">
        <Link
          to="/partner/account_settings"
          className="breadcrumb-link breadcrumb-link--inactive"
        >
          Account Settings
        </Link>
        <span> &gt; </span>
        <span>Personal Info</span>
      </div>
    );
    const is2FAEnabled = this.state?.user?.otp_required_for_login;

    return (
      <div className="legacy">
        {breadcrumbs}

        <div className="box | account-settings" data-box-style="dark">
          {error}
          {message}

          <section>
            <h2>Update Profile Information</h2>
            <p>
              <small>
                Note: If you change your email address, you will be required to
                log in with the new email
              </small>
            </p>

            <div className="box stack" data-box-style="dark-glare">
              <div className="cluster" data-justify-content="space-between">
                <label>Email Address</label>
                <input
                  autoComplete="off"
                  type="text"
                  id="email"
                  onChange={this.onInput("email")}
                  value={this.state.email}
                />
              </div>
              <hr />
              <div className="cluster" data-justify-content="space-between">
                <label>Name</label>
                <input
                  autoComplete="off"
                  type="text"
                  id="name"
                  onChange={this.onInput("name")}
                  value={this.state.name}
                />
              </div>
              <hr />
              <div className="cluster" data-justify-content="space-between">
                <label>Phone Number</label>
                <input
                  autoComplete="off"
                  type="text"
                  id="phone"
                  onChange={this.onInput("phone")}
                  value={this.state.phone}
                />
              </div>
              <hr />
              <div className="cluster" data-justify-content="space-between">
                <br />
                <button
                  className="button button--primary"
                  onClick={this.callUpdateUser}
                  disabled={this.disableSubmit}
                >
                  Update Profile
                </button>
              </div>
            </div>
          </section>

          <section>
            <h2>Change Password</h2>
            <p>
              <small>
                Make sure its 8 characters including at least one lowercase
                letter, one upperase letter, one special character and one
                number
              </small>
            </p>

            <div className="box stack" data-box-style="dark-glare">
              <div className="cluster" data-justify-content="space-between">
                <label>New Password</label>
                <input
                  autoComplete="off"
                  type="password"
                  id="newPassword"
                  onChange={this.onInput("newPassword")}
                  value={this.state.password}
                />
              </div>
              <hr />
              <div className="cluster" data-justify-content="space-between">
                <label> Confirm Password </label>
                <input
                  autoComplete="off"
                  type="password"
                  id="confirmPassword"
                  onChange={this.onInput("confirmPassword")}
                  value={this.state.password}
                />
              </div>
              <hr />
              <div className="cluster" data-justify-content="space-between">
                <br />
                <button
                  className="button button--primary"
                  onClick={this.changePassword}
                  disabled={this.disableSubmit}
                >
                  Change Password
                </button>
              </div>
            </div>
          </section>

          <section>
            <h2>Two-Factor Authentication (2FA)</h2>

            <div className="box stack" data-box-style="dark-glare">
              <div className="cluster" data-align-items="start">
                <img
                  src={is2FAEnabled ? IconApproved : IconWarn}
                  height="14px"
                  width="14px"
                />

                <div className="stack">
                  <p className={is2FAEnabled ? "color-approved" : ""}>
                    Two-factor authentication is&nbsp;
                    {is2FAEnabled ? "enabled" : "not enabled"}
                  </p>
                  <p>
                    <small>
                      Add an extra layer of security to your account by using a
                      one-time security code. Each time you sign into the
                      portal, you will need your password and security code.
                      &nbsp;
                      <a href="https://docs.usesmileid.com/further-reading/securing-your-account-with-two-factor-authentication-2fa">
                        Learn more
                      </a>
                    </small>
                  </p>
                </div>
              </div>
              <hr />
              <div className="cluster" data-justify-content="space-between">
                <br />

                {this.state.orgRequires2FA && is2FAEnabled && (
                  <Tooltip
                    data-align="top"
                    component={
                      <button type="button" className="button button--disabled">
                        Disable 2FA
                      </button>
                    }
                  >
                    Two factor authentication is required by your organization
                  </Tooltip>
                )}

                {(!this.state.orgRequires2FA || !is2FAEnabled) && (
                  <button
                    type="button"
                    className={`button ${
                      is2FAEnabled ? "btn-red--outline" : "button--primary"
                    }`}
                    onClick={
                      is2FAEnabled
                        ? this.openDisableModal
                        : this.openEnableModal
                    }
                    disabled={this.disableSubmit}
                  >
                    {is2FAEnabled ? "Disable" : "Enable"} 2FA
                  </button>
                )}
              </div>
            </div>
          </section>
        </div>

        <Modal
          dismissModal={this.closeDisableModal}
          show={this.state.showDisableModal}
        >
          <div style={{ color: "#000" }} className="box stack">
            <img alt="" height="56" width="56" src={IconAlertLarge} />
            <h3>Disable Two-Factor Authentication?</h3>
            <p className="color-gray" style={{ maxInlineSize: "40ch" }}>
              Disabling two factor authentication will remove the extra security
              on your account, you will only use your password to sign in.
            </p>

            <div className="cluster" data-justify-content="space-between">
              <br />
              <div style={{ "--space": ".5rem" }} className="cluster">
                <button
                  type="button"
                  onClick={this.closeDisableModal}
                  className="button button--outline"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={this.disable2FA}
                  className="button button--red"
                >
                  Disable 2FA
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          dismissModal={this.closeEnableModal}
          additionalClasses="dark-modal"
          show={this.state.showEnableModal}
        >
          <div style={{ color: "#000" }}>
            <SetUpTFA modal dismissModal={this.closeEnableModal} />
          </div>
        </Modal>
      </div>
    );
  }
}

export default Settings;
