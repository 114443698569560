import React, { createContext } from "react";

export const AnalyticsContext = createContext(null);

export function AnalyticsProvider({
  children,
  initialFilters = {},
  partnerId,
}) {
  return (
    <AnalyticsContext.Provider value={{ filters: initialFilters, partnerId }}>
      {children}
    </AnalyticsContext.Provider>
  );
}
