import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";

import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
);
ChartJS.defaults.scales.linear.min = 0;

export default function StackedAreaChart({ xAxis = {}, yAxis = {}, ...props }) {
  const { data, legend } = props;

  const options = {
    responsive: true,
    interaction: {
      intersect: false,
      mode: "nearest",
      axis: "x",
      includeInvisible: true,
    },
    layout: {
      padding: {
        top: 30,
      },
    },
    elements: {
      lines: { showLine: false },
      point: {
        radius: 0,
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      animations: {
        tension: {
          duration: 1000,
          easing: "linear",
          from: 1,
          to: 0,
        },
      },
      legend: {
        position: "bottom",
        align: "center",
        labels: {
          boxWidth: 8,
          boxHeight: 8,
        },
        padding: {
          left: 300,
        },
        ...legend,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: { align: "center", padding: 20 },
        ...xAxis,
      },
      y: {
        grid: {
          drawOnChartArea: true,
          color: "rgba(147, 148, 171, 0.6)",
          lineWidth: 0.5,
          drawTicks: false,
        },
        ticks: {
          padding: 20,
          callback: (value, index) => {
            if (index % 2 !== 0) {
              return "";
            }

            if (value >= 1000000000 || value <= -1000000000) {
              return `${value / 1e9}B`;
            }
            if (value >= 1000000 || value <= -1000000) {
              return `${value / 1e6}M`;
            }
            if (value >= 1000 || value <= -1000) {
              return `${value / 1e3}K`;
            }
            return value;
          },
          ...yAxis,
        },
      },
    },
  };

  return <Line options={options} data={data} {...props} />;
}
