import React, { useState, useEffect, useContext } from "react";
import * as Sentry from "@sentry/react";
import GraphPagination from "components/pagination/graph_pagination";
import TableShimmer from "components/reusable/shimmers/table_shimmer";
import { AnalyticsContext } from "contexts/analytics_context";
import ArrowDown from "resources/img/icons/arrow_down.svg";
import ArrowUp from "resources/img/icons/arrow_up.svg";
import { buildState, fetchPartnerAnalytics } from "util/api_util";
import { formatLocalisedDate } from "util/format_helpers";
import { shortIDNames } from "./id_volumes_graph";
import SummaryMetricsHeader from "./summary_metrics_header";
import Style from "./weekly_id_jobs.module.css";

export default function WeeklyIdJobsTable() {
  const [summaries, setSummaries] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const { filters, partnerId } = useContext(AnalyticsContext);
  const filterObject = buildState(filters);
  const pageSize = 4;

  const idPeriodMap = {
    day: "Daily",
    week: "Weekly",
    month: "Monthly",
    year: "Yearly",
  };

  useEffect(() => {
    if (partnerId == null) {
      return;
    }
    setPage(1);
    const abortController = new AbortController();
    setLoading(true);
    fetchPartnerAnalytics(
      "/metrics/weekly_id_volumes_analytics",
      partnerId,
      filters,
      abortController,
    )
      .then((response) => {
        setSummaries(response);
        setLoading(false);
      })
      .catch((e) => {
        if (e.name !== "AbortError") {
          console.error(e);
          Sentry.captureException(e);
        }
      });

    return () => abortController.abort();
  }, [partnerId, filters]);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const totalIdChange =
    summaries.length > 0
      ? summaries.reduce((a, b) => a + +b.change_rate, 0)
      : 0;

  const totalIdAverage = (totalIdChange / summaries.length) * 100 || 0;

  let createdDate = new Set();
  const jobsByIdTypes = summaries.reduce((r, a) => {
    const [country, id_type] = (
      a.id_type ?? "Not Available - Not Available"
    ).split("-");
    const countryIdType = `${country} ${shortIDNames[id_type] || id_type}`;
    r[countryIdType] = r[countryIdType] || [];
    r[countryIdType].push(a);
    createdDate.add(a.created_at);
    return r;
  }, Object.create(null));

  createdDate = Array.from(createdDate);

  let nextPage = 0;
  const result_keys = Object.keys(jobsByIdTypes);
  const offset = (page - 1) * pageSize;
  const end_at = offset + pageSize;

  if (end_at < result_keys.length) nextPage = page + 1;

  const paginatedKeys = result_keys.slice(offset, end_at);

  const jobsByDate = paginatedKeys.map((idType) => {
    const row = jobsByIdTypes[idType].reduce(
      (r, data) => ({
        ...r,
        [data.created_at]: {
          count: (r[data.created_at]?.count || 0) + 1,
          pass_rate: (r[data.created_at]?.pass_rate || 0) + +data.pass_rate,
          passed_jobs:
            (r[data.created_at]?.passed_jobs || 0) + +data.passed_jobs,
          total_jobs: (r[data.created_at]?.total_jobs || 0) + +data.total_jobs,
          previous_total_jobs:
            (r[data.created_at]?.previous_total_jobs || 0) +
            +data.previous_total_jobs,
          pass_rate_summary_avg_for_period:
            (r[data.created_at]?.pass_rate_summary_avg_for_period || 0) +
            +data.pass_rate_summary_avg_for_period,
          change: (r[data.created_at]?.change || 0) + +data.change,
        },
      }),
      Object.create(null),
    );

    return { id_type: idType, ...row };
  });

  const rows = jobsByDate.map((job) => {
    Object.keys(job).forEach((key) => {
      if (key !== "id_type") {
        job[key].pass_rate /= job[key].count;
      }
    });
    return job;
  });

  const parseRowData = (object, field) => {
    if (!object) return 0;
    return object[field] || 0;
  };

  const rowData = (item, header_item) => (
    <td key={header_item} className={Style.id_data_row}>
      <div className={`color-tetiary ${Style.volume}`}>
        <div>
          {new Intl.NumberFormat().format(
            parseRowData(item[header_item], "total_jobs"),
          )}
        </div>
        <div className={`color-success ${Style.pass_rate}`}>
          <div>
            {`${(
              (parseRowData(item[header_item], "pass_rate") || 0) * 100
            ).toFixed(0)}%`}
          </div>
          {parseFloat(parseRowData(item[header_item], "change")) > 0 ? (
            <img src={ArrowUp} alt="" />
          ) : (
            <img src={ArrowDown} alt="" />
          )}
        </div>
      </div>
    </td>
  );

  const renderTableItem = (item) => {
    if (!item) return;

    return (
      <tr key={item.id_type} className={Style.table_row}>
        <td className={`${Style.header_item} ${Style.id_type_row}`}>
          {item.id_type.split(/-|_/).join(" ")}
        </td>
        {createdDate.map((id_type) => rowData(item, id_type))}
      </tr>
    );
  };

  return (
    <div className="summary-table-container legacyTable">
      <SummaryMetricsHeader
        title={`${
          idPeriodMap[filterObject.display_period] || idPeriodMap.week
        } ID Jobs`}
        value={`${Math.abs(totalIdAverage.toFixed(1))}%`}
        note="Average ID jobs growth for the period."
        icon={<img src={totalIdAverage < 0 ? ArrowDown : ArrowUp} alt="" />}
        loading={loading}
      />

      {loading && <TableShimmer />}

      {!loading && (
        <>
          <GraphPagination
            nextPage={nextPage}
            iconsOnly
            activePage={+page}
            onPageChange={handlePageChange}
            style={{ marginBottom: "1.3em", marginRight: "30px" }}
          />
          <h5 className="mute-note center">Data shown is grouped by weeks</h5>
          <div className={Style.table_wrapper}>
            <table>
              <thead>
                <tr>
                  <th className={`${Style.header_item} ${Style.id_type_row}`}>
                    Identity Type
                  </th>
                  {createdDate.map((date) => (
                    <th
                      className={`${Style.header_item} ${Style.id_date}`}
                      key={date}
                    >
                      {formatLocalisedDate(date, "MMM DD")}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>{rows.map((item) => renderTableItem(item))}</tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
}
