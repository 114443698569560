import React, { useContext, useEffect, useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import classNames from "classnames";
import { jwtDecode } from "jwt-decode";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import Paginated from "containers/reusable/paginated";
import { DisplayEnvironment } from "contexts/displayEnvironment";
import useEnvironment from "hooks/useEnvironment";
import CopyIcon from "resources/img/icons/copy-icon.svg";
import EditIcon from "resources/img/icons/edit.svg";
import ExpiredIcon from "resources/img/icons/expired.svg";
import PauseIcon from "resources/img/icons/pause.svg";
import PlayIcon from "resources/img/icons/play-icon.svg";
import UsedIcon from "resources/img/icons/used-link.svg";
import ViewIcon from "resources/img/icons/view.svg";
import { buildState, fetchSmileLinks, updateSmileLink } from "util/api_util";
import { css, formatLocalisedDate } from "util/format_helpers";
import { Toast } from "util/Toast";
import PageHeader from "../../page_header/PageHeader";
import TableShimmer from "../shimmers/table_shimmer";
import styles from "./manage_links.module.css";
import { MediumCard } from "./MediumCard";
import { StyledButton } from "./StyledButton";
import {
  BodyItem,
  HeaderItem,
  Table,
  TableBody,
  TableHeader,
  TableRow,
} from "./Table";
import { TableAction, TableActions } from "./table_actions";
import tabStyles from "./tabs.module.css";
import TextInput from "./TextInput";

export default function ManageSmileLinks() {
  const SingleUse = "single-use";
  const MultiUse = "multi-use";
  // TODO: find a better way of handling routes when env change
  useEnvironment("../../:environment/smile_links/manage");
  const location = useLocation();
  const queryParams = buildState(location.search);
  const [loading, setLoading] = useState({ refreshing: true });
  const [tab, setTab] = useState(queryParams?.tab ?? MultiUse);
  const [links, setLinks] = useState([]);
  const [filter, setFilter] = useState("");

  const [paginationDetails, setPaginationDetails] = useState({
    page: queryParams?.page ?? 1,
    limit: queryParams?.items_per_page ?? 10,
    totalPages: 0,
  });
  const history = useHistory();
  const { environment } = useContext(DisplayEnvironment);
  const abortControllerRef = useRef(new AbortController());
  const CAPTIONS = {
    [MultiUse]: "Manage your Multi-Users Smile Links",
    [SingleUse]: "Manage your Single-User Links",
  };

  const user = localStorage.token ? jwtDecode(localStorage.token) : undefined;
  useEffect(() => {
    if (!user) return;
    fetchLinks(tab, paginationDetails.page, paginationDetails.limit);
    return () => abortControllerRef.current?.abort();
  }, [environment]);

  const fetchLinks = async (forTab, page, limit) => {
    const isSingleUse = forTab == "single-use";
    history.replace(
      `manage?tab=${forTab}&page=${page ?? paginationDetails.page}&items_per_page=${limit ?? paginationDetails.limit}`,
    );
    const abortController = abortControllerRef.current;
    if (abortController && !abortController.signal.aborted) {
      abortController.abort();
    }
    abortControllerRef.current = new AbortController();
    setTab(forTab);
    setLoading({ refreshing: true });
    fetchSmileLinks(
      { is_single_use: isSingleUse, limit, page },
      abortControllerRef.current,
    )
      .then(({ links, meta: { currentPage, limit, totalPages } }) => {
        setLoading({ refreshing: false });
        setLinks(links);
        setPaginationDetails({ page: currentPage, limit, totalPages });
      })
      .catch((e) => {
        if (e.name !== "AbortError") {
          console.error(e);
          Sentry.captureException(e);
        }
      });
  };

  const updatePaginationAndFetch = async ({ page, limit }) => {
    const newPage = page || 1;
    const newLimit = limit || paginationDetails.limit;
    setLoading((current) => ({ ...current, loadMore: true }));
    setPaginationDetails((current) => ({
      ...current,
      page: newPage,
      limit: newLimit,
    }));
    await fetchLinks(tab, newPage, newLimit);
    setLoading((current) => ({ ...current, loadMore: false }));
  };

  const filteredLinks = links.filter((link) => {
    const searchTerm = filter?.toLocaleLowerCase();
    return (
      link.name.toLowerCase().includes(searchTerm) ||
      link.user_id?.toLowerCase().includes(searchTerm)
    );
  });

  const copyLink = async (link) => {
    Toast.fire({
      icon: "success",
      title: "Link copied successfully",
    });

    if ("clipboard" in navigator) {
      return navigator.clipboard.writeText(link);
    }

    document.execCommand("copy", true, link);
  };

  const handleRefIdClick = (refId) => {
    history.push(
      `/partner/job_list?page=1&results_per_page=20&env=${environment}&search=${refId}`,
    );
  };

  const stopLink = (refId, status) => {
    setLoading((current) => ({ ...current, [refId]: true }));
    const isDisabled = status === null;
    updateSmileLink(refId, { is_disabled: isDisabled })
      .then((result) => {
        setLoading((current) => ({ ...current, [refId]: false }));
        Toast.fire({ title: "Link updated successfully" });
        const newLinks = links.map((link) => {
          if (link.user_id == refId) {
            link.disabled_at = isDisabled ? result.disabled_at : null;
          }

          return link;
        });
        setLinks([...newLinks]);
      })
      .catch(() => {});
  };

  const linkUsed = (is_single_use, total_jobs) =>
    is_single_use && total_jobs > 0;
  const linkExpired = (expires_at) => moment(expires_at).isBefore(moment());

  const getLinkStats = (link, statName) => {
    const linkStats = link[statName] || 0;

    if (!link.is_single_use) return linkStats;

    if (linkStats > 0) return "Yes";

    return "No";
  };

  const getActionLabel = (link) => {
    if (linkExpired(link.expires_at)) return "Expired";
    if (linkUsed(link.is_single_use, link.total_jobs)) return "Used";
    if (link.disabled_at) return "Enable";
    return "Disable";
  };

  const iconMaps = {
    Enable: PlayIcon,
    Used: UsedIcon,
    Expired: ExpiredIcon,
    Disable: PauseIcon,
  };

  const expiry = (expires_at) => {
    if (!expires_at) return "Never";

    const duration = moment.duration(moment(expires_at).diff(moment()));
    const days = duration.asDays();

    if (days <= 0) return "Expired";

    const daysStr =
      days > 90
        ? `${moment(expires_at).fromNow(true)}`
        : `${days.toFixed()} days`;

    return duration.asHours() >= 0 && duration.asHours() <= 24
      ? "Today"
      : daysStr;
  };

  const linkRow = filteredLinks.map((link) => (
    <TableRow key={link.user_id}>
      <BodyItem
        onClick={() =>
          handleRefIdClick(link.is_single_use ? link.user_id : link.id)
        }
      >
        {link.is_single_use ? link.user_id : link.name}
      </BodyItem>
      <BodyItem>{formatLocalisedDate(link.created_at, "DD MMM, YY")}</BodyItem>
      <BodyItem>{expiry(link.expires_at)}</BodyItem>
      <BodyItem>{getLinkStats(link, "total_jobs")}</BodyItem>
      <BodyItem>{getLinkStats(link, "successful_jobs")}</BodyItem>
      <BodyItem>
        <TableActions>
          {!(
            linkExpired(link.expires_at) ||
            linkUsed(link.is_single_use, link.total_jobs)
          ) && (
            <TableAction
              label="Copy"
              onClick={() => copyLink(link.link_url)}
              icon={CopyIcon}
            />
          )}
          <TableAction
            label="View"
            icon={ViewIcon}
            href={`settings?refId=${link.user_id}`}
          />
          {!(
            linkExpired(link.expires_at) ||
            linkUsed(link.is_single_use, link.total_jobs)
          ) && (
            <TableAction
              label="Edit"
              icon={EditIcon}
              href={`new?refId=${link.user_id}`}
            />
          )}
          {!(
            linkExpired(link.expires_at) ||
            linkUsed(link.is_single_use, link.total_jobs)
          ) && (
            <TableAction
              label={getActionLabel(link)}
              icon={iconMaps[getActionLabel(link)]}
              disabled={
                linkExpired(link.expires_at) ||
                linkUsed(link.is_single_use, link.total_jobs) ||
                loading[link.user_id]
              }
              onClick={() => stopLink(link.user_id, link.disabled_at)}
            />
          )}
        </TableActions>
      </BodyItem>
    </TableRow>
  ));
  return (
    <div className={css("new-typography legacy", styles.manageWebLinkWrapper)}>
      <div className={styles.manageHeader}>
        <PageHeader
          title="Manage Links"
          showBack
          onBackPressed={() => history.goBack()}
        />
      </div>
      <div className="flex">
        <div>
          <div className={tabStyles.tabWrapper}>
            <h4
              className={classNames(
                tabStyles.tab,
                tab === MultiUse && tabStyles.active,
              )}
              onClick={() => fetchLinks(MultiUse)}
            >
              Multi-User Link
            </h4>
            <h4
              className={classNames(
                tabStyles.tab,
                tab === SingleUse && tabStyles.active,
              )}
              onClick={() => fetchLinks(SingleUse)}
            >
              Single-User Link
            </h4>
          </div>
          <div className={css("h4", styles.tableCaption)}>{CAPTIONS[tab]}</div>
        </div>
        <TextInput
          placeholder="Search link name or user id"
          hideIcon
          inputClassName={styles.searchInput}
          onChange={(value) => setFilter(value)}
        />
      </div>
      {loading.refreshing && <TableShimmer />}
      {!loading.refreshing && (
        <div>
          <Table>
            <TableHeader>
              {tab == MultiUse && <HeaderItem>Link Name</HeaderItem>}
              {tab == SingleUse && <HeaderItem>User ID</HeaderItem>}
              <HeaderItem>Created At</HeaderItem>
              <HeaderItem>Expires In</HeaderItem>
              <HeaderItem>Done</HeaderItem>
              <HeaderItem>Passed</HeaderItem>
              <HeaderItem>Actions</HeaderItem>
            </TableHeader>
            <TableBody>{linkRow}</TableBody>
          </Table>
          {links.length === 0 && (
            <MediumCard
              containerClass={css("center", styles.addIdCard)}
              label="No Links found"
              buttonText="Create a new Link"
              href="new"
            />
          )}
        </div>
      )}
      {links.length > 0 && (
        <div className="flex">
          <Paginated
            activePage={paginationDetails.page}
            itemsCountPerPage={paginationDetails.limit}
            totalItems={paginationDetails.totalPages}
            handlePageChange={(page) => updatePaginationAndFetch({ page })}
            changeNumberOfItems={(limit) => updatePaginationAndFetch({ limit })}
          />
          <StyledButton className={styles.continueButton} isActive href="new">
            Create Smile Link
          </StyledButton>
        </div>
      )}
    </div>
  );
}
