import React from "react";

import {
  getRelevantActionValueIcons,
  getRelevantDisplayValue,
} from "util/actions_helpers";

function ActionsInterface({ keyLabel, keyName, title, value, actionIcons }) {
  const displayValue = getRelevantDisplayValue(
    actionIcons,
    value,
    title,
    keyName,
  );
  return (
    <div key={keyName}>
      <div className="job-field__container">
        <div className="job-field__label job-field__label--bigger-width">
          {keyLabel}
        </div>
        <div className="job-field__value-container job-field__value-container--bigger-width">
          <div className="job-field__value job-field__value-split">
            <div>
              <img
                src={getRelevantActionValueIcons(actionIcons, value)}
                alt={displayValue}
                className="icon-14-10"
              />
            </div>
            <div>{displayValue}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActionsInterface;
