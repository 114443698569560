import React, { Component } from "react";
import * as d3 from "d3";

import { formatNumberWithSeperators } from "util/format_helpers";

class PieChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      SVGHeight: this.props.height,
      SVGWidth: this.props.width,
    };
  }

  componentDidMount() {
    this.drawChart().then((box) => {
      this.setState({
        SVGWidth: box.width,
        SVGHeight: box.height,
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.drawChart().then((box) => {
        this.setState({
          SVGWidth: box.width,
          SVGHeight: box.height,
        });
      });
    }
  }

  drawChart() {
    const { width } = this.props;
    const { height } = this.props;
    const radius = Math.min(width, height) / 2;
    const data = this.props.data.sort((a, b) => b.percent - a.percent);

    d3.select(`${this.props.containerClassName} svg`).selectAll("*").remove();

    const color = d3.scaleOrdinal().range(this.props.range);

    const innerRadius = radius * 0.8;
    const outerRadius = radius * 0.5;

    const arc = d3.arc().innerRadius(innerRadius).outerRadius(outerRadius);

    const labelArc = d3
      .arc()
      .innerRadius(radius * 0.9)
      .outerRadius(radius * 0.9);

    const pie = d3.pie().value((datapoint) => datapoint.percent);

    const svg = d3
      .select(`${this.props.containerClassName} svg`)
      .append("g")
      .attr("style", "transform: translate(50%, 50%)");

    const g = svg
      .selectAll(".arc")
      .data(pie(data))
      .enter()
      .append("g")
      .attr("class", "arc");

    g.append("path")
      .attr("d", arc)
      .attr("stroke", "white")
      .style("fill", (datapoint, i) => color(i));

    // NOTE: used to extend stems where necessary
    const polyLineExtensionCounter = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    const textAnchorExtensionCounter = [1, 2, 3, 4, 5, 6, 7, 8, 9];

    const safeAngleValue = 18; // ((5 / 100) * 360) degrees of a circle
    const safePercentageValue = Number((safeAngleValue / 360) * 100).toFixed(2);

    svg
      .selectAll("allPolylines")
      .data(pie(data))
      .enter()
      .append("polyline")
      .attr("stroke", (datapoint, i) => color(i))
      .style("fill", "none")
      .attr("stroke-width", 1)
      .attr("points", (d) => {
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2; // we need the angle to see if the X position will be at the extreme right or extreme left
        const posA = arc.centroid(d); // line insertion in the slice
        const posB = labelArc.centroid(d); // line break: we use the other arc generator that has been built only for that
        const posC = labelArc.centroid(d); // Label position = almost the same as posB
        posC[0] = radius * 0.95 * (midangle < Math.PI ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left

        if (d.value < safePercentageValue) {
          const extensionLength = polyLineExtensionCounter.shift() * 20;

          posB[1] -= extensionLength;
          posC[1] -= extensionLength;
        }

        return [posA, posB, posC];
      });

    svg
      .selectAll("allLabels")
      .data(pie(data))
      .enter()
      .append("text")
      .attr("class", "pie-chart__label")
      .style("height", "100px")
      .style("width", "100px")
      .html((d) => {
        if (d.value > 0) {
          return `${d.data.label} - ${formatNumberWithSeperators(
            d.data.count,
          )} Checks (${d.data.percent}%)`;
        }
      })
      .attr("transform", (d) => {
        const pos = labelArc.centroid(d);
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;

        if (d.value > safePercentageValue) {
          pos[0] = radius * 0.99 * (midangle < Math.PI ? 1 : -1);
        } else {
          const extensionLength = textAnchorExtensionCounter.shift() * 20;

          pos[0] = radius * 0.99 * (midangle < Math.PI ? 1 : -1);
          pos[1] -= extensionLength;
        }

        return `translate(${pos})`;
      })
      .style("text-anchor", (d) => {
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        if (d.value > 0) {
          return midangle < Math.PI ? "start" : "end";
        }
      });

    // NOTE: Get final svg dimensions
    const box = svg.node().getBBox();

    return Promise.resolve(box);
  }

  render() {
    const width = this.state.SVGWidth;
    const height = this.state.SVGHeight;

    return <svg className="piechart" width={width} height={height} />;
  }
}

export default PieChart;
