import React from "react";

import Accordion from "components/ui/Accordion";
import MetaIcon from "resources/img/icons/meta_icon.svg";
import { toTitleCase } from "util/format_helpers";
import {
  Fields,
  ImgIcon,
  JobField,
  NOT_AVAILABLE,
  TextSpan,
  TriggerDiv,
} from "./IdInfoAccordion";

function renderFields(metadata, key, label) {
  if (!metadata) {
    return [];
  }
  return metadata
    .filter((item) => item.name === key)
    .map((item, i) => ({
      key: `${key}${i}`,
      label,
      value: toTitleCase(item.value || NOT_AVAILABLE),
    }));
}

type Props = {
  metadata: array;
};

const MetadataAccordion: React.FC<Props> = ({ metadata }) => {
  const notApplicable = !metadata || metadata.length === 0;
  const dataMap = {
    ip: "Source IP Address",
    client_ip: "Client's IP Address",
  };

  const fields = Object.keys(dataMap).flatMap((key) =>
    renderFields(metadata, key, dataMap[key]),
  );
  if (notApplicable || fields.length === 0) {
    return;
  }
  const hasNoInfo = fields.every((field) => field.value === NOT_AVAILABLE);
  const notApplicableIcon =
    notApplicable || hasNoInfo ? (
      <TextSpan data-testid="not-applicable">Not Applicable</TextSpan>
    ) : null;
  const trigger = (
    <TriggerDiv data-testid="trigger">
      <ImgIcon src={MetaIcon} alt="metadata" />
      <span>Metadata</span>
    </TriggerDiv>
  );

  return (
    <Accordion defaultValue="Metadata" disabled={notApplicable || hasNoInfo}>
      <Accordion.Item
        icon={notApplicableIcon}
        trigger={trigger}
        value="metadata"
      >
        <Fields data-testid="fields">
          {...fields.map((field) => (
            <JobField key={field.key} label={field.label} value={field.value} />
          ))}
        </Fields>
      </Accordion.Item>
    </Accordion>
  );
};

export default MetadataAccordion;
