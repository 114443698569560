import React, { useState } from "react";
import CloseIcon from "resources/img/icons/closecircle.svg";
import UploadIcon from "resources/img/icons/upload-icon.svg";
import { css } from "util/format_helpers";
import ErrorLabel from "./ErrorLabel";
import styles from "./image_upload_button.module.css";

export function ImageUploadButton({
  id,
  onImageChange,
  previewImage,
  file,
  fileName,
  disabled,
  maxFileSize = 2, // Default to 2mb,
}) {
  const [errorMessage, setErrorMessage] = useState("");

  const validateImage = (file) => {
    if (!file) return;

    const maxFileSizeKB = maxFileSize * 1024 * 1000;
    let error = "";
    if (file.size > maxFileSizeKB) {
      error = `File size should not be more than ${maxFileSize}MB`;
    }
    return error;
  };

  const handleImageChange = (e) => {
    resetLogo();
    const file = e.target.files[0];
    const logoError = validateImage(file);
    if (logoError) {
      setErrorMessage(logoError);
      return;
    }
    onImageChange(file);
  };

  const resetLogo = () => {
    setErrorMessage("");
    onImageChange(null);
  };

  const borderClass = previewImage
    ? styles.imageAvailable
    : styles.imageNotAvailable;

  return (
    <div>
      <div className={styles.container}>
        <div className={css(styles.fileButton, borderClass)}>
          <input
            type="file"
            id={id}
            onChange={handleImageChange}
            accept=".jpg,.jpeg,.png"
            disabled={disabled}
          />
          {previewImage && <img src={previewImage} alt="logo preview" />}
          {!previewImage && (
            <label htmlFor={id} className="sub-text-descriptor">
              <img src={UploadIcon} />
              <div>Upload Logo</div>
            </label>
          )}
        </div>
        {!previewImage && (
          <div className="fw4 h4">
            <div>Upload a square image (png/jpg)</div>
            <div>Image must be below 2mb</div>
          </div>
        )}
        {previewImage && (
          <div className="fw4 h4">
            <div className={styles.fileName}>
              <div className={css("h5", styles.name)}>
                {file?.name || fileName}
              </div>
              <button
                className={styles.iconButton}
                onClick={resetLogo}
                disabled={disabled}
              >
                <img src={CloseIcon} className={styles.fileNameIcon} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div>{errorMessage && <ErrorLabel message={errorMessage} />}</div>
    </div>
  );
}
