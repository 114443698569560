import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect, useHistory, useLocation, matchPath } from "react-router-dom";
import { fetchPartnerInfo } from "./actions/actions";
import AdminSidebar from "./components/reusable/admin_sidebar";
import Sidebar from "./components/reusable/SmileSideBar";
import HeaderContainer from "./containers/reusable/headerContainer";
import { DisplayEnvironment } from "./contexts/displayEnvironment";
import { decode, logoutUser } from "./util/route_util";
import { getAdminPartnerId } from "./util/selectors";

function AuthAppView(props) {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [adminPartnerId, setAdminPartnerId] = useState(getAdminPartnerId());
  const context = useContext(DisplayEnvironment);
  const { viewAs } = context;
  const user = useMemo(() => {
    try {
      return decode(localStorage.getItem("token"));
    } catch {
      return <Redirect to="/login" />;
    }
  }, [localStorage.token]);
  const match = matchPath(location.pathname, {
    path: props.children.flatMap(
      (child) =>
        child.props?.path ||
        child.props?.children.map((child) => child.props.path),
    ),
    exact: true,
  });
  useEffect(() => {
    // watch and automatically sign-out all open tabs when token becomes invalid
    window.addEventListener(
      "storage",
      (event) => {
        if (event.storageArea === localStorage) {
          const user = decode(localStorage.getItem("token"));
          if (!user) {
            window.location.reload();
          }
        }
      },
      false,
    );
  }, []);

  useEffect(() => {
    const partnerId = getAdminPartnerId();
    if (adminPartnerId !== partnerId) {
      setAdminPartnerId(partnerId);
    }
    // always scroll to the top when we change route
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const partner = adminPartnerId;
    if (user.type === "admin" && partner) {
      fetchPartnerInfo({ partner }, dispatch);
    } else if (user.type === "partner") {
      fetchPartnerInfo({}, dispatch);
    }
  }, [adminPartnerId]);

  if (!match) {
    return <Redirect to="/404" />;
  }

  if (viewAs === "unauthorized") {
    logoutUser();
    history.push("/");
    window.location.reload();
    return null;
  }

  if (viewAs === "partner" && user.type === "admin" && !adminPartnerId) {
    // set viewAs to admin to prevent indefinite loop while an navigating to admin/partner_dashboards path
    context.setViewAs("admin");
    history.replace("/admin/partner_dashboards");
    return null;
  }

  let sidebar = <div />;

  if (viewAs === "admin") {
    sidebar = (
      <AdminSidebar displayEnvironment={context.environment} key={viewAs} />
    );
  } else if (viewAs === "partner") {
    sidebar = <Sidebar key={viewAs} />;
  }

  return (
    <div>
      <HeaderContainer isAuthenticated />
      <div className="newsmile-container">
        {sidebar}
        <div className="newsmile-container__main">{props.children}</div>
      </div>
    </div>
  );
}

export default AuthAppView;
