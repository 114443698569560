import React from "react";
import { ToggleButton } from "components/ToggleButton";

function CurrencyTag({ title, id, onSelect, selectedValue, ...props }) {
  return (
    <ToggleButton
      name="currency"
      id={id}
      pressed={selectedValue}
      onClick={onSelect}
      data-testid={props["data-testid"]}
    >
      {title}
    </ToggleButton>
  );
}

export default CurrencyTag;
