import React from "react";
import ErrorsControl from "./ErrorsControl";

function Control({ option, name }) {
  const id = React.useId();
  return (
    <>
      <input
        type="radio"
        id={id}
        value={option.value}
        name={name}
        checked={option.checked}
        onChange={option.onChange}
      />
      <label htmlFor={id} className={option.labelClass}>
        {option.label}
      </label>
    </>
  );
}

function QualityControl({
  name,
  isControlGood,
  setControl,
  errorsName,
  errors,
  setErrors,
}) {
  const goodOption = {
    value: true,
    label: "Good Photo",
    labelClass: "thumb-up",
    checked: isControlGood === true,
    onChange: (e) => setControl(e.target.value === "true"),
  };
  const badOption = {
    value: false,
    label: "Bad Photo",
    labelClass: "thumb-down",
    checked: isControlGood === false,
    onChange: (e) => setControl(e.target.value === "true"),
  };

  return (
    <div data-testid="quality-control" className="thumb mt-5">
      <Control name={name} option={goodOption} id="good-control" />
      <ErrorsControl
        open={isControlGood == false}
        name={errorsName}
        errors={errors}
        setSource={setErrors}
      >
        <Control name={name} option={badOption} id="bad-control" />
      </ErrorsControl>
    </div>
  );
}

export default QualityControl;
