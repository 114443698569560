import React, { useState } from "react";
import Continue from "assets/svgs/continue.svg";
import ViewIcon from "resources/img/icons/view.svg";
import { formatLocalisedDate } from "util/format_helpers";
import PageHeader from "../../page_header/PageHeader";
import "./esignature.scss";
import PrintPdfButton from "../print_pdf_button";
import { StyledButton } from "../web_links/StyledButton";
import {
  BodyItem,
  HeaderItem,
  Table,
  TableBody,
  TableCaption,
  TableHeader,
  TableRow,
} from "../web_links/Table";
import { TableAction, TableActions } from "../web_links/table_actions";

export default function ESignatureDetail({ jobResult }) {
  const [imageStatus, setImageStatus] = useState("loading");
  const [showSignature, setShowSignature] = useState(false);
  const {
    documents,
    signature,
    partner_params: partnerParams,
  } = jobResult.e_signature;

  const notice = {
    true: "The user signed this signature.",
    false: "User signature mark is hidden to ensure data Privacy.",
  };

  return (
    <div className="esignature job-result__container new-typography new-styles">
      <section className="header">
        <PageHeader titleClassName="title" title="E-signature details" />
        <PrintPdfButton resultPayload={jobResult} />
      </section>
      <section className="job-info">
        <div className="job-info-item">
          <div className="h2">Job ID</div>
          <span className="h3">{jobResult.smile_job_id}</span>
        </div>
        <div className="job-info-item">
          <div className="h2">User ID</div>
          <span className="h3">{partnerParams.user_id}</span>
        </div>
        <div className="job-info-item">
          <div className="h2">Docs read at</div>
          <span className="h3">
            {formatLocalisedDate(signature.document_read_at, "DD MMM, YYYY")}
          </span>
        </div>
        <div className="job-info-item">
          <div className="h2">Signed at</div>
          <span className="h3">
            {formatLocalisedDate(
              signature?.document_signed_at,
              "DD MMM, YYYY h:mm A",
            )}
          </span>
        </div>
      </section>
      <section className="signature-details">
        <div className="h2">Signer Full Name</div>
        <div className="h1">{signature.name}</div>
        <div className="signature-info-container">
          <div className="signature-container">
            <StyledButton
              variant="ghost"
              className="signature"
              onClick={() => setShowSignature((current) => !current)}
            >
              <SignatureView
                link={signature.link}
                showSignature={showSignature}
                imageStatus={imageStatus}
                setImageStatus={setImageStatus}
              />
            </StyledButton>
            <span className="helper-text">{notice[showSignature]}</span>
          </div>
          <div className="signature-info">
            <div className="job-info-item">
              <div className="h2">Signature type</div>
              <span className="h3">Signed</span>
            </div>
            <div className="job-info-item">
              <div className="h2">Signed at</div>
              <span className="h3">
                {formatLocalisedDate(
                  signature?.document_signed_at,
                  "DD MMM, YYYY h:mm A",
                )}
              </span>
            </div>
          </div>
        </div>
      </section>
      <section className="document-list">
        <Table>
          <TableCaption>{documents?.length} Documents Signed</TableCaption>
          <TableHeader>
            <HeaderItem>#</HeaderItem>
            <HeaderItem>Doc ID</HeaderItem>
            <HeaderItem>Document Name</HeaderItem>
            <HeaderItem>Date Added</HeaderItem>
            <HeaderItem>Actions</HeaderItem>
          </TableHeader>
          <TableBody>
            {documents.map((esignatureDocument, index) => (
              <TableRow key={index}>
                <BodyItem>{index + 1}</BodyItem>
                <BodyItem>{esignatureDocument.id}</BodyItem>
                <BodyItem>{esignatureDocument.name}</BodyItem>
                <BodyItem>
                  {formatLocalisedDate(
                    esignatureDocument.created_at,
                    "DD MMM, YYYY",
                  )}
                </BodyItem>
                <BodyItem>
                  <TableActions>
                    <TableAction
                      label="View"
                      labelClassName="action-buttons"
                      icon={ViewIcon}
                      onClick={() =>
                        window.open(
                          esignatureDocument.link,
                          "_blank",
                          "noreferrer",
                        )
                      }
                    />
                    <TableAction
                      type="link"
                      href={esignatureDocument.link}
                      target="_blank"
                      download={esignatureDocument.name}
                      rel="noopener noreferrer"
                      icon={ViewIcon}
                      label="Download"
                    />
                  </TableActions>
                </BodyItem>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </section>
    </div>
  );
}

function SignatureView({ link, showSignature, imageStatus, setImageStatus }) {
  if (showSignature) {
    return (
      <>
        {imageStatus === "loading" && (
          <div className="loader loading-data-spinner loading-data-spinner--photo" />
        )}
        <img
          src={link}
          alt="signature"
          onLoad={() => setImageStatus("loaded")}
        />
      </>
    );
  }

  return (
    <div className="show-signature">
      <img src={Continue} alt="" />
      Click to reveal Signature
    </div>
  );
}
