import React, { useEffect, useRef, useState } from "react";
import { IMaskInput } from "react-imask";
import { useHistory, Link } from "react-router-dom";
import Continue from "assets/svgs/continue.svg";
import { CURRENCIES, MIN_BALANCE } from "components/auto_pay/constants";
import CurrencyTag from "components/auto_pay/CurrencyTag";
import CustomAutoPayModal from "components/auto_pay/CustomAutoPayModal";
import InputLabel from "components/reusable/web_links/InputLabel";
import { StyledButton } from "components/reusable/web_links/StyledButton";
import moduleStyles from "components/reusable/web_links/text_input.module.css";
import TextInput from "components/reusable/web_links/TextInput";
import WarningIcon from "resources/img/icons/auto-pay-warning.svg";
import TopUpIcon from "resources/img/icons/card-save.svg";
import {
  buildState,
  cardSetup,
  changeAutoPaySetup,
  deletePaymentMethod,
  getCards,
} from "util/api_util";
import {
  css,
  formatCurrency,
  formatLocalisedDate,
  getCurrencySymbol,
} from "util/format_helpers";
import { Toast } from "util/Toast";

import "./auto_pay.scss";
import AutoPayCardSuccessModal from "./AutoPayCardSuccessModal";

function AutoPayModal({ autoPay, setAutoPay, isOpen, onPageChange, getCards }) {
  const history = useHistory();
  const [card, setCard] = useState();

  const queryParams = buildState(history.location.search);
  const pages = ["setup", "cardAdded", "manageCard", "removeCard"];
  const page = queryParams.step || pages[0];

  const onClose = () => {
    history.replace("billing");
  };

  useEffect(() => {
    getCards({ currency: autoPay.designatedCurrency }).then((resp) => {
      if (!resp.error) {
        setCard(resp.payment_methods[0]);
      }
    });
  }, [autoPay.enabled, autoPay.designatedCurrency]);

  const CurrentPage = {
    setup: (
      <AutoPaySetupModal
        autoPay={autoPay}
        setAutoPay={setAutoPay}
        card={card}
        isOpen={isOpen}
        onClose={onClose}
        onPageChange={onPageChange}
      />
    ),
    cardAdded: (
      <AutoPayCardSuccessModal
        onClose={onClose}
        isOpen={isOpen}
        onPageChange={onPageChange}
        setAutoPay={setAutoPay}
      />
    ),
    manageCard: (
      <ManageCardOnFileModal
        card={card}
        onClose={onClose}
        onPageChange={onPageChange}
        autoPay={autoPay}
        setCard={setCard}
        isOpen={isOpen}
        onNext={onPageChange}
        setAutoPay={setAutoPay}
      />
    ),
  }[page];

  if (!isOpen || !CurrentPage || !pages.includes(page)) {
    return null;
  }

  return CurrentPage;
}

AutoPayModal.defaultProps = {
  isOpen: false,
  getCards,
};

function AutoPaySetupModal({
  onClose,
  isOpen,
  autoPay,
  card,
  setAutoPay,
  onPageChange,
}) {
  return (
    <CustomAutoPayModal
      showBackButton={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div
        className="auto-pay-setup-modal split-pair"
        data-align="center"
        style={{ "--gap": "4.5rem" }}
      >
        <SetupForm
          autoPay={autoPay}
          hasCard={!!card}
          setAutoPay={setAutoPay}
          onNext={onPageChange}
        />
        <AutoPayInfo hasCard={!!card} autoPay={autoPay} />
      </div>
    </CustomAutoPayModal>
  );
}
function ManageCardOnFileModal({
  card,
  isOpen,
  setCard,
  setAutoPay,
  onClose,
  autoPay,
  onNext,
}) {
  const history = useHistory();
  const [page, setPage] = useState("manage");

  return {
    manage: (
      <CustomAutoPayModal
        onBackPressed={() => history.goBack()}
        isOpen={isOpen}
        onClose={onClose}
      >
        <div
          className="auto-pay-setup-modal split-pair"
          data-align="center"
          style={{ "--gap": "4.5rem" }}
        >
          <CardOnFile
            card={card}
            autoPay={autoPay}
            onNext={onNext}
            onPageChange={() => setPage("confirm")}
          />
          <AutoPayInfo />
        </div>
      </CustomAutoPayModal>
    ),
    confirm: (
      <AutoPayRemoveCard
        onClose={onClose}
        isOpen={isOpen}
        card={card}
        setCard={setCard}
        onPageChange={() => setPage("manage")}
        setAutoPay={setAutoPay}
      />
    ),
  }[page];
}

function AutoPayRemoveCard({
  onClose,
  setAutoPay,
  onPageChange,
  isOpen,
  card,
  setCard,
  removeCard,
}) {
  const [status, setStatus] = useState("idle");

  const onRemove = async () => {
    setStatus("submitting");
    const res = await removeCard(card.card_id);
    setStatus("idle");
    if (res.error) {
      Toast.fire({ title: res.error });
      return;
    }
    setAutoPay((prev) => ({ ...prev, enabled: false }));
    setCard();
    Toast.fire({ title: "Card removed successfully" });
    onClose();
  };

  return (
    <CustomAutoPayModal
      onBackPressed={onPageChange}
      isOpen={isOpen}
      onClose={onClose}
      containerClassName="small-modal"
    >
      <div className="auto-pay-confirm-remove-card center stack">
        <img src={WarningIcon} alt="" />
        <h2>Are you sure you want to remove this card?</h2>
        <p className="text-center h4">
          Auto-Pay will be disabled if there are no cards configured on for
          Auto-Pay. Also when your wallet balance falls below the required
          amount, you won't be able to process production jobs.
        </p>
        <div>
          <StyledButton
            className="danger-btn h3 justify-center mr-12"
            disabled={status === "submitting"}
            variant="none"
            onClick={onRemove}
          >
            Remove
          </StyledButton>
          <StyledButton
            disabled={status === "submitting"}
            className="btn-white h3 self-flex-right"
            variant="none"
            onClick={onClose}
          >
            Cancel
          </StyledButton>
        </div>
      </div>
    </CustomAutoPayModal>
  );
}
AutoPayRemoveCard.defaultProps = {
  removeCard: deletePaymentMethod,
};

function SetupForm({
  autoPay,
  setAutoPay,
  hasCard,
  cardSetup,
  changeAutoPaySetup,
  getCards,
  onNext,
}) {
  const ref = useRef(null);
  const inputRef = useRef(null);
  const convertMinBalance = (
    currency = transientAutoPay.designatedCurrency,
  ) => {
    let convertedAmount =
      parseFloat(autoPay.exchangeRates[currency]) * parseFloat(MIN_BALANCE);
    if (currency !== "USD") {
      // Round to nearest 1000 for non-USD currencies
      // This make it easy to communicate whole numbers to users
      convertedAmount = Math.ceil(convertedAmount);
      convertedAmount /= 1000;
      convertedAmount = Math.round(convertedAmount);
      convertedAmount *= 1000;
    }

    return convertedAmount;
  };

  const [transientAutoPay, setTransientAutoPay] = useState({
    ...autoPay,
    formattedMin: autoPay.minBalance,
  });
  const [formState, setFormState] = useState("idle");
  const [errors, setErrors] = useState({});
  const history = useHistory();

  const hasValidationErrors = Object.values(errors).some((error) => !!error);

  const minAmount = convertMinBalance();
  const currencySymbol = getCurrencySymbol(transientAutoPay.designatedCurrency);

  const validatePayload = (name, value) => {
    let error = "";
    if (name === "minBalance" && value < minAmount) {
      const amount = formatCurrency(
        minAmount,
        transientAutoPay.designatedCurrency,
      );
      error = `Minimum balance should be ${amount} or more`;
    }
    if (name === "payAmount" && value < minAmount) {
      const amount = formatCurrency(
        minAmount,
        transientAutoPay.designatedCurrency,
      );
      error = `Auto-Pay amount balance should be ${amount} or more`;
    }
    if (name === "currency" && !value) {
      error = "Currency is required";
    }

    setErrors((state) => ({ ...state, [name]: error }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { designatedCurrency, minBalance, payAmount } = transientAutoPay;

    setFormState("submitting");

    try {
      if (hasValidationErrors) {
        throw new Error("Please fix the errors and try again");
      }
      const pricingPlanResponse = await changeAutoPaySetup({
        minimum_wallet_balance_usd: minBalance,
        recurring_top_up_amount_usd: payAmount,
        designated_currency: designatedCurrency,
        auto_pay_enabled: hasCard, // We only enable after successful card setup
      });

      if (pricingPlanResponse.error) {
        setFormState("error");
        Toast.fire({ title: pricingPlanResponse.error });
        return;
      }

      const response = await getCards({
        currency: designatedCurrency,
      });

      if (response.payment_methods.length > 0) {
        Toast.fire({ title: "Auto-Pay updated successfully" });
        setFormState("idle");
        setAutoPay({ ...transientAutoPay, enabled: true });
        history.goBack();
        return;
      }

      redirectToPaymentProvider(designatedCurrency);
    } catch (error) {
      console.error(error);
      Toast.fire({
        title: error.message || "Auto-Pay setup failed, please try again",
      });
      setFormState("error");
    }
  };

  const redirectToPaymentProvider = async (currency) => {
    // Show dlocal for other currencies
    try {
      const response = await cardSetup({
        currency,
      });
      if (response.dlocal_sf_key) {
        onNext({ dlocalKey: response.dlocal_sf_key, currency });
        return;
      }

      if ((!response.redirect_url && response.has_redirect) || response.error) {
        setFormState("error");
        Toast.fire({ title: "Auto-Pay setup failed, please try again" });
        return;
      }

      Toast.fire({ title: "You will be redirected to continue card setup" });
      window.location = response.redirect_url;
    } catch (error) {
      setFormState("error");
      Toast.fire({ title: "Auto-Pay setup failed, please try again" });
    }
  };

  const handleChange = (name, value) => {
    setTransientAutoPay((state) => ({
      ...state,
      [name]: value,
    }));

    validatePayload(name, value);
  };

  useEffect(() => {
    validatePayload("minBalance", transientAutoPay.minBalance);
    validatePayload("payAmount", transientAutoPay.payAmount);
  }, [transientAutoPay.designatedCurrency]);

  const handleCurrencyChange = (value) => {
    setTransientAutoPay((state) => {
      const newState = {
        ...state,
        designatedCurrency: value,
      };
      return newState;
    });
  };

  return (
    <form onSubmit={handleSubmit} className="legacy setup-form stack">
      <p className="h1 setup-title">Set up Auto-pay</p>
      <div>
        <InputLabel label="Select Currency" />
      </div>
      <div className="currency-tags-container">
        {autoPay.enabledCurrencies?.map((currency) => (
          <CurrencyTag
            key={currency}
            selectedValue={transientAutoPay.designatedCurrency === currency}
            id={currency}
            value={currency}
            title={`${currency} - ${CURRENCIES[currency].name}`}
            onSelect={() => handleCurrencyChange(currency)}
          />
        ))}
      </div>
      <InputLabel label="Minimum balance" />
      <IMaskInput
        mask={`${currencySymbol} d`}
        radix="."
        className="text-input"
        blocks={{
          d: {
            mask: Number,
            scale: 2,
            signed: true,
            thousandsSeparator: ",",
            padFractionalZeros: true,
            normalizeZeros: false,
            radix: ".",
          },
        }}
        value={transientAutoPay.minBalance}
        unmask
        ref={ref}
        inputRef={inputRef}
        onAccept={(value) => handleChange("minBalance", value)}
      />
      <span className={css(moduleStyles.error, "error")}>
        {errors.minBalance}
      </span>

      <InputLabel label="Auto-Pay amount" />
      <IMaskInput
        mask={`${currencySymbol} d`}
        radix="."
        className="text-input"
        blocks={{
          d: {
            mask: Number,
            scale: 2,
            signed: true,
            thousandsSeparator: ",",
            padFractionalZeros: true,
            normalizeZeros: false,
            radix: ".",
          },
        }}
        value={transientAutoPay.payAmount}
        unmask
        ref={ref}
        inputRef={inputRef}
        onAccept={(value) => handleChange("payAmount", value)}
      />
      <span className={css(moduleStyles.error, "error")}>
        {errors.payAmount}
      </span>

      <StyledButton
        className="form-button self-flex-end"
        variant="primary"
        disabled={formState === "submitting" || hasValidationErrors}
      >
        <img src={Continue} alt="" />
        <span>Continue</span>
      </StyledButton>
    </form>
  );
}

SetupForm.defaultProps = {
  cardSetup,
  changeAutoPaySetup,
  getCards,
  hasCard: false,
};

function CardOnFile({ card, autoPay, onNext, onPageChange, cardSetup }) {
  const [formState, setFormState] = useState("idle");

  const replaceCard = async () => {
    // Show dlocal for other currencies
    try {
      const response = await cardSetup({
        currency: autoPay.designatedCurrency,
        replace_card: true,
        id: card.id,
      });

      if ((!response.redirect_url && response.has_redirect) || response.error) {
        setFormState("error");
        Toast.fire({ title: "Auto-Pay setup failed, please try again" });
        return;
      }

      if (response.dlocal_sf_key) {
        onNext({
          dlocalKey: response.dlocal_sf_key,
          currency: autoPay.designatedCurrency,
        });
        return;
      }

      Toast.fire({ title: "You will be redirected to continue card setup" });
      window.location = response.redirect_url;
    } catch (error) {
      setFormState("error");
      Toast.fire({ title: "Auto-Pay setup failed, please try again" });
    }
  };

  return (
    <div className="stack">
      <p className="h1 setup-title">This is your card on file</p>
      <p className="h2">
        This card would be used for Auto-Pay. You will get a notification before
        each payment
      </p>
      <TextInput
        label="Card number"
        value={`**** **** **** ${card.last_4}`}
        disabled
        wrapperClassName="card-input"
        name="minBalance"
      />
      <TextInput
        styles={{ wrapperStyle: { marginTop: "2rem" } }}
        label="Expiry"
        disabled
        wrapperClassName="card-expiry-input"
        value={formatLocalisedDate(card.expiration, "MM/YY")}
        name="payAmount"
      />

      <div>
        <StyledButton
          className="danger-btn h3 mr-12"
          variant="none"
          onClick={onPageChange}
          disabled={formState === "submitting"}
        >
          Remove
        </StyledButton>

        <StyledButton
          className="btn-white h3"
          variant="outline"
          disabled={formState === "submitting"}
          onClick={replaceCard}
        >
          Add New
        </StyledButton>
      </div>
    </div>
  );
}

CardOnFile.defaultProps = {
  cardSetup,
};

function AutoPayInfo({ hasCard }) {
  return (
    <div className="auto-pay-info stack center">
      <div className="box">
        <p className="h1">Auto-Pay</p>
        <p className="h4 space:zero color-green">Never run out of credits</p>
        <ul className="auto-pay-info__cards value-prop-list stack">
          <AutoPayInfoCard
            title="Minimum Balance"
            description="Your card on file will get charged automatically when your wallet balance reaches this amount."
          />
          <AutoPayInfoCard
            title="Auto-Pay Amount"
            description="This is the amount you will get charged when your wallet reaches the minimum balance."
          />
        </ul>
      </div>
      {hasCard && (
        <Link to="?mode=auto-pay&step=manageCard">Manage card on file</Link>
      )}
    </div>
  );
}

AutoPayInfo.defaultProps = {
  hasCard: false,
};

function AutoPayInfoCard({ title, description }) {
  return (
    <li className="auto-pay-info-card">
      <img className="auto-pay-info-card__icon" src={TopUpIcon} alt="" />
      <div className="auto-pay-info-card__content stack">
        <h3 className="h3">{title}</h3>
        <p className="h4 color-green">{description}</p>
      </div>
    </li>
  );
}

export default AutoPayModal;
