import React from "react";
import styles from "./product_overview_graph.module.css";

export default function AnalyticsLegend() {
  return (
    <div className={styles.legend_container}>
      <div className={styles.legend}>
        <div className={`${styles.legend_box} ${styles.success_background}`} />
        <div className="mute-note">Pass</div>
      </div>
      <div className={styles.legend}>
        <div className={`${styles.legend_box} ${styles.error_background}`} />
        <div className="mute-note">Error</div>
      </div>
      <div className={styles.legend}>
        <div className={`${styles.legend_box} ${styles.fail_background}`} />
        <div className="mute-note">Fail</div>
      </div>
    </div>
  );
}
