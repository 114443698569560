import React from "react";
import SuccessIcon from "assets/svgs/success.svg";
import { StyledButton } from "components/reusable/web_links/StyledButton";
import CustomAutoPayModal from "./CustomAutoPayModal";

function AutoPayCardSuccessModal({ onClose }) {
  return (
    <CustomAutoPayModal
      isOpen
      onClose={onClose}
      showNav={false}
      containerClassName="small-modal"
    >
      <div className="auto-pay-setup-modal auto-pay-success center stack">
        <img src={SuccessIcon} alt="" />
        <h1>Auto-Pay set up successful</h1>

        <StyledButton
          className="form-button justify-center"
          variant="primary"
          onClick={onClose}
        >
          <span>Done</span>
        </StyledButton>
      </div>
    </CustomAutoPayModal>
  );
}

export default AutoPayCardSuccessModal;
