import React from "react";
import moment from "moment";
import styles from "./enforcement.module.css";
import SourceLink from "./source_link";

function Enforcement({ enforcementActions }) {
  return enforcementActions.map((action) => (
    <div className={styles.container}>
      <div className={styles.rowFlex}>
        <h6>Description</h6>
        <h6>{moment(action.date).format("DD-MMM-YYYY")}</h6>
      </div>
      <div>
        <p>{action.description}</p>
      </div>

      <div className={styles.source}>
        <div className={styles.itemBadge}>
          <p>Source(s)</p>
          <span>{action.source_details.length}</span>
        </div>
        <div className={styles.sourceLinks}>
          {action.source_details.map((source) => (
            <SourceLink name={source.source_name} link={source.source_link} />
          ))}
        </div>
      </div>
    </div>
  ));
}

export default Enforcement;
