import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

interface GetInvitationResponse {
  email: string;
  name: string;
}

interface GetInvitationInput {
  id: string;
}

const getInvitation = async ({ id }: GetInvitationInput) => {
  const { data } = await axios.get(`/invitation?invitation_id=${id}`);
  return data;
};

export const INVITATIONS_QUERY_KEY = "invitations";

export const useInvitation = (
  input: GetInvitationInput,
  options?: UseQueryOptions<
    GetInvitationResponse,
    AxiosError<{ errors: string[] }>
  >,
) =>
  useQuery({
    queryKey: [INVITATIONS_QUERY_KEY, input],
    queryFn: () => getInvitation(input),
    enabled: !!input.id,
    ...options,
  });
