import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { AddressVerification } from "types/api/addressVerification";

interface GetAddressVerificationResult {
  id: number;
}

const getAddressVerificationResult = async ({
  id,
}: GetAddressVerificationResult) => {
  const { data } = await axios.get(
    `/smile_results/address_verifications/${id}`,
  );
  return data;
};

export const USE_ADDRESS_VERIFICATION_KEY = "use_address_verification";

export const useAddressVerificationResult = (
  input: GetAddressVerificationResult,
  options?: UseQueryOptions<
    AddressVerification,
    AxiosError<{ errors: string[]; error: string }>
  >,
) =>
  useQuery({
    queryKey: [USE_ADDRESS_VERIFICATION_KEY, input],
    queryFn: () => getAddressVerificationResult(input),
    enabled: !!input.id,
    ...options,
  });
