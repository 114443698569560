import React from "react";
import LocationPinSvg from "resources/img/icons/location-pin.svg";

type Props = {
  count?: number;
};

const LocationPin = ({ count }: Props) => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null);

  React.useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas && count !== undefined) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      const scale = window.devicePixelRatio || 1;

      // Set the canvas size (in pixels) to 32px height and 24px width,
      // multiplied by scale for high-DPI screens
      canvas.width = 24 * scale;
      canvas.height = 32 * scale;

      // Set the CSS size of the canvas
      canvas.style.width = "24px";
      canvas.style.height = "32px";

      // Scale the drawing context to ensure sharp rendering
      ctx?.scale(scale, scale);

      // Load the SVG or image
      const img = new Image();
      img.src = LocationPinSvg;
      if (ctx) {
        img.onload = () => {
          ctx?.drawImage(img, 0, 0, 24, 32);

          ctx.font = "bold 10px Arial";
          ctx.fillStyle = "#fff";
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";

          ctx?.fillText(`${count}`, 12, 16);
        };
      }
    }
  }, [count]);

  return <canvas ref={canvasRef} height={32} width={24} />;
};

export default LocationPin;
