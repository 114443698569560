import React from "react";
import moment from "moment";
import styles from "./adverse_media.module.css";

function Media({ posts }) {
  return posts.map((post) => (
    <div className={styles.container}>
      <div className={styles.source}>
        <div className={styles.sourceDetails}>
          <h6>{post.publisher}</h6>
          <h6>
            {moment(post.date_published).format("DD-MM-YYYY")} |{" "}
            {moment(post.date_published).format("HH:mm")}
          </h6>
        </div>
        <p>{post.title}</p>
        <div className={styles.sourceLink}>
          <p>Click to open source link</p>
          <a target="_blank" rel="noreferrer" href={post.source_link}>
            {post.source_link}
          </a>
        </div>
      </div>
    </div>
  ));
}

export default Media;
