import * as React from "react";
import classNames from "classnames";
import styles from "./aml_results.module.css";

const AmlIndicator = ({ count, label }: { count: number; label: string }) => {
  let indicatorCount = count.toString();
  try {
    const formatter = Intl.NumberFormat("en", { notation: "compact" });
    indicatorCount = formatter.format(count);
  } catch (error) {
    console.error("Error creating number formatter", error);
  }

  return (
    <div className={styles.amlIndicator}>
      <div className={classNames(styles.roundedLabel, "text-sm font-medium")}>
        <span>{indicatorCount || 0}</span>
      </div>
      <span className="font-medium">{label}</span>
    </div>
  );
};

export default AmlIndicator;
