import React, { useState } from "react";

/**
 * Checks to see if a currency string is equal to or above
 * a minimum value.
 * @param {string} amount - currency string.
 * @param {number} minimumValue - value to check against.
 * @returns {boolean} - true if the amount is equal to or above the minimum value.
 */
const isValidAmount = (amount, minimumValue) => {
  try {
    return parseFloat(amount) >= minimumValue;
  } catch (err) {
    return false;
  }
};

export function CurrencyInput({
  id = "currency-field",
  name = "currency-field",
  value,
  minimumValue,
  onChange = () => {},
  disabled = false,
  autoFocus = false,
}) {
  const [v, setValue] = useState(value);
  const [valid, setValid] = useState(true);
  const [entered, setEntered] = useState(false);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    onChange(value);
    setValue(value);
    if (typeof minimumValue === "number") {
      setValid(isValidAmount(value, minimumValue));
    }
  };

  return (
    <label
      htmlFor={id}
      className={`currency-input${disabled ? " disabled" : ""}`}
      style={{
        borderColor: entered && !valid ? "red" : "",
      }}
    >
      <input
        data-current-value={v}
        inputMode="numeric"
        autoFocus={autoFocus}
        disabled={disabled}
        name={name}
        id={id}
        value={v}
        placeholder=""
        onChange={handleChange}
        onInput={() => setEntered(true)}
      />
    </label>
  );
}
