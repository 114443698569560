import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ChartDataLabels,
);
ChartJS.defaults.scales.linear.min = 0;
ChartJS.defaults.font.family = "Nunito"; // , style: 'normal', weight: '600'};

const defaultPadding = {
  top: 10,
  right: 0,
  bottom: 13,
  left: 0,
};
export default function BarChart({
  data,
  showLegend,
  height,
  width,
  type,
  displayYAxis = false,
  displayXAxis = true,
  datalabelOptions,
  extraOptions = {},
  padding = defaultPadding,
  yTicks = {},
  xAxis = {},
  yAxis = {},
  xGrid = {},
  yGrid = {},
}) {
  const indexAxis = type === "horizontal" ? "y" : "x";

  const options = {
    maintainAspectRatio: false,
    animation: {
      duration: 1,
    },
    indexAxis,
    ...extraOptions,
    plugins: {
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            let label = (context.dataset.legend || [])[context.dataIndex] || "";

            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat("en-US").format(context.parsed.y);
            }
            if ((context.dataset.data || [])[context.dataIndex] !== null) {
              label += new Intl.NumberFormat("en-US").format(
                context.dataset.data,
              );
            }
            return context.formattedValue;
          },
        },
      },
      legend: {
        position: "bottom",
        align: "center",
        display: showLegend,
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          font: {
            size: 12,
          },
        },
        padding: {
          left: 300,
        },
      },
      datalabels: {
        font: {
          size: 8,
        },
        ...datalabelOptions,
        formatter: (value, graph) => {
          if (graph.chart.config.options.indexAxis === "x") {
            return (graph?.dataset?.datalabels || [])[graph.dataIndex];
          }
          return new Intl.NumberFormat().format(
            (graph?.dataset?.datalabels || [])[graph.dataIndex],
          );
        },
      },
    },
    responsive: true,
    layout: {
      padding,
    },

    scales: {
      x: {
        grid: {
          display: false,
          drawTicks: false,
          ...xGrid,
        },
        ticks: {
          align: "center",
          maxRotation: 0,
        },
        display: displayXAxis,
        ...xAxis,
      },
      y: {
        display: displayYAxis,
        grid: {
          display: false,
          ...yGrid,
        },
        ticks: {
          display: true,
          ...yTicks,
        },
        ...yAxis,
      },
    },
  };

  return (
    <Bar
      options={options}
      data={data}
      height={height || 260.85}
      width={width || 456}
    />
  );
}
