import React, { Component } from "react";

import Tooltip from "components/reusable/tooltip";
import { getEnvironment } from "contexts/displayEnvironment";
import { userHasPermission } from "util/route_util";
import { formatDuration } from "util/selectors";
import styles from "./job_and_user_list_table.module.css";
import { TableV2 } from "./tableV2";

class JobAndUserListTable extends Component {
  constructor(props) {
    super(props);
    this.redirect = this.redirect.bind(this);
  }

  openNewTab(url) {
    window.open(url, "_blank", "noreferrer");
  }

  redirect(el) {
    const environment = getEnvironment();
    if (el.enrollee_id !== "No partner_uid on file") {
      if (this.props.pageName === "user_list") {
        if (this.props.userType !== "admin") {
          this.openNewTab(`/partner/kyc/${environment}/${el.partner_uid}`);
        } else {
          this.openNewTab(
            `/admin/${this.props.partnerId}/kyc/${environment}/${el.partner_uid}`,
          );
        }
      } else if (this.props.userType !== "admin") {
        this.openNewTab(
          `/partner/job_results/${environment}/${el.smile_job_id}`,
        );
      } else {
        this.openNewTab(
          `/admin/${this.props.partnerId}/job_results/${environment}/${el.smile_job_id}`,
        );
      }
    }
  }

  getPaginationProps() {
    const {
      activePage,
      totalPages,
      resultCount,
      pageChange,
      resultCountChange,
    } = this.props;
    return {
      activePage,
      totalPages,
      itemCount: resultCount,
      onPageChange: (value) => pageChange(value),
      onItemCountChange: (value) => resultCountChange(value),
    };
  }

  render() {
    const permissionGranted = userHasPermission([
      "selfie read",
      "id_card read",
    ]);
    let timeZone = "";
    const dateComponents = new Date()
      .toLocaleString(undefined, { timeZoneName: "short" })
      .split(" ");
    timeZone = dateComponents[dateComponents.length - 1];

    if (this.props.pageName === "job_list") {
      const columns = [
        { field: "smile_job_id", title: "Job ID" },
        { field: "enrollee_id", title: "User ID" },
        { field: "sdk", title: "Source" },
        { field: "job_created_at", title: "Date", sortBy: "created_at" },
        {
          field: "timestamp",
          title: `Timestamp (${timeZone})`,
          sortBy: "created_at",
        },
        {
          field: "duration",
          title: "Job Time",
          tooltip:
            "Job processing includes waiting for file upload, server processing, and possible human review.",
        },
        {
          field: "product",
          title: "Product",
          render: (data) => (
            <>
              <div>{data.product || data.job_type}</div>
              <div className="job-table__job-type-number">{`Job Type ${data.job_type_number}`}</div>
            </>
          ),
        },
        {
          field: "id_type",
          title: "ID Type",
          render: (data) => data.id_type ?? "-",
        },
        {
          field: "country",
          title: "Country",
        },
        { field: "job_result", title: "Result" },
        { field: "job_message", title: "Message" },
      ];
      const data = this.props.data.map((row) => {
        const { duration } = row;
        const job_created_at = row.created_at.toLocaleString(undefined, {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
        });
        const timestamp = row.created_at.toLocaleString(undefined, {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });
        let { enrollee_id } = row;
        if (/Rejected Enroll/g.exec(row.job_type)) {
          enrollee_id = "No partner_uid on file";
        }

        return {
          ...row,
          enrollee_id,
          timestamp,
          job_created_at,
          duration: formatDuration(duration),
        };
      });
      return (
        <TableV2
          columns={columns}
          data={data}
          sortTableBy={this.props.sortTableBy}
          enableRow={(el) =>
            permissionGranted && !/No partner_uid/g.exec(el.enrollee_id)
          }
          onRowClick={permissionGranted ? (el) => this.redirect(el) : undefined}
          {...this.getPaginationProps()}
        />
      );
    }
    const suspectedFraudTooltip = (
      <div className={styles.suspectedFraud}>
        <span>Suspected Fraud</span>
        <Tooltip data-align="top">
          We can flag users for suspected fraud. Contact us at&nbsp;
          <a href="mailto:info@usesmileid.com">info@usesmileid.com</a> to learn
          more.
        </Tooltip>
      </div>
    );

    const columns = [
      { field: "partner_uid", title: "User ID" },
      {
        field: "suspected_fraud",
        title: this.props.smileSecureEnabled
          ? "Suspected Fraud"
          : suspectedFraudTooltip,
      },
      { field: "last_activity", title: "Last Activity" },
      { field: "activity_count", title: "Activity Count" },
      { field: "aml_status", title: "AML Check Result" },
    ];
    const data = this.props.data.map((el) => {
      const last_activity_date = new Date(el.last_activity).toLocaleString(
        undefined,
        {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
        },
      );
      const timestamp = new Date(el.last_activity).toLocaleString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
      });

      return {
        partner_uid: el.partner_uid,
        activity_count: el.activity_count,
        last_activity: `${last_activity_date} ${timestamp}`,
        suspected_fraud: el.suspected_fraud,
        aml_status: el.aml_status,
      };
    });
    return (
      <TableV2
        columns={columns}
        data={data}
        sortTableBy={this.props.sortTableBy}
        enableRow={() => permissionGranted}
        onRowClick={permissionGranted ? (el) => this.redirect(el) : () => {}}
        {...this.getPaginationProps()}
      />
    );
  }
}

export default JobAndUserListTable;
